import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { createColumnHelper } from '@tanstack/react-table';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { ColumnActionType, ColumnGeneratorInputProps, EventUiModel } from 'core/types';
import { DeleteCss, EditCss } from 'components/table';

export default function Columns(props: ColumnGeneratorInputProps<EventUiModel>): any {
    const { t } = useTranslation();
    let { companyId } = useParams();
    const navigate = useNavigate();
    const columnHelper = createColumnHelper<any>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.eventVenueId, {
            id: 'eventVenueId',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.event_venues_listing_table.headers.id', ''),
        }),
        columnHelper.accessor((row) => row.addressLine1, {
            id: 'addressLine1',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_venues.event_venues_listing_table.headers.address', ''),
        }),
        columnHelper.accessor((row) => row.city, {
            id: 'city',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.event_venues_listing_table.headers.city', ''),
        }),
        columnHelper.accessor((row) => row.postcode, {
            id: 'postcode',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.event_venues_listing_table.headers.post_code', ''),
        }),
        columnHelper.accessor((row) => row.contactEmail, {
            id: 'contactEmail',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.event_venues_listing_table.headers.email', ''),
        }),
        columnHelper.accessor((row) => row.contactPhone, {
            id: 'contactPhone',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.event_venues_listing_table.headers.phone', ''),
        }),
    ];
    columnDefs.push(
        columnHelper.accessor((row) => row.isFeatured, {
            id: 'isFeatured',
            cell: (info) => (
                <>{info.getValue() === true ? <CheckIcon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}</>
            ),
            header: () => t('pages.event_detail.venues.table.headers.is_featured', 'MISSING'),
        }),
    );
    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            style={{ marginRight: 4 }}
                            type='button'
                            onClick={() =>
                                navigate(
                                    `/companies/${companyId}/eventvenues/${props.row.original.eventVenueId}/eventdetails`,
                                )
                            }
                            className={
                                'inline-flex items-center rounded bg-gray-700 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-800 mr-1'
                            }>
                            {t('buttons.details', '')}
                        </button>
                        <button
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                            type='button'
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
