import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { AppNotification } from '../../core/types';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../context/notification-context';

export interface InputProps {
    notification: AppNotification;
}

export default function SuccessSimple(props: InputProps) {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);
    const { clearNotification } = useNotifications();
    const { notification } = props;

    if (notification.timeoutInMilliseconds) {
        setTimeout(() => {
            clearNotification(notification._internalIdentifier!!);
        }, notification.timeoutInMilliseconds!!);
    }

    return (
        <>
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
                show={show}
                as={Fragment}
                enter='transform ease-out duration-300 transition'
                enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                    <div className='p-4'>
                        <div className='flex items-start'>
                            <div className='flex-shrink-0'>
                                <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden='true' />
                            </div>
                            <div className='ml-3 w-0 flex-1 pt-0.5'>
                                <p className='text-sm font-medium text-gray-900'>{notification.title}</p>
                                <p className='mt-1 text-sm text-gray-500'>{notification.description}</p>
                            </div>
                            <div className='ml-4 flex flex-shrink-0'>
                                <button
                                    type='button'
                                    className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
                                    onClick={() => {
                                        clearNotification(notification._internalIdentifier!!);
                                        setShow(false);
                                    }}>
                                    <span className='sr-only'>{t('close_notification')}</span>
                                    <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
}
