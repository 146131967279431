import { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import LoadingPlaceHolder from 'components/loading';
import LoadingError from 'components/loading-error';
import DataTable from 'components/table';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, ExperienceUiModel, NotificationType } from 'core/types';
import { hasManageClaims, keepForNMinutes } from 'core/utils';
import { useClient } from 'core/api-client';

import Columns from './columns';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'context/notification-context';
import Spin from 'components/Spin';
import { deleteBrand, getBrandList } from 'fetch/brands';
import { useConfirm } from 'context/confirmation-context';
import { useAuth } from 'context/auth-context';
import { fetchAuthenticated } from 'fetch/operator/system';
import { useSelectedCompany } from 'context/company-context';

function Experiences() {
    const navigate = useNavigate();
    let { companyId } = useParams();
    const { isConfirmed } = useConfirm();
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [isDeleting, setIsDeleting] = useState(false);
    const location = useLocation();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [data, setData] = useState<ExperienceUiModel[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        id: true,
        description: true,
        url: true,
        dateEffective: true,
        sortOrder: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const handleAdd = () => {
        navigate('./create');
    };
    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: isFetching } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    const {
        data: brandData,
        isLoading: brandDataLoading,
        refetch,
    } = useQuery(
        ['brand-data'],
        () => {
            return getBrandList(companyId as string, client);
        },
        keepForNMinutes(1),
    );

    // delete brand
    async function handleDelete(brandId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteBrand(companyId!!, brandId!!, client);
            if (res === 202 || res === 200) {
                addNotification({
                    title: t('pages.brands.api_messages.status_202'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else if (res === 409) {
                addNotification({
                    title: t('pages.brands.api_messages.status_409'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res === 422) {
                addNotification({
                    title: t('pages.brands.api_messages.status_422'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    title: t('pages.brands.api_messages.status_400_404'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting brand');
        }
    }
    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                navigate(`./${row.brandId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.brandId!! as unknown as string);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('bulk delete!');
        };

        deleteBulkItem();
    };

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Set the data to a format that is easier to use in the UI.
    useEffect(() => {
        if (brandDataLoading) {
            return;
        }

        if (brandData === undefined || !Array.isArray(brandData)) {
            return;
        }

        const uiModel: any[] = brandData?.map((item) => {
            return {
                brandId: item.brandId,
                name: item.name,
                displayTitle: item.displayTitle,
                url: item.urlSlug,
                dateEffective: item.dateEffective,
                sortOrder: item.sortOrder,
                original: item,
            };
        });
        setData(uiModel);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandData, brandDataLoading]);

    if (brandDataLoading) {
        return <LoadingPlaceHolder resourceName='pages.brands.loading_brands' />;
    }

    if (brandData === undefined || brandData.brand === null) {
        return <LoadingError resourceName='pages.brands.loading_brands_error' />;
    }

    return (
        <Spin spinning={isDeleting || isFetching}>
            <TableActionPanel
                setGlobalFilter={setGlobalFilter}
                setColumnFilterOpen={setColumnFilterOpen}
                title='pages.brands.context.title'
                description='pages.brands.context.description'
                actionButtonText='pages.brands.action_button'
                target={HelpContextTarget.Experiences}
                handleActionButton={handleAdd}
            />

            <DataTable<ExperienceUiModel>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.brands.table.headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.brands.table.empty'}
            />
        </Spin>
    );
}

export default Experiences;
