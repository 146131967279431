import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { HelpContextTarget } from 'core/types';

export interface InputProps {
    title: string;
    description: string;
    target: HelpContextTarget;
}

export default function ContextHelp(props: InputProps) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className='content-end mb-4 text-right relative inline-flex'>
                <button
                    onClick={() => setOpen((so) => !so)}
                    type='button'
                    className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <QuestionMarkCircleIcon
                            className='lg:text-gray-400 text-white group-hover:text-gray-300 lg:mr-2 flex-shrink-0 h-5 w-5'
                            aria-hidden='true'
                        />
                        <span className='hidden lg:block'>{t('help_context')}</span>
                    </>
                </button>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(props.title, '')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() => setOpen(false)}>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>{t(props.description, '')}</p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                {/* Replace with your content */}
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    <div
                                                        className='h-full border-2 border-dashed border-gray-200'
                                                        aria-hidden='true'
                                                    />
                                                </div>
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
