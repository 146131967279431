import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActionPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { AllPageSchema, AllPageSchemaByType, EventsSchema, PageSchema, BrandsSchema } from 'core/validation-schema';
import Spin from 'components/Spin';
import PageSection from './form';
import {
    ClearAllEventCache,
    ClearAllBrandCache,
    ClearAllPageCache,
    ClearAllPageCacheByType,
    ClearEvents,
    ClearBrands,
    ClearSinglePageCache,
} from 'fetch/Cache';
import useSoftDirty from 'hooks/useSoftDirty';

const EnumOptions = {
    ALL: 'all',
    SINGLE: 'single',
    ALL_EVENT_CACHE: 'allEventCache',
    ALL_BRAND_CACHE: 'allBrandCache',
    ALL_BY_TYPE: 'allByType',
    CLEAR_EVENT_CACHE: 'clearEventCache',
    CLEAR_BRAND_CACHE: 'clearBrandCache',
};

export default function PageCache() {
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [enabled, setEnabled] = useState(false);
    const { isDirty } = useSoftDirty('pageForm');
    const [selectedOption, setSelectedOption] = useState();

    // Format the form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(
            selectedOption === EnumOptions.SINGLE
                ? PageSchema
                : selectedOption === EnumOptions.ALL_BY_TYPE
                ? AllPageSchemaByType
                : selectedOption === EnumOptions.CLEAR_EVENT_CACHE
                ? EventsSchema
                : selectedOption === EnumOptions.CLEAR_BRAND_CACHE
                ? BrandsSchema
                : AllPageSchema,
        ),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        setProcessing(true);
        let rsp = null;
        if (selectedOption === EnumOptions.ALL) {
            rsp = await ClearAllPageCache('', client);
        } else if (selectedOption === EnumOptions.ALL_EVENT_CACHE) {
            rsp = await ClearAllEventCache('', client);
        } else if (selectedOption === EnumOptions.ALL_BRAND_CACHE) {
            rsp = await ClearAllBrandCache('', client);
        } else if (selectedOption === EnumOptions.ALL_BY_TYPE && data?.pageType) {
            rsp = await ClearAllPageCacheByType(data?.pageType.toLowerCase(), client);
        } else if (selectedOption === EnumOptions.CLEAR_EVENT_CACHE && data?.event) {
            rsp = await ClearEvents(data?.event, client);
        } else if (selectedOption === EnumOptions.CLEAR_BRAND_CACHE && data?.brand) {
            rsp = await ClearBrands(data?.brand, client);
        } else {
            rsp = await ClearSinglePageCache(data.routeId, client);
        }
        if (rsp === 200) {
            let desc = t('pages.page_cache.messages.success_description');
            if (data?.option5 && data?.event) {
                desc = t('pages.page_cache.messages.success_event_cache_description');
            } else if (data?.option6 && !data?.routeId) {
                desc = t('pages.page_cache.messages.success_brand_cache_description');
            } else if (data?.option2 && !data?.routeId) {
                desc = t('pages.page_cache.messages.success_event_cache_description');
            } else if (data?.option3 && !data?.routeId) {
                desc = t('pages.page_cache.messages.success_brand_cache_description');
            } else {
                desc = t('pages.page_cache.messages.success_description');
            }

            addNotification({
                description: desc,
                title: t('pages.page_cache.messages.success_title'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 3000,
            });
            setProcessing(false);
        } else {
            addNotification({
                description: t('pages.page_cache.messages.error_description'),
                title: t('pages.page_cache.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setProcessing(false);
        }
    });

    return (
        <>
            <Spin spinning={processing}>
                <FormActionPanel
                    isDirty={isDirty}
                    processing={processing}
                    handleReset={handleReset}
                    contextTitle={'pages.page_cache.context.title'}
                    contextDescription={'pages.page_cache.context.description'}
                    contextTarget={HelpContextTarget.Users}
                    formTarget='pageForm'
                    buttonText={t('pages.page_cache.action_button')}
                />
                <form onSubmit={onSubmit} noValidate={true} id='pageForm' className='space-y-6'>
                    <PageSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        enabled={enabled}
                        setEnabled={setEnabled}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                </form>
            </Spin>
        </>
    );
}
