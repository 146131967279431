import { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Transition, Dialog, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';

import { NotificationType, EventSubSectionTarget } from 'core/types';
import { EventVenueSchema } from 'core/validation-schema';
import { classNames, keepForOneDay } from 'core/utils';
import { useClient } from 'core/api-client';
import { useNotifications } from 'context/notification-context';
import { fetchLocationData } from 'fetch/location';

import { FieldInputProps, Spacer, TextField } from 'components/form-fields';
import { ErrorPanel } from 'components/action-panel';
import Spin from 'components/Spin';
import { useParams } from 'react-router-dom';
import { createEventVenues, updateEventVenues } from 'fetch/event';

export interface InputProps {
    closeCallback: (target: EventSubSectionTarget) => void;
    open: boolean;
    loading: boolean;
    isEdit: undefined | null | boolean;
    title: string;
    description: string;
    data: any;
    setOpen: any;
    refetch?: any;
}

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    defaultValue?: string;
}

interface SubFormInputProps {
    data: any;
    isEdit: undefined | null | boolean;
    setOpen: any;
    refetch?: any;
}

export default function EventVenueDrawer(props: InputProps) {
    const { t } = useTranslation();
    const { closeCallback, open, title, description, data, loading, isEdit, setOpen, refetch } = props;

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={() => closeCallback(EventSubSectionTarget.Closed)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-4xl'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(title, 'MISSING')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() => closeCallback(EventSubSectionTarget.Closed)}
                                                            data-cy='close'>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>{t(description, 'MISSING')}</p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    <Spin spinning={loading}>
                                                        <EventVenueForm
                                                            refetch={refetch}
                                                            setOpen={setOpen}
                                                            isEdit={isEdit}
                                                            data={data}
                                                        />
                                                    </Spin>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function EventVenueForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const { companyId, eventId } = useParams();
    const client = useClient();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { data, isEdit, setOpen, refetch } = props;
    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventVenueSchema),
        mode: 'onSubmit',
    });

    const { data: locationsData, isLoading: locationsIsLoading } = useQuery(
        ['locations-data'],
        () => {
            return fetchLocationData(client);
        },
        keepForOneDay(),
    );

    const onSubmit = handleSubmit(async (values) => {
        const countryId = process.env.REACT_APP_COUNTRY_ID;
        values.address.countryId = parseInt(countryId as string);
        values.address.addressId = data?.address?.addressId;
        values.longitude = parseInt(values.longitude);
        values.latitude = parseInt(values.latitude);
        values.sortOrder = 0;
        values.eventId = eventId;
        values.eventVenueId = isEdit ? parseInt(data?.eventVenueId) : 0;
        setProcessing(true);
        setIsFormError(false);
        let newData = {
            ...values,
            isFeatured: enabled,
        };
        if (isEdit) {
            setIsLoading(true);
            try {
                const rsp = await updateEventVenues(companyId!!, newData, client);
                if (rsp?.traceId) {
                    setIsLoading(false);
                    setProcessing(false);
                    addNotification({
                        description: t('pages.event_detail.venues.update_error_description'),
                        title: t('pages.event_detail.venues.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 2000,
                    });
                } else {
                    setIsLoading(false);
                    setProcessing(false);
                    addNotification({
                        description: t('pages.event_detail.venues.success_description'),
                        title: t('pages.event_detail.venues.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 2000,
                    });
                    reset();
                    setOpen(false);
                    refetch();
                }
            } catch (error) {
                reset();
                setIsLoading(false);
                setProcessing(false);
                console.log('error while updating event venue');
            }
        } else {
            setIsLoading(true);
            try {
                const rsp = await createEventVenues(companyId!!, newData, client);
                if (rsp?.traceId) {
                    setIsLoading(false);
                    setProcessing(false);
                    addNotification({
                        description: t('pages.event_detail.venues.save_error_description'),
                        title: t('pages.event_detail.venues.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 2000,
                    });
                } else {
                    setIsLoading(false);
                    setProcessing(false);
                    addNotification({
                        description: t('pages.event_detail.venues.success_description'),
                        title: t('pages.event_detail.venues.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 2000,
                    });
                    reset();
                    setOpen(false);
                    refetch();
                }
            } catch (error) {
                setIsLoading(false);
                setProcessing(false);
                console.log('error while creating event venue');
            }
        }
    });

    useEffect(() => {
        // TODO: reset not working, make it functional
        if (isEdit === false || isEdit === null) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    useEffect(() => {
        if (isEdit) {
            setValue('locationId', data?.locationId);
            setValue('longitude', data?.longitude);
            setValue('latitude', data?.latitude);
            setValue('contactEmail', data?.contactEmail);
            setValue('contactPhone', data?.contactPhone);
            setValue('menuUrl', data?.menuUrl);
            setEnabled(data?.isFeatured);
            setValue('address.addressLine1', data?.address?.addressLine1);
            setValue('address.addressLine2', data?.address?.addressLine2);
            setValue('address.addressLine3', data?.address?.addressLine3);
            setValue('address.town', data?.address?.town);
            setValue('address.city', data?.address?.city);
            setValue('address.postcode', data?.address?.postcode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Spin spinning={isLoading}>
            <form onSubmit={onSubmit} noValidate={true} id='eventVenueForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <LocationReferenceField
                        defaultValue={
                            locationsData?.find((item) => item?.locationId === data?.locationId) && data?.locationId
                        }
                        referenceData={locationsData ?? []}
                        referenceType='locationId'
                        showDefault={true}
                        fieldName='locationId'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.location'
                        data_cy='locations-dropdown'
                    />
                    <TextField
                        fieldName='longitude'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.longitude'
                        isRequired={true}
                        data_cy='longitude'
                    />

                    <TextField
                        fieldName='latitude'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.latitude'
                        isRequired={true}
                        data_cy='latitude'
                    />

                    <TextField
                        fieldName='contactEmail'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.contact_email'
                        isRequired={true}
                        data_cy='contact-email'
                    />

                    <TextField
                        fieldName='contactPhone'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.contact_phone'
                        isRequired={true}
                        data_cy='contact-phone'
                    />

                    <TextField
                        fieldName='menuUrl'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.menu_url'
                        isRequired={false}
                        data_cy='menu-url'
                    />
                    <div className='grid col-span-3'>
                        <div className='mt-6 flex items-center'>
                            <h1 className='text-base'>
                                {t('pages.event_detail.venues.field_names.is_featured', 'MISSING')}
                            </h1>
                            <Switch
                                style={{ marginLeft: '20px' }}
                                checked={enabled}
                                onChange={setEnabled}
                                className={`${
                                    enabled ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                <span className='sr-only'>Enable notifications</span>
                                <span
                                    className={`${
                                        enabled ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                    </div>

                    <h1 className='col-span-6'>{t('pages.event_detail.venues.field_names.address')}</h1>
                    <TextField
                        fieldName='address.addressLine1'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_1'
                        isRequired={true}
                        data_cy='address-line-1'
                    />
                    <TextField
                        fieldName='address.addressLine2'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_2'
                        isRequired={false}
                        data_cy='address-line-2'
                    />
                    <TextField
                        fieldName='address.addressLine3'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_3'
                        isRequired={false}
                        data_cy='address-line-3'
                    />
                    <TextField
                        fieldName='address.town'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.town'
                        isRequired={true}
                        data_cy='address-town'
                    />
                    <TextField
                        fieldName='address.city'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.city'
                        isRequired={true}
                        data_cy='address-city'
                    />
                    <TextField
                        fieldName='address.postcode'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.postcode'
                        isRequired={true}
                        data_cy='address-postcode'
                    />

                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='eventVenueForm'
                            disabled={processing}
                            className={classNames(
                                'mb-4 inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_detail.button.saving_changes')
                                : t('pages.event_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </Spin>
    );
}

function LocationReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [value, setValue]: any = useState();

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue]);

    return (
        <>
            <div className='col-span-6 lg:col-span-6'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
                <select
                    {...props.register(props.fieldName, {
                        onChange: (val) => {
                            setValue(val.target.value);
                        },
                    })}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    value={value}
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && !value && (
                        <option key={`${props.referenceType}-default`} value={''}>
                            {t('default_select')}
                        </option>
                    )}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item.name}-${index}`} value={item.locationId}>
                            {t(`referenceData.${props.referenceType}.${item.id}`, `${item.name}`)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
