import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldInputProps } from 'components/form-fields';
import { classNames, getSortedData, keepForOneDay } from 'core/utils';
import { useQuery } from 'react-query';
import { getEventList, getPageRoutes, getBrandList } from 'fetch/company';
import { useClient } from 'core/api-client';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    enabled: boolean;
    setEnabled: any;
    setSelectedOption?: any;
    selectedOption?: any;
}

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    errors?: any;
}

export default function PageSection(props: InputProps) {
    const { t } = useTranslation();
    const client = useClient();

    const handleOptionChange = (value: string) => {
        props.setSelectedOption(value);
        props.setValue('pageType', '');
        props.setValue('routeId', '');
        props.setValue('event', '');
        props.setValue('brand', '');
    };

    const { data: pagesRoutesData } = useQuery(
        ['pages-routes-data'],
        () => {
            return getPageRoutes(client);
        },
        keepForOneDay(),
    );

    const { data: eventList } = useQuery(
        ['event-list'],
        () => {
            return getEventList(client);
        },
        keepForOneDay(),
    );

    const { data: brandList } = useQuery(
        ['brand-list'],
        () => {
            return getBrandList(client);
        },
        keepForOneDay(),
    );

    const _data = ['Generic', 'City', 'City-Experience', 'Event', 'Experience', 'Brand'];

    return (
        <>
            <div className=' bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.page_cache.context.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.page_cache.context.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <RadioButtonGroup
                            selectedOption={props.selectedOption}
                            handleOptionChange={handleOptionChange}
                            register={props.register}
                            errors={props.errors}
                            data_cy='radioButton'
                        />
                        {props.selectedOption === t('single', '') && (
                            <CategoryReferenceField
                                referenceData={getSortedData(pagesRoutesData) ?? []}
                                referenceType='routeId'
                                showDefault={true}
                                fieldName='routeId'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='routes_select_label'
                                data_cy='menuCategoryId'
                                errors={props.errors}
                            />
                        )}
                        {props.selectedOption === t('allByType', '') && (
                            <CategoryReferenceField
                                referenceData={getSortedData(_data) ?? []}
                                referenceType='pageType'
                                showDefault={true}
                                fieldName='pageType'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='page_types_label'
                                data_cy='pageType'
                                errors={props.errors}
                            />
                        )}
                        {props.selectedOption === t('clearEventCache', '') && (
                            <CategoryReferenceField
                                referenceData={getSortedData(eventList) ?? []}
                                referenceType='event'
                                showDefault={true}
                                fieldName='event'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='event_cache_label'
                                data_cy='event'
                                errors={props.errors}
                            />
                        )}
                        {props.selectedOption === t('clearBrandCache', '') && (
                            <CategoryReferenceField
                                referenceData={getSortedData(brandList) ?? []}
                                referenceType='brand'
                                showDefault={true}
                                fieldName='brand'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='brand_cache_label'
                                data_cy='brand'
                                errors={props.errors}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const RadioButtonGroup = (props: any) => {
    const { t } = useTranslation();
    return (
        <div className='mt-[15px] flex space-y-4 flex-col'>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('single', '')}
                    {...props.register('option', {
                        onChange: () => {
                            props.handleOptionChange(t('single', ''));
                        },
                    })}
                    checked={props.selectedOption === t('single', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />
                <span className='ml-2 text-gray-700'> {t('buttons.clear_single_page_cache', '')} </span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('all', '')}
                    {...props.register('option1', {
                        onChange: () => {
                            props.handleOptionChange(t('all', ''));
                        },
                    })}
                    checked={props.selectedOption === t('all', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />
                <span className='ml-2 text-gray-700'>{t('buttons.clear_all_pages_cache', '')}</span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('all_event_cache', '')}
                    {...props.register('option2', {
                        onChange: () => {
                            props.handleOptionChange(t('all_event_cache', ''));
                        },
                    })}
                    checked={props.selectedOption === t('all_event_cache', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />

                <span className='ml-2 text-gray-700'>{t('buttons.clear_all_event_cache', '')}</span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('all_brand_cache', '')}
                    {...props.register('option3', {
                        onChange: () => {
                            props.handleOptionChange(t('all_brand_cache', ''));
                        },
                    })}
                    checked={props.selectedOption === t('all_brand_cache', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />

                <span className='ml-2 text-gray-700'>{t('buttons.clear_all_brand_cache', '')}</span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('allByType', '')}
                    {...props.register('option4', {
                        onChange: () => {
                            props.handleOptionChange(t('allByType', ''));
                        },
                    })}
                    checked={props.selectedOption === t('allByType', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />
                <span className='ml-2 text-gray-700'>{t('buttons.clear_all_pages_cache_by_type', '')}</span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('clearEventCache', '')}
                    {...props.register('option5', {
                        onChange: () => {
                            props.handleOptionChange(t('clearEventCache', ''));
                        },
                    })}
                    checked={props.selectedOption === t('clearEventCache', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />
                <span className='ml-2 text-gray-700'>{t('buttons.clear_event_cache', '')}</span>
            </label>
            <label className='inline-flex items-center'>
                <input
                    data_cy='radio'
                    type='radio'
                    value={t('clearBrandCache', '')}
                    {...props.register('option6', {
                        onChange: () => {
                            props.handleOptionChange(t('clearBrandCache', ''));
                        },
                    })}
                    checked={props.selectedOption === t('clearBrandCache', '')}
                    className='form-radio h-5 w-5 text-blue-600'
                />
                <span className='ml-2 text-gray-700'>{t('buttons.clear_brand_cache', '')}</span>
            </label>
        </div>
    );
};

function CategoryReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-6 lg:col-span-6 w-52'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>
                {props.errors?.routeId && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.routeId?.message}
                    </p>
                )}
                {props.errors?.pageType && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.pageType?.message}
                    </p>
                )}
                {props.errors?.event && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.event?.message}
                    </p>
                )}
                {props.errors?.brand && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.brand?.message}
                    </p>
                )}
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && <option value=''>Select an option</option>}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item}-${index}`} value={item}>
                            {t(`referenceData.${props.referenceType}.${item}`, `${item}`)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
