import { OPERATOR_API_URL } from 'core/api-client';
import { ExperienceAdminDto, ExperienceRequest, ExperienceResponseModel } from 'api/operator-api-types';
import { ClientApiOptions } from 'core/types';

export const deleteExperience = async (
    id: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/experience/${id}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const fetchExperienceData = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<ExperienceResponseModel>,
) => {
    const defaultReturn: ExperienceResponseModel = ExperienceResponseModel.fromJS({
        experience: new ExperienceAdminDto(),
    });

    return client(`${OPERATOR_API_URL}/api/v1/experience/all`, {})
        .then((rsp) => rsp)
        .catch(() => defaultReturn);
};

export const addExperience = async (
    data: ExperienceRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/experience`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateExperience = async (
    experienceId: string,
    data: ExperienceRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/experience/${experienceId}`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getExperience = async (
    experienceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/experience/${experienceId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getAllExperiences = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<ExperienceAdminDto[] | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/experience/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
