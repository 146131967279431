import { useTranslation } from 'react-i18next';
import DataTable, { DeleteCss } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps, CompanyCommissionSubSectionTarget } from 'core/types';
import { useEffect, useState } from 'react';
import { formattedDate, timer } from 'core/utils';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useConfirm } from 'context/confirmation-context';
import { CompanyCommissionDto } from 'api/operator-api-types';

export interface InputProps {
    data: CompanyCommissionDto[] | null;
    commissions: CompanyCommissionDto[] | null;
    addCallback: (target: CompanyCommissionSubSectionTarget) => void;
    deleteFromCommissions: (commissionId: string) => void;
}

export default function CommissionSection(props: InputProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<CompanyCommissionDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();
    const { data: input, commissions, addCallback, deleteFromCommissions } = props;

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
    }, [input, commissions]);

    const handleDelete = (row: any, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                if (row.commissionId != null) {
                    deleteFromCommissions(row.commissionId);
                }
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    const CommissionColumns = (props: ColumnGeneratorInputProps<any>): any => {
        const { t } = useTranslation();
        const columnHelper = createColumnHelper<any>();
        // unwind props.
        const { selectSingleAction } = props;
        let columnDefs = [];

        columnDefs.push(
            columnHelper.accessor((row) => row.commissionPercentage, {
                id: 'commission_percentage',
                cell: (info) => <>{info.getValue()}</>,
                header: () => t('pages.company_detail.detail.field_names.commission_percentage', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.sortOrder, {
                id: 'sortOrder',
                cell: (info) => <>{info.getValue()}</>,
                header: () => t('pages.menus.field_names.sort_order', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.startDate, {
                id: 'startDate',
                cell: (info) => (
                    <div>
                        <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                            {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                        </span>
                    </div>
                ),
                header: () => t('pages.company_reports.dates.start', ''),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.endDate, {
                id: 'endDate',
                cell: (info) => (
                    <div>
                        <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                            {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                        </span>
                    </div>
                ),
                header: () => t('pages.company_reports.dates.end', ''),
            }),
        );

        if (selectSingleAction !== undefined) {
            columnDefs.push(
                columnHelper.display({
                    id: 'actions',
                    cell: (props) => (
                        <button
                            type='button'
                            onClick={() =>
                                selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)
                            }
                            className={DeleteCss()}
                            data-cy='delete'>
                            {t('buttons.delete', '')}
                        </button>
                    ),
                    enableHiding: false,
                    enableSorting: false,
                    enableMultiSort: false,
                    enableColumnFilter: false,
                    enableGlobalFilter: false,
                }),
            );
        }

        return columnDefs;
    };

    const sortedDate = data?.sort((a: any, b: any) => a?.sortOrder - b.sortOrder);

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.company_reports.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.company_reports.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(CompanyCommissionSubSectionTarget.Commission)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<any>
                                columnTranslationPrefix={'pages.company_reports.commission_table.headers'}
                                columns={CommissionColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={sortedDate}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.company_reports.commission_table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
