import { ChevronDownIcon, ChevronUpIcon, MinusIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnGeneratorInputProps, EventUiModel } from 'core/types';
import { DeleteCss, EditCss, IndeterminateCheckbox } from 'components/table';
import { formattedDate } from 'core/utils';
import { useNavigate, useParams } from 'react-router-dom';

export default function Columns(props: ColumnGeneratorInputProps<EventUiModel>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<EventUiModel>();
    const navigate = useNavigate();
    const { companyId } = useParams();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.display({
            id: 'expander',
            header: () => null,
            cell: ({ row }) => {
                return (
                    <>
                        {row.getCanExpand() ? (
                            <button
                                className='mt-2'
                                {...{
                                    onClick: row.getToggleExpandedHandler(),
                                    style: { cursor: 'pointer' },
                                }}>
                                {row.getIsExpanded() ? (
                                    <ChevronUpIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                ) : (
                                    <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                )}
                            </button>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
            enableHiding: false,
            enableSorting: false,
            enableMultiSort: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
        }),
        columnHelper.accessor((row) => row.name, {
            id: 'name',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.events.table.headers.name', ''),
        }),
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.events.table.headers.description', ''),
        }),
        columnHelper.accessor((row) => row.url, {
            id: 'url',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.events.table.headers.url', ''),
        }),
        columnHelper.accessor((row) => row.dateEffective, {
            id: 'dateEffective',
            cell: (info) => (
                <>{info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}</>
            ),
            header: () => t('pages.events.table.headers.dateEffective', ''),
        }),
        columnHelper.accessor((row) => row.sortOrder, {
            id: 'sortOrder',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.events.table.headers.sortOrder', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            style={{ marginRight: 4 }}
                            type='button'
                            onClick={() =>
                                navigate(`/companies/${companyId}/events/${props.row.original.eventId}/venues`)
                            }
                            className={
                                'inline-flex items-center rounded bg-gray-700 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-800 mr-1'
                            }>
                            {t('buttons.venues', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 1)}
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 0)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
