import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { VisibilityState } from '@tanstack/react-table';

import { TableActionPanel } from 'components/action-panel';
import { getAllEventVenues, getEventVenueList } from 'fetch/event';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, NotificationType } from 'core/types';
import { hasManageClaims, keepForNMinutes } from 'core/utils';
import { useClient } from 'core/api-client';
import DataTable from 'components/table';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';

import Columns from './columns';
import EventVenueDrawer from './form';
import { useConfirm } from 'context/confirmation-context';
import { deleteVenue } from 'fetch/event/event-venue';
import { useAuth } from 'context/auth-context';
import { fetchAuthenticated } from 'fetch/operator/system';
import { useSelectedCompany } from 'context/company-context';

function EventVenueListing() {
    let { companyId, eventId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isConfirmed } = useConfirm();
    const { addNotification } = useNotifications();
    const [isDeleting, setIsDeleting] = useState(false);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [eventVenueData, setEventVenueData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState<null | undefined | boolean>();

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        displayTitle: true,
        resourceCapacity: true,
        totalResources: true,
        minSize: true,
        maxSize: true,
        eventVenueResourceConfigurationId: true,
    });
    const [open, setOpen] = useState(false);
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const handleAdd = () => {
        setIsEdit(false);
        setOpen((prev) => !prev);
        setEventVenueData(null);
    };

    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: claimsLoading } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    // delete venue
    async function handleDelete(eventVenueId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteVenue(eventVenueId as string, companyId as string, client);
            if (res === 202 || res === 200) {
                addNotification({
                    description: t('common.api_messages.status_202'),
                    title: t('common.api_messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else if (res === 409) {
                addNotification({
                    description: t('common.api_messages.status_409'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res === 422) {
                addNotification({
                    description: t('common.api_messages.status_422'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting location');
        }
    }
    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                setLoading(true);
                setIsEdit(true);
                setOpen((prev) => !prev);
                getAllEventVenues(companyId!!, row.eventVenueId, eventId!!, client)
                    .then((rsp) => {
                        if (rsp?.traceId) {
                            addNotification({
                                description: t('pages.event_venues.messages.list_error_description'),
                                title: t('pages.event_venues.messages.error_title'),
                                style: NotificationType.ErrrorSumple,
                                timeoutInMilliseconds: 3000,
                            });
                            setLoading(false);
                        } else {
                            setEventVenueData(rsp);
                            setLoading(false);
                        }
                    })
                    .catch((error) => console.log('error', error));
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.eventVenueId!!);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    const {
        data: eventVenuesData,
        isLoading,
        refetch,
        isRefetching,
    } = useQuery({
        queryKey: ['eventVenuesListing-data', companyId, eventId],
        queryFn: async () => {
            try {
                const res = await getEventVenueList(companyId as string, eventId as string, client);
                if (res) return res;
            } catch (error) {
                console.log('error in listing', error);
            }
        },
        refetchOnWindowFocus: false,
        ...keepForNMinutes(0),
    });

    // Set the event data to a format that is easier to use in the UI.
    useEffect(() => {
        if (eventVenuesData === undefined || eventVenuesData === null || !Array.isArray(eventVenuesData)) {
            if (!isLoading && eventVenuesData?.traceId) {
                addNotification({
                    description: t('pages.event_venues.messages.list_error_description'),
                    title: t('pages.event_venues.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        } else {
            const uiModel: any[] = eventVenuesData?.map(
                (item: {
                    eventVenueId: any;
                    isFeatured: any;
                    totalResources: any;
                    contactEmail: any;
                    contactPhone: any;
                    address: { addressLine1: any; city: any; postcode: any };
                }) => {
                    return {
                        eventVenueId: item?.eventVenueId,
                        isFeatured: item?.isFeatured,
                        totalResources: item?.totalResources,
                        contactEmail: item?.contactEmail,
                        contactPhone: item?.contactPhone,
                        addressLine1: item?.address?.addressLine1,
                        city: item?.address?.city,
                        postcode: item?.address?.postcode,
                    };
                },
            );
            setData(uiModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventVenuesData, isLoading]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleDrawer = () => {
        setOpen((prev) => !prev);
        setIsEdit(null);
    };

    return (
        <Spin spinning={isLoading || isRefetching || isDeleting || claimsLoading}>
            <span className='relative inline-flex w-full items-start justify-between'>
                <button
                    onClick={() => navigate(`/companies/${companyId}/events`, { state: 'fromVenues' })}
                    type='button'
                    className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                    </>
                </button>

                <TableActionPanel
                    setGlobalFilter={setGlobalFilter}
                    setColumnFilterOpen={setColumnFilterOpen}
                    title='pages.event_venues.event_venues_context.title'
                    description='pages.event_venues.event_venues_context.description'
                    actionButtonText='pages.event_venues.action_button'
                    target={HelpContextTarget.EventVenue}
                    handleActionButton={handleAdd}
                />
            </span>
            <DataTable<any>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.event_venues.event_venues_listing_table.headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.event_venues.event_venues_listing_table.empty'}
            />
            <EventVenueDrawer
                closeCallback={toggleDrawer}
                open={open}
                isEdit={isEdit}
                loading={loading}
                title={'pages.event_detail.venues.title'}
                description={'pages.event_detail.venues.description'}
                data={eventVenueData}
                setOpen={setOpen}
                refetch={refetch}
            />
        </Spin>
    );
}

export default EventVenueListing;
