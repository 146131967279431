import { CompanyDto, LocationImageDto } from 'api/operator-api-types';
import { Dispatch, SetStateAction } from 'react';
import { Location } from 'react-router-dom';
import { LocationDto, ExperienceDto, EventDto } from 'api/operator-api-types';

export type minutes = number;

export interface TimeInMinutes {
    timeInMinutes: number;
    days: number;
    hours: number;
    minutes: number;
}

export interface BaseRefereceModel {
    /** Gets or sets the Id. */
    id: number;
    /** Gets or sets the Name. */
    name: string | null;
    /** Gets or sets the Description. */
    description: string | null;
    /** Gets or sets the SortOrder. */
    sortOrder: number;
    /** Gets or sets the IsDeleted. */
    isDeleted: boolean;
}

export enum ColumnActionType {
    Edit = 1,
    Delete = 2,
}

export interface ColumnGeneratorInputProps<T> {
    selectSingleAction?: (row: T, action: ColumnActionType, index: number) => void;
}

export enum LocationsSubSectionTarget {
    Closed = 0,
    Images = 1,
}

export enum CompanyCommissionSubSectionTarget {
    Closed = 0,
    Commission = 1,
}
export enum EventFundSubSectionTarget {
    Closed = 0,
    Fund = 1,
}

export enum EventSubSectionTarget {
    Closed = 0,
    Experiences = 1,
    Images = 2,
    Venues = 3,
}

export enum EventVenueSubSectionTarget {
    Closed = 0,
    Resource = 1,
    Configuration = 2,
}

export interface ColumnFilterList {
    id: string;
    handler: (event: unknown) => void;
    displayName: string;
}

export interface ImagesUiModel {
    imageId: number;
    displayName: string;
    ranking: number;
    original: LocationImageDto;
}

export interface CalendarInputProperties {
    open: boolean;
    year: number;
    month: number;
    day: number;
    minimum?: Date | undefined;
    maximum?: Date | undefined;
    setOpen: Dispatch<SetStateAction<boolean>>;
    callback: (input: Date) => void;
}

export interface CalendarModeInputProperties {
    activeDisplayDate: Date;
    setActiveDisplayDate: Dispatch<SetStateAction<Date>>;
    setSelectedDate: Dispatch<SetStateAction<Date>>;
    minimum: Date;
    maximum: Date;
    open: boolean;
}

export interface CalendarDateEntry {
    date: string;
    isCurrentMonth: boolean;
    isToday: boolean;
    isSelected: boolean;
    day: number;
    month: number;
    year: number;
    isSelectable: boolean;
}

export interface DateFormats {
    shortDate: string;
    longDate: string;
    longDateTime: string;
    day: number;
    month: number;
    year: number;
    date: Date;
    offset: minutes;
    isToday: boolean;
}

export interface CompanyContextType {
    companyIdentifier: number;
    setCompanyIdentifier: Dispatch<SetStateAction<number>>;
}

export interface AuthContextType {
    user: UserContext | null;
    login: (data: LoginForm, success: VoidFunction, failed: VoidFunction) => void;
    logout: (callback: VoidFunction) => void;
}

export interface NotificationContextType {
    addNotification(notification: AppNotification): void;
    clearNotification(identifier: number): void;
    notifications: AppNotification[];
}

export interface AppNotification {
    style: NotificationType;
    title: string;
    description?: string;
    timeoutInMilliseconds: number | null;
    _internalIdentifier?: number;
}

export enum NotificationType {
    SuccessSimple = 1,
    ErrrorSumple = 2,
}

export interface AuthService {
    getUserContext(): UserContext | null;
    login(data: LoginForm): Promise<UserContext | null>;
    logout: () => void;
}

export interface LoginForm {
    userName: string;
    password: string;
}

export interface UserContext {
    accessToken: string;
    expiresIn: number;
    userId: string;
}

export interface LoginReturn {
    from: Location;
}

export interface ClientApiOptions {
    data?: any;
    token?: string | null;
    headers?: any;
    config?: any;
    methodOverride?: string | null;
}

export interface LocationUiModel {
    locationId: string | undefined;
    name: string | undefined;
    description: string | undefined;
    url: string | undefined;
    dateEffective: Date | undefined;
    sortOrder: number | undefined;
    original: LocationDto;
}
export interface ExperienceUiModel {
    experienceId: string | null;
    name: string | null;
    description: string | null;
    url: string | null;
    dateEffective: Date | null;
    sortOrder: number;
    original: ExperienceDto;
}

export interface EventUiModel {
    eventId: string | undefined;
    name: string | undefined;
    description: string | undefined;
    url: string | undefined;
    dateEffective: Date | undefined;
    sortOrder?: number | undefined;
    original: EventDto;
}

export interface CompanyUiModel {
    companyId: number | null;
    name: string | null;
    displayName: string | null;
    email: string | null;
    phone: Date | null;
}

export interface CompanyUiModel {
    companyId: number | null;
    name: string | null;
    displayName: string | null;
    email: string | null;
    phone: Date | null;
    original: CompanyDto;
}

export interface ColumnFilterList {
    id: string;
    handler: (event: unknown) => void;
    displayName: string;
}

export interface ConfirmActionContextState {
    confirmationTitle: string | null;
    confirmationDescription: string | null;
    confirmButton: string | null;
    cancelButton: string | null;
    keepAlive: boolean;
    _totalTasks: number | null;
    _currentTaskNumber: number | null;
    _accept: () => void;
    _cancel: () => void;
    _isActive: boolean;
}

export interface ConfirmActionContextType {
    state: ConfirmActionContextState;
    setState: Dispatch<SetStateAction<ConfirmActionContextState>>;
}

export interface DateFormats {
    shortDate: string;
    longDate: string;
    longDateTime: string;
    day: number;
    month: number;
    year: number;
    date: Date;
    offset: minutes;
    isToday: boolean;
}

export enum HelpContextTarget {
    CompanyDetail = 1,
    CompnyUsers = 2,
    PaymentProfiles = 3,
    PaymentRule = 4,
    Locations = 5,
    Events = 6,
    Experiences = 7,
    EventVenue = 8,
    Users = 9,
    reports = 10,
    brands = 11,
}

export enum IdentifierType {
    Location = 1,
    Resource = 2,
    Duration = 3,
    Variant = 4,
    Configuration = 5,
    NumberOrCreate = 6,
    GuidOrCreate = 7,
    Company = 8,
    StringOrCreate = 9,
    Event = 10,
    EventVenue = 11,
    Configurations = 12,
    EventVenueConfigurationId = 13,
    Brands = 14,
}

export interface ConfirmActionContextState {
    confirmationTitle: string | null;
    confirmationDescription: string | null;
    confirmButton: string | null;
    cancelButton: string | null;
    keepAlive: boolean;
    _totalTasks: number | null;
    _currentTaskNumber: number | null;
    _accept: () => void;
    _cancel: () => void;
    _isActive: boolean;
}

export interface ConfirmActionContextType {
    state: ConfirmActionContextState;
    setState: Dispatch<SetStateAction<ConfirmActionContextState>>;
}
