import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnGeneratorInputProps } from '../../../core/types';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    let columnDefs = [
        columnHelper.accessor((row) => row.ticketTypeName, {
            id: 'ticketTypeName',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.resource_amount_headers.name', ''),
        }),
        columnHelper.accessor((row) => row.eventAmount, {
            id: 'eventAmount',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.resource_amount_headers.amount', ''),
        }),
        // hide by client ticket/LILI-268 issue-5
        // columnHelper.accessor((row) => row.ticketTypeId, {
        //     id: 'ticketTypeId',
        //     cell: (info) => <>{info.getValue()}</>,
        //     header: () => t('pages.event_venues.resource_config_table.resource_amount_headers.type_id', ''),
        // }),
    ];

    return columnDefs;
}
