import { OPERATOR_API_URL } from 'core/api-client';
import { ClientApiOptions } from 'core/types';

export const getEvents = async (
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/report/company/${companyId}/events`, {
        methodOverride: 'GET',
    })
        .then((rsp) => {
            const newData: any[] = [];
            newData.push({ eventId: 'all', name: 'All' }, ...rsp?.events);
            return newData;
        })
        .catch((rsp) => rsp);
};

export const getLocations = async (
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/report/company/${companyId}/locations`, {
        methodOverride: 'GET',
    })
        .then((rsp) => {
            const newData: any[] = [];
            newData.push({ eventId: 'all', name: 'All' }, ...rsp?.locations);
            return newData;
        })
        .catch((rsp) => rsp);
};

export const getCompanyEventCapacity = async (
    companyId: string,
    startDate: string,
    endDate: string,
    locationId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/report/company/${companyId}/event-capacity?startDate=${startDate}&endDate=${endDate}`;

    if (locationId !== 'all' && locationId !== '') {
        url += `&locationId=${locationId}`;
    }
    if (eventId !== 'all' && eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getCompanyEventSales = async (
    companyId: string,
    startDate: string,
    endDate: string,
    locationId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/report/company/${companyId}/event-sales?startDate=${startDate}&endDate=${endDate}`;

    if (locationId !== 'all' && locationId !== '') {
        url += `&locationId=${locationId}`;
    }
    if (eventId !== 'all' && eventId !== '') {
        url += `&eventId=${eventId}`;
    }
    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.companyEventSales)
        .catch((rsp) => rsp);
};

export const getAllEvenSalesData = async (
    companyId: string,
    eventId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/report/company/${companyId}/events/${eventId}/${eventVenueResourceId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
