import { Transition, Dialog, Combobox } from '@headlessui/react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { ManagedCompanyDto } from 'api/user-api-types';
import { useSelectedCompany } from 'context/company-context';
import { Dispatch, SetStateAction, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface InputProperties {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    companies: ManagedCompanyDto[];
}

function CompanyDialog(props: InputProperties) {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const { setCompanyIdentifier } = useSelectedCompany();
    const { open, companies, setOpen } = props;

    const filteredCompanies =
        query === ''
            ? companies
            : companies.filter((company) => {
                  return company.displayName?.toLowerCase().includes(query.toLowerCase());
              });

    const confirmLocation = (value: any): void => {
        setCompanyIdentifier(value.companyId);
        setOpen(false);
    };

    return (
        <>
            <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')}>
                <Dialog as='div' className='fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20' onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 scale-95'
                        enterTo='opacity-100 scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 scale-100'
                        leaveTo='opacity-0 scale-95'>
                        <Combobox
                            as='div'
                            value=''
                            className='mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'
                            onChange={confirmLocation}>
                            <Combobox.Input
                                className='w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
                                placeholder={t('company_selection.search', '')}
                                onChange={(event) => setQuery(event.target.value)}
                            />

                            {filteredCompanies.length > 0 && (
                                <Combobox.Options
                                    static
                                    className='-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-600'>
                                    {filteredCompanies.map((company) => (
                                        <Combobox.Option
                                            key={company.companyId}
                                            value={company}
                                            className='cursor-default select-none rounded-md px-4 py-2 hover:text-gray-900 hover:bg-gray-50'>
                                            {company.displayName}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            )}

                            {query !== '' && filteredCompanies.length === 0 && (
                                <div className='py-14 px-4 text-center sm:px-14'>
                                    <BriefcaseIcon className='mx-auto h-6 w-6 text-gray-400' aria-hidden='true' />
                                    <p className='mt-4 text-sm text-gray-900'>{t('company_selection.bad_search')}</p>
                                </div>
                            )}
                        </Combobox>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default CompanyDialog;
