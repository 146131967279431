import { OPERATOR_API_URL } from 'core/api-client';
import { ClientApiOptions } from 'core/types';

export const getEvents = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/report/finance/events/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getBrands = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/report/finance/brands/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export const getCompanies = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getAllEvenFundsData = async (
    eventId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/event-detail/${eventId}/${eventVenueResourceId}`;
    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.eventDetail)
        .catch((error) => error);
};

export const addRefund = async (
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/finance/refund`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getReportDataForDownload = async (
    startDate: string,
    endDate: string,
    companyId: string,
    brandId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/report/affiliate?startDate=${startDate}&endDate=${endDate}`;

    if (companyId !== '') {
        url += `&companyId=${companyId}`;
    }
    if (brandId !== '') {
        url += `&brandId=${brandId}`;
    }
    if (eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.reportData)
        .catch((rsp) => rsp);
};

export const getAffiliateReportData = async (
    startDate: string,
    endDate: string,
    companyId: string,
    brandId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/report/affiliate?startDate=${startDate}&endDate=${endDate}`;

    if (companyId !== 'all' && companyId !== '') {
        url += `&companyId=${companyId}`;
    }
    if (brandId !== 'all' && brandId !== '') {
        url += `&brandId=${brandId}`;
    }
    if (eventId !== 'all' && eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.reportData)
        .catch((error) => error);
};

export const getEventRefunds = async (
    startDate: string,
    endDate: string,
    companyId: string,
    brandId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/company/${companyId}/event-refunds?startDate=${startDate}&endDate=${endDate}`;

    if (companyId !== 'all' && companyId !== '') {
        url += `&companyId=${companyId}`;
    }
    if (brandId !== 'all' && brandId !== '') {
        url += `&brandId=${brandId}`;
    }
    if (eventId !== 'all' && eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.companyEventRefunds)
        .catch((error) => error);
};
export const getBrunchReportDataForDownload = async (
    startDate: string,
    endDate: string,
    companyId: string,
    brandId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/report/brunch-club?startDate=${startDate}&endDate=${endDate}`;

    if (companyId !== '') {
        url += `&companyId=${companyId}`;
    }
    if (brandId !== '') {
        url += `&brandId=${brandId}`;
    }
    if (eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.reportData)
        .catch((rsp) => rsp);
};

export const getBrunchReportData = async (
    startDate: string,
    endDate: string,
    companyId: string,
    brandId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    let url = `${OPERATOR_API_URL}/api/v1/finance/report/brunch-club?startDate=${startDate}&endDate=${endDate}`;

    if (companyId !== 'all' && companyId !== '') {
        url += `&companyId=${companyId}`;
    }
    if (brandId !== 'all' && brandId !== '') {
        url += `&brandId=${brandId}`;
    }
    if (eventId !== 'all' && eventId !== '') {
        url += `&eventId=${eventId}`;
    }

    return client(url, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp?.reportData)
        .catch((error) => error);
};
export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
