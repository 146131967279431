import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnGeneratorInputProps } from 'core/types';
import { DeleteCss, EditCss } from 'components/table';
import { formattedDate } from 'core/utils';
import { useNavigate, useParams } from 'react-router-dom';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { companyId } = useParams();
    const columnHelper = createColumnHelper<any>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.menuName, {
            id: 'menuName',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.menus.table.headers.menue_name', ''),
        }),
        columnHelper.accessor((row) => row.displayTitle, {
            id: 'displayTitle',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.menus.table.headers.display_title', ''),
        }),
        columnHelper.accessor((row) => row.dateEffective, {
            id: 'dateEffective',
            cell: (info) => <>{info.getValue()?.split('T')[0]}</>,
            header: () => t('pages.menus.table.headers.date_effective', ''),
        }),
        columnHelper.accessor((row) => row.dateExpires, {
            id: 'dateExpires',
            cell: (info) => <>{info?.getValue() ? info.getValue()?.split('T')[0] : 'N/A'}</>,
            header: () => t('pages.menus.table.headers.date_expire', ''),
        }),
        columnHelper.accessor((row) => row.sortOrder, {
            id: 'sortOrder',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.menus.table.headers.sort_order', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            style={{ marginRight: 4 }}
                            type='button'
                            onClick={() =>
                                navigate(`/companies/${companyId}/menus/${props.row.original.eventMenuId}/products`)
                            }
                            className={
                                'inline-flex items-center rounded bg-gray-700 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-800 mr-1'
                            }>
                            {t('buttons.products', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
