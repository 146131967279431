import React, { useRef, useEffect, useState } from 'react';
import jsQR from 'jsqr';

function QRScanner({ show = true, onRead, onError }: any) {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    let stream: MediaStream | null = null;
    const [streamInitialized, setStreamInitialized] = useState(false);

    useEffect(() => {
        if (!show) {
            return;
        }

        const videoElement = videoRef.current;

        const initCameraStream = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });

                if (videoElement) {
                    videoElement.srcObject = stream;
                    videoElement.play();
                    setStreamInitialized(true);

                    const canvas = document.createElement('canvas');
                    const ctx: any = canvas.getContext('2d');

                    const scanInterval = setInterval(() => {
                        if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
                            canvas.width = videoElement.videoWidth;
                            canvas.height = videoElement.videoHeight;

                            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            const code = jsQR(imageData.data, imageData.width, imageData.height);

                            if (code && onRead) {
                                onRead(code.data);
                                clearInterval(scanInterval);
                            }
                        }
                    }, 500); // Check every 500ms
                }
            } catch (err) {
                if (onError) {
                    onError(err);
                }
            }
        };

        if (!streamInitialized) {
            initCameraStream();
        }
    }, [show, onRead, onError, streamInitialized]);

    // Cleanup effect to close the camera stream when the component unmounts
    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    return (
        <div>
            {show && (
                <video
                    ref={videoRef}
                    style={{
                        width: 500,
                        height: 500,
                        aspectRatio: '1', // Maintain a 1:1 aspect ratio (height will adjust accordingly)
                        margin: 'auto',
                    }}
                />
            )}
        </div>
    );
}

export default QRScanner;
