import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
    deleteConfiguration,
    eventVenueResourceUpdate,
    getEventTicketsData,
    getEventVenueResource,
    getEventVenueResources,
    getFoodOrderingData,
} from 'fetch/event';

import DataTable from 'components/table';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, NotificationType } from 'core/types';
import {
    classNames,
    downloadTicketsCSV,
    downloadFoodOrderCSV,
    keepForNMinutes,
    keepForOneDay,
    filterExpiredItems,
    hasManageClaims,
} from 'core/utils';
import { useClient } from 'core/api-client';
import Columns from './columns';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';
import { getBookingFeeTypes, getMenus } from 'fetch/company';
import { FieldInputProps, SingleDateSelectionField, TextField, TimePickerField } from 'components/form-fields';
import { useForm } from 'react-hook-form';
import { getEventVenueResourceMenu } from 'fetch/event/event-venue';
import { EventVenueResourceConfigurationAdminDto } from 'api/operator-api-types';
import { Switch } from '@headlessui/react';
import { filterEventsByDate, getTimeFromDate, isValidTimePeriod, mergeDateAndTime } from '../helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { EventVenueResourceSchema } from 'core/validation-schema';
import { useConfirm } from 'context/confirmation-context';
import { toTimeString } from '../manage/venue-resource-section';
import { useAuth } from 'context/auth-context';
import { useSelectedCompany } from 'context/company-context';
import { fetchAuthenticated } from 'fetch/operator/system';

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    defaultValue?: string;
}

interface SubFormInputProps {
    addConfiguration?: (configuration: EventVenueResourceConfigurationAdminDto) => void;
    eventMenusData?: any;
    eventMenuResourceMenu?: any;
    eventVenueResourceData: any;
    eventVenueId?: any;
    companyId?: any;
    eventVenueResourceId?: any;
    bookingFeeTypes: any;
}

function ResourceConfigurationList() {
    let { companyId, identifier, eventVenueResourceId } = useParams();

    const { t } = useTranslation();
    const { isConfirmed } = useConfirm();
    const { addNotification } = useNotifications();
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        displayTitle: true,
        resourceCapacity: true,
        totalResources: true,
        minSize: true,
        maxSize: true,
        eventVenueResourceConfigurationId: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: claimsLoading } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    const handleAdd = () => {
        navigate('./create');
    };
    // delete configuration
    async function handleDelete(eventVenueResourceConfigurationId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteConfiguration(
                eventVenueResourceConfigurationId as string,
                identifier!!,
                companyId!!,
                client,
            );
            if (res === 202 || res === 200) {
                addNotification({
                    description: t('common.api_messages.status_202'),
                    title: t('common.api_messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else if (res === 409) {
                addNotification({
                    description: t('common.api_messages.status_409'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res === 422) {
                addNotification({
                    description: t('common.api_messages.status_422'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting location');
        }
    }

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                // navigate(`./${row.eventVenueId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.eventVenueResourceConfigurationId!!);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    const {
        data: eventVenuesResourceData,
        refetch,
        isLoading,
        isRefetching,
    } = useQuery(
        ['eventVenuesResource-data', companyId, eventVenueResourceId],
        () => {
            const res = getEventVenueResources(companyId as string, eventVenueResourceId as string, client);
            if (res) return res;
        },
        keepForNMinutes(0),
    );

    const { data: eventVenueResourceData, isLoading: loading } = useQuery(
        ['event-venue-resource-data', identifier, eventVenueResourceId],
        () => {
            const res = getEventVenueResource(identifier as string, eventVenueResourceId as string, client);
            if (res) return res;
        },
        keepForNMinutes(0),
    );

    // Set the event data to a format that is easier to use in the UI.
    useEffect(() => {
        if (
            eventVenuesResourceData === undefined ||
            !Array.isArray(eventVenuesResourceData) ||
            eventVenuesResourceData === null
        ) {
            if (!isLoading && !eventVenuesResourceData) {
                addNotification({
                    description: t('pages.event_venues.messages.resource_list_error_description'),
                    title: t('pages.event_venues.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        } else {
            const uiModel: any[] = eventVenuesResourceData?.map((item) => {
                return {
                    displayTitle: item?.displayTitle,
                    resourceCapacity: item?.resourceCapacity,
                    totalResources: item?.totalResources,
                    minSize: item?.minSize,
                    maxSize: item?.maxSize,
                    eventVenueResourceConfigurationId: item?.eventVenueResourceConfigurationId,
                    eventVenueResourceId: item?.eventVenueResourceId,
                };
            });
            setData(uiModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventVenuesResourceData, isLoading]);

    const { data: eventMenusData, isLoading: eventMenusDataLoading } = useQuery(
        ['event-menus-data'],
        () => {
            return getMenus(companyId as string, client);
        },
        keepForNMinutes(1),
    );
    const { data: bookingFeeTypes } = useQuery(
        ['booking-fee-types'],
        () => {
            return getBookingFeeTypes(client);
        },
        keepForOneDay(),
    );

    const {
        data: eventMenuResourceMenu,
        isLoading: eventMenuResourceLoading,
        isRefetching: isReFetchEventMenuResourceMenu,
    } = useQuery(
        ['event-menu-resource-menu', identifier, eventVenueResourceId],
        () => {
            return getEventVenueResourceMenu(identifier as string, eventVenueResourceId as string, client);
        },
        keepForNMinutes(0),
    );

    return (
        <Spin
            spinning={
                eventMenusDataLoading ||
                isLoading ||
                isRefetching ||
                loading ||
                isDeleting ||
                eventMenuResourceLoading ||
                isReFetchEventMenuResourceMenu ||
                claimsLoading
            }>
            <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                <button
                    onClick={() => navigate(-1)}
                    type='button'
                    className='inline-flex w-24 h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                    </>
                </button>
                <TableActionPanel
                    setGlobalFilter={setGlobalFilter}
                    setColumnFilterOpen={setColumnFilterOpen}
                    title='pages.event_venues.resource_config_context.title'
                    description='pages.event_venues.resource_config_context.description'
                    actionButtonText='pages.event_venues.action_button'
                    target={HelpContextTarget.EventVenue}
                    hideSearch={true}
                    handleActionButton={handleAdd}
                />
            </div>
            {eventVenueResourceData && (
                <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right '>
                    <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                        <EventVenueResourceForm
                            eventMenusData={eventMenusData}
                            eventMenuResourceMenu={eventMenuResourceMenu}
                            eventVenueResourceData={eventVenueResourceData}
                            companyId={companyId}
                            eventVenueId={identifier}
                            bookingFeeTypes={bookingFeeTypes}
                            eventVenueResourceId={eventVenueResourceId}
                        />
                    </div>
                </div>
            )}
            <div className='mt-10'>
                <DataTable<any>
                    data={data}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilterList={setColumnFilterList}
                    selectSingleAction={handleTableAction}
                    columnTranslationPrefix={'pages.event_venues.resource_config_table.headers'}
                    columns={Columns}
                    canExpand={false}
                    canGlobalFilter={true}
                    enableRowSelection={true}
                    selectBulkdDelete={handleBulkDelete}
                    noDataMessage={'pages.event_venues.resource_config_table.empty'}
                />
            </div>
        </Spin>
    );
}

export default ResourceConfigurationList;

function MenuReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [value, setValue] = useState(props.defaultValue);
    return (
        <>
            <div className='col-span-6 lg:col-span-6'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
                <select
                    {...props.register(props.fieldName, {
                        onChange: (val) => setValue(val.target.value),
                        onBlur: (e) => {},
                    })}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    value={value}
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option key={`${props.referenceType}-default`} value={-1}>
                            {t('default_select')}
                        </option>
                    )}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item.menuName}-${index}`} value={item.eventMenuId}>
                            {t(`referenceData.${props.referenceType}.${item.eventMenuId}`, `${item.menuName}`)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}

function EventVenueResourceForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [processing, setProcessing] = useState<boolean>(false);
    const [foodOrderReportData, setFoodOrderReportData] = useState<any[]>([]);
    const [ticketsReportData, setTicketsReportData] = useState<any[]>([]);
    const [enabled, setEnabled] = useState(false);
    const [enabledBooking, setEnabledBooking] = useState(false);
    const [timeError, setTimeError] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const client = useClient();
    const {
        register,
        handleSubmit,
        getFieldState,
        getValues,
        setValue,
        formState: { errors },
        trigger,
        watch,
    } = useForm({
        resolver: yupResolver(EventVenueResourceSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        const dateObj = new Date(data.eventDate);
        const adjustedDateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000); // Adjust for the timezone offset
        const isoString = adjustedDateObj.toISOString();

        const eventVenueResourceObj = {
            eventVenueResource: {
                eventVenueResourceId: props.eventVenueResourceId,
                eventVenueId: props.eventVenueId,
                reservationResourceId: 0,
                description: data.description,
                eventDate: isoString,
                startTime: calculateTimeInMinutes(data.startTime),
                endTime: calculateTimeInMinutes(data.endTime),
                bookingFeeTypeId: parseInt(props?.eventVenueResourceData?.bookingFeeTypeId),
                minBookingFee: props.eventVenueResourceData.minBookingFee,
                maxBookingFee: props.eventVenueResourceData.maxBookingFee,
                bookingFee: props.eventVenueResourceData.bookingFee,
                bookingCutOffDateTime: mergeDateAndTime(data?.bookingCutOffDate, data?.bookingCutOffTime),
                allowBooking: enabledBooking,
                allowVoucher: enabled,
                menuOrderingCutOffDateTime: mergeDateAndTime(data?.orderingCutOffDate, data?.orderingCutOffTime),
                eventExternalCode: data?.eventExternalCode,
                eventResourceMenu: {
                    eventResourceMenuId: props.eventMenuResourceMenu
                        ? props.eventMenuResourceMenu.eventResourceMenuId
                        : 0,
                    eventVenueResourceId: props.eventVenueResourceId,
                    eventMenuId: data.eventMenuId,
                    isDeleted: true,
                    sortOrder: 1,
                },
            },
        };
        const rsp = await eventVenueResourceUpdate(props.companyId, props.eventVenueId, eventVenueResourceObj, client);
        setProcessing(false);
        if (rsp.traceId) {
            addNotification({
                title: t('failed_to_update'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 30000000,
            });
        }
    });

    const eventDate = watch('eventDate');

    useEffect(() => {
        if (startTime && endTime && !isValidTimePeriod(startTime, endTime)) {
            setTimeError(true);
        } else {
            setTimeError(false);
        }
    }, [startTime, endTime]);

    function calculateTimeInMinutes(time: string) {
        var colonIndex = time.indexOf(':');
        var hourString = time.substring(0, 2);
        var minuteString = time.substring(colonIndex + 1, 5);
        // Return total minutes.
        return Number(hourString) * 60 + Number(minuteString);
    }

    let previousDate = new Date();
    if (eventDate && eventDate !== undefined) {
        const _eventDate = new Date(eventDate);
        previousDate = new Date(_eventDate?.getTime() - 24 * 60 * 60 * 1000);
    }
    const handleResetDatePicker = () => {
        setValue('bookingCutOffDate', null);
        setValue('orderingCutOffDate', null);
    };

    useEffect(() => {
        if (props.eventMenuResourceMenu && props.eventMenuResourceMenu?.eventMenuId > 0) {
            setValue('eventMenuId', props.eventMenuResourceMenu?.eventMenuId);
        }
        if (props.eventVenueResourceData) {
            const startTime = toTimeString(Number(props.eventVenueResourceData.startTime));
            const endTime = toTimeString(Number(props.eventVenueResourceData.endTime));

            // Find the booking Fee type
            const bookingFeeType =
                props.bookingFeeTypes?.bookingFeeTypes?.find(
                    (feeType: any) => feeType?.id === props?.eventVenueResourceData?.bookingFeeTypeId,
                ).name || '';
            setValue('endTime', endTime);
            setValue('startTime', startTime);
            setEnabled(props.eventVenueResourceData.allowVoucher);
            setEnabledBooking(props.eventVenueResourceData.allowBooking);
            setValue('eventDate', props.eventVenueResourceData.eventDate);
            setValue('description', props.eventVenueResourceData.description);
            setValue('eventExternalCode', props.eventVenueResourceData.eventExternalCode);
            setValue('bookingCutOffDate', props.eventVenueResourceData.bookingCutOffDateTime);
            setValue('orderingCutOffDate', props.eventVenueResourceData.menuOrderingCutOffDateTime);
            setValue('bookingCutOffTime', getTimeFromDate(props.eventVenueResourceData.bookingCutOffDateTime));
            setValue('orderingCutOffTime', getTimeFromDate(props.eventVenueResourceData.menuOrderingCutOffDateTime));
            setValue('minBookingFee', props.eventVenueResourceData.minBookingFee);
            setValue('bookingFeeType', bookingFeeType);
            setValue('maxBookingFee', props.eventVenueResourceData.maxBookingFee);
            setValue('bookingFee', props.eventVenueResourceData.bookingFee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eventMenuResourceMenu, props.eventVenueResourceData]);

    useEffect(() => {
        const fetchFoodOrderData = async () => {
            try {
                setProcessing(true);
                const orders = await getFoodOrderingData(props.eventVenueId, props.eventVenueResourceId, client);
                const tickets = await getEventTicketsData(props.eventVenueId, props.eventVenueResourceId, client);
                setTicketsReportData(tickets?.tickets);
                setFoodOrderReportData(orders?.foodOrders);
                setProcessing(false);
            } catch (error) {
                console.log('error in fetching orders or tickets data :', error);
            }
        };
        fetchFoodOrderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownloadFoodOrderData = async () => {
        if (foodOrderReportData?.length > 0) {
            downloadFoodOrderCSV(foodOrderReportData);
        } else {
            addNotification({
                title: t('pages.event_venues_detail.resources.errors.no_food_order_data_found_message'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        }
    };

    const handleDownloadTickets = async () => {
        if (ticketsReportData?.length > 0) {
            downloadTicketsCSV(ticketsReportData);
        } else {
            addNotification({
                title: t('pages.event_venues_detail.resources.errors.no_ticket_found_message'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        }
    };

    const eventMenus = filterEventsByDate(props.eventMenusData, props.eventVenueResourceData.eventDate);
    const defaultMenu = props.eventVenueResourceData?.eventResourceMenu?.eventMenuId || -1;
    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventVenueResourceForm' className='space-y-6'>
                <div className='grid grid-cols-6 gap-6'>
                    <TextField
                        disabled={true}
                        fieldName='description'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.description'
                        isRequired={true}
                        data_cy='name'
                    />

                    <SingleDateSelectionField
                        disabled={true}
                        fieldName='eventDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        trigger={trigger} // Trigger revalidation for the field
                        getValues={getValues}
                        minimum={new Date()}
                        fieldLabel='pages.event_venues_detail.resources.field_names.event_date'
                        defaultValue={null}
                        isRequired={true}
                        data_cy='event-date'
                        handleResetDatePicker={handleResetDatePicker}
                    />

                    <TextField
                        disabled={true}
                        fieldName='startTime'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.start_time'
                        isRequired={true}
                        data_cy='startTime'
                    />
                    <TextField
                        disabled={true}
                        fieldName='endTime'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.end_time'
                        isRequired={true}
                        data_cy='endTime'
                    />
                    <TextField
                        disabled={true}
                        fieldName='bookingFeeType'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        isRequired={true}
                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee_type'
                        data_cy='bookingFeeType'
                    />

                    <TextField
                        disabled={true}
                        fieldName='bookingFee'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        isRequired={true}
                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee'
                        data_cy='bookingFee'
                    />

                    <TextField
                        disabled={true}
                        fieldName='minBookingFee'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        isRequired={true}
                        fieldLabel='pages.event_venues_detail.resources.field_names.min_booking_fee'
                        data_cy='minBookingFee'
                    />

                    <TextField
                        disabled={true}
                        fieldName='maxBookingFee'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        isRequired={true}
                        fieldLabel='pages.event_venues_detail.resources.field_names.max_booking_fee'
                        data_cy='maxBookingFee'
                    />
                    {true && (
                        <>
                            <div className='col-span-3'>
                                <div className='grid gap-6 grid-cols-6'>
                                    <SingleDateSelectionField
                                        overrideContainerCss='col-span-3'
                                        fieldName='bookingCutOffDate'
                                        getFieldState={getFieldState}
                                        register={register}
                                        setValue={setValue}
                                        trigger={trigger} // Trigger revalidation for the field
                                        getValues={getValues}
                                        maximum={eventDate ? previousDate : new Date()}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_date'
                                        defaultValue={null}
                                        isRequired={true}
                                        data_cy='booking-cut-off-date'
                                    />
                                    <TimePickerField
                                        overrideContainerCss='col-span-3'
                                        fieldName='bookingCutOffTime'
                                        getFieldState={getFieldState}
                                        register={register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_time'
                                        isRequired={true}
                                        data_cy='bookingCutOffTime'
                                    />
                                </div>
                            </div>

                            <div className='col-span-3'>
                                <div className='grid gap-6 grid-cols-6'>
                                    <SingleDateSelectionField
                                        overrideContainerCss='col-span-3'
                                        fieldName='orderingCutOffDate'
                                        getFieldState={getFieldState}
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger} // Trigger revalidation for the field
                                        maximum={eventDate ? previousDate : new Date()}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.ordering_date'
                                        defaultValue={null}
                                        isRequired={true}
                                        data_cy='orderingCutOffDate'
                                    />
                                    <TimePickerField
                                        overrideContainerCss='col-span-3'
                                        fieldName='orderingCutOffTime'
                                        getFieldState={getFieldState}
                                        register={register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.ordering_time'
                                        isRequired={true}
                                        data_cy='orderingCutOffTime'
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <TextField
                        fieldName='eventExternalCode'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues.table.headers.event_external_code'
                        isRequired={true}
                        data_cy='eventExternalCode'
                    />
                    {eventMenus?.length > 0 && (
                        <div className='col-span-6 lg:col-span-3'>
                            <MenuReferenceField
                                referenceData={eventMenus ?? []}
                                referenceType='eventMenuId'
                                showDefault={true}
                                fieldName='eventMenuId'
                                defaultValue={defaultMenu}
                                getFieldState={getFieldState}
                                register={register}
                                fieldLabel='pages.event_venues_detail.configurations.event_menu_label'
                                data_cy='event-menus-dropdown'
                            />
                        </div>
                    )}

                    <div className='grid col-span-5 '>
                        <div className='flex '>
                            <div className=' flex items-center '>
                                <h1 className='text-base'>
                                    {t('pages.event_venues_detail.resources.field_names.allow_voucher', 'MISSING')}
                                </h1>
                                <Switch
                                    style={{ marginLeft: '20px' }}
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${
                                        enabled ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                    <span className='sr-only'>Enable notifications</span>
                                    <span
                                        className={`${
                                            enabled ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                            <div className='ml-12 flex items-center '>
                                <h1 className='text-base'>
                                    {t('pages.event_venues_detail.resources.field_names.allow_booking', 'MISSING')}
                                </h1>
                                <Switch
                                    style={{ marginLeft: '20px' }}
                                    checked={enabledBooking}
                                    onChange={setEnabledBooking}
                                    className={`${
                                        enabledBooking ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                    <span className='sr-only'>Enable notifications</span>
                                    <span
                                        className={`${
                                            enabledBooking ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                            <div className='ml-8 flex items-center '>
                                <button
                                    type='button'
                                    form='eventVenueResourceForm'
                                    disabled={processing}
                                    onClick={handleDownloadFoodOrderData}
                                    className={classNames(
                                        'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                        processing ? 'cursor-cursor-not-allowed' : 'bg-gray-700 hover:bg-gray-800',
                                        'focus:outline-none focus:ring-0 bg-gray-700',
                                    )}
                                    data-cy='submit'>
                                    {t('pages.event_venues_detail.button.download_food_order')}
                                </button>
                            </div>
                            <div className='ml-6 flex items-center '>
                                <button
                                    type='button'
                                    form='eventVenueResourceForm'
                                    disabled={processing}
                                    className={classNames(
                                        'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                        processing ? 'cursor-cursor-not-allowed' : 'bg-gray-700 hover:bg-gray-800',
                                        'focus:outline-none focus:ring-0 bg-gray-700',
                                    )}
                                    onClick={handleDownloadTickets}
                                    data-cy='button'>
                                    {t('pages.event_venues_detail.button.download_tickets')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex -mr-2 justify-end items-end  col-span-4 lg:col-span-1 text-right'>
                        <button
                            type='submit'
                            form='eventVenueResourceForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing && (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            )}
                            {(processing && t('pages.event_venues_detail.button.saving_changes')) ||
                                t('pages.event_venues_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
