import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.locationName, {
            id: 'locationName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.location_name', ''),
        }),
        columnHelper.accessor((row) => row.eventName, {
            id: 'eventName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.event_name', ''),
        }),
        columnHelper.accessor((row) => row.eventDescription, {
            id: 'eventDescription',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? info.getValue() : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.event_description', ''),
        }),

        columnHelper.accessor((row) => row.totalCapacity, {
            id: 'totalCapacity',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_capacity', ''),
        }),
        columnHelper.accessor((row) => row.totalSold, {
            id: 'totalSold',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_sold', ''),
        }),
    ];

    return columnDefs;
}
