import { OPERATOR_API_URL } from '../../../core/api-client';
import {
    EventUpdatePutRequest,
    EventVenueResourceConfigurationCreateRequest,
    EventVenueResourceCreateRequest,
    UpdateEventResourceMenuRequest,
} from 'api/operator-api-types';
import { ClientApiOptions } from 'core/types';

export const createEventVenueResource = async (
    eventVenueId: number,
    data: EventVenueResourceCreateRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}`, {
        data: {
            ...data,
            eventVenueResource: data?.eventVenueResource?.map((r) => ({ ...r, eventVenueId })),
            eventVenueResourceConfiguration: data?.eventVenueResourceConfiguration?.map((c) => ({
                ...c,
                resourceTypeId: (() => {
                    const resourceTypeId = parseInt(process.env.REACT_APP_RESOURCE_TYPE_ID as string);
                    // check for NaN and null
                    if (!resourceTypeId) {
                        throw new Error('REACT_APP_RESOURCE_TYPE_ID is not found in .env');
                    }

                    return resourceTypeId;
                })(),
                eventVenueResourceConfigurationAmount: c.eventVenueResourceConfigurationAmount?.map((e) => ({
                    ...e,
                    ticketTypeId: (() => {
                        const ticketTypeId = parseInt(process.env.REACT_APP_TICKET_TYPE_ID as string);
                        // check for NaN and null
                        if (!ticketTypeId) {
                            throw new Error('REACT_APP_TICKET_TYPE_ID is not found in .env');
                        }
                        return ticketTypeId;
                    })(),
                })),
            })),
        },
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventVenueResourceMenu = async (
    eventVenueId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}/${eventVenueResourceId}/menu`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateEventVenueResource = async (
    eventVenueId: string,
    eventVenueResourceId: string,
    data: UpdateEventResourceMenuRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}/${eventVenueResourceId}/menu`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const createEventVenueResourceConfiguration = async (
    companyId: number,
    eventVenueResourceId: number,
    data: EventVenueResourceConfigurationCreateRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/${eventVenueResourceId}/configuration`, {
        data: {
            ...data,
            eventVenueResourceConfiguration: data?.eventVenueResourceConfiguration?.map((c) => ({
                ...c,
                resourceTypeId: (() => {
                    const resourceTypeId = parseInt(process.env.REACT_APP_RESOURCE_TYPE_ID as string);
                    // check for NaN and null
                    if (!resourceTypeId) {
                        throw new Error('REACT_APP_RESOURCE_TYPE_ID is not found in .env');
                    }

                    return resourceTypeId;
                })(),
                eventVenueResourceConfigurationAmount: c.eventVenueResourceConfigurationAmount?.map((e) => ({
                    ...e,
                    ticketTypeId: (() => {
                        const ticketTypeId = parseInt(process.env.REACT_APP_TICKET_TYPE_ID as string);
                        // check for NaN and null
                        if (!ticketTypeId) {
                            throw new Error('REACT_APP_TICKET_TYPE_ID is not found in .env');
                        }
                        return ticketTypeId;
                    })(),
                })),
            })),
        },
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteVenue = async (
    eventVenueId: string,
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenue/${companyId}/event-venue/${eventVenueId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
