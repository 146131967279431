import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    isDirty: boolean;
}

export default function ChangesPanel(props: InputProps) {
    const { t } = useTranslation();
    const { isDirty } = props;

    if (!isDirty) {
        return <></>;
    }

    return (
        <>
            <div className='rounded-md bg-yellow-50 px-4 py-5'>
                <div className='flex'>
                    <div className='flex-shrink-0'>
                        <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' aria-hidden='true' />
                    </div>
                    <div className='ml-3'>
                        <h3 className='text-sm font-medium text-yellow-800'>{t('unsaved_changes')}</h3>
                        <div className='mt-2 text-sm text-yellow-700'>
                            <p>{t('unsaved_changes_description')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
