import { Profiler, useEffect, useState } from 'react';
import TopNav from './stack';
import { reportProfile } from '../core/profiler';
import Sidebar from './sidebar';
import NotificationRegion from './notification';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ConfirmDelete from './confirm-delete';
import { useSelectedCompany } from 'context/company-context';

export interface InputProps {
    companyIsRefetching?: any;
    companyIsFetching?: any;
    companyIsLoading?: any;
    companyManagedData?: any;
}

export default function Layout(props: InputProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { companyIdentifier } = useSelectedCompany();
    const params = useParams();
    const navigate = useNavigate();

    // Watch for a location change.
    useEffect(() => {
        if (companyIdentifier > 0 && params?.companyId !== undefined) {
            let numericId = parseInt(params.companyId, 10);
            if (numericId !== companyIdentifier) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyIdentifier]);

    return (
        <>
            <div>
                {/* Static sidebar for desktop */}
                <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <Profiler id='Sidebar' onRender={reportProfile}>
                        <Sidebar
                            companyIsRefetching={props.companyIsRefetching}
                            companyIsFetching={props.companyIsFetching}
                            companyIsLoading={props.companyIsLoading}
                            companyManagedData={props.companyManagedData}
                            setSidebarOpen={setSidebarOpen}
                            sidebarOpen={sidebarOpen}
                        />
                    </Profiler>
                </div>
                <div className='md:pl-64 flex flex-col'>
                    {/* Top menu */}
                    <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow md:hidden'>
                        <Profiler id='TopNav' onRender={reportProfile}>
                            <TopNav setSidebarOpen={setSidebarOpen} />
                        </Profiler>
                    </div>
                    <main className='flex-1'>
                        <div className='py-4 px-4 sm:px-6 md:px-8'>
                            <Profiler id='ContentOutlet' onRender={reportProfile}>
                                <Outlet />
                            </Profiler>
                        </div>
                    </main>
                </div>
                <NotificationRegion />
            </div>
            <ConfirmDelete />
        </>
    );
}
