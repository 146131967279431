import { EventVenueResourceConfigurationAdminDto } from 'api/operator-api-types';
import { FieldInputProps } from 'components/form-fields';
import { EventVenueSubSectionTarget } from 'core/types';
import { classNames, filterExpiredEventMenus, filterExpiredItems } from 'core/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    data: EventVenueResourceConfigurationAdminDto[] | null;
    addCallback: (target: EventVenueSubSectionTarget) => void;
    setIsDirty?: () => void;
    setKeepDirty?: any;
    eventMenusData?: any;
    getFieldState?: any;
    register?: any;
    minEventDate?: any;
    maxEventDate?: any;
}

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    defaultValue?: string;
}

export default function EventMenuConfigurationSection(props: InputProps) {
    const { t } = useTranslation();
    const { data: input, setKeepDirty, minEventDate, maxEventDate } = props;
    const [data, setData] = useState<EventVenueResourceConfigurationAdminDto[]>([]);

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
    }, [input]);

    useEffect(() => {
        if (data?.length === 0) {
            setKeepDirty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let _data: any;
    if (props.eventMenusData) {
        const uniqueArray = Object.values(
            props.eventMenusData.reduce((acc: any, obj: any) => {
                // Use the `id` property as the key to check for uniqueness
                if (!acc[obj.eventMenuId]) {
                    acc[obj.eventMenuId] = obj;
                }
                return acc;
            }, {}),
        );
        _data = uniqueArray;
    }
    const filterEventMenusArray = filterExpiredEventMenus(_data, minEventDate, maxEventDate);

    return (
        <>
            {filterEventMenusArray?.length > 0 && (
                <>
                    <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                        <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                            <div className='lg:col-span-1'>
                                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                    {t('pages.event_venues_detail.configurations.title')}
                                </h3>
                                <p className='mt-1 text-sm text-gray-500'>
                                    {t('pages.event_venues_detail.configurations.event_menu_label')}
                                </p>
                            </div>
                            <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                                <div className='relative'>
                                    <LocationReferenceField
                                        referenceData={props.eventMenusData ? filterEventMenusArray : []}
                                        referenceType='eventMenuId'
                                        showDefault={true}
                                        fieldName='eventMenuId'
                                        getFieldState={props.getFieldState}
                                        register={props.register}
                                        fieldLabel='pages.event_detail.venues.field_names.menu'
                                        data_cy='event-menus-dropdown'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

function LocationReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [value, setValue] = useState(props.defaultValue);

    return (
        <>
            <div className='col-span-6 lg:col-span-6'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    value={value}
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    onChange={(val) => setValue(val.target.value)}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option key={`${props.referenceType}-default`} value={-1}>
                            {t('default_select')}
                        </option>
                    )}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item.menuName}-${index}`} value={item.eventMenuId}>
                            {t(`referenceData.${props.referenceType}.${item.eventMenuId}`, `${item.menuName}`)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
