import { useTranslation } from 'react-i18next';
import { EventExperienceDto, ExperienceAdminDto } from 'api/operator-api-types';
import DataTable, { DeleteCss } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps, EventSubSectionTarget } from 'core/types';
import { useEffect, useState } from 'react';
import { timer } from 'core/utils';
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useConfirm } from 'context/confirmation-context';
import { XMarkIcon } from '@heroicons/react/20/solid';

export interface InputProps {
    data: EventExperienceDto[] | null;
    experiences: ExperienceAdminDto[] | null;
    addCallback: (target: EventSubSectionTarget) => void;
    deleteFromExperiences: (experienceId: string) => void;
}

export default function ExperienceSection(props: InputProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<EventExperienceDto[]>([]);
    const [allExperiences, setAllExperiences] = useState<ExperienceAdminDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();
    const { data: input, experiences, addCallback, deleteFromExperiences } = props;

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
        if (experiences !== null) {
            setAllExperiences(experiences);
        }
    }, [input, experiences]);

    const handleDelete = (row: EventExperienceDto, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                if (row.experienceId != null) {
                    deleteFromExperiences(row.experienceId);
                }
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    function getExperienceName(experienceId: string | undefined) {
        if (allExperiences) {
            var result = allExperiences.find((exp: ExperienceAdminDto) => {
                return exp.experienceId == experienceId;
            });
            return result?.name;
        }
    }

    const ExperienceColumns = (props: ColumnGeneratorInputProps<EventExperienceDto>): any => {
        const { t } = useTranslation();
        const columnHelper = createColumnHelper<EventExperienceDto>();
        // unwind props.
        const { selectSingleAction } = props;
        let columnDefs = [];

        columnDefs.push(
            columnHelper.accessor((row) => row.experienceId, {
                id: 'name',
                cell: (info) => <>{getExperienceName(info.getValue())}</>,
                header: () => t('pages.event_detail.experiences.table.headers.name', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.ratingValue, {
                id: 'ratingValue',
                cell: (info) => <>{info.getValue()}</>,
                header: () => t('pages.event_detail.experiences.table.headers.rating_value', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.sortOrder, {
                id: 'sortOrder',
                cell: (info) => <>{info.getValue()}</>,
                header: () => t('pages.event_detail.experiences.table.headers.sort_order', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.isFeatured, {
                id: 'isFeatured',
                cell: (info) => (
                    <>
                        {info.getValue() == true ? (
                            <CheckIcon className='h-5 w-5' />
                        ) : (
                            <XMarkIcon className='h-5 w-5' />
                        )}
                    </>
                ),
                header: () => t('pages.event_detail.experiences.table.headers.is_featured', 'MISSING'),
            }),
        );

        if (selectSingleAction !== undefined) {
            columnDefs.push(
                columnHelper.display({
                    id: 'actions',
                    cell: (props) => (
                        <button
                            type='button'
                            onClick={() =>
                                selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)
                            }
                            className={DeleteCss()}
                            data-cy='delete'>
                            {t('buttons.delete', '')}
                        </button>
                    ),
                    enableHiding: false,
                    enableSorting: false,
                    enableMultiSort: false,
                    enableColumnFilter: false,
                    enableGlobalFilter: false,
                }),
            );
        }

        return columnDefs;
    };

    const sortedDate = data?.sort((a: any, b: any) => a?.sortOrder - b.sortOrder);

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.event_detail.experiences.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.event_detail.experiences.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(EventSubSectionTarget.Experiences)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<EventExperienceDto>
                                columnTranslationPrefix={'pages.event_detail.experiences.table.headers'}
                                columns={ExperienceColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={sortedDate}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.event_detail.experiences.table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
