import { BuildingOfficeIcon, HomeIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { classNames, hasClaim } from '../../core/utils';
import { AuthenticatedResponseModel } from 'api/operator-api-types';
import Company from './company';
import CMS from './cms';
import FinanceReportSystem from './finance';

interface InputProperties {
    claims: AuthenticatedResponseModel | undefined;
}

function Main(props: InputProperties) {
    const { t } = useTranslation();
    const { claims } = props;

    const hasLocationAccess = hasClaim(claims?.claims, 'operator.access.location', ['full']);
    const hasExperianceAccess = hasClaim(claims?.claims, 'operator.access.experience', ['full']);

    return (
        <>
            <NavLink
                key='menu.dashboard'
                to='/'
                className={({ isActive }) =>
                    classNames(
                        isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                    )
                }
                end>
                <>
                    <HomeIcon
                        className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                    />
                    {t('menu.dashboard', 'MISSING')}
                </>
            </NavLink>
            <Company claims={claims} />
            <CMS claims={claims} />
            {hasLocationAccess && (
                <NavLink
                    key='menu.location'
                    to='/locations'
                    className={({ isActive }) =>
                        classNames(
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                        )
                    }
                    end>
                    <>
                        <BuildingOfficeIcon
                            className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                            aria-hidden='true'
                        />
                        {t('menu.location', 'MISSING')}
                    </>
                </NavLink>
            )}
            {hasExperianceAccess && (
                <NavLink
                    key='menu.experience'
                    to='/experience'
                    className={({ isActive }) =>
                        classNames(
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                        )
                    }
                    end>
                    <>
                        <SparklesIcon
                            className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                            aria-hidden='true'
                        />
                        {t('menu.experience', 'MISSING')}
                    </>
                </NavLink>
            )}
            <FinanceReportSystem claims={claims} />
        </>
    );
}

export default Main;
