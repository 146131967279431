import { yupResolver } from '@hookform/resolvers/yup';
import { ChangesPanel, ErrorPanel, FormActionPanel } from 'components/action-panel';
import { CompanyCommissionSubSectionTarget, HelpContextTarget, NotificationType } from 'core/types';
import { CompanySchema, CompanySchemaWithoutBooking, CompanySchemaWithoutMinMaxBooking } from 'core/validation-schema';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from 'context/auth-context';
import { useForm } from 'react-hook-form';
import CompanySection from './company-section';
import { useClient } from 'core/api-client';
import { useNotifications } from 'context/notification-context';
import { useTranslation } from 'react-i18next';
import { createCompany, getBookingFeeTypes, getCompany, updateCompany } from 'fetch/company';
import { CompanyCreateRequest, CompanyResponseModel } from 'api/operator-api-types';
import { useQuery } from 'react-query';
import { getDateWithoutModification, keepForNMinutes } from 'core/utils';
import { useNavigate, useParams } from 'react-router';
import useSoftDirty from 'hooks/useSoftDirty';
import CommissionSection from './commission-section';
import SubSectionAddForm from './sub-section.add';
import { addRandomId } from './helper';

export interface InputProps {
    refetch?: any;
}

// An interface for our actions
interface ReducerAction {
    type: CompanyCommissionSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: CompanyCommissionSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== CompanyCommissionSubSectionTarget.Closed,
    };

    switch (action.type) {
        case CompanyCommissionSubSectionTarget.Commission:
            res.title = 'pages.company_reports.title';
            res.description = 'pages.company_reports.description';
            break;
    }

    return res;
}

export default function ManageCompany(props: InputProps) {
    const client = useClient();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier, companyId } = useParams();
    const { user } = useAuth();
    const [processing, setProcessing] = useState(false);
    const [formError] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [bookingTypeId, setBookingTypeId] = useState<string>('');
    const [isAffiliate, setIsAffiliate] = useState<boolean>(false);
    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [commissions, setCommissions] = useState<any[]>([]);
    const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [existingCommissions, setExistingCommissions] = useState<any[]>([]);
    const [isEdit, setIsEdit] = useState(false);
    const { isDirty, _setIsDirty: setDirty } = useSoftDirty('addCompanyForm');

    var identifierInt = parseInt(identifier!!);

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const {
        data: companyData,
        isLoading: companyDataIsLoading,
        refetch,
    } = useQuery(
        ['companyData', identifierInt!!],
        () => {
            if (identifierInt && identifier !== 'create') {
                return getCompany(identifierInt!!, client).then((rsp) => {
                    return rsp;
                });
            }
        },
        keepForNMinutes(1),
    );

    const addCommissions = (data: any) => {
        var newCommissions = [...commissions];
        newCommissions.push(data);
        setCommissions(newCommissions);
        setDirty();
    };
    const deleteFromCommission = (commissionId: string) => {
        var newExistingCommissions = [...existingCommissions];
        var existingCommissionToRemoveIndex = newExistingCommissions.findIndex(
            (com) => com.commissionId === commissionId,
        );
        newExistingCommissions.splice(existingCommissionToRemoveIndex, 1);
        setExistingCommissions(newExistingCommissions);

        var newCommissions = [...commissions];
        var commissionToRemoveIndex = newCommissions.findIndex((com) => com.commissionId === commissionId);
        newCommissions.splice(commissionToRemoveIndex, 1);
        setCommissions(newCommissions);
        setDirty();
    };

    const addToExistingCommissions = (data: any) => {
        var newExistingCommissions = [...existingCommissions];
        newExistingCommissions.push(data);
        setExistingCommissions(newExistingCommissions);
        setDirty();
    };

    const {
        register,
        handleSubmit,
        getFieldState,
        getValues,
        setValue,
        reset,
        watch,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            bookingTypeId === '0'
                ? CompanySchemaWithoutBooking
                : bookingTypeId == '1'
                ? CompanySchemaWithoutMinMaxBooking
                : CompanySchema,
        ),
        mode: 'onSubmit',
    });
    const { data: bookingFeeTypes } = useQuery(
        ['booking-fee-types'],
        () => {
            return getBookingFeeTypes(client);
        },
        keepForNMinutes(1),
    );

    useEffect(() => {
        if (isAffiliate && commissions?.length === 0) {
            setShowFormErrors(true);
            setErrorMessage(t('pages.company_detail.minimum_one_required'));
        } else {
            if (processing) {
                if (commissions?.length > 0 || !isAffiliate) {
                    setShowFormErrors(false);
                } else {
                    setShowFormErrors(true);
                    if (commissions.length === 0) {
                        setErrorMessage(t('pages.company_detail.minimum_commission'));
                        setProcessing(false);
                    }
                }
            } else {
                if (commissions?.length > 0 || !isAffiliate) {
                    setShowFormErrors(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, commissions]);

    const onSubmit = handleSubmit(async (data) => {
        if (isAffiliate) {
            if (commissions?.length > 0) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                setServerErrorList([]);
                setTraceId(null);
                setProcessing(true);
                if (isEdit && identifier) {
                    commissions?.forEach((item) => {
                        delete item['commissionId'];
                        item.startDate = getDateWithoutModification(item?.startDate).split('T')[0];
                        item.endDate = getDateWithoutModification(item?.endDate).split('T')[0];
                    });
                    if (!(data.detail.bookingFeeType === '2' || data.detail.bookingFeeType === '3')) {
                        data.detail.minBookingFee = '';
                        data.detail.maxBookingFee = '';
                    }
                    const rsp = await updateCompany(
                        identifierInt,
                        CompanyResponseModel.fromJS({
                            company: {
                                name: data.detail.name,
                                displayName: data.detail.displayName,
                                companyTypeId: Number.parseInt(process.env.REACT_APP_COMPANY_TYPE_ID ?? '1'),
                                email: data.detail.email,
                                phone: data.detail.phone,
                                address: {
                                    addressId: companyData?.address?.addressId,
                                    addressLine1: data.detail.address.line1,
                                    addressLine2: data.detail.address.addressLine2,
                                    addressLine3: data.detail.address.addressLine3,
                                    town: data.detail.address.town,
                                    city: data.detail.address.city,
                                    postcode: data.detail.address.postCode,
                                    countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                                },
                                currencyId: Number.parseInt(process.env.REACT_APP_CURRENCY_ID ?? '1'),
                                vatPercentage: Number.parseInt(data.detail.vatPercentage),
                                bookingFeeTypeId: Number.parseInt(data.detail.bookingFeeType),
                                bookingFee: bookingTypeId === '0' ? 0.0 : data?.detail?.bookingFee,
                                minBookingFee: data?.detail?.minBookingFee === '' ? null : data?.detail?.minBookingFee,
                                maxBookingFee: data?.detail?.maxBookingFee === '' ? null : data?.detail?.maxBookingFee,
                                isAffiliate: isAffiliate,
                                companyCommissions: commissions,
                            },
                            userId: user?.userId!!,
                        }),
                        client,
                    );
                    setProcessing(false);
                    if (rsp.traceId) {
                        setTraceId(rsp.traceId);
                    }

                    if (rsp.traceId) {
                        addNotification({
                            description: t('pages.company_detail.detail.error_description'),
                            title: t('pages.company_detail.detail.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                        setProcessing(false);
                    } else {
                        // Success
                        addNotification({
                            description: t('pages.company_detail.update.success_description'),
                            title: t('pages.company_detail.update.success_title'),
                            style: NotificationType.SuccessSimple,
                            timeoutInMilliseconds: 3000,
                        });
                        props.refetch();
                        navigate(`/companies`, { replace: true });
                        setProcessing(false);
                        refetch();
                    }
                } else {
                    commissions?.forEach((item) => {
                        delete item['commissionId'];
                        item.startDate = getDateWithoutModification(item?.startDate).split('T')[0];
                        item.endDate = getDateWithoutModification(item?.endDate).split('T')[0];
                    });
                    if (!(data.detail.bookingFeeType === '2' || data.detail.bookingFeeType === '3')) {
                        data.detail.minBookingFee = '';
                        data.detail.maxBookingFee = '';
                    }
                    const rsp = await createCompany(
                        CompanyCreateRequest.fromJS({
                            company: {
                                name: data.detail.name,
                                displayName: data.detail.displayName,
                                companyTypeId: Number.parseInt(process.env.REACT_APP_COMPANY_TYPE_ID ?? '1'),
                                email: data.detail.email,
                                phone: data.detail.phone,
                                address: {
                                    addressLine1: data.detail.address.line1,
                                    addressLine2: data.detail.address.addressLine2,
                                    addressLine3: data.detail.address.addressLine3,
                                    town: data.detail.address.town,
                                    city: data.detail.address.city,
                                    postcode: data.detail.address.postCode,
                                    countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                                },
                                currencyId: Number.parseInt(process.env.REACT_APP_CURRENCY_ID ?? '1'),
                                vatPercentage: data.detail.vatPercentage,
                                bookingFeeTypeId: Number.parseInt(data.detail.bookingFeeType),
                                bookingFee: bookingTypeId === '0' ? 0.0 : data?.detail?.bookingFee,
                                minBookingFee: data?.detail?.minBookingFee === '' ? null : data?.detail?.minBookingFee,
                                maxBookingFee: data?.detail?.maxBookingFee === '' ? null : data?.detail?.maxBookingFee,
                                isAffiliate: isAffiliate,
                                companyCommissions: commissions,
                            },
                            userId: user?.userId!!,
                        }),

                        client,
                    );
                    setProcessing(false);
                    if (rsp.traceId) {
                        setTraceId(rsp.traceId);
                    }

                    if (rsp.traceId) {
                        if (rsp.status === 409) {
                            addNotification({
                                description: t('pages.company_detail.detail.conflict_error_description'),
                                title: t('pages.company_detail.detail.conflict_error_title'),
                                style: NotificationType.ErrrorSumple,
                                timeoutInMilliseconds: 3000,
                            });
                            setProcessing(false);
                        } else {
                            addNotification({
                                description: t('pages.company_detail.detail.error_description'),
                                title: t('pages.company_detail.detail.error_title'),
                                style: NotificationType.ErrrorSumple,
                                timeoutInMilliseconds: 3000,
                            });
                            setProcessing(false);
                        }
                    } else {
                        addNotification({
                            description: t('pages.company_detail.detail.success_description'),
                            title: t('pages.company_detail.detail.success_title'),
                            style: NotificationType.SuccessSimple,
                            timeoutInMilliseconds: 3000,
                        });
                        props.refetch();
                        navigate(`/companies`, { replace: true });
                        setProcessing(false);
                    }
                }
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setServerErrorList([]);
            setTraceId(null);
            setProcessing(true);
            if (isEdit && identifier) {
                commissions?.forEach((item) => {
                    delete item['commissionId'];
                    item.startDate = getDateWithoutModification(item?.startDate).split('T')[0];
                    item.endDate = getDateWithoutModification(item?.endDate).split('T')[0];
                });
                if (!(data.detail.bookingFeeType === '2' || data.detail.bookingFeeType === '3')) {
                    data.detail.minBookingFee = '';
                    data.detail.maxBookingFee = '';
                }
                const rsp = await updateCompany(
                    identifierInt,
                    CompanyResponseModel.fromJS({
                        company: {
                            name: data.detail.name,
                            displayName: data.detail.displayName,
                            companyTypeId: Number.parseInt(process.env.REACT_APP_COMPANY_TYPE_ID ?? '1'),
                            email: data.detail.email,
                            phone: data.detail.phone,
                            address: {
                                addressId: companyData?.address?.addressId,
                                addressLine1: data.detail.address.line1,
                                addressLine2: data.detail.address.addressLine2,
                                addressLine3: data.detail.address.addressLine3,
                                town: data.detail.address.town,
                                city: data.detail.address.city,
                                postcode: data.detail.address.postCode,
                                countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                            },
                            currencyId: Number.parseInt(process.env.REACT_APP_CURRENCY_ID ?? '1'),
                            vatPercentage: Number.parseInt(data.detail.vatPercentage),
                            bookingFeeTypeId: Number.parseInt(data.detail.bookingFeeType),
                            bookingFee: bookingTypeId === '0' ? 0.0 : data?.detail?.bookingFee,
                            minBookingFee: data?.detail?.minBookingFee === '' ? null : data?.detail?.minBookingFee,
                            maxBookingFee: data?.detail?.maxBookingFee === '' ? null : data?.detail?.maxBookingFee,
                            isAffiliate: isAffiliate,
                            companyCommissions: isAffiliate ? commissions : [],
                        },
                        userId: user?.userId!!,
                    }),
                    client,
                );
                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.traceId) {
                    addNotification({
                        description: t('pages.company_detail.detail.error_description'),
                        title: t('pages.company_detail.detail.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setProcessing(false);
                } else {
                    // Success
                    addNotification({
                        description: t('pages.company_detail.update.success_description'),
                        title: t('pages.company_detail.update.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    props.refetch();
                    navigate(`/companies`, { replace: true });
                    setProcessing(false);
                    refetch();
                }
            } else {
                commissions?.forEach((item) => {
                    delete item['commissionId'];
                    item.startDate = getDateWithoutModification(item?.startDate).split('T')[0];
                    item.endDate = getDateWithoutModification(item?.endDate).split('T')[0];
                });
                if (!(data.detail.bookingFeeType === '2' || data.detail.bookingFeeType === '3')) {
                    data.detail.minBookingFee = '';
                    data.detail.maxBookingFee = '';
                }
                const rsp = await createCompany(
                    CompanyCreateRequest.fromJS({
                        company: {
                            name: data.detail.name,
                            displayName: data.detail.displayName,
                            companyTypeId: Number.parseInt(process.env.REACT_APP_COMPANY_TYPE_ID ?? '1'),
                            email: data.detail.email,
                            phone: data.detail.phone,
                            address: {
                                addressLine1: data.detail.address.line1,
                                addressLine2: data.detail.address.addressLine2,
                                addressLine3: data.detail.address.addressLine3,
                                town: data.detail.address.town,
                                city: data.detail.address.city,
                                postcode: data.detail.address.postCode,
                                countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                            },
                            currencyId: Number.parseInt(process.env.REACT_APP_CURRENCY_ID ?? '1'),
                            vatPercentage: data.detail.vatPercentage,
                            bookingFeeTypeId: Number.parseInt(data.detail.bookingFeeType),
                            bookingFee: bookingTypeId === '0' ? 0.0 : data?.detail?.bookingFee,
                            minBookingFee: data?.detail?.minBookingFee === '' ? null : data?.detail?.minBookingFee,
                            maxBookingFee: data?.detail?.maxBookingFee === '' ? null : data?.detail?.maxBookingFee,
                            isAffiliate: isAffiliate,
                            companyCommissions: isAffiliate ? commissions : [],
                        },
                        userId: user?.userId!!,
                    }),

                    client,
                );
                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.traceId) {
                    if (rsp.status === 409) {
                        addNotification({
                            description: t('pages.company_detail.detail.conflict_error_description'),
                            title: t('pages.company_detail.detail.conflict_error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                        setProcessing(false);
                    } else {
                        addNotification({
                            description: t('pages.company_detail.detail.error_description'),
                            title: t('pages.company_detail.detail.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                        setProcessing(false);
                    }
                } else {
                    addNotification({
                        description: t('pages.company_detail.detail.success_description'),
                        title: t('pages.company_detail.detail.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    props.refetch();
                    navigate(`/companies`, { replace: true });
                    setProcessing(false);
                }
            }
        }
    });

    const handleReset = () => {
        if (companyData === undefined) {
            return;
        }

        setServerErrorList([]);

        setTraceId(null);

        reset(companyData);
    };
    useEffect(() => {
        if (identifier !== 'create' && identifierInt) {
            addRandomId(companyData?.companyCommissions);
            setCommissions(companyData?.companyCommissions);
            setValue('detail.name', companyData?.name);
            setValue('detail.displayName', companyData?.displayName);
            setValue('detail.email', companyData?.email);
            setValue('detail.phone', companyData?.phone);
            setValue('detail.address.line1', companyData?.address?.addressLine1);
            setValue('detail.address.addressLine2', companyData?.address?.addressLine2);
            setValue('detail.address.addressLine3', companyData?.address?.addressLine3);
            setValue('detail.address.town', companyData?.address?.town);
            setValue('detail.address.city', companyData?.address?.city);
            setValue('detail.address.postCode', companyData?.address?.postcode);
            setValue('detail.vatPercentage', companyData?.vatPercentage);
            setValue('detail.bookingFeeType', companyData?.bookingFeeTypeId);
            setBookingTypeId(companyData?.bookingFeeTypeId);
            setValue('detail.bookingFee', companyData?.bookingFee);
            setValue(
                'detail.minBookingFee',
                companyData?.minBookingFee === 0 ? 0 : companyData?.minBookingFee || undefined,
            );
            setValue(
                'detail.maxBookingFee',
                companyData?.maxBookingFee === 0 ? 0 : companyData?.maxBookingFee || undefined,
            );
            setIsAffiliate(companyData?.isAffiliate);
            setValue('detail.commission.startDate', companyData?.companyCommissions[0]?.startDate);
            setValue('detail.commission.endDate', companyData?.companyCommissions[0]?.endDate);
            setValue('detail.commission.sortOrder', companyData?.companyCommissions[0]?.sortOrder);
            setValue(
                'detail.commission.commissionPercentage',
                companyData?.companyCommissions[0]?.commissionPercentage,
            );
            setDirty();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyData, bookingFeeTypes]);

    const selectedBookingTypeId = watch('detail.bookingFeeType');
    useEffect(() => {
        setBookingTypeId(selectedBookingTypeId);
    }, [selectedBookingTypeId]);

    const [state, dispatch] = useReducer(reducer, {
        panel: CompanyCommissionSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });

    const openAddSubSection = (target: CompanyCommissionSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };
    return (
        <>
            <FormActionPanel
                isDirty={isDirty}
                processing={processing}
                handleReset={handleReset}
                contextTitle='pages.company_detail.context.title'
                contextDescription='pages.company_detail.context.description'
                contextTarget={HelpContextTarget.CompanyDetail}
                formTarget='addCompanyForm'
            />
            <form onSubmit={onSubmit} noValidate={true} id='addCompanyForm' className='space-y-6'>
                {isAffiliate && (
                    <ErrorPanel formError={showFormErrors} serverErrorList={[errorMessage]} traceId={traceId} />
                )}
                <CompanySection
                    key='company-section'
                    errors={errors}
                    register={register}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    setValue={setValue}
                    selectedBookingTypeId={bookingTypeId}
                    bookingFeeTypes={bookingFeeTypes}
                    company={companyData}
                    isAffiliate={isAffiliate}
                    setIsAffiliate={setIsAffiliate}
                    trigger={trigger}
                />
                {isAffiliate && (
                    <CommissionSection
                        data={commissions}
                        commissions={companyData?.companyCommissions}
                        addCallback={openAddSubSection}
                        deleteFromCommissions={deleteFromCommission}
                    />
                )}
            </form>
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                addCommissions={addCommissions}
                addToExistingCommissions={addToExistingCommissions}
                existingCommissions={existingCommissions}
                commissionData={companyData?.companyCommissions}
                setDirty={setDirty}
                date={date}
            />
        </>
    );
}
