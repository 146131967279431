import { rankItem } from '@tanstack/match-sorter-utils';
import { FilterFn } from '@tanstack/react-table';
import { ClaimDetailDto } from 'api/operator-api-types';
import { DateFormats, TimeInMinutes } from './types';

const timer = (ms: any) => new Promise((res) => setTimeout(res, ms));

function debounce<T extends (...args: any[]) => void>(
    callback: T,
    debounceDelay: number = 300,
    immediate: boolean = false,
) {
    let timeout: ReturnType<typeof setTimeout> | null;

    return function <U>(this: U, ...args: Parameters<typeof callback>) {
        const context = this;
        if (immediate && !timeout) {
            callback.apply(context, args);
        }
        if (typeof timeout === 'number') {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate) {
                callback.apply(context, args);
            }
        }, debounceDelay);
    };
}

function getRange(start: number, end: number) {
    return (
        Array(end - start + 1)
            // @ts-ignore
            .fill()
            .map((v, i) => i + start)
    );
}

function pagination(currentPage: number, pageCount: number): (number | null)[] {
    let delta: number;
    if (pageCount <= 7) {
        // delta === 7: [1 2 3 4 5 6 7]
        delta = 7;
    } else {
        // delta === 2: [1 ... 4 5 6 ... 10]
        // delta === 4: [1 2 3 4 5 ... 10]
        delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 4;
    }

    const range = {
        start: Math.round(currentPage - delta / 2),
        end: Math.round(currentPage + delta / 2),
    };

    if (range.start - 1 === 1 || range.end + 1 === pageCount) {
        range.start += 1;
        range.end += 1;
    }

    let pages: any =
        currentPage > delta
            ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
            : getRange(1, Math.min(pageCount, delta + 1));

    const withDots = (value: any, pair: any) => (pages.length + 1 !== pageCount ? pair : [value]);

    if (pages[0] !== 1) {
        pages = withDots(1, [1, null]).concat(pages);
    }

    if (pages[pages.length - 1] < pageCount) {
        pages = pages.concat(withDots(pageCount, [null, pageCount]));
    }

    return pages;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

function formatBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function keepForOneDay() {
    return {
        cacheTime: 1000 * 60 * 60 * 24,
        staleTime: 1000 * 60 * 60 * 24,
    };
}

function keepForNMinutes(n: number) {
    return {
        cacheTime: 1000 * 60 * n,
        staleTime: 1000 * 60 * n,
    };
}

function formattedDate(override: Date | null): DateFormats {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const options2: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const today = new Date();
    const now = override ? override : new Date();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');

    const shortDate = `${now.getFullYear()}-${month}-${day}`;
    const longDate = now.toLocaleDateString(undefined, options);
    const longDateTime = now.toLocaleDateString(undefined, options2);

    return {
        shortDate,
        longDate,
        longDateTime,
        day: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        date: now,
        offset: today.getHours() * 60 + today.getMinutes(),
        isToday:
            today.getFullYear() === now.getFullYear() &&
            today.getMonth() === now.getMonth() &&
            today.getDate() === now.getDate(),
    };
}
function formatDateTime(inputDate: string) {
    const date = new Date(inputDate);

    // Extract date and time components
    const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
    const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);

    // Format the time components manually
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const time = `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')} ${ampm}`;

    // Construct the formatted date and time string
    return `${weekday}, ${month} ${day}, ${year} at ${time}`;
}

// Note: The following function will return date in formate yyyy-mm-dd hh:mm:ss
function formattedDateWithTime(override: Date | null): DateFormats {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const options2: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const today = new Date();
    const now = override ? override : new Date();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');
    const seconds = `${now.getSeconds()}`.padStart(2, '0');

    const shortDate = `${now.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const longDate = now.toLocaleDateString(undefined, options);
    const longDateTime = now.toLocaleDateString(undefined, options2);

    return {
        shortDate,
        longDate,
        longDateTime,
        day: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        date: now,
        offset: today.getHours() * 60 + today.getMinutes(),
        isToday:
            today.getFullYear() === now.getFullYear() &&
            today.getMonth() === now.getMonth() &&
            today.getDate() === now.getDate(),
    };
}

function parseLocationIdFromString(locationId: string | undefined): number {
    let pathLocationId = NaN;
    if (locationId !== undefined) {
        pathLocationId = parseInt(locationId, 10);
    }

    // We have a location id in the path and it was a number, then use it.
    if (!isNaN(pathLocationId)) {
        return pathLocationId;
    }

    return 0;
}

function timeToString(timeInMinutes: number): string {
    if (timeInMinutes === -1) {
        return 'ASAP';
    }

    let hours = Math.floor(timeInMinutes / 60);

    if (hours > 24) {
        hours -= 24;
    }

    if (hours === 24) {
        hours = 0;
    }

    const minutes = timeInMinutes % 60;
    return `${hours}`.padStart(2, '0') + ':' + `${minutes}`.padStart(2, '0');
}

function hasClaim(
    claims: ClaimDetailDto[] | null | undefined,
    claimType: string,
    allowedClaimValues: string[],
): boolean {
    if (claims === null || claims === undefined || claims.length === 0) {
        return false;
    }

    const matchedClaims = claims.filter(
        (c) =>
            c.type === claimType &&
            (allowedClaimValues.includes(c.value ?? 'FAILED') || allowedClaimValues.includes('*')),
    ).length;

    return matchedClaims > 0;
}

function getClaims(claims: ClaimDetailDto[] | null | undefined, claimType: string): ClaimDetailDto[] {
    if (claims === null || claims === undefined || claims.length === 0) {
        return [];
    }

    const filterClaims = claims.filter((c) => c.type === claimType) ?? [];

    return filterClaims;
}

function timeInMinutesToSections(timeInMinutes: number): TimeInMinutes {
    if (timeInMinutes === 0) {
        return {
            timeInMinutes: 0,
            days: 0,
            hours: 0,
            minutes: 0,
        };
    }

    const totalDays = Math.floor(timeInMinutes / (60 * 24));
    let totalHours = 0;

    const remainderHours = timeInMinutes - totalDays * (60 * 24);

    if (remainderHours > 0) {
        totalHours = Math.floor(remainderHours / 60);
    }

    const totalMinutes = remainderHours - totalHours * 60;

    return {
        timeInMinutes: timeInMinutes,
        days: totalDays,
        hours: totalHours,
        minutes: totalMinutes,
    };
}

function parseIdentifierFromString(identifier: string | undefined): number {
    let pathId = NaN;
    if (identifier !== undefined) {
        pathId = parseInt(identifier, 10);
    }

    // We have a location id in the path and it was a number, then use it.
    if (!isNaN(pathId)) {
        return pathId;
    }

    return 0;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item

    let rowValue = row.getValue(columnId);

    // some swap to fix for boolean searches.
    if (typeof rowValue === 'boolean') {
        rowValue = rowValue ? 'Yes' : 'No';
    }

    const itemRank = rankItem(rowValue, value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

export const getDateWithoutModification = (date: string) => {
    const dateObj = new Date(date);
    const adjustedDateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000); // Adjust for the timezone offset
    const isoString = adjustedDateObj.toISOString();
    return isoString;
};

function findTimeDifferenceInMinutes(startTime: string, endTime: string) {
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');
    const startMinutes = parseInt(startHour) * 60 + parseInt(startMinute);
    const endMinutes = parseInt(endHour) * 60 + parseInt(endMinute);
    let timeDiffMinutes = endMinutes - startMinutes;
    if (endMinutes < startMinutes) {
        timeDiffMinutes += 24 * 60;
    }
    return [startMinutes, startMinutes + timeDiffMinutes];
}

function convertTo24HourFormat(time: string) {
    const [hour, minute, period] = time.split(':');

    let hourIn24Format = parseInt(hour);

    if (period === 'PM' && hourIn24Format !== 12) {
        hourIn24Format += 12;
    } else if (period === 'AM' && hourIn24Format === 12) {
        hourIn24Format = 0;
    }

    return `${hourIn24Format}:${minute}`;
}

function normalizeGMTString(dateTimeStr: string) {
    let localDate = new Date(dateTimeStr);
    let year = localDate.getFullYear();
    let month = localDate.getMonth() + 1;
    let day = localDate.getDate();
    // Create a new Date object in UTC with the given year, month, and day.
    // Note that JavaScript's months are 0-indexed, so subtract 1 from the month.
    let date = new Date(Date.UTC(year, month - 1, day));
    // Return the date as an ISO string.
    return date.toISOString();
}

const minutesToHoursMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
};

// Conversion of food data in csv format
const generateCSVDataForFoodOrder = (data: any[]) => {
    const csvRows: string[] = [];

    // Adding the CSV header row with bold and capitalized headers
    const headers = Object.keys(data[0]).filter((item) => item !== 'products');
    const productHeaders = data[0].products.map((product: any, index: number) => `Product${index + 1}`);
    const headerRow = headers.concat(productHeaders);

    const formatHeaderName = (name: string) => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    const formattedHeaderRow = headerRow.map((header) => {
        return formatHeaderName(header.charAt(0).toUpperCase() + header.slice(1));
    });
    csvRows.push(formattedHeaderRow.join(','));

    // Adding data rows
    data.forEach((item) => {
        // Create a new object with formatted eventDate, startTime, and endTime, and other columns unchanged
        const formattedData = {
            ...item,
            eventDate: new Date(item.eventDate).toISOString().split('T')[0],
            startTime: minutesToHoursMinutes(item.startTime),
            endTime: minutesToHoursMinutes(item.endTime),
        };

        // Convert product items into a string representation
        const productColumns = item.products.map((product: any) => `${product.categoryName}: ${product.productName}`);

        // Create a new row with main data and product data, including formatted eventDate, startTime, and endTime
        const dataRow = [...headers.map((header) => formattedData[header]), ...productColumns];
        csvRows.push(dataRow.join(','));
    });

    return csvRows.join('\n');
};
// Download food data in csv format
const downloadFoodOrderCSV = (data: any[]) => {
    const csvData = generateCSVDataForFoodOrder(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = 'food-orders.csv';

    // Trigger the click event on the anchor element to initiate download
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the URL and removing the temporary anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
// Conversion of tickets data in csv format
const generateCSVDataForTickets = (data: any[]) => {
    const csvRows: string[] = [];

    // Adding the CSV header row with bold and capitalized headers
    const headers = Object.keys(data[0]);

    const formatHeaderName = (name: string) => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
    };
    const formattedHeaderRow = headers.map((header) => {
        return formatHeaderName(header.charAt(0).toUpperCase() + header.slice(1));
    });
    csvRows.push(formattedHeaderRow.join(','));

    // Adding data rows
    data.forEach((item) => {
        const formattedData = {
            ...item,
            eventDate: new Date(item.eventDate).toISOString().split('T')[0],
            dateUsed: new Date(item.eventDate).toISOString().split('T')[0],
            startTime: minutesToHoursMinutes(item.startTime),
            endTime: minutesToHoursMinutes(item.endTime),
        };

        const dataRow = headers.map((header) => {
            return `"${formattedData[header]}"`;
        });
        csvRows.push(dataRow.join(','));
    });

    return csvRows.join('\n');
};
// Download tickets data in csv format
const downloadTicketsCSV = (data: any[]) => {
    const csvContent = generateCSVDataForTickets(data);

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodedURI}`);
    link.setAttribute('download', 'tickets.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// Conversion of report data in csv format
const generateCSVDataForReport = (data: any[]) => {
    const csvRows: string[] = [];

    // Adding the CSV header row with bold and capitalized headers
    const headers = Object.keys(data[0]);

    const formatHeaderName = (name: string) => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
    };
    const formattedHeaderRow = headers.map((header) => {
        return formatHeaderName(header.charAt(0).toUpperCase() + header.slice(1));
    });
    csvRows.push(formattedHeaderRow.join(','));

    // Adding data rows
    data.forEach((item) => {
        const formattedData = {
            ...item,
            eventDate: formattedDate(new Date(item.eventDate?.toString())).shortDate,
            startTime: minutesToHoursMinutes(item.startTime),
        };

        const dataRow = headers.map((header) => {
            return `"${formattedData[header]}"`;
        });
        csvRows.push(dataRow.join(','));
    });

    return csvRows.join('\n');
};
// Download report data in csv format
const downloadReportCSV = (data: any[], name?: string) => {
    const csvData = generateCSVDataForReport(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = name ? name : 'event-report.csv';

    // Trigger the click event on the anchor element to initiate download
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the URL and removing the temporary anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
function toTimeString(time: number) {
    var h = Math.floor(time / 60);
    var m = time % 60;

    var hours = h < 10 ? '0' + h : h;
    var minutes = m < 10 ? '0' + m : m;
    return `${hours}:${minutes}`;
}
interface MenuItem {
    eventMenuId: number;
    menuName: string;
    displayTitle: string;
    dateEffective: string;
    dateExpires: string | null;
    sortOrder: number;
}

function filterExpiredItems(menuItems: MenuItem[]): MenuItem[] {
    const currentDate = new Date();

    if (menuItems?.length === 0) return [];
    const filteredItems = menuItems?.filter((item) => {
        if (item.dateExpires === null) {
            return true; // Keep items with null dateExpires
        }

        const expiresDate = new Date(item.dateExpires);
        return expiresDate >= currentDate;
    });

    return filteredItems;
}

function filterExpiredEventMenus(menuItems: MenuItem[], minEventDate: any, maxEventDate: any): MenuItem[] {
    const parsedMinEventDate = new Date(minEventDate);
    const parsedMaxEventDate = new Date(maxEventDate);

    const filteredArray = menuItems?.filter((item: any) => {
        const parsedDateExpires = new Date(item.dateExpires);
        const parsedDateEffective = new Date(item.dateEffective);
        const isMinEventDateGreaterThanEffectiveDate = parsedMinEventDate > parsedDateEffective;
        const isMaxEventDateLessThanExpireDate = parsedMaxEventDate < parsedDateExpires;

        if (!item?.dateExpires) {
            return isMinEventDateGreaterThanEffectiveDate;
        }
        return isMinEventDateGreaterThanEffectiveDate && isMaxEventDateLessThanExpireDate;
    });

    return filteredArray;
}
function formatDate(d: Date) {
    return (
        d.getFullYear() +
        '-' +
        (d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
        '-' +
        (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
    );
}

const getSortedData = (data: string[]) => {
    const sortedArray = [...data].sort();
    return sortedArray;
};

function findMinDate(events: any[]) {
    if (events?.length === 1) {
        return events[0]?.eventDate;
    }

    return events.reduce(
        (minDate, event) => (event.eventDate < minDate ? event.eventDate : minDate),
        events[0].eventDate,
    );
}

function findMaxDate(events: any) {
    if (events.length === 1) {
        return events[0].eventDate;
    }

    return events.reduce(
        (maxDate: any, event: any) => (event.eventDate > maxDate ? event.eventDate : maxDate),
        events[0].eventDate,
    );
}

function roundToTwoDecimalPoints(value: any): string {
    return parseFloat(value).toFixed(2);
}

const hasManageClaims = (claims: any, currentSelectedCompanyId: number) => {
    const manageCompanyClaims = getClaims(claims?.claims, 'manage.company');
    const selectedCompanyId =
        manageCompanyClaims.length === 1 ? parseInt(manageCompanyClaims[0].value ?? '0', 10) : currentSelectedCompanyId;
    const hasFullCompanyAccess = hasClaim(claims?.claims, 'operator.access.company', ['full']);
    const hasCompanyAccess = hasClaim(claims?.claims, 'manage.company', ['*']);
    if (hasFullCompanyAccess) {
        return true;
    } else if (hasCompanyAccess) {
        const hasCompanyManageDetailsClaim = hasClaim(claims?.claims, 'manage.company.details', ['*']);
        const manageCompanyClaims = getClaims(claims?.claims, 'manage.company');
        const defaultCompanyId = parseInt(manageCompanyClaims[0]?.value ?? '0', 10);
        const manageCompanyDetailClaims = getClaims(claims?.claims, 'manage.company.details');
        const manageCompanyDetailId = parseInt(manageCompanyDetailClaims[0]?.value ?? '0', 10);
        if (
            selectedCompanyId === defaultCompanyId &&
            hasCompanyManageDetailsClaim &&
            manageCompanyDetailId === selectedCompanyId
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
export {
    hasManageClaims,
    normalizeGMTString,
    classNames,
    debounce,
    formatBase64,
    formatBytes,
    formattedDate,
    fuzzyFilter,
    getRange,
    keepForNMinutes,
    keepForOneDay,
    parseLocationIdFromString,
    parseIdentifierFromString,
    pagination,
    timeInMinutesToSections,
    timer,
    timeToString,
    hasClaim,
    getClaims,
    convertTo24HourFormat,
    findTimeDifferenceInMinutes,
    downloadFoodOrderCSV,
    downloadTicketsCSV,
    downloadReportCSV,
    toTimeString,
    filterExpiredItems,
    formatDate,
    getSortedData,
    formatDateTime,
    filterExpiredEventMenus,
    findMaxDate,
    findMinDate,
    formattedDateWithTime,
    roundToTwoDecimalPoints,
};
