import { useNotifications } from '../../context/notification-context';
import { NotificationType } from '../../core/types';
import ErrorSimple from './error-simple';
import SuccessSimple from './sucess-simple';

export default function NotificationRegion() {
    const { notifications } = useNotifications();

    // TODO: clear callback timer on pressing close/cross button/icon
    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live='assertive'
                className='pointer-events-none z-50 fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'>
                <div className='max w-sm w-full grid grid-cols-1 gap-4'>
                    {notifications.filter((ntf) => ntf.style === NotificationType.SuccessSimple).length > 0 && (
                        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                            {notifications
                                .filter((ntf) => ntf.style === NotificationType.SuccessSimple)
                                .map((ntf, idx) => (
                                    <SuccessSimple key={`success_simple_${idx}_${Date.now()}`} notification={ntf} />
                                ))}
                        </div>
                    )}
                    {notifications.filter((ntf) => ntf.style === NotificationType.ErrrorSumple).length > 0 && (
                        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                            {notifications
                                .filter((ntf) => ntf.style === NotificationType.ErrrorSumple)
                                .map((ntf, idx) => (
                                    <ErrorSimple key={`error_simple_${idx}_${Date.now()}`} notification={ntf} />
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
