import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import { NumberField, SingleDateSelectionInputProps, TextField } from 'components/form-fields';
import { useEffect, useState } from 'react';
import { classNames } from 'core/utils';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    trigger?: any;
    setDirty?: any;
}

export default function BrandSection(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.brands.brand_details', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.brands.brand_details_description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextField
                                fieldName='brand.name'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.brands.field_names.name'
                                isRequired={true}
                                data_cy='name'
                            />
                            <TextField
                                fieldName='brand.displayTitle'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.brands.field_names.display_title'
                                isRequired={true}
                                data_cy='display-title'
                            />
                            <NumberField
                                fieldName='brand.sortOrder'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                step='1'
                                fieldLabel='pages.brands.field_names.sort_order'
                                minimum={1}
                                isRequired={true}
                                data_cy='sort-order'
                            />
                            <DatePickerField
                                fieldName='brand.dateEffective'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                minimum={new Date()}
                                trigger={props.trigger}
                                fieldLabel='pages.brands.field_names.date_effective'
                                defaultValue={null}
                                isRequired={true}
                                data_cy='date-effective'
                                setDirty={props.setDirty}
                            />
                            <TextField
                                fieldName='brand.urlSlug'
                                overrideContainerCss='col-span-12'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.brands.field_names.url_slug'
                                isRequired={true}
                                data_cy='url-slug'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function DatePickerField(props: SingleDateSelectionInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [inputDate, setInputDate] = useState<Date | null>(props.defaultValue);
    const initialValue = props.getValues(props.fieldName);

    useEffect(() => {
        props.setValue(props.fieldName, inputDate, {
            shouldDirty: true,
        });
        if (inputDate && props.trigger) {
            props.trigger(props.fieldName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDate, props.trigger]);

    // this is used to clear the field if reset is called on the main react-form-hook
    useEffect(() => {
        if (initialValue) {
            setInputDate(new Date(initialValue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (initialValue === null || initialValue === undefined || initialValue === '') {
            setInputDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    {...props.register(props.fieldName)}
                    type='hidden'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    data-cy={`${props.data_cy}`}
                />
                <DatePicker
                    todayButton={t('calendar.today')}
                    locale='en-GB'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
                    selected={inputDate}
                    calendarStartDay={1}
                    minDate={props.minimum ? props.minimum!! : null}
                    maxDate={props.maximum ? props.maximum!! : null}
                    isClearable={false}
                    dateFormat='yyyy-MM-dd'
                    onChange={(date: Date) => setInputDate(date)}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}
