import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnGeneratorInputProps } from '../../../core/types';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();

    const columnHelper = createColumnHelper<any>();
    let columnDefs = [
        columnHelper.accessor((row) => row.displayTitle, {
            id: 'displayTitle',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_venues.resource_config_table.headers.display_title', ''),
        }),
        columnHelper.accessor((row) => row.resourceCapacity, {
            id: 'resourceCapacity',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.resource_capacity', ''),
        }),
        columnHelper.accessor((row) => row.totalResources, {
            id: 'totalResources',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.total_resources', ''),
        }),
        columnHelper.accessor((row) => row.minSize, {
            id: 'minSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.min_size', ''),
        }),
        columnHelper.accessor((row) => row.maxSize, {
            id: 'maxSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.max_size', ''),
        }),
    ];

    return columnDefs;
}
