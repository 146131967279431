export function getDateIsoString() {
    var currentDate = new Date(); // Current date

    // Calculate tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);

    // Format the date
    const formattedTomorrowDate = tomorrowDate.toISOString().split('T')[0];

    // Formatting the dates as "YYYY-MM-DDTHH:mm:ss.sssZ"
    var currentDateString = currentDate.toISOString().split('T')[0];
    return {
        currentDate: currentDateString,
        tomorrowDate: formattedTomorrowDate,
    };
}
