import React, { useEffect, useState } from 'react';
import ConfigurationSlotsList from './configurationSlots';
import ConfigurationAmountList from './configurationAmount';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from 'components/table';
import { getEventVenueResourcesConfigurations } from 'fetch/event';
import { useQuery } from 'react-query';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { keepForNMinutes } from 'core/utils';
import { NotificationType } from 'core/types';
import Columns from './columns';
import Spin from 'components/Spin';
interface ResourceData {
    data: Data | undefined;

    isLoading: boolean;
    refetch: Function;
}

interface Data {
    eventVenueResourceConfigurationId: number;
    eventVenueResourceId: number;
    displayTitle: string;
    resourceTypeId: number;
    resourceTypeName: any;
    resourceCapacity: number;
    totalResources: number;
    minSize: number;
    maxSize: number;
    eventVenueResourceConfigurationAmount: any;
    eventVenueResourceBookingSlots: any;
}
const ConfigurationList = () => {
    let { companyId, eventVenueResourceConfigurationId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [data, setData] = useState<any[]>([]);
    const client = useClient();

    const { data: eventVenuesResourceData, isLoading }: ResourceData = useQuery(
        ['configurationList-data', companyId, eventVenueResourceConfigurationId],
        () => {
            const res = getEventVenueResourcesConfigurations(
                companyId as string,
                eventVenueResourceConfigurationId as string,
                client,
            );
            if (res) return res;
        },
        keepForNMinutes(1),
    );

    // Set the event data to a format that is easier to use in the UI.
    useEffect(() => {
        if (eventVenuesResourceData === undefined || eventVenuesResourceData === null) {
            if (!isLoading && !eventVenuesResourceData) {
                addNotification({
                    description: t('pages.event_venues.messages.resource_list_error_description'),
                    title: t('pages.event_venues.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        }
        if (eventVenuesResourceData && eventVenuesResourceData !== undefined) {
            const uiModel: any = {
                displayTitle: eventVenuesResourceData?.displayTitle,
                resourceCapacity: eventVenuesResourceData?.resourceCapacity,
                totalResources: eventVenuesResourceData?.totalResources,
                minSize: eventVenuesResourceData?.minSize,
                maxSize: eventVenuesResourceData?.maxSize,
                eventVenueResourceConfigurationId: eventVenuesResourceData?.eventVenueResourceConfigurationId,
            };
            setData([uiModel]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventVenuesResourceData, isLoading]);

    return (
        <Spin spinning={isLoading}>
            <span className='mb-8 relative inline-flex w-full items-center justify-between'>
                <button
                    onClick={() => navigate(-1)}
                    type='button'
                    className='inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                    </>
                </button>
                <h1 className='font-semibold'>{t('pages.event_venues_detail.resources.configurations', 'MISSING')}</h1>
                <button
                    onClick={() => navigate('../')}
                    type='button'
                    className='inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.cancel', 'MISSING')}</span>
                    </>
                </button>
            </span>
            <DataTable<any>
                data={data}
                columnTranslationPrefix={'pages.event_venues.resource_config_table.headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                hideTableFooter={true}
                noDataMessage={'pages.event_venues.resource_config_table.empty'}
            />
            <div className='mt-8 grid gap-6 grid-cols-12'>
                <div className='col-span-6'>
                    <ConfigurationSlotsList />
                </div>
                <div className='col-span-6'>
                    <ConfigurationAmountList />
                </div>
            </div>
        </Spin>
    );
};

export default ConfigurationList;
