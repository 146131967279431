import { MagnifyingGlassIcon, AdjustmentsVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import ContextHelp from 'components/context-help';
import { HelpContextTarget } from 'core/types';
import { debounce } from 'core/utils';

export interface InputProps {
    setGlobalFilter: Dispatch<SetStateAction<string>>;
    setColumnFilterOpen: Dispatch<SetStateAction<boolean>>;
    title: string;
    description: string;
    target: HelpContextTarget;
    actionButtonText: string;
    hideActionButton?: boolean;
    hideSearch?: boolean;
    handleActionButton: () => void;
}

export default function TableActionPanel(props: InputProps) {
    const { setGlobalFilter, setColumnFilterOpen, hideActionButton = false, hideSearch = false } = props;

    const filterChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        debounce(() => setGlobalFilter(event.target.value), 500)();
    };

    return (
        <>
            <div className='md:top-1 right-10 w-full justify-items-center lg:justify-items-start grid grid-cols-1 lg:grid lg:grid-cols-2'>
                <div className='w-1/2'>
                    {!hideSearch && (
                        <>
                            <label htmlFor='email' className='sr-only'>
                                {t('action_panel.search_box')}
                            </label>
                            <div className='relative mt-1 rounded-md shadow-sm'>
                                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                                    <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                </div>
                                <input
                                    type='text'
                                    name='tableDataFilter'
                                    id='tableDataFilter'
                                    onChange={filterChangeHandler}
                                    className='block w-full rounded-md border-gray-300 pl-10 focus:border-gray-500 focus:ring-gray-500 sm:text-sm'
                                    placeholder={t('action_panel.search_box')}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className='w-full mt-4 lg:mt-0 flex justify-center lg:justify-end'>
                    {!hideActionButton ? (
                        <span className='relative inline-flex'>
                            <button
                                onClick={props.handleActionButton}
                                type='button'
                                className='inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                                <>
                                    <PlusIcon
                                        className='lg:text-gray-400 text-white group-hover:text-gray-300 lg:mr-2 flex-shrink-0 h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span className='hidden lg:block'>{t(props.actionButtonText, 'MISSING')}</span>
                                </>
                            </button>
                        </span>
                    ) : null}
                    <ContextHelp title={props.title} description={props.description} target={props.target} />
                </div>
            </div>
        </>
    );
}
