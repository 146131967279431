import { EventVenueSubSectionTarget, HelpContextTarget, NotificationType } from 'core/types';
import {
    EventVenueResourceConfigurationAdminDto,
    EventVenueResourceConfigurationCreateRequest,
} from 'api/operator-api-types';
import { useReducer, useState } from 'react';
import { ChangesPanel, ErrorPanel, FormActionPanel } from 'components/action-panel';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SubSectionAddForm from '../../manage/sub-section-add';
import EventVenueConfigurationSection from '../../manage/venue-configuration-section';
import { createEventVenueResourceConfiguration } from 'fetch/event/event-venue';
import { useClient } from 'core/api-client';
import { useNotifications } from 'context/notification-context';
import { useTranslation } from 'react-i18next';

// An interface for our actions
interface ReducerAction {
    type: EventVenueSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: EventVenueSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== EventVenueSubSectionTarget.Closed,
    };

    switch (action.type) {
        case EventVenueSubSectionTarget.Configuration:
            res.title = 'pages.event_venues_detail.configurations.title';
            res.description = 'pages.event_venues_detail.configurations.description';
            break;
    }

    return res;
}

export default function ManageEventVenueResouceConfigurations() {
    const client = useClient();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [keepDirty, setKeepDirty] = useState(false);
    const { addNotification } = useNotifications();
    const { companyId, eventVenueResourceId } = useParams();

    const [configurations, setConfigurations] = useState<EventVenueResourceConfigurationAdminDto[]>([]);
    const [processing, setProcessing] = useState(false);
    const [formError, setIsFormError] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [state, dispatch] = useReducer(reducer, {
        panel: EventVenueSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });

    const {
        handleSubmit,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        if (companyId && eventVenueResourceId) {
            setProcessing(true);
            const rsp = await createEventVenueResourceConfiguration(
                Number(companyId),
                Number(eventVenueResourceId),
                EventVenueResourceConfigurationCreateRequest.fromJS({
                    eventVenueResourceConfiguration: configurations,
                }),
                client,
            );

            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }

            if (rsp.status && rsp.status != 200) {
                addNotification({
                    description: t('pages.event_detail.detail.error_description'),
                    title: t('pages.event_detail.detail.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            } else {
                // Success
                addNotification({
                    description: t('pages.event_detail.detail.success_description'),
                    title: t('pages.event_detail.detail.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                if (rsp != null) {
                    navigate(`../${eventVenueResourceId}/configurations`, { replace: true });
                }
            }
        }
    });

    const openAddSubSection = (target: EventVenueSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };

    const handleReset = () => {
        setServerErrorList([]);
        setTraceId(null);
        reset();
    };

    const addConfiguration = (data: EventVenueResourceConfigurationAdminDto) => {
        var newConfigurations = [...configurations];
        newConfigurations.push(data);
        setConfigurations(newConfigurations);
    };

    return (
        <>
            <FormActionPanel
                isDirty={!isEdit || isDirty || keepDirty}
                processing={processing}
                handleReset={handleReset}
                contextTitle='pages.event_detail.context.title'
                contextDescription='pages.event_detail.context.description'
                contextTarget={HelpContextTarget.EventVenue}
                formTarget='addConfigurationForm'
            />
            <form onSubmit={onSubmit} noValidate={true} id='addConfigurationForm' className='space-y-6'>
                <ChangesPanel isDirty={isDirty} />
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <EventVenueConfigurationSection
                    data={configurations}
                    setConfigurations={setConfigurations}
                    addCallback={openAddSubSection}
                    setKeepDirty={setKeepDirty}
                />
            </form>
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                addConfiguration={addConfiguration}
            />
        </>
    );
}
