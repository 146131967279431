import { OPERATOR_API_URL } from '../../core/api-client';
import { CompanyCreateRequest, CompanyDto, CompanyResponseModel } from 'api/operator-api-types';
import { ClientApiOptions } from 'core/types';

export const deleteCompany = async (
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const createCompany = async (
    data: CompanyCreateRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

// User APIS
export const addUser = async (
    companyId: string | undefined,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}/user`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateUser = async (
    companyId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}/updateuser`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getUser = async (
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}/user`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

// Products
export const getProducts = async (
    companyId: string,
    eventMenuId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu/${eventMenuId}/products`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getMenuCategories = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/category/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getPageRoutes = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/pages/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventList = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/events/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getBrandList = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/brands/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const addMenuProduct = async (
    companyId: string | undefined,
    eventMenuId: string | undefined,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu/${eventMenuId}/product`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const fetchMenuProductById = async (
    companyId: string,
    eventMenuProductId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu-product/${eventMenuProductId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateMenuProduct = async (
    companyId: string,
    eventMenuProductId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu-product/${eventMenuProductId}`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getBookingFeeTypes = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/ref/getbookingtypes`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

// Menus
export const getMenus = async (
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu/all`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateMenu = async (
    companyId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/eventmenu`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export const deleteMenu = async (
    eventMenuId: string,
    companyId: string,

    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/menus/${eventMenuId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteProduct = async (
    companyId: string,
    eventMenuId: string,
    eventMenuProductId: string,

    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/menus/${eventMenuId}/products/${eventMenuProductId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const addMenu = async (
    companyId: string | undefined,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/eventmenu`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteCompanyUser = async (
    companyId: string,
    userId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}/user/${userId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const fetchUserById = async (
    companyId: string,
    userId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}/user/${userId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const fetchMenuById = async (
    companyId: string,
    eventMenuId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/menu/${companyId}/event-menu/${eventMenuId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateCompany = async (
    companyId: number,
    //potentially the wrong data model but we dont have "LocationRequest" equivalent. although this model has the same values
    data: CompanyResponseModel,
    client: (endpoint: string, config: ClientApiOptions) => Promise<CompanyResponseModel | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

//fetches all the companies for the company index page
export const fetchAllCompanies = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<CompanyResponseModel>,
) => {
    const defaultReturn: CompanyResponseModel = CompanyResponseModel.fromJS({ company: new CompanyDto() });
    return client(`${OPERATOR_API_URL}/api/v1/company/all`, {})
        .then((rsp) => rsp)
        .catch(() => defaultReturn);
};

export const getCompany = async (
    companyId: number,
    client: (endpoint: string, config: ClientApiOptions) => Promise<CompanyDto>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/company/${companyId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
