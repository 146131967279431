import { Link } from 'react-router-dom';
import lostImage from '../assets/image-404.jpg';

function NotFound() {
    return (
        <div className='bg-white min-h-full flex flex-col lg:relative'>
            <div className='flex-grow flex flex-col'>
                <main className='flex-grow flex flex-col bg-white'>
                    <div className='flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8'>
                        <div className='flex-shrink-0 my-auto py-16 sm:py-32'>
                            <p className='text-sm font-semibold text-gray-100 uppercase tracking-wide'>404 error</p>
                            <h1 className='mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
                                Page not found
                            </h1>
                            <p className='mt-2 text-base text-gray-500'>
                                Sorry, we couldn't find the page you're looking for.
                            </p>
                            <div className='mt-6'>
                                <Link
                                    to={'/'}
                                    className='inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                                    Go back home
                                    <span className='ml-2' aria-hidden='true'>
                                        &rarr;
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
                <img
                    className='absolute inset-0 h-full w-full object-cover'
                    src={lostImage}
                    alt='This page could not be found.'
                />
            </div>
        </div>
    );
}

export default NotFound;
