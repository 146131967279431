import { createContext, useContext, useState } from 'react';
import { CompanyContextType } from 'core/types';
const CompanyContext = createContext<CompanyContextType | null>(null);

export function AppCompanyContext({ children }: { children: React.ReactNode }) {
    // read the location id param from the path.
    // Check if the location id in the path was a number, if it was present.
    const [companyIdentifier, setCompanyIdentifier] = useState<number>(0);

    return (
        <CompanyContext.Provider value={{ companyIdentifier, setCompanyIdentifier }}>
            {children}
        </CompanyContext.Provider>
    );
}

export function useSelectedCompany() {
    const context = useContext(CompanyContext);

    if (!context) {
        throw new Error('useSelectedCompany has to be used within <AppLocationContext.Provider>');
    }

    return context;
}
