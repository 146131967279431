import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchTicketSchema } from 'core/validation-schema';
import { yupResolver } from '@hookform/resolvers/yup';

import DataTable from 'components/table';
import { ColumnActionType, NotificationType } from 'core/types';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';
import { EmailField } from 'components/form-fields';

import { SaveButton } from 'components/buttons';
import { useConfirm } from 'context/confirmation-context';
import { getTicketsByEmail, validateTicketsByScanning } from 'fetch/tickets';
import Columns from './columns';
import QRScanner from 'components/QRScanner';
import { useAuth } from 'context/auth-context';

function SearchTicketByEmail() {
    let { companyId, eventVenueResourceId } = useParams();
    const client = useClient();
    const { user } = useAuth();
    const userId = user?.userId;
    const { isConfirmed } = useConfirm();
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [showScanner, setShowScanner] = useState(false);
    const [scannerError, setScannerError] = useState(false);
    const [scannedData, setScannedData] = useState('');

    const [submit, setSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [data, setData] = useState<any[]>([]);

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                ticketValidator(row?.ticketCode);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        console.log('confirmed');
                    }
                };

                deleteItem();
                break;
        }
    };

    async function getTickets(email: string) {
        setIsLoading(true);
        try {
            const eventTicketsData = await getTicketsByEmail(
                companyId as string,
                email,
                eventVenueResourceId!!,
                client,
            );
            setIsLoading(false);
            if (eventTicketsData) {
                if (eventTicketsData === undefined || !Array.isArray(eventTicketsData) || eventTicketsData === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.event_tickets.messages.list_error_description'),
                            title: t('pages.event_tickets.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                        setSubmit(true);
                    }
                } else {
                    const uiModel: any[] = eventTicketsData?.map((item) => {
                        return {
                            ticketId: item?.ticketId,
                            customerEventBookingId: item?.customerEventBookingId,
                            ticketKey: item?.ticketKey,
                            displayName: item?.displayName,
                            ticketReadyNotes: item?.ticketReadyNotes,
                            notes: item?.notes,
                            ticketDate: item?.ticketDate,
                            ticketCode: item?.ticketCode,
                            isUsed: item?.isUsed,
                            dateUsed: item?.dateUsed,
                        };
                    });
                    setData(uiModel);
                    if (!uiModel?.length) {
                        setSubmit(true);
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            setSubmit(true);
            console.log('error', error);
        }
    }

    // validate ticket scanning
    async function ticketValidator(ticketId?: string) {
        setIsLoading(true);
        try {
            const scanningResult = await validateTicketsByScanning(
                companyId!!,
                userId!!,
                eventVenueResourceId!!,
                ticketId ? ticketId : scannedData!!,
                client,
            );
            setIsLoading(false);
            if (scanningResult && !ticketId) {
                if (scanningResult?.status === 422) {
                    addNotification({
                        description: t('pages.event_tickets.messages.scan_failed'),
                        title: t('pages.event_tickets.messages.scan_failed'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setShowScanner(false);
                } else if (scanningResult?.status === 400) {
                    addNotification({
                        description: t('pages.event_tickets.messages.already_use_ticket'),
                        title: t('pages.event_tickets.messages.already_use_ticket'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setShowScanner(false);
                } else if (scanningResult?.status === 202 || scanningResult === true) {
                    addNotification({
                        description: t('pages.event_tickets.messages.scan_success'),
                        title: t('pages.event_tickets.messages.scan_success'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    setShowScanner(false);
                } else {
                    addNotification({
                        description: t('pages.event_tickets.messages.scan_failed'),
                        title: t('pages.event_tickets.messages.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setShowScanner(false);
                }
            } else {
                if (scanningResult) {
                    if (scanningResult?.status === 422) {
                        addNotification({
                            description: t('pages.event_tickets.messages.scan_failed'),
                            title: t('pages.event_tickets.messages.scan_failed'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    } else if (scanningResult?.status === 400) {
                        addNotification({
                            description: t('pages.event_tickets.messages.already_use_ticket'),
                            title: t('pages.event_tickets.messages.already_use_ticket'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    } else if (scanningResult?.status === 202 || scanningResult === true) {
                        addNotification({
                            description: t('pages.event_tickets.messages.scan_success'),
                            title: t('pages.event_tickets.messages.scan_success'),
                            style: NotificationType.SuccessSimple,
                            timeoutInMilliseconds: 3000,
                        });
                    } else {
                        addNotification({
                            description: t('pages.event_tickets.messages.scan_failed'),
                            title: t('pages.event_tickets.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    if (scanningResult) {
                        addNotification({
                            description: t('pages.event_tickets.messages.scan_success'),
                            title: t('pages.event_tickets.messages.scan_success'),
                            style: NotificationType.SuccessSimple,
                            timeoutInMilliseconds: 3000,
                        });
                        getTickets(email);
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    // Format the form handler.
    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(SearchTicketSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit((data: any) => {
        const formData = data?.ticket;
        if (formData?.email !== null) {
            setEmail(formData?.email);
            getTickets(formData?.email);
            reset();
        }
    });

    useEffect(() => {
        if (scannedData !== '') {
            setShowScanner(false);
            ticketValidator();
        }
        if (scannerError) {
            setShowScanner(false);
            addNotification({
                description: t('pages.event_tickets.messages.scanner_error'),
                title: t('pages.event_tickets.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scannedData, scannerError]);

    return (
        <>
            {showScanner && (
                <div
                    style={{ zIndex: 9999 }}
                    className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col justify-center items-center'>
                    <QRScanner
                        show={showScanner}
                        onRead={(data: any) => setScannedData(data)}
                        onError={(error: any) => setScannerError(error)}
                    />
                    <div>
                        <SaveButton
                            onClick={() => {
                                setShowScanner(false);
                                setScannedData('');
                            }}
                            processing={false}
                            isDirty={true}
                            formTarget={''}
                            buttonText={t('pages.event_tickets.stop_scan')}
                        />
                    </div>
                </div>
            )}
            <Spin spinning={isLoading}>
                {!showScanner && (
                    <div className='grid grid-cols-12 gap-2'>
                        <div className='col-span-12 sm:col-span-4'>
                            <SaveButton
                                processing={false}
                                isDirty={true}
                                onClick={() => {
                                    setShowScanner(true);
                                    setScannedData('');
                                }}
                                formTarget={''}
                                buttonText={t('pages.event_tickets.scan_ticket')}
                            />
                        </div>
                    </div>
                )}
                <p className='my-4 text-sm'>{t('pages.event_tickets.text_info')}</p>
                <form onSubmit={onSubmit} noValidate={true} id='formRef' className='space-y-6'>
                    <div>
                        <div className='my-8 grid grid-cols-12 gap-2'>
                            <EmailField
                                placeholder={t('pages.event_tickets.email_placeholder')}
                                fieldName='ticket.email'
                                overrideContainerCss='col-span-12 md:col-span-8'
                                getFieldState={getFieldState}
                                register={register}
                                fieldLabel='pages.users.field_names.email'
                                isRequired={true}
                                data_cy='ticket.email'
                            />
                            <div
                                className={`flex ${
                                    errors?.ticket ? 'items-center' : 'items-end'
                                } col-span-12 md:col-span-4`}>
                                <SaveButton
                                    processing={false}
                                    isDirty={showScanner ? false : true}
                                    formTarget={'formRef'}
                                    buttonText={t('pages.event_tickets.search_btn_text')}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                {data?.length > 0 ? (
                    <DataTable<any>
                        data={data}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        selectSingleAction={handleTableAction}
                        columnTranslationPrefix={'pages.event_tickets.table.headers'}
                        columns={Columns}
                        canExpand={false}
                        canGlobalFilter={true}
                        enableRowSelection={true}
                        noDataMessage={'pages.event_tickets.table.empty_tickets'}
                    />
                ) : (
                    <p className='my-8'>{submit && t('pages.event_tickets.messages.no_ticket_found_error')}</p>
                )}
            </Spin>
        </>
    );
}

export default SearchTicketByEmail;
