import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deleteEventVenueDetails, getEventVenue } from 'fetch/event';

import DataTable from 'components/table';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, LocationUiModel, NotificationType } from 'core/types';
import { useClient } from 'core/api-client';
import Columns from './columns';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';
import { convertToTimeFormat, getDateRange } from './helper';
import { DatePickerField } from 'components/form-fields';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FieldValues } from 'react-hook-form';
import { DateSchema } from 'core/validation-schema';
import { SaveButton } from 'components/buttons';
import { useConfirm } from 'context/confirmation-context';
import { toTimeString } from './manage/venue-resource-section';
import { useAuth } from 'context/auth-context';
import { useSelectedCompany } from 'context/company-context';
import { useQuery } from 'react-query';
import { fetchAuthenticated } from 'fetch/operator/system';
import { hasManageClaims, keepForNMinutes } from 'core/utils';

function EventVenue() {
    let { companyId, identifier: eventId } = useParams();
    const formValues = JSON.parse(localStorage?.getItem('dateCookie') as string);
    const date = formValues ? formValues : getDateRange();
    const { isConfirmed } = useConfirm();
    const [isDeleting, setIsDeleting] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [startDate, setStartDate] = useState(date.startDate);
    const [endDate, setEndDate] = useState(date.endDate);
    const [isLoading, setIsLoading] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [data, setData] = useState<any[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        id: true,
        description: true,
        url: true,
        dateEffective: true,
        sortOrder: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const handleAdd = () => {
        navigate('./create');
    };

    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: claimsLoading } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    // delete even-venue
    async function handleDelete(eventVenueId: string, eventVenueResourceId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteEventVenueDetails(
                eventVenueId as string,
                companyId!!,
                eventVenueResourceId!!,
                client,
            );
            if (res === 202 || res === 200) {
                addNotification({
                    description: t('common.api_messages.status_202'),
                    title: t('common.api_messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                getEventData();
                setIsDeleting(false);
            } else if (res === 409) {
                addNotification({
                    description: t('common.api_messages.status_409'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res === 422) {
                addNotification({
                    description: t('common.api_messages.status_422'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting location');
        }
    }

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                navigate(`./${row.eventVenueId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.eventVenueId!!, row.eventVenueResourceId!!);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    async function getEventData() {
        setIsLoading(true);
        try {
            const eventVenuesData = await getEventVenue(
                companyId as string,
                eventId as string,
                startDate,
                endDate,
                client,
            );
            if (eventVenuesData) {
                setIsLoading(false);
                if (eventVenuesData === undefined || !Array.isArray(eventVenuesData) || eventVenuesData === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.event_venues.messages.list_error_description'),
                            title: t('pages.event_venues.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const uiModel: any[] = eventVenuesData?.map((item) => {
                        return {
                            description: item?.description,
                            eventDate: item?.eventDate,
                            startTime: toTimeString(Number(item?.startTime)),
                            endTime: toTimeString(Number(item?.endTime)),
                            phone: item?.phoneNumber,
                            eventVenueId: item?.eventVenueId,
                            allowVoucher: item?.allowVoucher,
                            bookingCutOffDateTime: item?.bookingCutOffDateTime,
                            allowBooking: item?.allowBooking,
                            menuOrderingCutOffDateTime: item?.menuOrderingCutOffDateTime,
                            eventVenueResourceId: item?.eventVenueResourceId,
                            eventExternalCode: item?.eventExternalCode,
                        };
                    });
                    setData(uiModel);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    // Set the event data to a format that is easier to use in the UI.
    useEffect(() => {
        getEventData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, submit]);

    // Format the form handler.
    const { register, handleSubmit, getFieldState, getValues, setValue } = useForm<any>({
        resolver: yupResolver(DateSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        if (data?.date?.startDate !== null && data?.date?.endDate !== null) {
            const startDate = new Date(data?.date?.startDate);
            const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
            const endDate = new Date(data?.date?.endDate);
            const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            // setting cookie
            const dateCookieData = {
                startDate,
                endDate,
            };
            localStorage.setItem('dateCookie', JSON.stringify(dateCookieData));
            setSubmit(!submit);
        } else {
            setSubmit(!submit);
            setStartDate(date?.startDate);
            setEndDate(date?.endDate);
        }
    });

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1); // Set minimum date to 1 year ago

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1); // Set maximum date to 1 year later

    useEffect(() => {
        setValue('date.startDate', date.startDate);
        setValue('date.endDate', date.endDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Spin spinning={isLoading || isDeleting || claimsLoading}>
            <span className='relative inline-flex w-full items-start justify-between'>
                <button
                    onClick={() => navigate(-1)}
                    type='button'
                    className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                    </>
                </button>
                <TableActionPanel
                    setGlobalFilter={setGlobalFilter}
                    setColumnFilterOpen={setColumnFilterOpen}
                    title='pages.event_venues.context.title'
                    description='pages.event_venues.context.description'
                    actionButtonText='pages.event_venues.action_button'
                    target={HelpContextTarget.EventVenue}
                    handleActionButton={handleAdd}
                />
            </span>
            <form onSubmit={onSubmit} noValidate={true} id='dateForm' className='space-y-6'>
                <div className='my-8 grid grid-cols-12 gap-2'>
                    <DatePickerField
                        fieldName='date.startDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        minimum={minDate}
                        fieldLabel='pages.event_venues.dates.start'
                        defaultValue={null}
                        isRequired={false}
                        data_cy='date-startDate'
                    />
                    <DatePickerField
                        fieldName='date.endDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        maximum={maxDate}
                        fieldLabel='pages.event_venues.dates.end'
                        defaultValue={null}
                        isRequired={false}
                        data_cy='date-endDate'
                    />
                    <div className='flex items-end col-span-3'>
                        <SaveButton processing={false} isDirty={true} formTarget={'dateForm'} buttonText={'Apply'} />
                    </div>
                </div>
            </form>
            <DataTable<any>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.event_venues.table.headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.event_venues.table.empty'}
            />
        </Spin>
    );
}

export default EventVenue;
