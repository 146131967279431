import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.today, {
            id: 'today',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.today', ''),
        }),
        columnHelper.accessor((row) => row.thisWeek, {
            id: 'thisWeek',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.this_week', ''),
        }),

        columnHelper.accessor((row) => row.thisMonth, {
            id: 'thisMonth',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.this_month', ''),
        }),
    ];

    return columnDefs;
}
