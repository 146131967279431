import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';
import dayjs from 'dayjs';

import { FormActionPanel, ErrorPanel } from 'components/action-panel';
import { addExperience, getExperience, updateExperience } from 'fetch/experience';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { ExperienceSchema } from 'core/validation-schema';
import ExperienceSection from './create';
import { ExperienceRequest } from 'api/operator-api-types';
import Spin from 'components/Spin';
import useSoftDirty from 'hooks/useSoftDirty';

export default function ManageExperiences() {
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [data, setData] = useState<any>();
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const { isDirty, _setIsDirty: setDirty } = useSoftDirty('experienceForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const {
        data: experienceData,
        isLoading,
        refetch,
    } = useQuery(
        ['experienceData', identifier!!],
        () => {
            if (identifier && identifier !== 'create') {
                return getExperience(identifier!!, client).then((rsp) => {
                    return rsp;
                });
            }
        },
        keepForNMinutes(1),
    );

    // Format the form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(ExperienceSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        if (isEdit && identifier) {
            setProcessing(true);
            const rsp = await updateExperience(
                identifier,
                ExperienceRequest.fromJS({
                    experience: {
                        experienceId: identifier,
                        name: data.experience.name,
                        description: data.experience.description,
                        displayTitle: data.experience.displayTitle,
                        sortOrder: Number(data.experience.sortOrder),
                        urlSlug: data.experience.urlSlug,
                        dateEffective: new Date(dayjs(data.experience.dateEffective).format('YYYY-MM-DD')),
                        languageId: Number.parseInt(process.env.REACT_APP_LANGUAGE_ID ?? '1'),
                        ratingId: Number.parseInt(process.env.REACT_APP_RATING_ID ?? '1'),
                    },
                }),
                client,
            );
            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if ((rsp.status && rsp.status === 200) || rsp === 202) {
                addNotification({
                    description: t('pages.experience_detail.success_description'),
                    title: t('pages.experience_detail.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
                navigate(`/experience`, { replace: true });
            } else {
                addNotification({
                    description: t('pages.experience_detail.error_description'),
                    title: t('pages.experience_detail.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
            }
        } else {
            setProcessing(true);
            const rsp = await addExperience(
                ExperienceRequest.fromJS({
                    experience: {
                        name: data.experience.name,
                        description: data.experience.description,
                        displayTitle: data.experience.displayTitle,
                        sortOrder: Number(data.experience.sortOrder),
                        urlSlug: data.experience.urlSlug,
                        dateEffective: new Date(dayjs(data.experience.dateEffective).format('YYYY-MM-DD')),
                        languageId: Number.parseInt(process.env.REACT_APP_LANGUAGE_ID ?? '1'),
                        ratingId: Number.parseInt(process.env.REACT_APP_RATING_ID ?? '1'),
                    },
                }),
                client,
            );

            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if (!rsp?.traceId || rsp === 200 || rsp === 202) {
                addNotification({
                    description: t('pages.experience_detail.save_success_description'),
                    title: t('pages.experience_detail.save_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
                navigate(`/experience`, { replace: true });
            } else {
                addNotification({
                    description: t('pages.experience_detail.error_description'),
                    title: t('pages.experience_detail.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
            }
        }
    });

    useEffect(() => {
        setData(experienceData);
        if (data?.status && data?.status !== 200) {
            addNotification({
                description: data?.errors?.experienceId[0],
                title: t('pages.experience_detail.single_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            if (isEdit && data) {
                setValue('experience.name', data?.name);
                setValue('experience.displayTitle', data?.displayTitle);
                setValue('experience.description', data?.description);
                setValue('experience.sortOrder', data?.sortOrder);
                setValue('experience.urlSlug', data?.urlSlug);
                setValue('experience.dateEffective', new Date(data.dateEffective));
                refetch();
                setDirty();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experienceData, data]);
    return (
        <>
            <Spin spinning={(isEdit && isLoading) || processing}>
                <FormActionPanel
                    isDirty={isDirty}
                    processing={processing}
                    handleReset={handleReset}
                    contextTitle={'pages.experience_detail.context.title'}
                    contextDescription={'pages.experience_detail.context.description'}
                    contextTarget={HelpContextTarget.PaymentRule}
                    formTarget='experienceForm'
                />
                <form onSubmit={onSubmit} noValidate={true} id='experienceForm' className='space-y-6'>
                    <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                    <ExperienceSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        setDirty={setDirty}
                    />
                </form>
            </Spin>
        </>
    );
}
