import { CompletePasswordPostRequest, ManagedCompanyResponseModel, ResetPasswordPostRequest } from 'api/user-api-types';
import { ClientApiOptions } from 'core/types';
import { USER_API_URL } from 'core/api-client';

export const fetchManagedCompanies = async (
    userId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<ManagedCompanyResponseModel>,
) => {
    return client(`${USER_API_URL}/api/v1/account/managed-companies/${userId}`, {}).then((rsp) => rsp);
};

export const passwordReset = async (
    data: ResetPasswordPostRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<string | ValidationProblemDetails>,
) => {
    return client(`${USER_API_URL}/api/v1/account/password-reset/operator`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const verifiedPasswordReset = async (
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${USER_API_URL}/api/v1/account/password-reset`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}
export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
