import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { EmailField, NumberField, TextField } from 'components/form-fields';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    allowUser: boolean;
    setAllowUser: any;
    setAllowTicketScan: any;
    allowTicketScan: boolean;
    setAllowReport: any;
    allowReport: boolean;
    allowCompanyDetails: boolean;
    setAllowCompanyDetails: any;
}

export default function UserSection(props: InputProps) {
    const { t } = useTranslation();

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.users.context.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>{t('pages.users.context.description', 'MISSING')}</p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextField
                                fieldName='user.firstName'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.users.field_names.first_name'
                                isRequired={true}
                                data_cy='firstName'
                            />
                            <TextField
                                fieldName='user.lastName'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.users.field_names.last_name'
                                isRequired={true}
                                data_cy='lastName'
                            />
                            <EmailField
                                fieldName='user.email'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.users.field_names.email'
                                isRequired={true}
                                data_cy='email'
                            />
                            <NumberField
                                fieldName='user.phone'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.users.field_names.phone'
                                minimum={11}
                                maximum={11}
                                isRequired={true}
                                data_cy='phone'
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                            <div>
                                <h1>{t('pages.users.field_names.company_details_switch_title', 'MISSING')}</h1>
                                <div className='mt-1 flex items-center justify-between'>
                                    <p className='text-gray-400 text-base'>
                                        {t('pages.users.field_names.company_details_switch_description', 'MISSING')}
                                    </p>
                                    <Switch
                                        checked={props.allowCompanyDetails}
                                        onChange={props.setAllowCompanyDetails}
                                        className={`${
                                            props.allowCompanyDetails ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-12 items-center rounded-full`}>
                                        <span className='sr-only'>Enable notifications</span>
                                        <span
                                            className={`${
                                                props.allowCompanyDetails ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>

                            <div>
                                <h1>{t('pages.users.field_names.switch_title', 'MISSING')}</h1>
                                <div className='mt-1 flex items-center justify-between'>
                                    <p className='text-gray-400 text-base'>
                                        {t('pages.users.field_names.switch_description', 'MISSING')}
                                    </p>
                                    <Switch
                                        checked={props.allowUser}
                                        onChange={props.setAllowUser}
                                        className={`${
                                            props.allowUser ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-12 items-center rounded-full`}>
                                        <span className='sr-only'>Enable notifications</span>
                                        <span
                                            className={`${
                                                props.allowUser ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                            <div>
                                <h1>{t('pages.users.field_names.report_switch_title', 'MISSING')}</h1>
                                <div className='mt-1 flex items-center justify-between'>
                                    <p className='text-gray-400 text-base'>
                                        {t('pages.users.field_names.report_switch_description', 'MISSING')}
                                    </p>
                                    <Switch
                                        checked={props.allowReport}
                                        onChange={props.setAllowReport}
                                        className={`${
                                            props.allowReport ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-12 items-center rounded-full`}>
                                        <span className='sr-only'>Enable notifications</span>
                                        <span
                                            className={`${
                                                props.allowReport ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                            <div>
                                <h1>{t('pages.users.field_names.scan_switch_title', 'MISSING')}</h1>
                                <div className='mt-1 flex items-center justify-between'>
                                    <p className='text-gray-400 text-base'>
                                        {t('pages.users.field_names.scan_switch_description', 'MISSING')}
                                    </p>
                                    <Switch
                                        checked={props.allowTicketScan}
                                        onChange={props.setAllowTicketScan}
                                        className={`${
                                            props.allowTicketScan ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-12 items-center rounded-full`}>
                                        <span className='sr-only'>Enable notifications</span>
                                        <span
                                            className={`${
                                                props.allowTicketScan ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                            <div /> {/* helper div */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
