import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import { AppNotification, NotificationContextType } from '../core/types';

const NotificationContext = createContext<NotificationContextType>({
    addNotification(_: AppNotification) {},
    clearNotification(_: number) {},
    notifications: [],
});

export function AppNotificationContext({ children }: { children: React.ReactNode }) {
    const [notifications, setNotifications] = useState<AppNotification[]>([]);

    const addNotification = React.useCallback(
        (notification: AppNotification) => {
            notification._internalIdentifier = Date.now();
            setNotifications((ntfs) => [notification, ...ntfs]);
        },
        [setNotifications],
    );

    const clearNotification = React.useCallback(
        (identifier: number) => {
            const persisted = notifications.filter((n) => n._internalIdentifier !== identifier);
            setNotifications(persisted);
        },
        [notifications, setNotifications],
    );

    return (
        <NotificationContext.Provider value={{ addNotification, clearNotification, notifications }}>
            {children}
        </NotificationContext.Provider>
    );
}

export function useNotifications() {
    return useContext(NotificationContext);
}
