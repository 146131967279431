import { Transition, Dialog, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { NumberField, Spacer, TextField } from 'components/form-fields';
import { NotificationType, LocationsSubSectionTarget } from 'core/types';
import { LocationImageSchema } from 'core/validation-schema';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { classNames } from 'core/utils';
import { useClient } from 'core/api-client';
import { useNotifications } from 'context/notification-context';
import { ErrorPanel } from 'components/action-panel';
import { LocationImageDto } from 'api/operator-api-types';

export interface InputProps {
    closeCallback: (target: LocationsSubSectionTarget) => void;
    panel: LocationsSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
    identifier: string;
    addImage: (images: LocationImageDto) => void;
}

interface SubFormInputProps {
    identifier: string;
    addImage: (image: LocationImageDto) => void;
    closeCallback: (target: LocationsSubSectionTarget) => void;
}

export default function SubSectionAddForm(props: InputProps) {
    const { t } = useTranslation();
    const { closeCallback, open, title, description, panel, identifier, addImage } = props;

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10'
                    onClose={() => closeCallback(LocationsSubSectionTarget.Closed)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-4xl'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(title, 'MISSING')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() =>
                                                                closeCallback(LocationsSubSectionTarget.Closed)
                                                            }
                                                            data-cy='close'>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>{t(description, 'MISSING')}</p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                {/* Replace with your content */}
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    {panel === LocationsSubSectionTarget.Images && (
                                                        <LocationImageForm
                                                            identifier={identifier}
                                                            addImage={addImage}
                                                            closeCallback={closeCallback}
                                                        />
                                                    )}
                                                </div>
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function LocationImageForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const client = useClient();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const { identifier, addImage } = props;

    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LocationImageSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        setIsFormError(false);
        if (identifier === 'create') {
            delete data.locationId;
        } else {
            data.locationId = identifier;
        }
        addImage(LocationImageDto.fromJS(data));

        setProcessing(false);

        addNotification({
            description: t('pages.locations_detail.image_success_description'),
            title: t('pages.locations_detail.image_success_title'),
            style: NotificationType.SuccessSimple,
            timeoutInMilliseconds: 2000,
        });
        reset();
        props.closeCallback(LocationsSubSectionTarget.Closed);
    });

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='locationImageForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <TextField
                        fieldName='name'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.locations_detail.images.table.headers.name'
                        isRequired={true}
                        data_cy='name'
                    />

                    <NumberField
                        fieldName='sortOrder'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.locations_detail.images.table.headers.sort_order'
                        isRequired={true}
                        minimum={0}
                        data_cy='sort-order'
                    />

                    <TextField
                        fieldName='imageUrl'
                        overrideContainerCss='col-span-6'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.locations_detail.images.table.headers.image_url'
                        isRequired={true}
                        data_cy='image-url'
                    />

                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='locationImageForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.locations_detail.button.saving_changes')
                                : t('pages.locations_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
