import { useTranslation } from 'react-i18next';

export interface InputProps {
    resourceName: string;
}

export default function LoadingError(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='relative block w-full rounded-lg border-2 border-dashed border-red-300 p-12 text-center hover:border-red-400 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2'>
                <div role='status'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='inline mr-2 w-10 h-10 text-gray-200 dark:text-gray-600'>
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
                        />
                    </svg>
                </div>
                <span className='mt-2 block text-sm font-semibold text-gray-500'>
                    {t(props.resourceName, 'MISSING')}
                </span>
            </div>
        </>
    );
}
