import { EditCss } from 'components/table';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';
import { formattedDate, toTimeString } from 'core/utils';
import { useNavigate, useParams } from 'react-router-dom';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    const navigate = useNavigate();
    const { companyId } = useParams();

    let columnDefs = [
        columnHelper.accessor((row) => row.locationName, {
            id: 'locationName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.location_name', ''),
        }),
        columnHelper.accessor((row) => row.eventName, {
            id: 'eventName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.event_name', ''),
        }),
        columnHelper.accessor((row) => row.eventDate, {
            id: 'eventDate',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.event_date', ''),
        }),
        columnHelper.accessor((row) => row.startTime, {
            id: 'startTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.company_reports.table.headers.start_time', ''),
        }),
        columnHelper.accessor((row) => row.endTime, {
            id: 'endTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.company_reports.table.headers.end_time', ''),
        }),
        columnHelper.accessor((row) => row.totalCapacity, {
            id: 'totalCapacity',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_capacity', ''),
        }),
        columnHelper.accessor((row) => row.totalSold, {
            id: 'totalSold',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_sold', ''),
        }),
    ];

    columnDefs.push(
        columnHelper.display({
            id: 'actions',
            cell: (props) => (
                <button
                    type='button'
                    onClick={() => {
                        navigate(
                            `/companies/${companyId}/event/${props.row.original?.eventId}/${props.row.original?.eventVenueResourceId}/sales`,
                        );
                    }}
                    className={EditCss()}>
                    {t('buttons.live_sales')}
                </button>
            ),
            enableHiding: false,
            enableSorting: false,
            enableMultiSort: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
        }),
    );

    return columnDefs;
}
