import { yupResolver } from '@hookform/resolvers/yup';
import { ChangesPanel, ErrorPanel, FormActionPanel } from 'components/action-panel';
import { HelpContextTarget, LocationsSubSectionTarget, NotificationType } from 'core/types';
import { LocationSchema } from 'core/validation-schema';
import { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import LocationSection from './location-section';
import { useClient } from 'core/api-client';
import { createLocation, updateLocation, getLocation } from 'fetch/location';
import { LocationImageDto, LocationRequest } from 'api/operator-api-types';
import { useNotifications } from 'context/notification-context';
import { useTranslation } from 'react-i18next';
import ImageSection from './images-section';
import SubSectionAddForm from './sub-section-add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';
import useSoftDirty from 'hooks/useSoftDirty';
import Spin from 'components/Spin';

// An interface for our actions
interface ReducerAction {
    type: LocationsSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: LocationsSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== LocationsSubSectionTarget.Closed,
    };

    switch (action.type) {
        case LocationsSubSectionTarget.Images:
            res.title = 'pages.locations_detail.images.title';
            res.description = 'pages.locations_detail.images.description';
            break;
    }

    return res;
}

export default function ManageLocation() {
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [formError, setIsFormError] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [images, setImages] = useState<LocationImageDto[]>([]);
    const [isEdit, setIsEdit] = useState(false);
    const [state, dispatch] = useReducer(reducer, {
        panel: LocationsSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });

    const { isDirty, _setIsDirty: setIsDirty } = useSoftDirty('addLocationForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const {
        data: locationData,
        isLoading: locationDataIsLoading,
        isRefetching,
        refetch,
    } = useQuery(
        ['locationData', identifier!!],
        () => {
            if (identifier && identifier !== 'create') {
                return getLocation(identifier!!, client).then((rsp) => {
                    if (rsp.location.images) {
                        setImages(rsp.location.images);
                    }
                    return rsp;
                });
            }
        },
        keepForNMinutes(1),
    );

    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LocationSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var emptyErrorList = new Array<string>();
        setServerErrorList([]);
        setTraceId(null);
        setProcessing(true);

        if (images.length === 0) {
            emptyErrorList.push(t('pages.locations_detail.errors.minimum_images'));
            setServerErrorList((data) => [...emptyErrorList]);
            setIsFormError(true);
            setProcessing(false);
        } else {
            setIsFormError(false);
            if (isEdit && identifier) {
                const rsp = await updateLocation(
                    identifier,
                    LocationRequest.fromJS({
                        location: {
                            locationId: identifier,
                            name: data.location.name,
                            description: data.location.description,
                            countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                            regionId: Number.parseInt(process.env.REACT_APP_REGION_ID ?? '1'),
                            urlSlug: data.location.urlSlug,
                            longitude: data.location.longitude,
                            latitude: data.location.latitude,
                            dateEffective: new Date(dayjs(data.location.dateEffective).format('YYYY-MM-DD')),
                            dateUpdated: new Date(),
                            sortOrder: data.location.sortOrder,
                            languageId: Number.parseInt(process.env.REACT_APP_LANGUAGE_ID ?? '1'),
                            images: images,
                        },
                    }),
                    client,
                );

                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.status && rsp.status !== 200) {
                    addNotification({
                        description: t('pages.locations_detail.detail.error_description'),
                        title: t('pages.locations_detail.detail.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                } else {
                    // Success
                    addNotification({
                        description: t('pages.locations_detail.update.success_description'),
                        title: t('pages.locations_detail.update.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    if (rsp === 202 || rsp === 200) {
                        navigate(`/locations`, { replace: true });
                    }
                    refetch();
                }
            } else {
                const rsp = await createLocation(
                    LocationRequest.fromJS({
                        location: {
                            name: data.location.name,
                            description: data.location.description,
                            countryId: Number.parseInt(process.env.REACT_APP_COUNTRY_ID ?? '1'),
                            regionId: Number.parseInt(process.env.REACT_APP_REGION_ID ?? '1'),
                            urlSlug: data.location.urlSlug,
                            longitude: data.location.longitude,
                            latitude: data.location.latitude,
                            dateEffective: new Date(dayjs(data.location.dateEffective).format('YYYY-MM-DD')),
                            dateUpdated: new Date(),
                            sortOrder: data.location.sortOrder,
                            languageId: Number.parseInt(process.env.REACT_APP_LANGUAGE_ID ?? '1'),
                            images: images,
                        },
                    }),
                    client,
                );

                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.status && rsp.status !== 200) {
                    addNotification({
                        description: t('pages.locations_detail.detail.error_description'),
                        title: t('pages.locations_detail.detail.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                } else {
                    // Success
                    addNotification({
                        description: t('pages.locations_detail.detail.success_description'),
                        title: t('pages.locations_detail.detail.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    if (rsp === 202 || rsp === 200) {
                        navigate(`/locations/`, { replace: true });
                    }
                }
            }
        }
    });

    const addImage = (data: LocationImageDto) => {
        var newImages = [...images];
        newImages.push(data);
        setImages(newImages);
        setIsDirty();
    };

    const handleReset = () => {
        if (locationData === undefined || locationData.location === null || locationData.location === undefined) {
            return;
        }
        setServerErrorList([]);
        setTraceId(null);
        reset(locationData);
    };

    useEffect(() => {
        handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationData]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const openAddSubSection = (target: LocationsSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };

    return (
        <>
            <FormActionPanel
                isDirty={isDirty}
                processing={processing}
                handleReset={handleReset}
                contextTitle='pages.locations_detail.context.title'
                contextDescription='pages.locations_detail.context.description'
                contextTarget={HelpContextTarget.Locations}
                formTarget='addLocationForm'
            />
            <form onSubmit={onSubmit} noValidate={true} id='addLocationForm' className='space-y-6'>
                <ChangesPanel isDirty={isDirty} />
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <LocationSection
                    key='location-section'
                    errors={errors}
                    register={register}
                    trigger={trigger}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    setValue={setValue}
                    location={locationData?.location}
                    setDirty={setIsDirty}
                />
                <Spin spinning={locationDataIsLoading || isRefetching}>
                    <ImageSection
                        setIsDirty={setIsDirty}
                        setImages={setImages}
                        data={images}
                        addCallback={openAddSubSection}
                    />
                </Spin>
            </form>
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                identifier={identifier!!}
                addImage={addImage}
            />
        </>
    );
}
