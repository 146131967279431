import System from './system';
import Main from './main';
import { AuthenticatedResponseModel } from 'api/operator-api-types';
import CompanyDialog from './company-dialog';
import { ManagedCompanyResponseModel } from 'api/user-api-types';
import { useEffect, useState } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useSelectedCompany } from 'context/company-context';
import { getClaims, hasClaim } from 'core/utils';

interface InputProperties {
    claims: AuthenticatedResponseModel | undefined;
    data: ManagedCompanyResponseModel | undefined;
    isLoading: boolean;
}

function Desktop(props: InputProperties) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { companyIdentifier } = useSelectedCompany();
    const [companyName, setCompanyName] = useState<string>(t('company_selection.select_company', 'MISSING'));

    // Watch for a location change in global state.
    useEffect(() => {
        const errorName = props.isLoading
            ? t('company_selection.loading', 'MISSING')
            : t('company_selection.select_company', 'MISSING');
        setCompanyName(props.data?.companies?.find((l) => l.companyId === companyIdentifier)?.displayName ?? errorName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyIdentifier, props.isLoading]);

    const manageCompanyClaims = getClaims(props?.claims?.claims, 'manage.company');
    const hasCompanyAccess = hasClaim(props?.claims?.claims, 'operator.access.company', ['full']);

    return (
        <>
            {(manageCompanyClaims.length > 1 || hasCompanyAccess) && (
                <CompanyDialog
                    key='dt_loc_dialog'
                    open={open}
                    setOpen={setOpen}
                    companies={props.data?.companies ?? []}
                />
            )}
            <div key='dt_menu_container' className='flex-1 flex flex-col min-h-0 bg-gray-800'>
                {(manageCompanyClaims.length > 1 || hasCompanyAccess) && (
                    <div className='flex items-center h-16 flex-shrink-0 px-4 bg-gray-900'>
                        <button
                            type='button'
                            onClick={() => setOpen(true)}
                            className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-ring-gray-100 focus:border-ring-gray-100 sm:text-sm'>
                            <span className='block truncate'>{companyName}</span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                            </span>
                        </button>
                    </div>
                )}
                <div className='flex-1 flex flex-col overflow-y-auto'>
                    <nav className='flex-1 px-2 py-4 space-y-1'>
                        <Main claims={props.claims} />
                    </nav>
                    <System />
                </div>
            </div>
        </>
    );
}

export default Desktop;
