import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import {
    FieldInputProps,
    NumberField,
    SingleDateSelectionField,
    TextAreaField,
    TextField,
} from 'components/form-fields';
import { getMenuCategories } from 'fetch/company';
import { useClient } from 'core/api-client';
import { classNames, keepForOneDay } from 'core/utils';

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    errors?: any;
}

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    trigger?: any;
    setDirty?: any;
}

export default function ProductSection(props: InputProps) {
    const client = useClient();
    const { t } = useTranslation();

    const { data: menuCategoryData } = useQuery(
        ['menuCategoryData-data'],
        () => {
            return getMenuCategories(client);
        },
        keepForOneDay(),
    );

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.products.context.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.products.context.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextField
                                fieldName='eventMenuProduct.productName'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.products.field_names.product-name'
                                isRequired={true}
                                data_cy='productName'
                            />
                            <CategoryReferenceField
                                referenceData={menuCategoryData?.categories ?? []}
                                referenceType='eventMenuProduct.menuCategoryId'
                                showDefault={true}
                                fieldName='eventMenuProduct.menuCategoryId'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.products.field_names.menu_category'
                                data_cy='menuCategoryId'
                                errors={props.errors}
                            />
                        </div>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextAreaField
                                fieldName='eventMenuProduct.marketingDescription'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.products.field_names.marketing_description'
                                isRequired={false}
                                data_cy='marketingDescription'
                            />
                            <TextAreaField
                                fieldName='eventMenuProduct.allergenInfo'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.products.field_names.allergen_info'
                                isRequired={false}
                                data_cy='allergenInfo'
                            />
                            <SingleDateSelectionField
                                overrideContainerCss='col-span-6'
                                fieldName='eventMenuProduct.dateEffective'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                trigger={props.trigger}
                                minimum={new Date()}
                                fieldLabel='pages.products.field_names.date_effective'
                                defaultValue={null}
                                isRequired={true}
                                data_cy='dateEffective'
                                setDirty={props.setDirty}
                            />
                            <SingleDateSelectionField
                                overrideContainerCss='col-span-6'
                                fieldName='eventMenuProduct.dateExpires'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                trigger={props.trigger}
                                minimum={new Date()}
                                fieldLabel='pages.products.field_names.date_expire'
                                defaultValue={null}
                                isRequired={false}
                                data_cy='dateExpires'
                                setDirty={props.setDirty}
                            />

                            <NumberField
                                fieldName='eventMenuProduct.sortOrder'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                step='1'
                                fieldLabel='pages.products.field_names.sort_order'
                                minimum={1}
                                isRequired={true}
                                data_cy='sortOrder'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function CategoryReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-6 lg:col-span-6'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && <option value=''>Select an option</option>}
                    {props.referenceData?.map((item, index) => (
                        <option
                            key={`${props.referenceType}-${item.menuCategoryId}-${index}`}
                            value={item.menuCategoryId}>
                            {t(`referenceData.${props.referenceType}.${item.menuCategoryId}`, `${item.categoryName}`)}
                        </option>
                    ))}
                </select>
                {props.errors?.eventMenuProduct?.menuCategoryId && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.eventMenuProduct?.menuCategoryId?.message}
                    </p>
                )}
            </div>
        </>
    );
}
