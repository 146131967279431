import { useTranslation } from 'react-i18next';
import { classNames } from 'core/utils';

export interface ButtonInputProps {
    processing: boolean;
    isDirty: boolean;
    disabled?: boolean;
}

export interface SaveButtonInputProps extends ButtonInputProps {
    formTarget: string;
    buttonText?: string;
    onClick?: any;
}

export interface ResetButtonInputProps extends ButtonInputProps {
    handleReset: () => void;
}

function SaveButton(props: SaveButtonInputProps) {
    const { t } = useTranslation();
    const { processing, isDirty, formTarget, buttonText, onClick, disabled } = props;
    return (
        <button
            type='submit'
            onClick={onClick}
            form={formTarget}
            disabled={processing || !isDirty || disabled}
            className={classNames(
                'inline-flex h-10 w-full items-center justify-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                processing || !isDirty ? 'cursor-not-allowed ' : 'bg-gray-700 hover:bg-gray-800',
                'focus:outline-none focus:ring-0 bg-gray-700',
            )}
            data-cy='submit'>
            <>
                {processing ? (
                    <svg
                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'>
                        <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'></circle>
                        <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                    </svg>
                ) : (
                    ''
                )}
                {processing
                    ? t('pages.experience_detail.button.saving_changes', 'MISSING')
                    : buttonText
                    ? buttonText
                    : t('pages.experience_detail.button.save_changes', 'MISSING')}
            </>
        </button>
    );
}

function ResetButton(props: ResetButtonInputProps) {
    const { t } = useTranslation();
    const { processing, isDirty, handleReset } = props;
    return (
        <button
            onClick={() => handleReset()}
            disabled={processing || !isDirty}
            type='button'
            className={classNames(
                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4',
                processing || !isDirty ? '' : 'hover:bg-gray-400',
                'text-gray-700 shadow-sm focus:outline-none focus:ring-0 bg-gray-300',
            )}
            data-cy='reset-button'>
            <>{t('pages.experience_detail.button.reset', 'MISSING')}</>
        </button>
    );
}

export { SaveButton, ResetButton };
