import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';
import { formattedDate } from 'core/utils';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.bookingStatus, {
            id: 'bookingStatus',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.booking_status', ''),
        }),
        columnHelper.accessor((row) => row.bookingSize, {
            id: 'bookingSize',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.booking_size', ''),
        }),

        columnHelper.accessor((row) => row.bookingTotal, {
            id: 'bookingTotal',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.booking_total', ''),
        }),
        columnHelper.accessor((row) => row.bookingDate, {
            id: 'bookingDate',
            cell: (info) => (
                <div>
                    {info?.row?.original?.bookingStatus === 'Not Available' ? (
                        ''
                    ) : (
                        <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                            {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                        </span>
                    )}
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.booking_date_time', ''),
        }),
    ];

    return columnDefs;
}
