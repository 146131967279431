import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnGeneratorInputProps, CompanyUiModel } from '../../core/types';
import { DeleteCss, EditCss } from '../../components/table';

export default function Columns(props: ColumnGeneratorInputProps<CompanyUiModel>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<CompanyUiModel>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.name, {
            id: 'name',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.companies.table.headers.name', ''),
        }),
        columnHelper.accessor((row) => row.displayName, {
            id: 'displayName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.companies.table.headers.displayName', ''),
        }),
        columnHelper.accessor((row) => row.email, {
            id: 'email',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.companies.table.headers.email', ''),
        }),
        columnHelper.accessor((row) => row.phone, {
            id: 'phone',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.companies.table.headers.phone', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
