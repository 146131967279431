import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';

import { HelpContextTarget, ColumnActionType, ColumnFilterList, NotificationType } from 'core/types';
import { useNotifications } from 'context/notification-context';
import { deleteMenu, getMenus } from 'fetch/company';
import { hasManageClaims, keepForNMinutes } from 'core/utils';
import { useClient } from 'core/api-client';

import Spin from 'components/Spin';
import DataTable from 'components/table';

import Columns from './columns';
import { useConfirm } from 'context/confirmation-context';
import { useAuth } from 'context/auth-context';
import { fetchAuthenticated } from 'fetch/operator/system';
import { useSelectedCompany } from 'context/company-context';

export default function CompanyMenue() {
    let { companyId } = useParams();
    const { t } = useTranslation();
    const { isConfirmed } = useConfirm();
    const [isDeleting, setIsDeleting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        menueName: true,
        displayTitle: true,
        dateEffective: true,
        dateExpire: true,
        sortOrder: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const handleAdd = () => {
        navigate(`/companies/${companyId}/menus/create`);
    };

    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: claimsLoading } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    // delete menu
    async function handleDelete(eventMenuId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteMenu(eventMenuId as string, companyId!!, client);
            if (res?.status === 202 || res?.status === 200) {
                addNotification({
                    description: t('common.api_messages.status_202'),
                    title: t('common.api_messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else if (res?.status === 409) {
                addNotification({
                    title: t('pages.menus.messages.status_409_error'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res?.status === 422) {
                addNotification({
                    description: t('common.api_messages.status_422'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting menu');
        }
    }

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                navigate(`/companies/${companyId}/menus/${row.eventMenuId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.eventMenuId!!);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    const {
        data: menuData,
        isLoading,
        refetch,
    } = useQuery(
        ['menus-data', companyId],
        async () => {
            setIsFetching(true);
            const res = await getMenus(companyId as string, client);
            if (res) {
                setTimeout(() => {
                    setIsFetching(false);
                }, 1000);
                return res;
            }
        },
        keepForNMinutes(1),
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, companyId]);

    // Set the user data to a format that is easier to use in the UI.
    useEffect(() => {
        if (menuData === undefined || !Array.isArray(menuData) || menuData === null) {
            if (!isLoading && menuData?.traceId) {
                addNotification({
                    description: t('pages.menus.messages.list_error_description'),
                    title: t('pages.menus.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuData, isLoading]);

    return (
        <Spin spinning={isLoading || isFetching || claimsLoading || isDeleting}>
            <TableActionPanel
                setGlobalFilter={setGlobalFilter}
                setColumnFilterOpen={setColumnFilterOpen}
                title='pages.menus.context.title'
                description='pages.menus.context.description'
                actionButtonText='pages.menus.action_button'
                target={HelpContextTarget.Users}
                handleActionButton={handleAdd}
            />
            <DataTable<any>
                data={menuData || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.menus.table.headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.menus.table.empty'}
            />
        </Spin>
    );
}
