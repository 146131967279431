import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/form-fields';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { NotificationType } from 'core/types';
import { PasswordResetSchema } from 'core/validation-schema';
import { passwordReset } from 'fetch/user/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function PasswordReset() {
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const client = useClient();

    const [processing, setProcessing] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [traceId, setTraceId] = useState<string | null>(null);

    // Format the form handler.
    const {
        register,
        getFieldState,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(PasswordResetSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        const rsp = await passwordReset(
            {
                userName: data.email,
            },
            client,
        );

        setProcessing(false);
        if (rsp.traceId) {
            setTraceId(rsp.traceId);
        }

        if (rsp.status && rsp.status != 200) {
            addNotification({
                description: t('pages.event_detail.detail.error_description'),
                title: t('pages.event_detail.detail.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            // Success
            addNotification({
                description: t('pages.event_detail.detail.success_description'),
                title: t('pages.event_detail.detail.success_title'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 3000,
            });
            if (rsp != null) {
                setIsSuccess(true);
            }
        }
    });

    return (
        <>
            <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
                {isSuccess ? (
                    <div className='flex items-center w-full justify-center p-10 text-center text-xl'>
                        <h4 className='w-1/2 '>
                            Thank you for submitting a request to rest your password. We will email you the link to
                            reset your password. Please check your email.
                        </h4>
                    </div>
                ) : (
                    <>
                        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
                            <QuestionMarkCircleIcon className='mx-auto h-10 w-auto gray-800' />
                            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                                {t('pages.password_reset.header')}
                            </h2>
                        </div>

                        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                            {traceId && (
                                <div className='sm:mx-auto sm:w-full mb-4 sm:max-w-md'>
                                    <div className='flex'>
                                        <div className='flex-shrink-0'>
                                            <XCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                                        </div>
                                        <div className='ml-3'>
                                            <h3 className='text-sm font-medium text-red-500'>
                                                {t('pages.login.errors.invalid_email')}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <form onSubmit={onSubmit} noValidate={true} id='resetPasswordForm' className='space-y-6'>
                                <div>
                                    <TextField
                                        fieldName='email'
                                        overrideContainerCss='col-span-3'
                                        getFieldState={getFieldState}
                                        register={register}
                                        fieldLabel='pages.password_reset.field_names.email'
                                        data_cy='email'
                                    />
                                </div>
                                <div>
                                    <button
                                        type='submit'
                                        data-cy='submit'
                                        disabled={processing}
                                        tabIndex={4}
                                        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                            processing ? 'bg-gray-700' : 'bg-gray-800'
                                        } hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800`}>
                                        {processing ? (
                                            <svg
                                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'>
                                                <circle
                                                    className='opacity-25'
                                                    cx='12'
                                                    cy='12'
                                                    r='10'
                                                    stroke='currentColor'
                                                    strokeWidth='4'></circle>
                                                <path
                                                    className='opacity-75'
                                                    fill='currentColor'
                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                            </svg>
                                        ) : (
                                            ''
                                        )}
                                        {processing
                                            ? t('pages.password_reset.button.clicked')
                                            : t('pages.password_reset.button.active')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>{' '}
        </>
    );
}

export default PasswordReset;
