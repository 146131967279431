import { useTranslation } from 'react-i18next';
import { LocationImageDto } from 'api/operator-api-types';
import DataTable, { DeleteCss, IndeterminateCheckbox } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps, LocationsSubSectionTarget } from 'core/types';
import { useEffect, useState } from 'react';
import { timer } from 'core/utils';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useConfirm } from 'context/confirmation-context';

export interface InputProps {
    data: LocationImageDto[] | null;
    addCallback: (target: LocationsSubSectionTarget) => void;
    setIsDirty?: () => void;
    setImages?: any;
}

export default function ImageSection(props: InputProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<LocationImageDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();

    const { data: input, addCallback, setIsDirty, setImages } = props;

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
    }, [input]);

    const handleDelete = (row: LocationImageDto, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                var newData = [...data];
                newData.splice(index, 1);
                setData((data) => [...newData]);
                setImages(newData);
                if (setIsDirty) {
                    setIsDirty();
                }
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.locations_detail.images.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.locations_detail.images.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(LocationsSubSectionTarget.Images)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<LocationImageDto>
                                columnTranslationPrefix={'pages.locations_detail.images.table.headers'}
                                columns={ImageColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={data}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.locations_detail.images.table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ImageColumns(props: ColumnGeneratorInputProps<LocationImageDto>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<LocationImageDto>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [];

    columnDefs.push(
        columnHelper.accessor((row) => row.name, {
            id: 'name',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.locations_detail.images.table.headers.name', 'MISSING'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.sortOrder, {
            id: 'sortOrder',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.locations_detail.images.table.headers.sort_order', 'MISSING'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.imageUrl, {
            id: 'imageUrl',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.locations_detail.images.table.headers.image_url', 'MISSING'),
        }),
    );

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <button
                        type='button'
                        onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)}
                        className={DeleteCss()}
                        data-cy='delete'>
                        {t('buttons.delete', '')}
                    </button>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
