import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumberField, SingleDateSelectionField, TextField } from 'components/form-fields';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    trigger?: any;
    setDirty?: any;
}

export default function ExperienceSection(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.experience_detail.experience_details', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.experience_detail.experience_details_description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextField
                                fieldName='experience.name'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.experience_detail.field_names.name'
                                isRequired={true}
                                data_cy='name'
                            />
                            <TextField
                                fieldName='experience.displayTitle'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.experience_detail.field_names.display_title'
                                isRequired={true}
                                data_cy='display-title'
                            />
                            <TextField
                                fieldName='experience.description'
                                overrideContainerCss='col-span-12'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.experience_detail.field_names.description'
                                isRequired={true}
                                data_cy='description'
                            />

                            <NumberField
                                fieldName='experience.sortOrder'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                step='1'
                                fieldLabel='pages.experience_detail.field_names.sort_order'
                                minimum={1}
                                isRequired={true}
                                data_cy='sort-order'
                            />
                            <TextField
                                fieldName='experience.urlSlug'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.experience_detail.field_names.url_slug'
                                isRequired={true}
                                data_cy='url-slug'
                            />

                            <SingleDateSelectionField
                                fieldName='experience.dateEffective'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                minimum={new Date()}
                                trigger={props.trigger}
                                fieldLabel='pages.experience_detail.field_names.date_effective'
                                defaultValue={null}
                                isRequired={true}
                                data_cy='date-effective'
                                setDirty={props.setDirty}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
