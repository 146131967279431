import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnActionType, ColumnGeneratorInputProps } from '../../core/types';
import { DeleteCss, EditCss } from '../../components/table';
import { formattedDate } from '../../core/utils';
import { useNavigate, useParams } from 'react-router-dom';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId } = useParams();

    const columnHelper = createColumnHelper<any>();
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.locationName, {
            id: 'locationName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_tickets.table.headers.location_name', ''),
        }),
        columnHelper.accessor((row) => row.eventName, {
            id: 'eventName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_tickets.table.headers.event_name', ''),
        }),
        columnHelper.accessor((row) => row.eventDescription, {
            id: 'eventDescription',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_tickets.table.headers.event_description', ''),
        }),
        columnHelper.accessor((row) => row.eventDate, {
            id: 'eventDate',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.event_tickets.table.headers.event_date', ''),
        }),
        columnHelper.accessor((row) => row.startTime, {
            id: 'startTime',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_tickets.table.headers.start_time', ''),
        }),
        columnHelper.accessor((row) => row.endTime, {
            id: 'endTime',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_tickets.table.headers.end_time', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            type='button'
                            onClick={() => {
                                navigate(
                                    `/companies/${companyId}/${props.row.original?.eventVenueId}/events/${props.row.original?.eventVenueResourceId}`,
                                );
                            }}
                            className={EditCss()}>
                            {t('buttons.view')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
