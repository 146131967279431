import { Transition } from '@headlessui/react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { classNames, hasClaim } from 'core/utils';
import { AuthenticatedResponseModel } from 'api/operator-api-types';

interface InputProperties {
    claims: AuthenticatedResponseModel | undefined;
}

function ContentManagementSystem(props: InputProperties) {
    const { t } = useTranslation();
    const [isShowing, setIsShowing] = useState(false);
    const { claims } = props;
    const hasCmsAccess = hasClaim(claims?.claims, 'operator.access.cms', ['full']);

    if (!hasCmsAccess) {
        return <></>;
    }
    return (
        <>
            <button
                onClick={() => setIsShowing((isShowing) => !isShowing)}
                className='text-gray-300 hover:bg-gray-700 hover:text-white group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-semibold rounded-md focus:outline-none'>
                <BriefcaseIcon
                    className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden='true'
                />
                <span className='flex-1'>{t('menu.cms', 'MISSING')}</span>
                <svg
                    className={classNames(
                        isShowing ? 'text-gray-600 rotate-90' : 'text-gray-300',
                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-500',
                    )}
                    viewBox='0 0 20 20'
                    aria-hidden='true'>
                    <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                </svg>
            </button>
            <Transition
                show={isShowing}
                enter='transition-opacity duration-100'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <NavLink
                    key='menu.clear-page-cache'
                    to={`/clear-page-cache`}
                    className={({ isActive }) =>
                        classNames(
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                        )
                    }
                    end>
                    {t('menu.clear-page-cache', 'MISSING')}
                </NavLink>
                <NavLink
                    key='menu.clear-content-cache'
                    to={`/clear-content-cache`}
                    className={({ isActive }) =>
                        classNames(
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                        )
                    }
                    end>
                    {t('menu.clear-content-cache', 'MISSING')}
                </NavLink>
            </Transition>
        </>
    );
}

export default ContentManagementSystem;
