import { useEffect, useState } from 'react';
import { TableActionPanel } from 'components/action-panel';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReportSchema } from 'core/validation-schema';
import { yupResolver } from '@hookform/resolvers/yup';

import DataTable from 'components/table';
import { HelpContextTarget, NotificationType } from 'core/types';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';
import { DatePickerForReport } from 'components/form-fields';

import { SaveButton } from 'components/buttons';
import Columns from './columns';
import { useAuth } from 'context/auth-context';
import { classNames, downloadReportCSV, getDateWithoutModification, hasClaim, keepForNMinutes } from 'core/utils';
import { fetchAuthenticated } from 'fetch/operator/system';
import { useQuery } from 'react-query';
import { getAffiliateReportData, getReportDataForDownload, getEvents, getCompanies, getBrands } from 'fetch/finance';
import { useSelectedCompany } from 'context/company-context';
import { BrandDto, CompanyDto, EventDto } from 'api/operator-api-types';

function AffiliateReport() {
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();
    const companyId = companyIdentifier.toString();
    const client = useClient();
    const { user } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDashboard, setShowDashboard] = useState(false);
    const [dashboardData, setDashboardData] = useState<any[]>([]);
    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [companies, setCompanies] = useState<CompanyDto[]>([]);
    const [brands, setBrands] = useState<BrandDto[]>([]);
    const [events, setEvents] = useState<EventDto[]>([]);
    const [filteredBrands, setFilteredBrands] = useState<BrandDto[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<EventDto[]>([]);

    const handleAdd = () => {};

    // Load the claims.
    const { data: claims, isLoading: isFetching } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    // Format the form handler.
    const {
        register,
        handleSubmit,
        getFieldState,
        getValues,
        setValue,
        trigger,
        watch,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(ReportSchema),
        mode: 'onSubmit',
    });

    const downloadReportData = async (data: any) => {
        try {
            if (data?.date?.startDate !== null && data?.date?.endDate !== null) {
                const formattedStartDate = getDateWithoutModification(data?.date?.startDate).split('T')[0];
                const formattedEndDate = getDateWithoutModification(data?.date?.endDate).split('T')[0];
                const companyId = data?.companyId !== '' ? data?.companyId : '';
                const brandId = data?.companyId === '' ? '' : data?.brands !== '' ? data?.brands : '';
                const eventId = data?.companyId === '' ? '' : data?.events !== '' ? data?.events : '';
                setIsLoading(true);
                const dateForCsvReport = await getReportDataForDownload(
                    formattedStartDate,
                    formattedEndDate,
                    companyId,
                    brandId,
                    eventId,
                    client,
                );
                setIsLoading(false);
                if (dateForCsvReport?.length > 0) {
                    downloadReportCSV(dateForCsvReport, 'Affiliate_report');
                    addNotification({
                        description: t('pages.affiliate_report.messages.download_success'),
                        title: t('pages.affiliate_report.messages.download_success'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    setShowDashboard(true);
                } else {
                    addNotification({
                        description: t('pages.affiliate_report.messages.no_data_found'),
                        title: t('pages.affiliate_report.messages.no_data_found'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                }
            }
        } catch (error) {
            console.log('error in fetching event capacities data :', error);
        }
    };

    const fetchReportData = async (data: any) => {
        try {
            if (data?.date?.startDate !== null && data?.date?.endDate !== null) {
                const formattedStartDate = getDateWithoutModification(data?.date?.startDate).split('T')[0];
                const formattedEndDate = getDateWithoutModification(data?.date?.endDate).split('T')[0];
                const companyId = data?.companyId !== '' ? data?.companyId : '';
                const brandId = data?.companyId === '' ? '' : data?.brands !== '' ? data?.brands : '';
                const eventId = data?.companyId === '' ? '' : data?.events !== '' ? data?.events : '';
                setIsLoading(true);
                const affiliateReportData = await getAffiliateReportData(
                    formattedStartDate,
                    formattedEndDate,
                    companyId,
                    brandId,
                    eventId,
                    client,
                );
                if (affiliateReportData) {
                    setIsLoading(false);
                    if (
                        affiliateReportData === undefined ||
                        !Array.isArray(affiliateReportData) ||
                        affiliateReportData === null
                    ) {
                        if (!isLoading) {
                            if (affiliateReportData?.errors?.locationId[0]) {
                                addNotification({
                                    description: t('pages.event_tickets.messages.invalid_location_id_description'),
                                    title: t('pages.event_tickets.messages.invalid_location_id_title'),
                                    style: NotificationType.ErrrorSumple,
                                    timeoutInMilliseconds: 3000,
                                });
                            } else {
                                addNotification({
                                    description: t('pages.event_tickets.messages.list_error_description'),
                                    title: t('pages.event_tickets.messages.error_title'),
                                    style: NotificationType.ErrrorSumple,
                                    timeoutInMilliseconds: 3000,
                                });
                            }
                        }
                    } else {
                        if (affiliateReportData?.length === 0) {
                            addNotification({
                                description: t('pages.affiliate_report.messages.no_data_found'),
                                title: t('pages.affiliate_report.messages.no_data_found'),
                                style: NotificationType.ErrrorSumple,
                                timeoutInMilliseconds: 3000,
                            });
                        }

                        const uiModel: any[] = affiliateReportData?.map((item) => {
                            return {
                                bookingId: item?.bookingId,
                                category: item?.category,
                                product: item?.product,
                                productUnitPrice: item?.productUnitPrice,
                                quantity: item?.quantity,
                                productTotalPaid: item?.productTotalPaid,
                                brunchFee: item?.brunchFee,
                                brunchFeeVat: item?.brunchFeeVat,
                                totalNetAmount: item?.totalNetAmount,
                                userName: item?.userName,
                                orderDate: item?.orderDate,
                                eventName: item?.eventName,
                                eventDate: item?.eventDate,
                                eventExternalId: item?.eventExternalId,
                                locationName: item?.locationName,
                            };
                        });
                        setDashboardData(uiModel);
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error in fetching report data :', error);
        }
    };

    const selectedCompany = watch('companyId');
    const selectedBrand = watch('brands');

    useEffect(() => {
        if (selectedCompany !== '') {
            const filteredEvents = events?.filter((event) => event.companyId === parseInt(selectedCompany));
            const filteredBrands = brands?.filter((brand) => brand.companyId === parseInt(selectedCompany));
            setFilteredEvents(filteredEvents);
            setFilteredBrands(filteredBrands);
        } else {
            setFilteredEvents([]);
            setFilteredBrands([]);
            setValue('brands', '');
            setValue('events', '');
        }
        setValue('brands', '');
        setValue('events', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedBrand !== '') {
            if (selectedCompany === '') {
                const filteredEvents = events?.filter((event) => event.brandId === parseInt(selectedBrand));
                setFilteredEvents(filteredEvents);
            } else {
                const filteredEvents = events?.filter(
                    (event) =>
                        event.brandId === parseInt(selectedBrand) && event.companyId === parseInt(selectedCompany),
                );
                setFilteredEvents(filteredEvents);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrand]);

    useEffect(() => {
        if (claims?.claims) {
            const hasFinanceAccess = hasClaim(claims?.claims, 'operator.access.finance', ['full']);
            if (!hasFinanceAccess) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    async function getAllEvents() {
        setIsLoading(true);
        try {
            const events = await getEvents(client);
            if (events) {
                setIsLoading(false);
                if (events === undefined || !Array.isArray(events) || events === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.brunch_report.messages.list_error_description'),
                            title: t('pages.brunch_report.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const uiModel: any[] = events?.map((item) => {
                        return {
                            id: item?.eventId,
                            name: item?.name,
                            companyId: item?.companyId,
                            brandId: item?.brandId,
                        };
                    });
                    setEvents(uiModel);
                    setFilteredEvents(uiModel);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    async function getAllCompanies() {
        setIsLoading(true);
        try {
            const companies = await getCompanies(client);
            if (companies) {
                setIsLoading(false);
                if (companies === undefined || !Array.isArray(companies) || companies === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.brunch_report.messages.list_error_description'),
                            title: t('pages.brunch_report.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const uiModel: any[] = companies
                        ?.filter((company) => company.isAffiliate === true)
                        ?.map((item) => {
                            return {
                                id: item?.companyId,
                                name: item?.name,
                            };
                        });
                    setCompanies(uiModel);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    async function getAllBrands() {
        setIsLoading(true);
        try {
            const brands = await getBrands(client);
            if (brands) {
                setIsLoading(false);
                if (brands === undefined || !Array.isArray(brands) || brands === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.brunch_report.messages.list_error_description'),
                            title: t('pages.brunch_report.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const uiModel: any[] = brands?.map((item) => {
                        return {
                            id: item?.brandId,
                            name: item?.name,
                            companyId: item?.companyId,
                        };
                    });
                    setBrands(uiModel);
                    setFilteredBrands(uiModel);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    useEffect(() => {
        try {
            getAllEvents();
            getAllCompanies();
            getAllBrands();
        } catch (error) {
            console.log('error:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = handleSubmit(async (data) => {
        if (showDashboard) {
            setDate({ startDate: data.date?.startDate, endDate: data?.date?.endDate });
            fetchReportData(data);
            setDashboardData([]);
        } else {
            downloadReportData(data);
        }
    });

    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 1); // last 1 year
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 2); // next 2 year

    return (
        <Spin spinning={isLoading || isFetching}>
            <TableActionPanel
                setGlobalFilter={setGlobalFilter}
                setColumnFilterOpen={() => {}}
                title='pages.affiliate_report.context.title'
                description='pages.affiliate_report.context.description'
                actionButtonText='pages.affiliate_report.action_button'
                hideActionButton={true}
                hideSearch={true}
                target={HelpContextTarget.reports}
                handleActionButton={handleAdd}
            />
            <div className='my-8 grid grid-cols-12 gap-2'>
                <Dropdown
                    referenceData={companies ?? []}
                    referenceType='companyId'
                    showDefault={true}
                    fieldName='companyId'
                    getFieldState={getFieldState}
                    register={register}
                    isRequired={false}
                    fieldLabel='pages.brunch_report.selector.companies'
                    data_cy='companyId'
                    errors={errors}
                />
                <Dropdown
                    referenceData={filteredBrands}
                    referenceType='brands'
                    showDefault={true}
                    fieldName='brands'
                    getFieldState={getFieldState}
                    register={register}
                    isRequired={false}
                    fieldLabel='pages.brunch_report.selector.brands'
                    data_cy='brands'
                    errors={errors}
                />
                <Dropdown
                    referenceData={filteredEvents}
                    referenceType='events'
                    showDefault={true}
                    fieldName='events'
                    getFieldState={getFieldState}
                    register={register}
                    isRequired={false}
                    fieldLabel='pages.brunch_report.selector.events'
                    data_cy='events'
                    errors={errors}
                />
            </div>
            <form onSubmit={onSubmit} noValidate={true} id='formRef' className='space-y-6'>
                <div className='my-8 grid grid-cols-12 gap-2'>
                    <DatePickerForReport
                        fieldName='date.startDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        minimum={minDate}
                        maximum={maxDate}
                        fieldLabel='pages.affiliate_report.dates.start'
                        defaultValue={date?.startDate}
                        isRequired={true}
                        trigger={trigger}
                        data_cy='date-startDate'
                    />
                    <DatePickerForReport
                        fieldName='date.endDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        minimum={minDate}
                        maximum={maxDate}
                        trigger={trigger}
                        fieldLabel='pages.affiliate_report.dates.end'
                        defaultValue={date?.endDate}
                        isRequired={true}
                        data_cy='date-endDate'
                    />
                    <div className={`flex ${errors?.date ? 'items-center' : 'items-end'} col-span-3 gap-2`}>
                        <SaveButton
                            processing={false}
                            isDirty={true}
                            onClick={() => setShowDashboard(false)}
                            formTarget={'formRef'}
                            buttonText={t('pages.affiliate_report.download_button')}
                        />
                        <SaveButton
                            processing={false}
                            isDirty={true}
                            onClick={() => setShowDashboard(true)}
                            formTarget={'formRef'}
                            buttonText={t('pages.affiliate_report.sales_dashboard')}
                        />
                    </div>
                </div>
            </form>
            {dashboardData?.length > 0 && showDashboard ? (
                <DataTable<any>
                    data={dashboardData}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    columnTranslationPrefix={'pages.affiliate_report.table.headers'}
                    columns={Columns}
                    canExpand={false}
                    canGlobalFilter={true}
                    enableRowSelection={true}
                    noDataMessage={'pages.affiliate_report.table.empty'}
                />
            ) : null}
        </Spin>
    );
}

export default AffiliateReport;

function Dropdown(props: any) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const defaultSelectOption = t('common.all');
    return (
        <>
            <div className='col-span-3 lg:col-span-3'>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option value=''>{defaultSelectOption}</option>
                    )}
                    {props.referenceData?.map((item: any, index: number) => (
                        <option key={`${props.referenceType}-${item.id}-${index}`} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {props.errors?.cities && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.cities?.message}
                    </p>
                )}
                {props.errors?.events && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.events?.message}
                    </p>
                )}
            </div>
        </>
    );
}
