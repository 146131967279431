import { formatDate } from 'pages/event-venue/helper';
import * as yup from 'yup';
import 'yup-phone';

export const LoginSchema = yup.object().shape({
    email: yup.string().required('pages.login.errors.email_required').email('pages.login.errors.email_invalid'),
    password: yup.string().required('pages.login.errors.password_required'),
});

export const ExperienceSchema = yup.object().shape({
    experience: yup.object().shape({
        name: yup.string().required('pages.experiences.errors.name_required'),
        description: yup.string().required('pages.experiences.errors.description_required'),
        displayTitle: yup.string().required('pages.experiences.errors.displayTitle_required'),
        sortOrder: yup.string().required('pages.experiences.errors.SortOrder_required'),
        urlSlug: yup
            .string()
            .required('pages.experiences.errors.url_required')
            .matches(/^[a-zA-Z0-9\-_]+$/, 'pages.locations_detail.errors.valid_image_url'),
        dateEffective: yup
            .date()
            .typeError('pages.experiences.errors.date_effective_invalid')
            .required('pages.experiences.errors.date_effective_required'),
    }),
});

export const BrandSchema = yup.object().shape({
    brand: yup.object().shape({
        name: yup.string().required('pages.brands.errors.name_required'),
        displayTitle: yup.string().required('pages.brands.errors.display_title_required'),
        sortOrder: yup.string().required('pages.brands.errors.SortOrder_required'),
        urlSlug: yup
            .string()
            .required('pages.brands.errors.url_required')
            .matches(/^[a-zA-Z0-9\-_]+$/, 'pages.locations_detail.errors.valid_image_url'),
        dateEffective: yup
            .date()
            .typeError('pages.brands.errors.date_effective_invalid')
            .required('pages.brands.errors.date_effective_required'),
    }),
});

export const DateSchema = yup.object().shape({
    date: yup.object().shape({
        startDate: yup.string().notRequired().nullable(),
        endDate: yup
            .string()
            .test('end-date', 'pages.event_tickets.end_date_validation', function (value): any {
                const { startDate } = this.parent;
                if (value && startDate) {
                    return value && new Date(value) >= new Date(startDate);
                }
                return true;
            })
            .notRequired()
            .nullable(),
    }),
});

export const ReportSchema = yup.object().shape({
    date: yup.object().shape({
        startDate: yup
            .date()
            .typeError('pages.company_reports.messages.start_date_required')
            .required('pages.company_reports.messages.start_date_required'),
        endDate: yup
            .date()
            .min(yup.ref('startDate'), 'pages.company_reports.messages.invalid_end_Date')
            .typeError('pages.company_reports.messages.end_date_required')
            .required('pages.company_reports.messages.end_date_required'),
    }),
});

export const RefundSchema = yup.object().shape({
    date: yup.object().shape({
        companyId: yup.string().required('Please select a company.'),
        startDate: yup
            .date()
            .typeError('pages.company_reports.messages.start_date_required')
            .required('pages.company_reports.messages.start_date_required'),
        endDate: yup
            .date()
            .min(yup.ref('startDate'), 'pages.company_reports.messages.invalid_end_Date')
            .typeError('pages.company_reports.messages.end_date_required')
            .required('pages.company_reports.messages.end_date_required'),
    }),
});
export const EventReFundSchema = yup.object().shape({
    total: yup.string(), // Hidden field
    amount: yup
        .number()
        .typeError('pages.refund.amount_required')
        .test('greater-than-zero', 'pages.refund.amount_greater_than_zero', (value) => (value ? value > 0 : false))
        .test('decimal-places', 'pages.refund.two_decimal_limit', (value) => {
            if (value === undefined || value === 0) return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test('total', 'pages.refund.invalid_amount', function (value: number | undefined): boolean {
            const { total } = this.parent;
            if (value && total) {
                return parseFloat(parseFloat(value.toString()).toFixed(2)) <= parseFloat(parseFloat(total).toFixed(2));
            }
            return true;
        })
        .required('pages.refund.amount_required'),
    summary: yup.string().max(250, 'pages.refund.summary_limit'),
});

export const SearchTicketSchema = yup.object().shape({
    ticket: yup.object().shape({
        email: yup
            .string()
            .email()
            .typeError('pages.users.errors.email_invalid')
            .required('pages.users.errors.email_required'),
    }),
});

export const UserSchema = yup.object().shape({
    user: yup.object().shape({
        firstName: yup.string().required('pages.users.errors.first_name_required'),
        lastName: yup.string().required('pages.users.errors.last_name_required'),
        phone: yup.string().min(11).max(11).required('pages.users.errors.phone_required'),
        email: yup
            .string()
            .email()
            .typeError('pages.users.errors.email_invalid')
            .required('pages.users.errors.email_required'),
    }),
});
export const MenuSchema = yup.object().shape({
    menu: yup.object().shape({
        menuName: yup.string().required('pages.menus.errors.menue_name_required'),
        displayTitle: yup.string().required('pages.menus.errors.display_title_required'),
        dateEffective: yup
            .date()
            .typeError('pages.menus.errors.date_effective_required')
            .required('pages.menus.errors.date_effective_required'),
        sortOrder: yup
            .number()
            .typeError('pages.menus.errors.sort_order_required')
            .integer('pages.menus.errors.invalid_sort_order')
            .required('pages.menus.errors.sort_order_required')
            .positive('pages.menus.errors.sort_order_positive')
            .min(1, 'pages.menus.errors.sort_order_min'),
    }),
});
export const ProductSchema = yup.object().shape({
    eventMenuProduct: yup.object().shape({
        productName: yup.string().required('pages.products.errors.product_name_required'),
        dateEffective: yup
            .date()
            .typeError('pages.products.errors.date_effective_required')
            .required('pages.products.errors.date_effective_required'),
        sortOrder: yup
            .number()
            .typeError('pages.products.errors.sort_order_required')
            .integer('pages.products.errors.invalid_sort_order')
            .required('pages.products.errors.sort_order_required')
            .positive('pages.products.errors.sort_order_positive')
            .min(1, 'pages.products.errors.sort_order_min'),

        menuCategoryId: yup.string().required('Please select an option'),
    }),
});
export const ContentSchema = yup.object().shape({
    content: yup.object().shape({
        contentType: yup.string().matches(/^(?:[a-zA-Z0-9\s\-_%]+|)$/, 'pages.content_cache.errors.invalid'),
        entryId: yup.string().matches(/^(?:[a-zA-Z0-9\s\-_%]+|)$/, 'pages.content_cache.errors.invalid'),
    }),
});

export const PageSchema = yup.object().shape({
    routeId: yup.string().required('Please select an option'),
});

export const AllPageSchemaByType = yup.object().shape({
    pageType: yup.string().required('Please select an option'),
});

export const EventsSchema = yup.object().shape({
    event: yup.string().required('Please select an option'),
});

export const BrandsSchema = yup.object().shape({
    brand: yup.string().required('Please select an option'),
});

export const AllPageSchema = yup.object().shape({
    routeId: yup.string(),
});

export const LocationImageSchema = yup.object().shape({
    name: yup.string().required('pages.locations_detail.errors.name_required'),
    sortOrder: yup.string().required('pages.locations_detail.errors.sort_order_required'),
    imageUrl: yup
        .string()
        .required('pages.locations_detail.errors.image_url_required')
        .url('pages.locations_detail.errors.valid_image_url'),
});

export const LocationSchema = yup.object().shape({
    location: yup.object().shape({
        name: yup.string().required('pages.locations_detail.errors.name_required'),
        description: yup.string().required('pages.locations_detail.errors.description_required'),
        urlSlug: yup.string().matches(/^[a-zA-Z0-9\-_]+$/, 'pages.locations_detail.errors.valid_image_url'),
        longitude: yup
            .string()
            .required('pages.locations_detail.errors.longitude_required')
            .matches(
                /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                'pages.locations_detail.errors.invalid_longitude',
            ),
        latitude: yup
            .string()
            .required('pages.locations_detail.errors.latitude_required')
            .matches(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, 'pages.locations_detail.errors.invalid_latitude'),
        dateEffective: yup
            .date()
            .typeError('pages.locations_detail.errors.dateEffective_required')
            .required('pages.locations_detail.errors.dateEffective_required'),
        sortOrder: yup.string().required('pages.locations_detail.errors.sort_order_required'),
    }),
});

const postCodeRegex = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/;

export const CompanyCommissionSchema = yup.object().shape({
    detail: yup.object().shape({
        commission: yup.object().shape({
            commissionPercentage: yup
                .number()
                .typeError('pages.company_detail.errors.commission_percentage_required')
                .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                    if (value === undefined || value === 0) return true;
                    return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
                })
                .test('commission-percentage', 'pages.company_detail.errors.max_percentage', (value: any) => {
                    const MAX_VALUE = 100;
                    return value <= MAX_VALUE;
                })
                .required('pages.company_detail.errors.commission_percentage_required'),
            startDate: yup
                .date()
                .typeError('pages.company_reports.messages.start_date_required')
                .required('pages.company_reports.messages.start_date_required'),
            endDate: yup
                .date()
                .min(yup.ref('startDate'), 'pages.company_reports.messages.invalid_end_Date')
                .typeError('pages.company_reports.messages.end_date_required')
                .required('pages.company_reports.messages.end_date_required'),
            sortOrder: yup
                .number()
                .typeError('pages.menus.errors.sort_order_required')
                .integer('pages.menus.errors.invalid_sort_order')
                .required('pages.menus.errors.sort_order_required')
                .positive('pages.menus.errors.sort_order_positive')
                .min(1, 'pages.menus.errors.sort_order_min'),
        }),
    }),
});

export const CompanySchema = yup.object().shape({
    detail: yup.object().shape({
        name: yup
            .string()
            .required('pages.company_detail.errors.name_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        displayName: yup
            .string()
            .required('pages.company_detail.errors.displayName_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        address: yup.object().shape({
            line1: yup.string().required('pages.company_detail.errors.line1_required'),
            town: yup
                .string()
                .required('pages.company_detail.errors.town_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            city: yup
                .string()
                .required('pages.company_detail.errors.city_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            postCode: yup
                .string()
                .required('pages.company_detail.errors.postcode_required')
                .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/i, 'pages.company_detail.errors.invalid_post_code'),
        }),
        phone: yup
            .string()
            .test(
                'phone',
                'pages.company_detail.errors.invalid_phone_characters',
                function (value: string | undefined): boolean {
                    if (value) {
                        // only positive integer numbers, plus (+), and spaces allowed
                        if (!/^[0-9+()\s]+$/.test(value)) {
                            return false;
                        }
                    }
                    return true;
                },
            )
            .test(
                'positive-integer',
                'pages.company_detail.errors.only_positive_number',
                function (value: string | undefined): boolean {
                    if (value) {
                        const numericValue = parseInt(value, 10);
                        if (isNaN(numericValue) || numericValue <= 0) {
                            return false;
                        }
                    }
                    return true;
                },
            ),

        email: yup
            .string()
            .required('pages.company_detail.errors.email_required')
            .email('pages.event_detail.errors.contact_email_valid')
            .typeError('pages.users.errors.email_invalid'),
        bookingFeeType: yup.string().required('Please select a fee type.'),
        vatPercentage: yup
            .string()
            .required('pages.company_detail.errors.vat_percentage_required')
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === '0') return true;
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            }),
        maxBookingFee: yup
            .string()
            .test('valid-value', 'pages.event_venues_detail.resources.errors.invalid_value', (value: any): any => {
                if (value === undefined || value === '0' || value === '') return true;
                // Check if the value is a non-negative float with up to two decimal places
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            })
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === '0') return true;
                // Check if the value has at most two decimal places
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            })
            .test(
                'max-booking-fee',
                'pages.event_venues_detail.resources.errors.max_booking_fee_limit',
                function (value: string | undefined): boolean {
                    const MAX_VALUE = 200000;
                    if (value) {
                        // Ensure that the value is a non-negative float with up to two decimal places
                        const isNonNegativeFloat = /^\d+(\.\d{1,2})?$/.test(value);
                        // Ensure that the value is less than or equal to maxBookingFee
                        const isWithinLimit = parseFloat(value || '0') <= MAX_VALUE;
                        return isNonNegativeFloat && isWithinLimit;
                    }
                    return true;
                },
            ),
        minBookingFee: yup
            .string()
            .test('valid-value', 'pages.event_venues_detail.resources.errors.invalid_value', (value: any): any => {
                if (value === undefined || value === '0' || value === '') return true;
                // Check if the value is a non-negative float with up to two decimal places
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            })
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === '0') return true;
                // Check if the value has at most two decimal places
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            })
            .test(
                'minBookingFee',
                'pages.event_venues_detail.resources.errors.min_booking_fee_limit',
                function (value: string | undefined): boolean {
                    const { maxBookingFee } = this.parent;
                    if (value && maxBookingFee) {
                        // Ensure that the value is a non-negative float with up to two decimal places
                        const isNonNegativeFloat = /^\d+(\.\d{1,2})?$/.test(value);
                        // Ensure that the value is less than or equal to maxBookingFee
                        const isWithinLimit = parseFloat(value || '0') <= parseFloat(maxBookingFee || '0');
                        return isNonNegativeFloat && isWithinLimit;
                    }
                    return true;
                },
            ),

        bookingFee: yup
            .number()
            .typeError('pages.event_venues_detail.resources.errors.booking_fee_required')
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === 0) return true;
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            })
            .test('booking-fee', 'pages.event_venues_detail.resources.errors.booking_fee_limit', (value: any) => {
                const MAX_VALUE = 10000;
                return value <= MAX_VALUE;
            })
            .test(
                'bookingFee',
                'pages.event_venues_detail.resources.errors.booking_fee_should_greater',
                function (value: number | undefined): boolean {
                    const { minBookingFee } = this.parent;
                    if (value && minBookingFee) {
                        return value >= parseInt(minBookingFee || '0', 10);
                    }
                    return true;
                },
            )
            .required('pages.event_venues_detail.resources.errors.booking_fee_required'),
    }),
});

export const CompanySchemaWithoutMinMaxBooking = yup.object().shape({
    detail: yup.object().shape({
        name: yup
            .string()
            .required('pages.company_detail.errors.name_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        displayName: yup
            .string()
            .required('pages.company_detail.errors.displayName_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        address: yup.object().shape({
            line1: yup.string().required('pages.company_detail.errors.line1_required'),
            town: yup
                .string()
                .required('pages.company_detail.errors.town_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            city: yup
                .string()
                .required('pages.company_detail.errors.city_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            postCode: yup
                .string()
                .required('pages.company_detail.errors.postcode_required')
                .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/i, 'pages.company_detail.errors.invalid_post_code'),
        }),
        phone: yup
            .string()
            .test(
                'phone',
                'pages.company_detail.errors.invalid_phone_characters',
                function (value: string | undefined): boolean {
                    if (value) {
                        // only positive integer numbers, plus (+), and spaces allowed
                        if (!/^[0-9+()\s]+$/.test(value)) {
                            return false;
                        }
                    }
                    return true;
                },
            )
            .test(
                'positive-integer',
                'pages.company_detail.errors.only_positive_number',
                function (value: string | undefined): boolean {
                    if (value) {
                        const numericValue = parseInt(value, 10);
                        if (isNaN(numericValue) || numericValue <= 0) {
                            return false;
                        }
                    }
                    return true;
                },
            ),

        email: yup
            .string()
            .required('pages.company_detail.errors.email_required')
            .email('pages.event_detail.errors.contact_email_valid')
            .typeError('pages.users.errors.email_invalid'),
        bookingFeeType: yup.string().required('Please select a fee type.'),
        vatPercentage: yup
            .string()
            .required('pages.company_detail.errors.vat_percentage_required')
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === '0') return true;
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            }),

        bookingFee: yup
            .number()
            .typeError('pages.event_venues_detail.resources.errors.booking_fee_required')
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === 0) return true;
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            })
            .test('booking-fee', 'pages.event_venues_detail.resources.errors.booking_fee_limit', (value: any) => {
                const MAX_VALUE = 10000;
                return value <= MAX_VALUE;
            })
            .test(
                'bookingFee',
                'pages.event_venues_detail.resources.errors.booking_fee_should_greater',
                function (value: number | undefined): boolean {
                    const { minBookingFee } = this.parent;
                    if (value && minBookingFee) {
                        return value >= parseInt(minBookingFee || '0', 10);
                    }
                    return true;
                },
            )
            .required('pages.event_venues_detail.resources.errors.booking_fee_required'),
    }),
});

export const CompanySchemaWithoutBooking = yup.object().shape({
    detail: yup.object().shape({
        name: yup
            .string()
            .required('pages.company_detail.errors.name_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        displayName: yup
            .string()
            .required('pages.company_detail.errors.displayName_required')
            .max(250, 'pages.company_detail.errors.name_limit')
            .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
        address: yup.object().shape({
            line1: yup.string().required('pages.company_detail.errors.line1_required'),
            town: yup
                .string()
                .required('pages.company_detail.errors.town_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            city: yup
                .string()
                .required('pages.company_detail.errors.city_required')
                .max(50, 'pages.company_detail.errors.long_length_error')
                .matches(/^[a-zA-Z\s-]+$/, 'pages.company_detail.errors.Invalid_name'),
            postCode: yup
                .string()
                .required('pages.company_detail.errors.postcode_required')
                .matches(/^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/i, 'pages.company_detail.errors.invalid_post_code'),
        }),
        phone: yup
            .string()
            .test(
                'phone',
                'pages.company_detail.errors.invalid_phone_characters',
                function (value: string | undefined): boolean {
                    if (value) {
                        // only positive integer numbers, plus (+), and spaces allowed
                        if (!/^[0-9+()\s]+$/.test(value)) {
                            return false;
                        }
                    }
                    return true;
                },
            )
            .test(
                'positive-integer',
                'pages.company_detail.errors.only_positive_number',
                function (value: string | undefined): boolean {
                    if (value) {
                        const numericValue = parseInt(value, 10);
                        if (isNaN(numericValue) || numericValue <= 0) {
                            return false;
                        }
                    }
                    return true;
                },
            ),

        email: yup
            .string()
            .required('pages.company_detail.errors.email_required')
            .email('pages.event_detail.errors.contact_email_valid')
            .typeError('pages.users.errors.email_invalid'),
        bookingFeeType: yup.string().required('Please select a fee type.'),
        vatPercentage: yup
            .string()
            .required('pages.company_detail.errors.vat_percentage_required')
            .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
                if (value === undefined || value === '0') return true;
                return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
            }),
    }),
});

export const EventSchema = yup.object().shape({
    name: yup.string().required('pages.event_detail.errors.name_required'),
    displayTitle: yup.string().required('pages.event_detail.errors.display_title_required'),
    description: yup.string().required('pages.event_detail.errors.marketing_description_required'),
    sortOrder: yup.string().required('pages.event_detail.errors.sort_order_required'),
    brandId: yup.string().required('Please select a brand.'),
    urlSlug: yup
        .string()
        .required('pages.event_detail.errors.url_slug_required')
        .matches(/^[a-zA-Z0-9\-_]+$/, 'pages.locations_detail.errors.valid_image_url'),
    dateEffective: yup
        .date()
        .typeError('pages.menus.errors.date_effective_required')
        .required('pages.menus.errors.date_effective_required'),
});

export const EventExperienceSchema = yup.object().shape({
    experience: yup.string(),
    sortOrder: yup.string().required('pages.event_detail.errors.sort_order_required'),
});

export const EventImageSchema = yup.object().shape({
    name: yup.string().required('pages.event_detail.errors.name_required'),
    sortOrder: yup.string().required('pages.event_detail.errors.sort_order_required'),
    imageUrl: yup
        .string()
        .required('pages.event_detail.errors.image_url_required')
        .url('pages.locations_detail.errors.valid_image_url'),
});

export const EventVenueSchema = yup.object().shape({
    locationId: yup.string(),
    longitude: yup
        .string()
        .required('pages.event_detail.errors.longitude_required')
        .matches(
            /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
            'pages.locations_detail.errors.invalid_longitude',
        ),
    latitude: yup
        .string()
        .required('pages.event_detail.errors.latitude_required')
        .matches(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, 'pages.locations_detail.errors.invalid_latitude'),
    contactEmail: yup
        .string()
        .required('pages.event_detail.errors.contact_email_required')
        .email('pages.event_detail.errors.contact_email_valid'),
    contactPhone: yup
        .string()
        .required('pages.event_detail.errors.contact_phone_required')
        .min(11, 'min_phone_error')
        .max(11, 'max_phone_error'),
    menuUrl: yup.string(),
    address: yup.object().shape({
        addressLine1: yup.string().required('pages.event_detail.errors.address_line_1_required'),
        addressLine2: yup.string(),
        addressLine3: yup.string(),
        town: yup.string().required('pages.event_detail.errors.town_required'),
        city: yup.string().required('pages.event_detail.errors.city_required'),
        postcode: yup
            .string()
            .required('pages.event_detail.errors.postcode_required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'pages.event_detail.errors.invalid_characters')
            .max(12, 'pages.event_detail.errors.post_code_max_length'),
    }),
});

export const EventVenueResourceSchema = yup.object().shape({
    description: yup.string().required('pages.event_venues_detail.resources.errors.description_required'),
    eventDate: yup.string().required('pages.event_venues_detail.resources.errors.event_date_required').nullable(),
    startTime: yup.string().required('pages.event_venues_detail.resources.errors.start_time_required'),
    endTime: yup.string().required('pages.event_venues_detail.resources.errors.end_time_required'),
    bookingFeeType: yup.string().required('Please select a fee type.'),

    maxBookingFee: yup
        .string()
        .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
            if (value === undefined || value === '0') return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test('max-booking-fee', 'pages.event_venues_detail.resources.errors.max_booking_fee_limit', (value: any) => {
            const MAX_VALUE = 200000;
            if (value) {
                return parseInt(value || '0', 10) <= MAX_VALUE;
            }
            return true;
        }),
    minBookingFee: yup
        .string()
        .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
            if (value === undefined || value === '0') return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test(
            'minBookingFee',
            'pages.event_venues_detail.resources.errors.min_booking_fee_limit',
            function (value: string | undefined): boolean {
                const { maxBookingFee } = this.parent;
                if (value && maxBookingFee) {
                    return parseInt(value || '0', 10) <= parseInt(maxBookingFee || '0', 10);
                }
                return true;
            },
        ),
    bookingFee: yup
        .number()
        .typeError('pages.event_venues_detail.resources.errors.booking_fee_required')
        .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
            if (value === undefined || value === 0) return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test('booking-fee', 'pages.event_venues_detail.resources.errors.booking_fee_limit', (value: any) => {
            const MAX_VALUE = 10000;
            return value <= MAX_VALUE;
        })
        .test(
            'bookingFee',
            'pages.event_venues_detail.resources.errors.booking_fee_should_greater',
            function (value: number | undefined): boolean {
                const { minBookingFee } = this.parent;
                if (value && minBookingFee) {
                    return value >= parseInt(minBookingFee || '0', 10);
                }
                return true;
            },
        )
        .required('pages.event_venues_detail.resources.errors.booking_fee_required'),
    bookingCutOffDate: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.booking_date_required')
        .nullable(),
    bookingCutOffTime: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.booking_time_required')
        .test(
            'cutOffTimeBeforeStartTime',
            'pages.event_venues_detail.resources.errors.cut_off_booking_time_Error',
            function (value) {
                const { eventDate, bookingCutOffDate, orderingCutOffDate, startTime } = this.parent;
                const _eventDate = formatDate(eventDate);
                const _bookingCutOffDate = formatDate(bookingCutOffDate);
                const _orderingCutOffDate = formatDate(orderingCutOffDate);
                if (_eventDate === _bookingCutOffDate && _eventDate === _orderingCutOffDate) {
                    if (value) {
                        return value < startTime;
                    }
                }

                return true;
            },
        ),
    orderingCutOffDate: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.ordering_date_required')
        .nullable(),
    orderingCutOffTime: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.ordering_time_required')
        .test(
            'cutOffTimeBeforeStartTime',
            'pages.event_venues_detail.resources.errors.cut_off_ordering_time_Error',
            function (value) {
                const { eventDate, bookingCutOffDate, orderingCutOffDate, startTime } = this.parent;
                const _eventDate = formatDate(eventDate);
                const _bookingCutOffDate = formatDate(bookingCutOffDate);
                const _orderingCutOffDate = formatDate(orderingCutOffDate);
                if (_eventDate === _bookingCutOffDate && _eventDate === _orderingCutOffDate) {
                    if (value) {
                        return value < startTime;
                    }
                }

                return true;
            },
        ),
    eventExternalCode: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.event_external_code_required')
        .matches(/^[a-zA-Z0-9\-_]+$/, 'pages.event_venues_detail.resources.errors.only_alpha_numeric'),
});

export const EventSchemaWithoutBookingFee = yup.object().shape({
    description: yup.string().required('pages.event_venues_detail.resources.errors.description_required'),
    eventDate: yup.string().required('pages.event_venues_detail.resources.errors.event_date_required').nullable(),
    startTime: yup.string().required('pages.event_venues_detail.resources.errors.start_time_required'),
    endTime: yup.string().required('pages.event_venues_detail.resources.errors.end_time_required'),
    bookingFeeType: yup.string().required('Please select a fee type.'),

    maxBookingFee: yup
        .string()
        .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
            if (value === undefined || value === '0') return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test('max-booking-fee', 'pages.event_venues_detail.resources.errors.max_booking_fee_limit', (value: any) => {
            const MAX_VALUE = 200000;
            if (value) {
                return parseInt(value || '0', 10) <= MAX_VALUE;
            }
            return true;
        }),
    minBookingFee: yup
        .string()
        .test('decimal-places', 'pages.event_venues_detail.resources.errors.two_decimal_limit', (value) => {
            if (value === undefined || value === '0') return true;
            return /^(\d*\.?\d{0,2}|\.\d+)$/.test(value.toString());
        })
        .test(
            'minBookingFee',
            'pages.event_venues_detail.resources.errors.min_booking_fee_limit',
            function (value: string | undefined): boolean {
                const { maxBookingFee } = this.parent;
                if (value && maxBookingFee) {
                    return parseInt(value || '0', 10) <= parseInt(maxBookingFee || '0', 10);
                }
                return true;
            },
        ),
    bookingCutOffDate: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.booking_date_required')
        .nullable(),
    bookingCutOffTime: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.booking_time_required')
        .test(
            'cutOffTimeBeforeStartTime',
            'pages.event_venues_detail.resources.errors.cut_off_booking_time_Error',
            function (value) {
                const { eventDate, bookingCutOffDate, orderingCutOffDate, startTime } = this.parent;
                const _eventDate = formatDate(eventDate);
                const _bookingCutOffDate = formatDate(bookingCutOffDate);
                const _orderingCutOffDate = formatDate(orderingCutOffDate);
                if (_eventDate === _bookingCutOffDate && _eventDate === _orderingCutOffDate) {
                    if (value) {
                        return value < startTime;
                    }
                }

                return true;
            },
        ),
    orderingCutOffDate: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.ordering_date_required')
        .nullable(),
    orderingCutOffTime: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.ordering_time_required')
        .test(
            'cutOffTimeBeforeStartTime',
            'pages.event_venues_detail.resources.errors.cut_off_ordering_time_Error',
            function (value) {
                const { eventDate, bookingCutOffDate, orderingCutOffDate, startTime } = this.parent;
                const _eventDate = formatDate(eventDate);
                const _bookingCutOffDate = formatDate(bookingCutOffDate);
                const _orderingCutOffDate = formatDate(orderingCutOffDate);
                if (_eventDate === _bookingCutOffDate && _eventDate === _orderingCutOffDate) {
                    if (value) {
                        return value < startTime;
                    }
                }

                return true;
            },
        ),
    eventExternalCode: yup
        .string()
        .required('pages.event_venues_detail.resources.errors.event_external_code_required')
        .matches(/^[a-zA-Z0-9\-_]+$/, 'pages.event_venues_detail.resources.errors.only_alpha_numeric'),
});
export const EventVenueConfigurationSchema = yup.object().shape({
    displayTitle: yup.string().required('pages.event_venues_detail.configurations.errors.display_title_required'),
    totalResources: yup
        .string()
        .required('pages.event_venues_detail.configurations.errors.total_resources_required')
        .test(
            'totalResources',
            'pages.event_venues_detail.configurations.errors.total_resources_limit',
            function (value: string | undefined): boolean {
                return parseInt(value || '0', 10) > 0;
            },
        ),

    minSize: yup
        .string()
        .required('pages.event_venues_detail.configurations.errors.min_size_required')
        .test('isNonNegative', 'pages.event_venues_detail.configurations.errors.non-negative', function (value) {
            return parseInt(value || '0', 10) >= 0;
        }),
    maxSize: yup
        .string()
        .required('pages.event_venues_detail.configurations.errors.max_size_required')
        .test(
            'maxSize',
            'pages.event_venues_detail.configurations.errors.max_size_limit',
            function (value: string | undefined): boolean {
                const { minSize } = this.parent;
                return parseInt(value || '0', 10) >= 0 && parseInt(value || '0', 10) >= parseInt(minSize || '0', 10);
            },
        ),
    eventVenueResourceConfigurationAmount: yup.array().of(
        yup.object().shape({
            eventAmount: yup.string().required('pages.event_venues_detail.configurations.errors.amount_required'),
        }),
    ),
});

export const PasswordResetSchema = yup.object().shape({
    email: yup
        .string()
        .required('pages.password_reset.errors.email_required')
        .email('pages.password_reset.errors.email_invalid'),
});

export const VerifyEmailSchema = yup.object().shape({
    password: yup.string().required('pages.verify_email.errors.password_required'),
    confirmPassword: yup
        .string()
        .required('pages.verify_email.errors.confirm_password_required')
        .oneOf([yup.ref('password'), null], 'pages.verify_email.errors.passwords_must_match'),
});
