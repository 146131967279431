import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActionPanel, ErrorPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { ContentSchema } from 'core/validation-schema';
import Spin from 'components/Spin';
import ContentSection from './form';
import { ClearContentCache } from 'fetch/Cache';
import ErrorAlert from './Alert';
import useSoftDirty from 'hooks/useSoftDirty';

export default function ContentCache() {
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [enabled, setEnabled] = useState(false);
    const [isError, setIsError] = useState(false);
    const { isDirty } = useSoftDirty('contentForm');

    // Format the form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(ContentSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        setProcessing(true);
        if (data?.content?.entryId === '' && data?.content?.contentType === '') {
            setIsError(true);
            setProcessing(false);
        } else {
            setIsError(false);
            const rsp = await ClearContentCache(
                {
                    entryId: data.content.entryId,
                    contentType: data.content.contentType,
                },
                client,
            );
            if (rsp === 200) {
                addNotification({
                    description: t('pages.content_cache.messages.success_description'),
                    title: t('pages.content_cache.messages.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
                reset();
            } else {
                addNotification({
                    description: t('pages.content_cache.messages.error_description'),
                    title: t('pages.content_cache.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
            }
        }
    });

    return (
        <>
            <Spin spinning={processing}>
                <FormActionPanel
                    isDirty={isDirty}
                    processing={processing}
                    handleReset={handleReset}
                    contextTitle={'pages.content_cache.context.title'}
                    contextDescription={'pages.content_cache.context.description'}
                    contextTarget={HelpContextTarget.Users}
                    formTarget='contentForm'
                    buttonText={t('pages.content_cache.action_button')}
                />
                {isError && <ErrorAlert />}
                <form onSubmit={onSubmit} noValidate={true} id='contentForm' className='space-y-6'>
                    <ContentSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        enabled={enabled}
                        setEnabled={setEnabled}
                    />
                    <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                </form>
            </Spin>
        </>
    );
}
