import { Bars3Icon } from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

function TopNav({ setSidebarOpen }: { setSidebarOpen: Dispatch<SetStateAction<boolean>> }) {
    const { t } = useTranslation();

    return (
        <>
            <button
                type='button'
                className='px-4 bg-gray-800 border-gray-400 text-gray-400 focus:outline-none md:hidden'
                onClick={() => setSidebarOpen(true)}>
                <span className='sr-only'>{t('buttons.open_sidebar')}</span>
                <Bars3Icon className='h-6 w-6 text-grey-400' aria-hidden='true' />
            </button>
            <div className='flex-1 flex justify-center bg-gray-800'>
                <div className='flex-1 flex'></div>
            </div>
        </>
    );
}
export default TopNav;
