import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import System from './system';
import Main from './main';
import { AuthenticatedResponseModel } from 'api/operator-api-types';

interface InputProperties {
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
    sidebarOpen: boolean;
    claims: AuthenticatedResponseModel | undefined;
}

function Mobile(props: InputProperties) {
    const { t } = useTranslation();

    return (
        <>
            <Transition.Root show={props.sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 md:hidden' onClose={props.setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>

                    <div className='fixed inset-0 flex z-40'>
                        <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'>
                            <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-in-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in-out duration-300'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'>
                                    <div className='absolute top-0 right-0 -mr-12 pt-2 '>
                                        <button
                                            type='button'
                                            className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                            onClick={() => props.setSidebarOpen(false)}>
                                            <span className='sr-only'>{t('buttons.close_sidebar', 'MISSING')}</span>
                                            <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className='flex-shrink-0 flex items-center px-4'>RMS</div>
                                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                    <nav className='px-2 space-y-1'>
                                        <Main claims={props.claims} />
                                    </nav>
                                </div>
                                <System />
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className='flex-shrink-0 w-14' aria-hidden='true'>
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default Mobile;
