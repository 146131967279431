import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';

import { FormActionPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { MenuSchema } from 'core/validation-schema';
import Spin from 'components/Spin';
import { addMenu, updateMenu, fetchMenuById } from 'fetch/company';
import useSoftDirty from 'hooks/useSoftDirty';
import dayjs from 'dayjs';
import MenuSection from './create';

export default function ManageMenus() {
    const client = useClient();
    const { companyId } = useParams();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [data, setData] = useState<any>();
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [keepIsDirty, setKeepIsDirty] = useState(true);
    const { isDirty, _setIsDirty: setDirtyToTrue } = useSoftDirty('menuForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const { data: menuData, isLoading } = useQuery(
        ['menuData', identifier!!, companyId],
        () => {
            if (identifier && identifier !== 'create') {
                return fetchMenuById(companyId as string, identifier as string, client).then((rsp) => {
                    return rsp;
                });
            }
        },
        keepForNMinutes(0),
    );

    // The form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        watch,
        setError,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(MenuSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        if (isEdit && identifier) {
            setProcessing(true);

            const rsp = await updateMenu(
                companyId as string,
                {
                    eventMenu: {
                        eventMenuId: identifier,
                        menuName: data.menu.menuName,
                        displayTitle: data.menu.displayTitle,
                        dateEffective: new Date(dayjs(data.menu.dateEffective).format('YYYY-MM-DD')),
                        dateExpires: new Date(dayjs(data.menu.dateExpires).format('YYYY-MM-DD')),
                        companyId: Number(companyId),
                        sortOrder: parseInt(data.menu.sortOrder),
                    },
                },
                client,
            );
            setProcessing(false);
            if (!rsp?.traceId) {
                addNotification({
                    description: t('pages.menus.messages.success_description'),
                    title: t('pages.menus.messages.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                navigate(`/companies/${companyId}/menus`, { replace: true });
            } else {
                addNotification({
                    description: t('pages.menus.messages.error_description'),
                    title: t('pages.menus.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        } else {
            setProcessing(true);
            const rsp = await addMenu(
                companyId,
                {
                    eventMenu: {
                        eventMenuId: 0,
                        menuName: data.menu.menuName,
                        displayTitle: data.menu.displayTitle,
                        dateEffective: new Date(dayjs(data.menu.dateEffective).format('YYYY-MM-DD')),
                        dateExpires: new Date(dayjs(data.menu.dateExpires).format('YYYY-MM-DD')),
                        companyId: Number(companyId),
                        sortOrder: parseInt(data.menu.sortOrder),
                    },
                },
                client,
            );
            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if (!rsp?.traceId) {
                if (rsp?.success || rsp?.status === 200) {
                    addNotification({
                        description: t('pages.menus.messages.save_success_description'),
                        title: t('pages.menus.messages.save_success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    navigate(`/companies/${companyId}/menus`, { replace: true });
                }
            } else {
                addNotification({
                    description: t('pages.menus.messages.error_description'),
                    title: t('pages.menus.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        }
    });

    useEffect(() => {
        setData(menuData);
        if ((data?.status && data?.status !== 200) || !data === undefined) {
            addNotification({
                description: t('pages.menus.messages.single_menue_error_description'),
                title: t('pages.menus.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            if (isEdit && data) {
                setValue('menu.menuName', data?.menuName);
                setValue('menu.displayTitle', data?.displayTitle);
                setValue('menu.dateEffective', new Date(dayjs(data.dateEffective).format('YYYY-MM-DD')));
                if (data.dateExpires) {
                    setValue('menu.dateExpires', new Date(dayjs(data.dateExpires).format('YYYY-MM-DD')));
                }
                setValue('menu.sortOrder', data?.sortOrder);
                setDirtyToTrue();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuData, data]);

    const dateEffective = watch('menu.dateEffective');
    const expireDate = watch('menu.dateExpires');

    useEffect(() => {
        if (expireDate && dateEffective) {
            if (dateEffective < expireDate) {
                setKeepIsDirty(true);
            } else {
                setKeepIsDirty(false);
                setError('menu.dateExpires', { type: 'manual', message: 'pages.menus.errors.invalid_date_expire' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateEffective, expireDate]);

    return (
        <>
            <Spin spinning={(isEdit && isLoading) || processing}>
                <span className='relative inline-flex w-full items-start justify-between'>
                    <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                        <>
                            <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                        </>
                    </button>

                    <FormActionPanel
                        isDirty={keepIsDirty ? isDirty : keepIsDirty}
                        processing={processing}
                        handleReset={handleReset}
                        contextTitle={'pages.menus.context.title'}
                        contextDescription={'pages.menus.context.description'}
                        contextTarget={HelpContextTarget.Users}
                        formTarget='menuForm'
                    />
                </span>
                <form onSubmit={onSubmit} noValidate={true} id='menuForm' className='space-y-6'>
                    <MenuSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        setDirty={setDirtyToTrue}
                    />
                </form>
            </Spin>
        </>
    );
}
