import {
    ChevronUpIcon,
    ChevronDownIcon,
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
    ChevronUpDownIcon,
} from '@heroicons/react/24/outline';
import {
    Column,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    RowSelectionState,
    SortingState,
    useReactTable,
    VisibilityState,
} from '@tanstack/react-table';
import { Dispatch, Fragment, HTMLProps, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnFilterList, ColumnGeneratorInputProps } from 'core/types';
import { classNames, fuzzyFilter, pagination } from 'core/utils';

export function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate]);

    return <input type='checkbox' ref={ref} className={className + ' cursor-pointer'} {...rest} />;
}

export function EditCss(): string {
    return 'inline-flex items-center rounded bg-green-500 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-green-600 hover:bg-green-600 mr-1';
}

export function DeleteCss(): string {
    return 'inline-flex items-center rounded bg-red-500 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-600 hover:bg-red-600';
}

export interface InputProps<T> {
    data: T[];
    columns: (props: ColumnGeneratorInputProps<T>) => any;
    setGlobalFilter?: Dispatch<SetStateAction<string>>;
    globalFilter?: string;
    columnVisibility?: VisibilityState;
    setColumnVisibility?: Dispatch<SetStateAction<VisibilityState>>;
    setColumnFilterList?: Dispatch<SetStateAction<ColumnFilterList[]>>;
    selectSingleAction?: (row: T, action: ColumnActionType, index: number) => void;
    debug?: boolean;
    canExpand?: boolean;
    pageSize?: number;
    canGlobalFilter?: boolean;
    hideTableFooter?: boolean;
    columnTranslationPrefix: string;
    noDataMessage?: string;
    renderSubData?: (row: T) => React.ReactNode | JSX.Element;
    cssWrapperOverride?: string;
    showPaging?: boolean;
    enableRowSelection?: boolean;
    selectBulkdEdit?: (rowIdentifiers: number[]) => void;
    selectBulkdDelete?: (rowIdentifiers: number[], success?: () => void) => void;
}

export default function DataTable<T>(props: InputProps<T>) {
    const { t } = useTranslation();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    // unwind props.
    const {
        data,
        columns,
        setGlobalFilter,
        globalFilter,
        setColumnVisibility,
        columnVisibility,
        setColumnFilterList,
        selectSingleAction,
        debug,
        canExpand,
        pageSize,
        canGlobalFilter,
        columnTranslationPrefix,
        noDataMessage,
        renderSubData,
        cssWrapperOverride,
        showPaging,
        enableRowSelection,
        selectBulkdEdit,
        selectBulkdDelete,
        hideTableFooter,
    } = props;

    const table = useReactTable<T>({
        data,
        columns: columns({ selectSingleAction: selectSingleAction }),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowCanExpand: () => canExpand !== undefined && canExpand,
        debugTable: debug !== undefined && debug,
        initialState: {
            pagination: {
                pageSize: pageSize !== undefined ? pageSize : showPaging !== undefined && !showPaging ? 10000 : 10,
            },
        },
        filterFns: {
            fuzzyFilter,
        },
        state: {
            globalFilter,
            sorting,
            columnVisibility,
            rowSelection,
        },
        enableRowSelection: enableRowSelection !== undefined && enableRowSelection,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        globalFilterFn: fuzzyFilter,
        getColumnCanGlobalFilter: (_column: Column<any, unknown>) => {
            return canGlobalFilter !== undefined && canGlobalFilter;
        },
    });

    const recordsDisplayedFrom = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
    const recordsDisplayedTo = recordsDisplayedFrom + table.getRowModel().rows.length - 1;
    const pageIndex = table.getState().pagination.pageIndex;
    const pageCount = table.getPageCount();
    const paginationRange = useMemo(() => pagination(pageIndex, pageCount), [pageIndex, pageCount]);

    useEffect(() => {
        if (setColumnFilterList === undefined) {
            return;
        }

        var filter: ColumnFilterList[] = [];

        table.getAllLeafColumns().forEach((lc) => {
            if (lc.getCanHide()) {
                filter.push({
                    id: lc.id,
                    handler: lc.getToggleVisibilityHandler(),
                    displayName: t(`${columnTranslationPrefix}.${lc.id}`, 'MISSING'),
                });
            }
        });

        setColumnFilterList(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBulkDelete = () => {
        let idxs: number[] = [];

        Object.keys(rowSelection).forEach((idx) => {
            const parsed = parseInt(idx, 10);
            if (!isNaN(parsed)) {
                idxs.push(parsed);
            }
        });

        const cleanUp = () => {
            setRowSelection({});
        };

        if (selectBulkdDelete !== undefined) {
            selectBulkdDelete(idxs, cleanUp);
        }
    };

    const handleBulkEdit = () => {
        let idxs: number[] = [];

        Object.keys(rowSelection).forEach((idx) => {
            const parsed = parseInt(idx, 10);
            if (!isNaN(parsed)) {
                idxs.push(parsed);
            }
        });

        if (selectBulkdEdit !== undefined) {
            selectBulkdEdit(idxs);
        }
    };

    return (
        <>
            <div
                className={
                    cssWrapperOverride
                        ? cssWrapperOverride
                        : 'overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4'
                }>
                {Object.keys(rowSelection).length > 0 && (
                    <div className='absolute top-0 left-14 flex h-12 items-center space-x-3 bg-gray-100 sm:left-12'>
                        {selectBulkdEdit && (
                            <button
                                type='button'
                                onClick={() => handleBulkEdit()}
                                className='inline-flex items-center rounded bg-green-500 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-green-600 hover:bg-green-600 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white'>
                                {t('buttons.bulk_edit', '')}
                            </button>
                        )}

                        {/* Hidden by management */}
                        {/* {selectBulkdDelete && (
                            <button
                                type='button'
                                onClick={() => handleBulkDelete()}
                                className='inline-flex items-center rounded bg-red-500 px-2 py-1 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-600 hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white'>
                                {t('buttons.bulk_delete', '')}
                            </button>
                        )} */}
                    </div>
                )}
                <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-100'>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, hIdx, items) => (
                                    <th
                                        onClick={header.column.getToggleSortingHandler()}
                                        scope='col'
                                        className={classNames(
                                            'text-left text-sm font-semibold text-gray-900',
                                            header.column.getCanSort() ? 'cursor-pointer' : '',
                                            hIdx > 0 && hIdx < items.length - 1 ? 'px-3 py-3.5' : '',
                                            hIdx === 0 ? 'py-3.5 pl-6 pr-3' : '',
                                            header.id === 'selector' ? 'w-1' : '',
                                            header.id === 'expander' ? 'w-1' : '',
                                            header.id === 'actions' ? 'w-1' : '',
                                            hIdx === items.length - 1 ? 'relative py-3.5 pl-3 pr-6' : '',
                                        )}
                                        key={header.id}>
                                        {!header.column.getCanSort() ? (
                                            <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                                        ) : null}
                                        {header.column.getCanSort() && (
                                            <span className='group inline-flex w-full'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: (
                                                        <>
                                                            <span className='ml-2 flex-none rounded h-5 w-5 bg-gray-200 text-gray-900 group-hover:bg-gray-300'>
                                                                <ChevronUpIcon className='h-5 w-5' aria-hidden='true' />
                                                            </span>
                                                        </>
                                                    ),
                                                    desc: (
                                                        <>
                                                            <span className='ml-2 flex-none rounded h-5 w-5 bg-gray-200 text-gray-900 group-hover:bg-gray-300'>
                                                                <ChevronDownIcon
                                                                    className='h-5 w-5'
                                                                    aria-hidden='true'
                                                                />
                                                            </span>
                                                        </>
                                                    ),
                                                }[header.column.getIsSorted() as string] ?? (
                                                    <>
                                                        <span className='ml-2 opacity-0 group-hover:opacity-100 flex-none rounded h-5 w-5 bg-gray-200 text-gray-900 group-hover:bg-gray-300'>
                                                            <ChevronUpDownIcon className='h-5 w-5' aria-hidden='true' />
                                                        </span>
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                        {table.getRowModel().rows.map((row, rowIdx) => {
                            return (
                                <Fragment key={`master_${row.id}`}>
                                    <tr key={row.id} className={rowIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                        {row.getVisibleCells().map((cell, idx, cells) => (
                                            <td
                                                key={cell.id}
                                                className={classNames(
                                                    cell.column.id === 'selector' ? 'w-1' : '',
                                                    cell.column.id === 'expander' ? 'w-1' : '',
                                                    cell.column.id === 'actions' ? 'w-1' : '',
                                                    idx === 0
                                                        ? 'text-left whitespace-nowrap text-sm font-normal text-gray-900 py-3.5 pl-6 pr-3'
                                                        : '',
                                                    idx + 1 === cells.length
                                                        ? 'text-left whitespace-nowrap px-3 py-4 sm:pr-6'
                                                        : '',
                                                    idx > 0 && idx + 1 < cells.length
                                                        ? 'text-left whitespace-nowrap py-4 pl-3 pr-3 text-sm font-normal text-gray-900'
                                                        : '',
                                                )}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                    {row.getIsExpanded() && (
                                        <tr key={`expand_${row.id}`}>
                                            {/* 2nd row is a custom 1 cell row */}
                                            <td
                                                colSpan={row.getVisibleCells().length}
                                                className='whitespace-nowrap py-4 text-sm font-normal bg-white text-gray-900 sm:pl-6'>
                                                <div className='grid grid-cols-1 xl:grid-cols-2'>
                                                    {renderSubData && renderSubData(row.original)}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        {table.getRowModel().rows.length === 0 && (
                            <tr>
                                <td
                                    className='text-left whitespace-nowrap py-4 pl-3 pr-3 text-sm font-medium text-gray-900 sm:pl-6 sm:pr-6'
                                    colSpan={table.getAllColumns().length}>
                                    {t(noDataMessage === undefined ? 'table.empty' : noDataMessage, 'MISSING')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {(showPaging === undefined || showPaging) && (
                    <div className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
                        <div className='flex flex-1 justify-between sm:hidden'>
                            <button
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                                className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 enabled:hover:bg-gray-50'>
                                {t('paging.previous', 'MISSING')}
                            </button>
                            <button
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                                className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 enabled:hover:bg-gray-50'>
                                {t('paging.next', 'MISSING')}
                            </button>
                        </div>
                        {!hideTableFooter && (
                            <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
                                <div>
                                    <p className='text-sm text-gray-700 font-medium'>
                                        {t('paging.grid_current', {
                                            recordsDisplayedFrom: recordsDisplayedFrom,
                                            recordsDisplayedTo,
                                        })}
                                        <span>
                                            {t('paging.grid_total', {
                                                totalRecords: table.getCoreRowModel().rows.length,
                                            })}
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <span className='text-sm text-gray-700 font-medium'>
                                        {t('paging.page_jump', 'MISSING')}
                                        <input
                                            type='number'
                                            onKeyPress={(event: any) => {
                                                if (event.charCode < 48) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            min={1}
                                            defaultValue={table.getState().pagination.pageIndex + 1}
                                            onChange={(e) => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                table.setPageIndex(page);
                                            }}
                                            className='rounded-md ml-4 w-20 border-gray-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm'
                                        />
                                    </span>
                                </div>
                                <div>
                                    <nav
                                        className='isolate inline-flex -space-x-px rounded-md shadow-sm'
                                        aria-label='Pagination'>
                                        <button
                                            key='page_double_left'
                                            onClick={() => table.setPageIndex(0)}
                                            disabled={!table.getCanPreviousPage()}
                                            className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 enabled:hover:bg-gray-50 focus:z-20'>
                                            <span className='sr-only'>{t('paging.first_page', 'MISSING')}</span>
                                            <ChevronDoubleLeftIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                        <button
                                            key='page_left'
                                            onClick={() => table.previousPage()}
                                            disabled={!table.getCanPreviousPage()}
                                            className='relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 enabled:hover:bg-gray-50 focus:z-20'>
                                            <span className='sr-only'>{t('paging.previous', 'MISSING')}</span>
                                            <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                        {paginationRange.map((page, idx) => (
                                            <button
                                                key={`page_jump_${idx}`}
                                                onClick={() => {
                                                    if (page !== null) {
                                                        table.setPageIndex(page!! - 1);
                                                    }
                                                }}
                                                className='relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex'>
                                                {page === null ? '...' : page}
                                            </button>
                                        ))}
                                        <button
                                            key='page_right'
                                            onClick={() => table.nextPage()}
                                            disabled={!table.getCanNextPage()}
                                            className='relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 enabled:hover:bg-gray-50 focus:z-20'>
                                            <span className='sr-only'>{t('paging.next', 'MISSING')}</span>
                                            <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                        <button
                                            key='page_double_right'
                                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                            disabled={!table.getCanNextPage()}
                                            className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 enabled:hover:bg-gray-50 focus:z-20'>
                                            <span className='sr-only'>{t('paging.last_page', 'MISSING')}</span>
                                            <ChevronDoubleRightIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                    </nav>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
