import { useEffect, useRef, useState } from 'react';
import { UseFormRegister, FieldValues, UseFormGetFieldState, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseRefereceModel } from 'core/types';
import { classNames, getRange, timeInMinutesToSections } from 'core/utils';
import DatePicker from 'react-datepicker';
import 'assets/css/react-datepicker.css';
import './style.css';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: BaseRefereceModel[];
    referenceType: string;
    showDefault?: boolean;
}

export interface NumberFieldInputProps extends FieldInputProps {
    minimum: number;
    maximum?: number;
    step?: string;
    defaultValue?: number;
    disabled?: boolean;
}

export interface TimeSelectionFieldInputProps extends FieldInputProps {
    minimum?: number;
    maximum?: number;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
}

export interface FieldInputProps {
    register: UseFormRegister<FieldValues>;
    getFieldState: UseFormGetFieldState<FieldValues>;
    fieldName: string;
    fieldLabel: string;
    isRequired?: boolean;
    overrideContainerCss?: string;
    hidden?: boolean;
    data_cy: string;
    placeholder?: string;
}

export interface TextFieldInputProps extends FieldInputProps {
    isPassword?: boolean;
    disabled?: any;
}

export interface SingleDateSelectionInputProps extends FieldInputProps {
    minimum?: Date;
    maximum?: Date;
    showTimeSelect?: boolean;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    trigger?: any;
    defaultValue: Date | null;
    setDirty?: any;
    handleResetDatePicker?: any;
    disabled?: any;
}

function Spacer() {
    return <div className='hidden lg:block lg:gap-6 lg:col-span-3'></div>;
}

function SingleDateSelectionField(props: SingleDateSelectionInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [inputDate, setInputDate] = useState<Date | null>(props.defaultValue);
    const initialValue = props.getValues(props.fieldName);

    // to keep track of the init event
    const isInit = useRef<boolean>();

    useEffect(() => {
        props.setValue(props.fieldName, inputDate, {
            shouldDirty: true,
        });
        if (inputDate && props.trigger) {
            props.trigger(props.fieldName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDate, props.trigger]);

    // this is used to clear the field if reset is called on the main react-form-hook
    useEffect(() => {
        if (initialValue === null || initialValue === undefined || initialValue === '') {
            setInputDate(null);
        } else {
            // commenting out the following line as it gets break on resetting form
            // alternatively see the lines through 77 to 80
            // setInputDate(new Date(initialValue));
            // To initialize date field on first render
            if (!inputDate && !isInit.current) {
                isInit.current = true;
                setInputDate(new Date(initialValue));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    style={{
                        opacity: props.disabled && props.disabled ? 0.5 : 1,
                        cursor: props.disabled && props.disabled ? 'not-allowed' : 'auto',
                    }}
                    disabled={props.disabled ? props.disabled : false}
                    {...props.register(props.fieldName)}
                    type='hidden'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    data-cy={`${props.data_cy}`}
                />
                <DatePicker
                    disabled={props.disabled ? props.disabled : false}
                    todayButton={t('calendar.today')}
                    locale='en-GB'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        props.disabled && props.disabled ? 'opacity-50 cursor-not-allowed' : '',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
                    selected={inputDate}
                    calendarStartDay={1}
                    minDate={props.minimum ? props.minimum!! : null}
                    maxDate={props.maximum ? props.maximum!! : null}
                    isClearable={!props.isRequired}
                    dateFormat='yyyy-MM-dd'
                    onChange={(date: Date) => {
                        if (props.handleResetDatePicker) {
                            props.handleResetDatePicker();
                        }
                        setInputDate(date);
                        if (props.setDirty) {
                            props.setDirty();
                        }
                    }}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function DatePickerField(props: SingleDateSelectionInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [inputDate, setInputDate] = useState<Date | null>(props.defaultValue);
    const initialValue = props.getValues(props.fieldName);

    useEffect(() => {
        props.setValue(props.fieldName, inputDate, {
            shouldDirty: true,
        });
        if (inputDate && props.trigger) {
            props.trigger(props.fieldName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDate, props.trigger]);

    // this is used to clear the field if reset is called on the main react-form-hook
    useEffect(() => {
        if (initialValue === null || initialValue === undefined || initialValue === '') {
            setInputDate(null);
        } else {
            setInputDate(new Date(initialValue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    {...props.register(props.fieldName)}
                    type='hidden'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    data-cy={`${props.data_cy}`}
                />
                <DatePicker
                    todayButton={t('calendar.today')}
                    locale='en-GB'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
                    selected={inputDate}
                    calendarStartDay={1}
                    minDate={props.minimum ? props.minimum!! : null}
                    maxDate={props.maximum ? props.maximum!! : null}
                    isClearable={false}
                    dateFormat='yyyy-MM-dd'
                    onChange={(date: Date) => setInputDate(date)}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function DatePickerForReport(props: SingleDateSelectionInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [inputDate, setInputDate] = useState<any>(props.defaultValue);

    useEffect(() => {
        props.setValue(props.fieldName, inputDate, {
            shouldDirty: true,
        });
        if (inputDate && props.trigger) {
            props.trigger(props.fieldName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDate, props.trigger]);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    {...props.register(props.fieldName)}
                    type='hidden'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    data-cy={`${props.data_cy}`}
                />
                <DatePicker
                    todayButton={t('calendar.today')}
                    locale='en-GB'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
                    selected={inputDate ? new Date(inputDate) : null}
                    calendarStartDay={1}
                    minDate={props.minimum ? props.minimum!! : null}
                    maxDate={props.maximum ? props.maximum!! : null}
                    isClearable={false}
                    dateFormat='yyyy-MM-dd'
                    onChange={(date: Date) => setInputDate(date)}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function TimePickerField(props: any) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    style={{
                        opacity: props.disabled && props.disabled ? 0.5 : 1,
                        cursor: props.disabled && props.disabled ? 'not-allowed' : 'auto',
                    }}
                    disabled={props?.disabled ? props?.disabled : false}
                    {...props.register(props.fieldName, {
                        onChange: (e: any) => {
                            if (props.setTime) {
                                props.setTime(e.target.value);
                            }
                        },
                    })}
                    type='time'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
                {props.timeError && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t('pages.event_venues_detail.resources.errors.invalid_time', 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function TimeSelectionField(props: TimeSelectionFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const [days, setDays] = useState<number | typeof NaN>(0);
    const [hours, setHours] = useState<number | typeof NaN>(0);
    const [minutes, setMinutes] = useState<number | typeof NaN>(0);
    const [initial, setInitial] = useState<number>(0);

    useEffect(() => {
        const initialValue = props.getValues(props.fieldName);

        const parsedMinutes = parseInt(initialValue, 10);
        if (!isNaN(parsedMinutes) && initialValue > 0) {
            const defaults = timeInMinutesToSections(parsedMinutes);
            setDays(defaults.days);
            setHours(defaults.hours);
            setMinutes(defaults.minutes);
            setInitial(parsedMinutes);
        }
    }, [props]);

    useEffect(() => {
        const d = isNaN(days) ? 0 : days;
        const h = isNaN(hours) ? 0 : hours;
        const m = isNaN(minutes) ? 0 : minutes;

        const newValue = d * (60 * 24) + h * 60 + m;

        props.setValue(props.fieldName, newValue, {
            shouldDirty: initial !== newValue,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days, hours, minutes, initial]);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <div className='grid grid-cols-3 gap-1'>
                    <input
                        {...props.register(props.fieldName)}
                        type='hidden'
                        name={props.fieldName}
                        id={props.fieldName}
                        autoComplete='off'
                        data-cy={`${props.data_cy}`}
                    />
                    <input
                        type='number'
                        autoComplete='off'
                        placeholder='hello'
                        id={`${props.fieldName}-timefield-days`}
                        name={`${props.fieldName}-timefield-days`}
                        value={days}
                        onChange={(e) => setDays(parseInt(e.target.value, 10))}
                        min={0}
                        className={classNames(
                            'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                            state.error
                                ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                        )}
                    />
                    <select
                        autoComplete='off'
                        id={`${props.fieldName}-timefield-hours`}
                        name={`${props.fieldName}-timefield-hours`}
                        value={hours}
                        onChange={(e) => setHours(parseInt(e.target.value, 10))}
                        className={classNames(
                            'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                            state.error
                                ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                        )}>
                        {/* <option value={0}>{t('form_fields.hours')}</option> */}
                        {getRange(0, 23).map((item) => (
                            <option key={`hour-${item}-${props.fieldName}`} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <select
                        autoComplete='off'
                        id={`${props.fieldName}-timefield-minutes`}
                        name={`${props.fieldName}-timefield-minutes`}
                        value={minutes}
                        onChange={(e) => setMinutes(parseInt(e.target.value, 10))}
                        className={classNames(
                            'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                            state.error
                                ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                        )}>
                        {/* <option value={0}>{t('form_fields.minutes')}</option> */}
                        {getRange(0, 59).map((item) => (
                            <option key={`minutes-${item}-${props.fieldName}`} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    {state.error && (
                        <p
                            className='mt-1 text-sm text-red-600'
                            id={`${props.fieldName}-error`}
                            data-cy={`${props.data_cy}-error`}>
                            {t(`${state.error.message}`, 'MISSING')}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

function NumberField(props: NumberFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const errorMsg =
        (state.error && state.error.type === 'min') || (state.error && state.error.type === 'max')
            ? t('pages.users.errors.phone_invalid', 'MISSING')
            : 'MISSING';
    return (
        <>
            <div hidden={props.hidden} className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    {...props.register(props.fieldName)}
                    type='number'
                    value={props.defaultValue}
                    min={props.minimum}
                    max={props.maximum}
                    name={props.fieldName}
                    id={props.fieldName}
                    step={props.step ?? '1'}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, errorMsg)}
                    </p>
                )}
            </div>
        </>
    );
}

function ReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-6 lg:col-span-3'>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}></span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option key={`${props.referenceType}-default`} value={-1}>
                            {/* {t('default_select')} */}
                            'hello'
                        </option>
                    )}
                    {props.referenceData?.map((item) => (
                        <option key={`${props.referenceType}-${item.name}`} value={item.id}>
                            {t(`referenceData.${props.referenceType}.${item.id}`, `${item.name}`)}
                        </option>
                    ))}
                </select>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function TextAreaField(props: FieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <textarea
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function CheckField(props: FieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='flex col-span-6 lg:col-span-3 space-y-10'>
                <div className='relative flex items-center'>
                    <div className='flex h-6 items-center'>
                        <input
                            {...props.register(props.fieldName)}
                            id={props.fieldName}
                            name={props.fieldName}
                            type='checkbox'
                            className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                            data-cy={`${props.data_cy}`}
                        />
                    </div>
                    <div className='ml-3 text-sm leading-6'>
                        <label htmlFor={props.fieldName} className='font-medium text-gray-900'>
                            {t(props.fieldLabel, 'MISSING')}
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

function TextField(props: TextFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    const [passwordHidden, setPasswordHidden] = useState(false);
    const onToggleEye = () => {
        setPasswordHidden(!passwordHidden);
    };

    useEffect(() => {
        if (props.isPassword) {
            setPasswordHidden(props.isPassword);
        }
    }, [props.isPassword]);

    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                    <input
                        style={{
                            opacity: props.disabled && props.disabled ? 0.5 : 1,
                            cursor: props.disabled && props.disabled ? 'not-allowed' : 'auto',
                        }}
                        disabled={props.disabled ? props.disabled : false}
                        {...props.register(props.fieldName)}
                        type={passwordHidden ? 'password' : 'text'}
                        name={props.fieldName}
                        id={props.fieldName}
                        autoComplete='off'
                        className={classNames(
                            'block w-full rounded-md shadow-sm sm:text-sm disabled-input',
                            state.error
                                ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                            props.isPassword ? 'block rounded-l-md rounded-none w-full sm:text-sm' : 'mt-1',
                        )}
                        data-cy={`${props.data_cy}`}
                    />

                    {props.isPassword ? (
                        <>
                            <button
                                type='button'
                                onClick={onToggleEye}
                                className={`-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border ${
                                    state.error ? 'border-red-300' : 'border-gray-300'
                                } text-sm font-medium rounded-r-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500`}>
                                {passwordHidden ? (
                                    <EyeIcon tabIndex={-1} className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                ) : (
                                    <EyeSlashIcon tabIndex={-1} className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                )}
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function EmailField(props: FieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const inValidEmailErrorMessage = t('common.in_valid_email_message');
    return (
        <>
            <div className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>

                <input
                    {...props.register(props.fieldName)}
                    type='email'
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    placeholder={props?.placeholder}
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, inValidEmailErrorMessage)}
                    </p>
                )}
            </div>
        </>
    );
}

function Dropdown(props: any) {
    const { t } = useTranslation();
    const defaultOptionText = t('common.default_select_option_text');

    return (
        <>
            <div className='col-span-3 lg:col-span-3'>
                <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                    {t(props.fieldLabel, 'MISSING')}
                </label>
                <select
                    value={props.value}
                    onChange={(item) => props.onChange(item.target.value)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.referenceData?.map((item: any, index: number) => (
                        <option key={`${props.referenceType}-${item.brandId}-${index}`} value={item.brandId}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
export {
    TextField,
    ReferenceField,
    NumberField,
    Spacer,
    TimeSelectionField,
    CheckField,
    TextAreaField,
    SingleDateSelectionField,
    EmailField,
    TimePickerField,
    DatePickerField,
    DatePickerForReport,
    Dropdown,
};
