import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './en/translation.json';

export const defaultNS = 'translation';

export const resources = {
    en: {
        translation,
    },
} as const;

i18n.use(initReactI18next).init({
    lng: 'en',
    debug: false,
    returnNull: false,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources,
    defaultNS,
});
