import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getEventVenueResourcesConfigurations } from 'fetch/event';

import DataTable from 'components/table';
import { NotificationType } from 'core/types';
import { keepForNMinutes } from 'core/utils';
import { useClient } from 'core/api-client';
import Columns from './amountColumns';
import { useNotifications } from 'context/notification-context';

function ConfigurationAmountList() {
    let { companyId, eventVenueResourceConfigurationId } = useParams();
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        ticketTypeName: true,
        eventAmount: true,
        ticketTypeId: true,
    });
    const client = useClient();

    const { data: eventVenuesResourceData, isLoading } = useQuery(
        ['configurationAmountList-data', companyId, eventVenueResourceConfigurationId],
        () => {
            const res = getEventVenueResourcesConfigurations(
                companyId as string,
                eventVenueResourceConfigurationId as string,
                client,
            );
            if (res) return res;
        },
        keepForNMinutes(1),
    );

    // Set the event data to a format that is easier to use in the UI.
    useEffect(() => {
        if (eventVenuesResourceData === undefined || eventVenuesResourceData === null) {
            if (!isLoading && !eventVenuesResourceData) {
                addNotification({
                    description: t('pages.event_venues.messages.resource_list_error_description', 'MISSING'),
                    title: t('pages.event_venues.messages.error_title', 'MISSING'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        } else {
            const uiModel: any[] = eventVenuesResourceData?.eventVenueResourceConfigurationAmount?.map(
                (item: { ticketTypeName: any; eventAmount: any; ticketTypeId: any }) => {
                    return {
                        ticketTypeName: item?.ticketTypeName,
                        eventAmount: item?.eventAmount,
                        ticketTypeId: item?.ticketTypeId,
                    };
                },
            );
            if (uiModel) {
                setData(uiModel);
            } else {
                setData([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventVenuesResourceData, isLoading]);
    return (
        <>
            <DataTable<any>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                columnTranslationPrefix={'pages.event_venues.resource_config_table.resource_amount_headers'}
                columns={Columns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.event_venues.resource_config_table.empty'}
            />
        </>
    );
}

export default ConfigurationAmountList;
