import { useTranslation } from 'react-i18next';
import { EventVenueDto, LocationDto } from 'api/operator-api-types';
import DataTable, { DeleteCss } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps, EventSubSectionTarget } from 'core/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { timer } from 'core/utils';
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useConfirm } from 'context/confirmation-context';
import { XMarkIcon } from '@heroicons/react/20/solid';

export interface InputProps {
    data: EventVenueDto[] | null;
    locationData: LocationDto[] | undefined;
    companyId: number;
    setVenues: Dispatch<SetStateAction<EventVenueDto[]>>;
    addCallback: (target: EventSubSectionTarget) => void;
    setIsDirty: () => void;
}

export default function VenueSection(props: InputProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<EventVenueDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();

    const { data: input, locationData, setVenues, addCallback, setIsDirty } = props;

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    const getVenueName = (locationId: string | undefined) => {
        if (locationData) {
            var result = locationData.find((loc: LocationDto) => {
                return loc.locationId == locationId;
            });
            return result?.name;
        }
    };

    const handleDelete = (row: EventVenueDto, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                var newData = [...data];
                newData.splice(index, 1);
                setData((data) => [...newData]);
                setVenues(newData);
                setIsDirty();
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    function VenueColumns(props: ColumnGeneratorInputProps<EventVenueDto>): any {
        const { t } = useTranslation();
        const columnHelper = createColumnHelper<EventVenueDto>();

        // unwind props.
        const { selectSingleAction } = props;
        let columnDefs = [];

        columnDefs.push(
            columnHelper.accessor((row) => row.locationId, {
                id: 'name',
                cell: (info) => <>{getVenueName(info.getValue())}</>,
                header: () => t('pages.event_detail.venues.table.headers.name', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.address, {
                id: 'address',
                cell: (info) => <>{info.getValue()?.town}</>,
                header: () => t('pages.event_detail.venues.table.headers.address', 'MISSING'),
            }),
        );

        columnDefs.push(
            columnHelper.accessor((row) => row.isFeatured, {
                id: 'isFeatured',
                cell: (info) => (
                    <>
                        {info.getValue() == true ? (
                            <CheckIcon className='h-5 w-5' />
                        ) : (
                            <XMarkIcon className='h-5 w-5' />
                        )}
                    </>
                ),
                header: () => t('pages.event_detail.venues.table.headers.is_featured', 'MISSING'),
            }),
        );

        if (selectSingleAction !== undefined) {
            columnDefs.push(
                columnHelper.display({
                    id: 'actions',
                    cell: (props) => (
                        <button
                            type='button'
                            onClick={() =>
                                selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)
                            }
                            className={DeleteCss()}
                            data-cy='delete'>
                            {t('buttons.delete', '')}
                        </button>
                    ),
                    enableHiding: false,
                    enableSorting: false,
                    enableMultiSort: false,
                    enableColumnFilter: false,
                    enableGlobalFilter: false,
                }),
            );
        }

        return columnDefs;
    }

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.event_detail.venues.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.event_detail.venues.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(EventSubSectionTarget.Venues)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<EventVenueDto>
                                columnTranslationPrefix={'pages.event_detail.venues.table.headers'}
                                columns={VenueColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={data}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.event_detail.venues.table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
