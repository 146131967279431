import { yupResolver } from '@hookform/resolvers/yup';
import { ChangesPanel, ErrorPanel, FormActionPanel } from 'components/action-panel';
import { EventSubSectionTarget, HelpContextTarget, NotificationType } from 'core/types';
import { EventSchema } from 'core/validation-schema';
import { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useClient } from 'core/api-client';
import {
    EventCreateRequest,
    EventExperienceDto,
    EventImageDto,
    EventUpdatePutRequest,
    EventVenueDto,
    ExperienceAdminDto,
} from 'api/operator-api-types';
import { useNotifications } from 'context/notification-context';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';
import EventSection from './event-section';
import ImageSection from './images-section';
import ExperienceSection from './experiences-section';
import VenueSection from './venue-section';
import SubSectionAddForm from './sub-section-add';
import { createEvent, getEvent, updateEvent } from 'fetch/event';
import { fetchLocationData } from 'fetch/location';
import { getAllExperiences } from 'fetch/experience';
import useSoftDirty from 'hooks/useSoftDirty';
import { getBrandList } from 'fetch/brands';

// An interface for our actions
interface ReducerAction {
    type: EventSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: EventSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== EventSubSectionTarget.Closed,
    };

    switch (action.type) {
        case EventSubSectionTarget.Experiences:
            res.title = 'pages.event_detail.experiences.title';
            res.description = 'pages.event_detail.experiences.description';
            break;

        case EventSubSectionTarget.Images:
            res.title = 'pages.event_detail.images.title';
            res.description = 'pages.event_detail.images.description';
            break;

        case EventSubSectionTarget.Venues:
            res.title = 'pages.event_detail.venues.title';
            res.description = 'pages.event_detail.venues.description';
            break;
    }

    return res;
}

export default function ManageEvent() {
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { eventId, companyId } = useParams();

    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);

    const [experiences, setExperiences] = useState<EventExperienceDto[]>([]);
    const [images, setImages] = useState<EventImageDto[]>([]);
    const [venues, setVenues] = useState<EventVenueDto[]>([]);
    const [existingExperiences, setExistingExperiences] = useState<ExperienceAdminDto[]>([]);
    const { isDirty, _setIsDirty: setIsDirty } = useSoftDirty('addEventForm');

    const [state, dispatch] = useReducer(reducer, {
        panel: EventSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });

    useEffect(() => {
        if (eventId && eventId != 'create') {
            setIsEdit(true);
        }
    }, [eventId]);

    const {
        data: eventData,
        isLoading: eventDataIsLoading,
        refetch,
    } = useQuery(
        ['eventData', companyId!!, eventId!!],
        () => {
            if (companyId && eventId && eventId != 'create') {
                return getEvent(companyId!!, eventId!!, client).then((rsp) => {
                    if (rsp.eventExperiences) {
                        setExperiences(rsp.eventExperiences);
                    }
                    if (rsp.eventImages) {
                        setImages(rsp.eventImages);
                    }
                    if (rsp.eventVenues) {
                        setVenues(rsp.eventVenues);
                    }
                    return rsp;
                });
            }
        },
        keepForNMinutes(0),
    );

    const { data: locationData, isLoading: locationDataIsLoading } = useQuery(
        ['locationData'],
        () => {
            return fetchLocationData(client).then((rsp) => {
                return rsp;
            });
        },
        keepForNMinutes(0),
    );

    const { data: experiencesData, isLoading: experiencesDataIsLoading } = useQuery(
        ['experiencesData'],
        () => {
            return getAllExperiences(client).then((rsp) => {
                return rsp;
            });
        },
        keepForNMinutes(0),
    );

    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTraceId(null);
        setProcessing(true);
        if (experiences?.length > 0 && images?.length > 0 && venues?.length > 0) {
            setShowFormErrors(false);
            if (isEdit && companyId && eventId) {
                const rsp = await updateEvent(
                    eventId,
                    companyId,
                    EventUpdatePutRequest.fromJS({
                        event: {
                            eventId: eventId,
                            name: data.name,
                            displayTitle: data.displayTitle,
                            description: data.description,
                            sortOrder: data.sortOrder,
                            urlSlug: data.urlSlug,
                            brandId: data.brandId,
                            dateEffective: new Date(dayjs(data.dateEffective).format('YYYY-MM-DD')),
                            companyId: Number(companyId),
                        },
                        experiences: experiences,
                        images: images,
                        venues: venues,
                    }),
                    client,
                );

                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.status && rsp.status !== 200) {
                    addNotification({
                        description: t('pages.event_detail.detail.error_description'),
                        title: t('pages.event_detail.detail.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                } else {
                    // Success
                    addNotification({
                        description: t('pages.event_detail.update.success_description'),
                        title: t('pages.event_detail.update.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    if (rsp.eventId != null) {
                        navigate(`../`, { replace: true });
                    }
                    refetch();
                }
            } else {
                const rsp = await createEvent(
                    Number(companyId),
                    EventCreateRequest.fromJS({
                        event: {
                            name: data.name,
                            displayTitle: data.displayTitle,
                            description: data.description,
                            sortOrder: data.sortOrder,
                            urlSlug: data.urlSlug,
                            brandId: data.brandId,
                            dateEffective: new Date(dayjs(data.dateEffective).format('YYYY-MM-DD')),
                            companyId: Number(companyId),
                        },
                        experiences: experiences,
                        images: images,
                        venues: venues,
                    }),
                    client,
                );

                setProcessing(false);
                if (rsp.traceId) {
                    setTraceId(rsp.traceId);
                }

                if (rsp.status && rsp.status !== 200) {
                    if (rsp?.errors && rsp?.errors?.request[0] === 'AlreadyExists') {
                        addNotification({
                            description: t('pages.event_detail.detail.duplicate_error_description'),
                            title: t('pages.event_detail.detail.duplicate_error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    } else {
                        addNotification({
                            description: t('pages.event_detail.detail.error_description'),
                            title: t('pages.event_detail.detail.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    // Success
                    addNotification({
                        description: t('pages.event_detail.detail.success_description'),
                        title: t('pages.event_detail.detail.success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    if (rsp != null) {
                        navigate(`../`, { replace: true });
                    }
                }
            }
        }
    });

    const addExperience = (data: EventExperienceDto) => {
        var newExperiences = [...experiences];
        newExperiences.push(data);
        setExperiences(newExperiences);
        setIsDirty();
    };
    const addImage = (data: EventImageDto) => {
        var newImages = [...images];
        newImages.push(data);
        setImages(newImages);
        setIsDirty();
    };
    const addVenue = (data: EventVenueDto) => {
        var newVenues = [...venues];
        newVenues.push(data);
        setVenues(newVenues);
        setIsDirty();
    };
    const addToExistingExperiences = (data: ExperienceAdminDto) => {
        var newExistingExperiences = [...existingExperiences];
        newExistingExperiences.push(data);
        setExistingExperiences(newExistingExperiences);
        setIsDirty();
    };

    const deleteFromExperiences = (experienceId: string) => {
        var newExistingExperiences = [...existingExperiences];
        var existingExperienceToRemoveIndex = newExistingExperiences.findIndex(
            (exp) => exp.experienceId === experienceId,
        );
        newExistingExperiences.splice(existingExperienceToRemoveIndex, 1);
        setExistingExperiences(newExistingExperiences);

        var newExperiences = [...experiences];
        var experienceToRemoveIndex = newExperiences.findIndex((exp) => exp.experienceId === experienceId);
        newExperiences.splice(experienceToRemoveIndex, 1);
        setExperiences(newExperiences);
        setIsDirty();
    };

    const handleReset = () => {
        if (eventData === undefined) {
            return;
        }
        setTraceId(null);
        reset(eventData);
    };

    useEffect(() => {
        handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData, experiencesData]);

    const openAddSubSection = (target: EventSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };

    useEffect(() => {
        if (Object?.keys(errors)?.length > 0) {
            if (experiences?.length === 0 && images?.length === 0 && venues?.length == 0) {
                setShowFormErrors(true);
                setErrorMessage(t('pages.event_detail.errors.minimum_one_required'));
            }
        } else {
            if (processing) {
                if (experiences?.length > 0 && images?.length > 0 && venues?.length > 0) {
                    setShowFormErrors(false);
                } else {
                    setShowFormErrors(true);
                    if (experiences.length === 0) {
                        setErrorMessage(t('pages.event_detail.errors.minimum_experiences'));
                        setProcessing(false);
                    } else if (images.length === 0) {
                        setErrorMessage(t('pages.event_detail.errors.minimum_images'));
                        setProcessing(false);
                    } else {
                        setErrorMessage(t('pages.event_detail.errors.minimum_venues'));
                        setProcessing(false);
                    }
                }
            } else {
                if (experiences?.length > 0 && images?.length > 0 && venues?.length > 0) {
                    setShowFormErrors(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, experiences, images, venues]);

    const { data: brandList } = useQuery(
        ['brand-list'],
        () => {
            return getBrandList(companyId as string, client);
        },
        keepForNMinutes(0),
    );

    return (
        <>
            <span className='relative inline-flex w-full items-start justify-between'>
                <button
                    onClick={() => navigate(-1)}
                    type='button'
                    className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                    <>
                        <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                    </>
                </button>

                <FormActionPanel
                    isDirty={isDirty}
                    processing={processing}
                    handleReset={handleReset}
                    contextTitle='pages.event_detail.context.title'
                    contextDescription='pages.event_detail.context.description'
                    contextTarget={HelpContextTarget.Events}
                    formTarget='addEventForm'
                />
            </span>
            <form onSubmit={onSubmit} noValidate={true} id='addEventForm' className='space-y-6'>
                <ChangesPanel isDirty={isDirty} />
                <ErrorPanel formError={showFormErrors} serverErrorList={[errorMessage]} traceId={traceId} />
                <EventSection
                    key='event-section'
                    errors={errors}
                    register={register}
                    trigger={trigger}
                    getFieldState={getFieldState}
                    getValues={getValues}
                    setValue={setValue}
                    event={eventData?.event}
                    setDirty={setIsDirty}
                    brandList={brandList}
                />
                <ExperienceSection
                    data={experiences}
                    experiences={experiencesData}
                    addCallback={openAddSubSection}
                    deleteFromExperiences={deleteFromExperiences}
                />
                <ImageSection
                    setIsDirty={setIsDirty}
                    data={images}
                    setImages={setImages}
                    addCallback={openAddSubSection}
                />
                <VenueSection
                    setIsDirty={setIsDirty}
                    data={venues}
                    setVenues={setVenues}
                    locationData={locationData}
                    companyId={Number(companyId)}
                    addCallback={openAddSubSection}
                />
            </form>
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                identifier={eventId!!}
                addExperience={addExperience}
                addImage={addImage}
                addVenue={addVenue}
                addToExistingExperiences={addToExistingExperiences}
                existingExperiences={existingExperiences}
                experienceData={experiencesData}
                setDirty={setIsDirty}
            />
        </>
    );
}
