import { OPERATOR_API_URL } from 'core/api-client';
import { EventAdminDto, EventCreateRequest, EventUpdatePutRequest } from 'api/operator-api-types';
import { ClientApiOptions } from 'core/types';

export const fetchEventData = async (
    companyId: string,
    brandId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto[]>,
) => {
    const defaultReturn = new Array<EventAdminDto>();

    let url = `${OPERATOR_API_URL}/api/v1/event/${companyId}/all`;
    if (brandId !== 'all' && brandId !== '') {
        url += `?brandId=${brandId}`;
    }
    return client(url, {})
        .then((rsp) => rsp)
        .catch(() => defaultReturn);
};

export const deleteEvent = async (
    id: string,
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/event/${id}/${companyId}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const createEvent = async (
    companyId: number,
    data: EventCreateRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/event/${companyId}`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateEvent = async (
    eventId: string,
    companyId: string,
    data: EventUpdatePutRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<number | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/event/${eventId}/${companyId}`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEvent = async (
    companyId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/event/${companyId}/events/${eventId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventVenue = async (
    companyId: string,
    eventVenueId: string,
    startDate: any,
    endDate: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/${eventVenueId}/${startDate}/${endDate}/resources`,
        {
            methodOverride: 'GET',
        },
    )
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteEventVenueDetails = async (
    eventVenueId: string,
    companyId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/venue/${eventVenueId}/venueresource/${eventVenueResourceId}`,
        {
            methodOverride: 'DELETE',
        },
    )
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const eventVenueResourceUpdate = async (
    companyId: string,
    eventVenueId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/${eventVenueId}/resources`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getFoodOrderingData = async (
    eventVenueId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}/${eventVenueResourceId}/food-order`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventTicketsData = async (
    eventVenueId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}/${eventVenueResourceId}/tickets`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export const getAllEventVenues = async (
    companyId: string,
    eventVenueId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenue/${companyId}/${eventId}/venues/${eventVenueId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateEventVenues = async (
    companyId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenue/${companyId}/event-venue`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export const createEventVenues = async (
    companyId: string,
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenue/${companyId}/event-venue`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventVenueResources = async (
    companyId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/${eventVenueResourceId}/configuration`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteConfiguration = async (
    eventVenueResourceConfigurationId: string,
    identifier: string, // eventVenueId
    companyId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/venue/${identifier}/resourceconfiguration/${eventVenueResourceConfigurationId}`,
        {
            methodOverride: 'DELETE',
        },
    )
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export const getEventVenueResource = async (
    eventVenueId: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/eventvenueresource/${eventVenueId}/resources/${eventVenueResourceId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventVenueList = async (
    companyId: string,
    eventId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/event/${companyId}/events/${eventId}/event-venues`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getEventVenueResourcesConfigurations = async (
    companyId: string,
    eventVenueResourceConfigurationId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<EventAdminDto | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventvenueresource/${companyId}/configuration/${eventVenueResourceConfigurationId}`,
        {
            methodOverride: 'GET',
        },
    )
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};
export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
