import { XCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    traceId: string | null;
    serverErrorList: string[];
    formError: boolean;
}

export default function ErrorPanel(props: InputProps) {
    const { t } = useTranslation();
    const { traceId, serverErrorList, formError } = props;

    if (!formError) {
        return <></>;
    }

    return (
        <>
            <div className='rounded-md bg-red-50 p-4'>
                <div className='flex'>
                    <div className='flex-shrink-0'>
                        <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
                    </div>
                    <div className='ml-3'>
                        <h3 className='text-sm font-medium text-red-800'>
                            {traceId ? t('generic_error_trace_id', { traceId: traceId }) : t('generic_error')}
                        </h3>
                        {serverErrorList.length > 0 && (
                            <div className='mt-2 text-sm text-red-700'>
                                <ul className='list-disc space-y-1 pl-5'>
                                    {serverErrorList.map((er, idx) => (
                                        <li key={`server_error_${idx}`}>{er}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
