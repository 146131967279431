import { useAuth } from 'context/auth-context';
import { LoginReturn } from 'core/types';
import { LoginSchema } from 'core/validation-schema';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from 'assets/logo.png';
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon, XCircleIcon } from '@heroicons/react/24/outline';

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [loginError, setLoginError] = useState(false);

    // return the user to the origin
    var rstate = location?.state as LoginReturn;
    const targetPath = rstate?.from?.pathname || '/';

    // Control the all seeing eye.
    const onToggleEye = () => {
        setPasswordHidden(!passwordHidden);
    };

    // Format the form handler.
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        setLoginError(false);

        auth.login(
            {
                userName: data.email,
                password: data.password,
            },
            () => {
                navigate(targetPath, { replace: true });
            },
            () => {
                setLoginError(true);
                setProcessing(false);
            },
        );
    });

    return (
        <>
            <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <img className='mx-auto h-24 w-auto' src={logo} alt='LILI Admin' />
                    <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
                        {t('pages.login.header')}
                    </h2>
                </div>
                {process.env.REACT_APP_ENVIRONMENT !== 'Prod' ? (
                    <div className='mt-8 sm:mx-auto bg-green-50 p-4 sm:w-full sm:max-w-md'>
                        <div className='flex'>
                            <div className='flex-shrink-0'>
                                <XCircleIcon className='h-5 w-5 text-green-400' aria-hidden='true' />
                            </div>
                            <div className='ml-3'>
                                <h3 className='text-sm font-medium text-green-800'>
                                    {process.env.REACT_APP_ENVIRONMENT}
                                </h3>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                    <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                        {loginError ? (
                            <div className='sm:mx-auto sm:w-full mb-4 sm:max-w-md'>
                                <div className='flex'>
                                    <div className='flex-shrink-0'>
                                        <XCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                                    </div>
                                    <div className='ml-3'>
                                        <h3 className='text-sm font-medium text-red-500'>
                                            {t('pages.login.errors.generic')}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <form onSubmit={onSubmit} className='space-y-3' noValidate={true}>
                            <div className='flex justify-between'>
                                <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                                    {t('pages.login.field_names.email')}
                                </label>
                                <span className='text-sm text-red-500' id='email-required'>
                                    {t('required_field')}
                                </span>
                            </div>
                            <div className='mt-1 relative'>
                                <input
                                    type='email'
                                    id='email'
                                    data-cy='email'
                                    tabIndex={1}
                                    disabled={processing}
                                    autoComplete='off'
                                    className={`shadow-sm ${
                                        errors.email
                                            ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
                                            : 'border-gray-300 focus:ring-gray-800 focus:border-gray-800'
                                    }  block w-full sm:text-sm rounded-md`}
                                    aria-describedby='email-required'
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                                        <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                                    </div>
                                )}
                            </div>
                            {errors.email && (
                                <p className='mt-1 text-sm text-red-600' id='email-error' data-cy='email-error'>
                                    {t(`${errors.email.message}`, '')}
                                </p>
                            )}

                            <div className='flex justify-between'>
                                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                                    {t('pages.login.field_names.password')}
                                </label>
                                <span className='text-sm text-red-500' id='password-required'>
                                    {t('required_field')}
                                </span>
                            </div>
                            <div className='mt-1 flex rounded-md shadow-sm'>
                                <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                                    <input
                                        type={passwordHidden ? 'password' : 'text'}
                                        id='password'
                                        data-cy='password'
                                        tabIndex={2}
                                        disabled={processing}
                                        autoComplete='off'
                                        className={`shadow-sm ${
                                            errors.password
                                                ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
                                                : 'border-gray-300 focus:ring-gray-800 focus:border-gray-800'
                                        }  block rounded-l-md rounded-none w-full sm:text-sm`}
                                        {...register('password')}
                                    />
                                </div>
                                <button
                                    type='button'
                                    onClick={onToggleEye}
                                    className={`-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border ${
                                        errors.password ? 'border-red-300' : 'border-gray-300'
                                    } text-sm font-medium rounded-r-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500`}>
                                    {passwordHidden ? (
                                        <EyeIcon tabIndex={-1} className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                    ) : (
                                        <EyeSlashIcon
                                            tabIndex={-1}
                                            className='h-5 w-5 text-gray-400'
                                            aria-hidden='true'
                                        />
                                    )}
                                </button>
                            </div>
                            {errors.password && (
                                <p className='mt-1 text-sm text-red-600' id='password-error' data-cy='password-error'>
                                    {t(`${errors.password.message}`, '')}
                                </p>
                            )}

                            <div className='flex items-center justify-between'>
                                <div className='text-sm'>
                                    <NavLink
                                        to='/password-reset'
                                        className='font-medium text-indigo-600 hover:text-indigo-500'>
                                        {t('pages.login.password_reset')}
                                    </NavLink>
                                </div>
                            </div>
                            <div>
                                <button
                                    type='submit'
                                    data-cy='submit'
                                    disabled={processing}
                                    tabIndex={4}
                                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                        processing ? 'bg-gray-700' : 'bg-gray-800'
                                    } hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800`}>
                                    {processing ? (
                                        <svg
                                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                            xmlns='http://www.w3.org/2000/svg'
                                            fill='none'
                                            viewBox='0 0 24 24'>
                                            <circle
                                                className='opacity-25'
                                                cx='12'
                                                cy='12'
                                                r='10'
                                                stroke='currentColor'
                                                strokeWidth='4'></circle>
                                            <path
                                                className='opacity-75'
                                                fill='currentColor'
                                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                        </svg>
                                    ) : (
                                        ''
                                    )}
                                    {processing ? t('pages.login.button.clicked') : t('pages.login.button.active')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
