import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';

import { FormActionPanel, ErrorPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { UserSchema } from 'core/validation-schema';
import Spin from 'components/Spin';
import UserSection from './create';
import { addUser, updateUser, fetchUserById } from 'fetch/company';
import { useAuth } from 'context/auth-context';
import useSoftDirty from 'hooks/useSoftDirty';

export default function ManageUsers() {
    const client = useClient();
    const { user } = useAuth();
    const { companyId } = useParams();
    const userId = user?.userId;
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [allowTicketScan, setAllowTicketScan] = useState(false);
    const [allowReport, setAllowReport] = useState(false);
    const [allowCompanyDetails, setAllowCompanyDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [data, setData] = useState<any>();
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [allowUser, setAllowUser] = useState(false);
    const { isDirty, _setIsDirty: setDirty } = useSoftDirty('userForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const {
        data: userData,
        isLoading,
        refetch,
    } = useQuery(
        ['userData', companyId, identifier!!],
        () => {
            if (identifier && identifier !== 'create') {
                return fetchUserById(companyId as string, identifier as string, client).then((rsp) => {
                    return rsp;
                });
            }
        },
        keepForNMinutes(1),
    );

    // Format the form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(UserSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    const getData = async () => {
        setLoading(true);
        await refetch();
        setLoading(false);
    };

    useEffect(() => {
        getData();
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        if (isEdit && identifier) {
            setProcessing(true);
            const claims: { [key: string]: string | undefined } = {
                'manage.company': companyId,
            };
            if (allowUser) {
                claims['manage.company.user'] = companyId;
            }
            if (allowTicketScan) {
                claims['manage.event.ticket'] = companyId;
            }
            if (allowReport) {
                claims['manage.company.report'] = companyId;
            }
            if (allowCompanyDetails) {
                claims['manage.company.details'] = companyId;
            }
            const rsp = await updateUser(
                companyId as string,
                {
                    firstName: data.user.firstName,
                    lastName: data.user.lastName,
                    phoneNumber: data.user.phone,
                    email: data.user.email,
                    userId: identifier,
                    claims,
                    updatedByUserId: userId,
                },
                client,
            );
            setProcessing(false);
            if (!rsp?.traceId) {
                addNotification({
                    description: t('pages.users.messages.success_description'),
                    title: t('pages.users.messages.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                navigate(`/companies/${companyId}/users`, { replace: true });
            } else {
                if (rsp?.status === 409) {
                    addNotification({
                        description: t('pages.users.messages.error_description_duplicate'),
                        title: t('pages.users.messages.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                } else {
                    addNotification({
                        description: t('pages.users.messages.error_description'),
                        title: t('pages.users.messages.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                }
            }
        } else {
            setProcessing(true);
            const claims: { [key: string]: string | undefined } = {
                'manage.company': companyId,
            };
            if (allowUser) {
                claims['manage.company.user'] = companyId;
            }
            if (allowTicketScan) {
                claims['manage.event.ticket'] = companyId;
            }
            if (allowReport) {
                claims['manage.company.report'] = companyId;
            }
            if (allowCompanyDetails) {
                claims['manage.company.details'] = companyId;
            }

            const rsp = await addUser(
                companyId,
                {
                    firstName: data.user.firstName,
                    lastName: data.user.lastName,
                    phoneNumber: data.user.phone,
                    email: data.user.email,
                    userId: userId,
                    userClaims: claims,
                    target: 'operator',
                },
                client,
            );
            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if (!rsp?.traceId) {
                addNotification({
                    description: t('pages.users.messages.save_success_description'),
                    title: t('pages.users.messages.save_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                navigate(`/companies/${companyId}/users`, { replace: true });
            } else {
                if (rsp?.status === 409) {
                    addNotification({
                        description: t('pages.users.messages.error_description_duplicate'),
                        title: t('pages.users.messages.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                } else {
                    addNotification({
                        description: t('pages.users.messages.error_description'),
                        title: t('pages.users.messages.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                }
            }
        }
    });

    useEffect(() => {
        if (userData) {
            setData(userData);
        }
        if ((data?.status && data?.status !== 200) || !data === undefined) {
            addNotification({
                description: t('pages.users.messages.single_user_error_description'),
                title: t('pages.users.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            if (isEdit && data) {
                setValue('user.firstName', data?.firstName);
                setValue('user.lastName', data?.lastName);
                setValue('user.email', data?.email);
                setValue('user.phone', data?.phoneNumber);
                if (data?.claims?.hasOwnProperty('manage.company.user')) {
                    setAllowUser((prev) => !prev);
                }
                if (data?.claims?.hasOwnProperty('manage.event.ticket')) {
                    setAllowTicketScan((prev) => !prev);
                }
                if (data?.claims?.hasOwnProperty('manage.company.report')) {
                    setAllowReport((prev) => !prev);
                }
                if (data?.claims?.hasOwnProperty('manage.company.details')) {
                    setAllowCompanyDetails((prev) => !prev);
                }
                setDirty();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, data]);

    return (
        <>
            <Spin spinning={(isEdit && isLoading) || processing || loading}>
                <span className='relative inline-flex w-full items-start justify-between'>
                    <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                        <>
                            <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                        </>
                    </button>
                    <FormActionPanel
                        isDirty={isDirty}
                        processing={processing}
                        handleReset={handleReset}
                        contextTitle={'pages.users.context.title'}
                        contextDescription={'pages.users.context.description'}
                        contextTarget={HelpContextTarget.Users}
                        formTarget='userForm'
                    />
                </span>
                <form onSubmit={onSubmit} noValidate={true} id='userForm' className='space-y-6'>
                    <UserSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        allowUser={allowUser}
                        setAllowUser={setAllowUser}
                        setAllowTicketScan={setAllowTicketScan}
                        allowTicketScan={allowTicketScan}
                        setAllowReport={setAllowReport}
                        allowReport={allowReport}
                        allowCompanyDetails={allowCompanyDetails}
                        setAllowCompanyDetails={setAllowCompanyDetails}
                    />
                </form>
            </Spin>
        </>
    );
}
