import { Transition, Dialog, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    NumberField,
    NumberFieldInputProps,
    SingleDateSelectionField,
    Spacer,
    TextField,
    TimePickerField,
} from 'components/form-fields';
import { NotificationType, EventVenueSubSectionTarget } from 'core/types';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { classNames, convertTo24HourFormat, findTimeDifferenceInMinutes } from 'core/utils';
import { useNotifications } from 'context/notification-context';
import { ErrorPanel } from 'components/action-panel';
import {
    CompanyDto,
    EventVenueResourceAdminDto,
    EventVenueResourceConfigurationAdminDto,
} from 'api/operator-api-types';
import {
    EventSchemaWithoutBookingFee,
    EventVenueConfigurationSchema,
    EventVenueResourceSchema,
} from 'core/validation-schema';
import { getIsoDateWithTime } from '../helper';

export interface InputProps {
    closeCallback: (target: EventVenueSubSectionTarget) => void;
    panel: EventVenueSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
    addConfiguration: (configuration: EventVenueResourceConfigurationAdminDto) => void;
    addResource?: (resource: EventVenueResourceAdminDto) => void;
    setDirty?: any;
    bookingFeeTypes?: any;
    companyData?: CompanyDto;
}

interface SubFormInputProps {
    addConfiguration?: (configuration: EventVenueResourceConfigurationAdminDto) => void;
    addResource?: (resource: EventVenueResourceAdminDto) => void;
    bookingFeeTypes?: any;
    companyData?: CompanyDto;
}

export default function SubSectionAddForm(props: InputProps) {
    const { t } = useTranslation();
    const {
        closeCallback,
        open,
        title,
        description,
        panel,
        addConfiguration,
        addResource,
        bookingFeeTypes,
        companyData,
    } = props;

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10'
                    onClose={() => closeCallback(EventVenueSubSectionTarget.Closed)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-4xl'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(title, 'MISSING 2')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() =>
                                                                closeCallback(EventVenueSubSectionTarget.Closed)
                                                            }
                                                            data-cy='close'>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>
                                                        {t(description, 'MISSING 1')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                {/* Replace with your content */}
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    {panel === EventVenueSubSectionTarget.Resource && (
                                                        <EventVenueResourceForm
                                                            bookingFeeTypes={bookingFeeTypes}
                                                            companyData={companyData}
                                                            addResource={addResource}
                                                        />
                                                    )}
                                                    {panel === EventVenueSubSectionTarget.Configuration && (
                                                        <EventVenueConfigurationForm
                                                            addConfiguration={addConfiguration}
                                                        />
                                                    )}
                                                </div>
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function EventVenueResourceForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [enabled, setEnabled] = useState(false);
    const [bookingTypeId, setBookingTypeId] = useState('');
    const [enabledBooking, setEnabledBooking] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [timeError, setTimeError] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const { addResource, bookingFeeTypes, companyData } = props;
    const {
        register,
        handleSubmit,
        getFieldState,
        getValues,
        setValue,
        reset,
        formState: { errors },
        trigger,
        watch,
    } = useForm({
        resolver: yupResolver(bookingTypeId === '0' ? EventSchemaWithoutBookingFee : EventVenueResourceSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        const cutOffDateString = getIsoDateWithTime(data?.bookingCutOffDate, data?.bookingCutOffTime);
        const cutOffOrderingDateString = getIsoDateWithTime(data?.orderingCutOffDate, data?.orderingCutOffTime);
        const _startTime = convertTo24HourFormat(data.startTime);
        const _endTime = convertTo24HourFormat(data.endTime);
        const [startTimeInMinutes, endTimeInMinutes] = findTimeDifferenceInMinutes(_startTime, _endTime);
        const dateObj = new Date(data.eventDate);
        const adjustedDateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000); // Adjust for the timezone offset
        const isoString = adjustedDateObj.toISOString();
        if (data?.minBookingFee === '') {
            delete data?.minBookingFee;
        }
        if (data?.maxBookingFee === '') {
            delete data?.maxBookingFee;
        }
        let newData = {
            bookingCutOffDateTime: cutOffDateString,
            menuOrderingCutOffDateTime: cutOffOrderingDateString,
            allowBooking: enabledBooking,
            allowVoucher: enabled,
            description: data?.description,
            startTime: startTimeInMinutes,
            endTime: endTimeInMinutes,
            eventDate: isoString,
            eventExternalCode: data?.eventExternalCode,
            bookingFeeTypeId: parseInt(data?.bookingFeeType),
            bookingFee: bookingTypeId === '0' ? 0.0 : data?.bookingFee,
            minBookingFee: data?.minBookingFee,
            maxBookingFee: data?.maxBookingFee,
        };
        setProcessing(true);
        setIsFormError(false);
        if (addResource) {
            addResource(EventVenueResourceAdminDto.fromJS(newData));
        }
        setProcessing(false);

        addNotification({
            title: t('pages.event_venues_detail.resources.success_title'),
            description: t('pages.event_venues_detail.resources.success_description'),
            style: NotificationType.SuccessSimple,
            timeoutInMilliseconds: 2000,
        });
        reset();
    });

    const eventDate = watch('eventDate');
    const selectedBookingTypeId = watch('bookingFeeType');

    useEffect(() => {
        setBookingTypeId(selectedBookingTypeId);
    }, [selectedBookingTypeId]);

    let previousDate = new Date();
    if (eventDate && eventDate !== undefined) {
        const _eventDate = new Date(eventDate);
        previousDate = new Date(new Date(_eventDate).setDate(_eventDate.getDate()));
    }

    const handleResetDatePicker = () => {
        setValue('bookingCutOffDate', null);
        setValue('orderingCutOffDate', null);
    };

    useEffect(() => {
        if (companyData) {
            setValue('bookingFeeType', companyData?.bookingFeeTypeId);
            setBookingTypeId(`${companyData?.bookingFeeTypeId}`);
            setValue('bookingFee', companyData?.bookingFee);
            setValue('minBookingFee', companyData?.minBookingFee);
            setValue('maxBookingFee', companyData?.maxBookingFee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyData, bookingFeeTypes]);

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventVenueResourceForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <TextField
                        fieldName='description'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.description'
                        isRequired={true}
                        data_cy='name'
                    />

                    <SingleDateSelectionField
                        fieldName='eventDate'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        setValue={setValue}
                        trigger={trigger} // Trigger revalidation for the field
                        getValues={getValues}
                        minimum={new Date()}
                        fieldLabel='pages.event_venues_detail.resources.field_names.event_date'
                        defaultValue={null}
                        isRequired={true}
                        data_cy='event-date'
                        handleResetDatePicker={handleResetDatePicker}
                    />

                    <TimePickerField
                        fieldName='startTime'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.start_time'
                        isRequired={true}
                        data_cy='startTime'
                        setTime={setStartTime}
                    />
                    <TimePickerField
                        fieldName='endTime'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.resources.field_names.end_time'
                        isRequired={true}
                        data_cy='endTime'
                        timeError={timeError}
                        setTime={setEndTime}
                    />
                    {!companyData?.isAffiliate && (
                        <BookingFeeTypeSelect
                            referenceData={bookingFeeTypes?.bookingFeeTypes ?? []}
                            referenceType='bookingFeeType'
                            showDefault={true}
                            fieldName='bookingFeeType'
                            getFieldState={getFieldState}
                            register={register}
                            isRequired={true}
                            fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee_type'
                            data_cy='bookingFeeType'
                            errors={errors}
                        />
                    )}
                    {bookingTypeId != '0' && !companyData?.isAffiliate && (
                        <>
                            <BookingFeeNumberField
                                fieldName='bookingFee'
                                getFieldState={getFieldState}
                                register={register}
                                minimum={0}
                                fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee'
                                isRequired={true}
                                data_cy='bookingFee'
                            />
                        </>
                    )}
                    {!companyData?.isAffiliate && (
                        <>
                            {(bookingTypeId == '2' || bookingTypeId == '3') && (
                                <>
                                    <BookingFeeNumberField
                                        fieldName='minBookingFee'
                                        getFieldState={getFieldState}
                                        register={register}
                                        minimum={0}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.min_booking_fee'
                                        data_cy='minBookingFee'
                                    />
                                    <BookingFeeNumberField
                                        fieldName='maxBookingFee'
                                        getFieldState={getFieldState}
                                        register={register}
                                        minimum={0}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.max_booking_fee'
                                        data_cy='maxBookingFee'
                                    />
                                </>
                            )}
                        </>
                    )}
                    {eventDate && (
                        <>
                            <div className='col-span-3'>
                                <div className='grid gap-6 grid-cols-6'>
                                    <SingleDateSelectionField
                                        overrideContainerCss='col-span-3'
                                        fieldName='bookingCutOffDate'
                                        getFieldState={getFieldState}
                                        register={register}
                                        setValue={setValue}
                                        trigger={trigger} // Trigger revalidation for the field
                                        getValues={getValues}
                                        minimum={new Date()}
                                        maximum={previousDate}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_date'
                                        defaultValue={null}
                                        isRequired={true}
                                        data_cy='booking-cut-off-date'
                                    />
                                    <TimePickerField
                                        overrideContainerCss='col-span-3'
                                        fieldName='bookingCutOffTime'
                                        getFieldState={getFieldState}
                                        register={register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_time'
                                        isRequired={true}
                                        data_cy='bookingCutOffTime'
                                    />
                                </div>
                            </div>

                            <div className='col-span-3'>
                                <div className='grid gap-6 grid-cols-6'>
                                    <SingleDateSelectionField
                                        overrideContainerCss='col-span-3'
                                        fieldName='orderingCutOffDate'
                                        getFieldState={getFieldState}
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger} // Trigger revalidation for the field
                                        minimum={new Date()}
                                        maximum={previousDate}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.ordering_date'
                                        defaultValue={null}
                                        isRequired={true}
                                        data_cy='orderingCutOffDate'
                                    />
                                    <TimePickerField
                                        overrideContainerCss='col-span-3'
                                        fieldName='orderingCutOffTime'
                                        getFieldState={getFieldState}
                                        register={register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.ordering_time'
                                        isRequired={true}
                                        data_cy='orderingCutOffTime'
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <TextField
                        fieldName='eventExternalCode'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues.table.headers.event_external_code'
                        isRequired={true}
                        data_cy='eventExternalCode'
                    />

                    <div className='grid col-span-3'>
                        <div className='flex'>
                            <div className='mt-6 flex items-center '>
                                <h1 className='text-base'>
                                    {t('pages.event_venues_detail.resources.field_names.allow_voucher', 'MISSING')}
                                </h1>
                                <Switch
                                    style={{ marginLeft: '20px' }}
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${
                                        enabled ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                    <span className='sr-only'>Enable notifications</span>
                                    <span
                                        className={`${
                                            enabled ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                            <div className='ml-12 mt-6 flex items-center '>
                                <h1 className='text-base'>
                                    {t('pages.event_venues_detail.resources.field_names.allow_booking', 'MISSING')}
                                </h1>
                                <Switch
                                    style={{ marginLeft: '20px' }}
                                    checked={enabledBooking}
                                    onChange={setEnabledBooking}
                                    className={`${
                                        enabledBooking ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                    <span className='sr-only'>Enable notifications</span>
                                    <span
                                        className={`${
                                            enabledBooking ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>

                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='eventVenueResourceForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_venues_detail.button.saving_changes')
                                : t('pages.event_venues_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
function EventVenueConfigurationForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const { addConfiguration } = props;

    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventVenueConfigurationSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        data.resourceCapacity = parseInt(data?.totalResources) * parseInt(data?.maxSize);
        setProcessing(true);
        setIsFormError(false);
        if (addConfiguration) {
            addConfiguration(EventVenueResourceConfigurationAdminDto.fromJS(data));
        }
        setProcessing(false);

        addNotification({
            title: t('pages.event_venues_detail.configurations.success_title'),
            description: t('pages.event_venues_detail.configurations.success_description'),
            style: NotificationType.SuccessSimple,
            timeoutInMilliseconds: 2000,
        });
        reset();
    });

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventVenueConfigurationForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <TextField
                        fieldName='displayTitle'
                        overrideContainerCss='col-span-6'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_venues_detail.configurations.field_names.display_title'
                        isRequired={true}
                        data_cy='display-title'
                    />
                    <NumberField
                        fieldName='minSize'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        minimum={0}
                        fieldLabel='pages.event_venues_detail.configurations.field_names.min_size'
                        isRequired={true}
                        data_cy='min-size'
                    />
                    <NumberField
                        fieldName='maxSize'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        minimum={0}
                        fieldLabel='pages.event_venues_detail.configurations.field_names.max_size'
                        isRequired={true}
                        data_cy='max-size'
                    />
                    <NumberField
                        fieldName='totalResources'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        minimum={0}
                        fieldLabel='pages.event_venues_detail.configurations.field_names.total_resources'
                        isRequired={true}
                        data_cy='total-resources'
                    />
                    <NumberField
                        fieldName='eventVenueResourceConfigurationAmount[0].eventAmount'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        minimum={0}
                        fieldLabel='pages.event_venues_detail.configurations.field_names.amount'
                        isRequired={true}
                        data_cy='amount'
                    />
                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='eventVenueConfigurationForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_venues_detail.button.saving_changes')
                                : t('pages.event_venues_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

function BookingFeeTypeSelect(props: any) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-3 lg:col-span-3'>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    disabled={props?.disabled}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && <option value=''>Select an option</option>}
                    {props.referenceData?.map((item: any, index: number) => (
                        <option key={`${props.referenceType}-${item.id}-${index}`} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {props.errors?.bookingFeeType && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.bookingFeeType?.message}
                    </p>
                )}
            </div>
        </>
    );
}

export function BookingFeeNumberField(props: NumberFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const errorMsg =
        (state.error && state.error.type === 'min') || (state.error && state.error.type === 'max')
            ? t('pages.event_venues_detail.resources.errors.valid_number', 'MISSING')
            : 'MISSING';
    return (
        <>
            <div hidden={props.hidden} className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    disabled={props?.disabled}
                    {...props.register(props.fieldName)}
                    type='number'
                    value={props.defaultValue}
                    min={props.minimum}
                    max={props.maximum}
                    name={props.fieldName}
                    id={props.fieldName}
                    step={props.step ?? '1'}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, errorMsg)}
                    </p>
                )}
            </div>
        </>
    );
}
