import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps } from 'core/types';
import { formattedDateWithTime } from 'core/utils';
import { EditCss } from 'components/table';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.firstName, {
            id: 'firstName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.firstName', ''),
        }),
        columnHelper.accessor((row) => row.lastName, {
            id: 'lastName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.lastName', ''),
        }),

        columnHelper.accessor((row) => row.email, {
            id: 'email',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.email', ''),
        }),
        columnHelper.accessor((row) => row.partySize, {
            id: 'partySize',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.partySize', ''),
        }),

        columnHelper.accessor((row) => row.total, {
            id: 'total',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.total', ''),
        }),
        columnHelper.accessor((row) => row.bookingFee, {
            id: 'bookingFee',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.bookingFee', ''),
        }),

        columnHelper.accessor((row) => row.bookingDate, {
            id: 'bookingDate',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()
                            ? formattedDateWithTime(new Date(info.getValue()?.toString()!!)).shortDate
                            : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.bookingDate', ''),
        }),
        columnHelper.accessor((row) => row.bookingStatusName, {
            id: 'bookingStatusName',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.bookingStatusName', ''),
        }),

        columnHelper.accessor((row) => row.bookingStatusId, {
            id: 'bookingStatusId',
            cell: (info) => (
                <div>
                    {info?.getValue() == 5 && info?.row?.original?.total > 0 ? (
                        <button
                            type='button'
                            onClick={() =>
                                selectSingleAction && selectSingleAction(info?.row?.original, ColumnActionType.Edit, 0)
                            }
                            className={EditCss()}>
                            {t('pages.refund.refund')}
                        </button>
                    ) : null}
                </div>
            ),
            header: () => '',
        }),
    ];
    return columnDefs;
}
