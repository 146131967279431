import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import Layout from './components/layout';
import { useAuth } from './context/auth-context';
import Login from './pages/login';
import NotFound from './pages/not-found';
import Overview from './pages/overview';
import './i18n/config';
import Companies from 'pages/company';
import { IdentifierType } from 'core/types';
import Locations from 'pages/location';
import ManageLocation from 'pages/location/manage';
import ManageCompany from 'pages/company/manage';
import ManageExperiences from 'pages/experience/manage';
import Experiences from 'pages/experience';
import Users from 'pages/users';
import Events from 'pages/event';
import ManageEvent from 'pages/event/manage';
import EventVenue from 'pages/event-venue';
import ManageUsers from 'pages/users/manage';
import ManageEventVenue from 'pages/event-venue/manage';
import ManageEventVenueResouceConfigurations from 'pages/event-venue/resourceConfigurations/manage';
import PageCache from 'pages/CMS/Cache/pageCache';
import ContentCache from 'pages/CMS/Cache/contentCache';
import PasswordReset from 'pages/password-reset';
import VerifyEmail from 'pages/verify-email';
import ResourceConfigurationList from 'pages/event-venue/resourceConfigurations';
import ConfigurationList from 'pages/event-venue/configurationList';
import EventVenueListing from 'pages/event/venueListing';
import Products from 'pages/product';
import ManageProducts from 'pages/product/manage';
import CompanyMenu from 'pages/menu';
import ManageMenus from 'pages/menu/manage';
import { useQuery } from 'react-query';
import { fetchManagedCompanies } from 'fetch/user/system';
import { useClient } from 'core/api-client';
import { keepForOneDay } from 'core/utils';
import Tickets from 'pages/tickets';
import SearchTicketByEmail from 'pages/tickets/searchTicketByUserEmail';
import Reports from 'pages/reports';
import EventLiveSales from 'pages/reports/event-live-sales';
import BrunchReport from 'pages/finance/brunch-report';
import AffiliateReport from 'pages/finance/affiliate-report';
import Brands from 'pages/brands';
import ManageBrands from 'pages/brands/manage';
import EventRefunds from 'pages/finance/refunds';
import EventFundDetails from 'pages/finance/refunds/event-fund-details';

function App() {
    const client = useClient();
    const { user } = useAuth();
    const {
        data: companyManagedData,
        isLoading: companyIsLoading,
        isFetching: companyIsFetching,
        isRefetching: companyIsRefetching,
        refetch,
    } = useQuery(
        ['managed-companies', user?.userId!!],
        () => {
            if (!user) return;
            return fetchManagedCompanies(user?.userId!!, client);
        },
        keepForOneDay(),
    );

    return (
        <>
            <Routes>
                <Route
                    element={
                        <RequireAuth>
                            <Layout
                                companyIsRefetching={companyIsRefetching}
                                companyIsFetching={companyIsFetching}
                                companyIsLoading={companyIsLoading}
                                companyManagedData={companyManagedData}
                            />
                        </RequireAuth>
                    }>
                    <Route index element={<Overview />} />
                    <Route path='/clear-page-cache' element={<PageCache />} />
                    <Route path='/clear-content-cache' element={<ContentCache />} />
                    <Route path='/brunch-report' element={<BrunchReport />} />
                    <Route path='/affiliate-report' element={<AffiliateReport />} />
                    <Route path='/refunds' element={<EventRefunds />} />
                    <Route path='/refunds/:eventId/:eventVenueResourceId/details' element={<EventFundDetails />} />
                    <Route path='experience' element={<Outlet />}>
                        <Route index element={<Experiences />} />
                        <Route
                            path=':identifier'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.GuidOrCreate}>
                                    <ManageExperiences />
                                </RequireIdentifier>
                            }
                        />
                    </Route>
                    <Route path='companies' element={<Outlet />}>
                        <Route index element={<Companies />} />
                        <Route
                            path=':identifier'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.NumberOrCreate}>
                                    <ManageCompany refetch={refetch} />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/events' element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.Company}>
                                        <Events />
                                    </RequireIdentifier>
                                }
                            />
                            <Route
                                path=':eventId'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.Event}>
                                        <ManageEvent />
                                    </RequireIdentifier>
                                }
                            />
                            <Route path=':eventId/venues' element={<EventVenueListing />} />
                        </Route>
                        <Route path=':companyId/eventvenues/:identifier/eventdetails' element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <EventVenue />
                                    </RequireIdentifier>
                                }
                            />
                            <Route
                                path=':eventVenueId'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.EventVenue}>
                                        <ManageEventVenue />
                                    </RequireIdentifier>
                                }
                            />
                            <Route
                                path=':eventVenueResourceId/configurations'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <ResourceConfigurationList />
                                    </RequireIdentifier>
                                }
                            />
                            <Route
                                path=':eventVenueResourceId/configurations/:eventVenueResourceConfigurationId/'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.EventVenueConfigurationId}>
                                        <ManageEventVenueResouceConfigurations />
                                    </RequireIdentifier>
                                }
                            />
                            <Route
                                path='configurations/:eventVenueResourceConfigurationId/'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <ConfigurationList />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route
                            path=':companyId/companies'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.Company}>
                                    <Events />
                                </RequireIdentifier>
                            }
                        />
                        <Route
                            path=':companyId/events'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.Company}>
                                    <Events />
                                </RequireIdentifier>
                            }
                        />
                        <Route
                            path=':companyId/brands'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.GuidOrCreate}>
                                    <Brands />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/brands' element={<Outlet />}>
                            <Route index element={<Brands />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <ManageBrands />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>

                        <Route
                            path=':companyId/users'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.GuidOrCreate}>
                                    <Users />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/users' element={<Outlet />}>
                            <Route index element={<Users />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <ManageUsers />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route
                            path=':companyId/tickets'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                    <Tickets />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/tickets' element={<Outlet />}>
                            <Route index element={<Tickets />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <Tickets />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route path=':companyId/event/:eventId/:eventVenueResourceId/sales' element={<Outlet />}>
                            <Route index element={<EventLiveSales />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <EventLiveSales />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route
                            path=':companyId/reports'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                    <Reports />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/reports' element={<Outlet />}>
                            <Route index element={<Reports />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <Reports />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route path=':companyId/:eventVenueId/events/:eventVenueResourceId' element={<Outlet />}>
                            <Route index element={<SearchTicketByEmail />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <SearchTicketByEmail />
                                    </RequireIdentifier>
                                }
                            />
                        </Route>
                        <Route
                            path=':companyId/menus'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.GuidOrCreate}>
                                    <CompanyMenu />
                                </RequireIdentifier>
                            }
                        />
                        <Route path=':companyId/menus' element={<Outlet />}>
                            <Route index element={<CompanyMenu />} />
                            <Route
                                path=':identifier'
                                element={
                                    <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                        <ManageMenus />
                                    </RequireIdentifier>
                                }
                            />
                            <Route path=':eventMenuId/products' element={<Outlet />}>
                                <Route index element={<Products />} />
                                <Route
                                    path=':identifier'
                                    element={
                                        <RequireIdentifier identifierType={IdentifierType.StringOrCreate}>
                                            <ManageProducts />
                                        </RequireIdentifier>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path='locations' element={<Outlet />}>
                        <Route index element={<Locations />} />
                        <Route
                            path=':identifier'
                            element={
                                <RequireIdentifier identifierType={IdentifierType.GuidOrCreate}>
                                    <ManageLocation />
                                </RequireIdentifier>
                            }
                        />
                    </Route>
                </Route>
                <Route path='login' element={<Login />} />
                <Route path='verify-email' element={<VerifyEmail />} />
                <Route path='password-reset' element={<PasswordReset />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
}

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth?.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected.
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return children;
}

function RequireIdentifier({ children, identifierType }: { children: JSX.Element; identifierType: IdentifierType }) {
    let params = useParams();
    let identifier = undefined;

    switch (identifierType) {
        case IdentifierType.NumberOrCreate:
            identifier = params?.identifier === 'create' || params?.identifier?.match(/^\d+$/);
            break;
        case IdentifierType.GuidOrCreate:
            identifier =
                params?.identifier === 'create' ||
                params?.identifier?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/,
                ) ||
                params?.identifier?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){6}\}{0,1})$/,
                );
            break;
        case IdentifierType.StringOrCreate:
            identifier = typeof params?.identifier === 'string';
            break;
        case IdentifierType.Location:
            identifier = params?.locationId?.match(/^\d+$/);
            break;
        case IdentifierType.Company:
            identifier = params?.companyId?.match(/^\d+$/);
            break;
        case IdentifierType.Event:
            identifier =
                params?.eventId === 'create' ||
                params?.eventId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/,
                ) ||
                params?.eventId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){6}\}{0,1})$/,
                );
            break;

        case IdentifierType.EventVenue:
            identifier =
                params?.eventVenueId === 'create' ||
                params?.eventVenueId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/,
                ) ||
                params?.eventVenueId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){6}\}{0,1})$/,
                );
            break;

        case IdentifierType.EventVenueConfigurationId:
            identifier =
                params?.eventVenueResourceConfigurationId === 'create' ||
                params?.eventVenueResourceConfigurationId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/,
                ) ||
                params?.eventVenueResourceConfigurationId?.match(
                    /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){6}\}{0,1})$/,
                );
            break;

        case IdentifierType.Resource:
            identifier = params?.resourceId?.match(/^\d+$/);
            break;
        case IdentifierType.Duration:
            identifier = params?.durationId?.match(/^\d+$/);
            break;
        case IdentifierType.Variant:
            identifier = params?.variantId?.match(/^\d+$/);
            break;
        case IdentifierType.Configuration:
            identifier = params?.configurationId?.match(/^\d+$/);
            break;
    }

    if (!identifier) {
        // redirect if the location id was missing.
        return <Navigate to='/' />;
    }

    return children;
}

export { RequireAuth, RequireIdentifier };
export default App;
