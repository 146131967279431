import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnGeneratorInputProps } from 'core/types';
import { DeleteCss, EditCss } from 'components/table';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.firstName, {
            id: 'firstName',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.users.table.headers.firstName', ''),
        }),
        columnHelper.accessor((row) => row.lastName, {
            id: 'lastName',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.users.table.headers.lastName', ''),
        }),
        columnHelper.accessor((row) => row.email, {
            id: 'email',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.users.table.headers.email', ''),
        }),
        columnHelper.accessor((row) => row.phone, {
            id: 'phone',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.users.table.headers.phone', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
