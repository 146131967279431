import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { keepForNMinutes } from 'core/utils';

import { FormActionPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { ProductSchema } from 'core/validation-schema';
import Spin from 'components/Spin';
import { addMenuProduct, fetchMenuProductById, updateMenuProduct } from 'fetch/company';
import useSoftDirty from 'hooks/useSoftDirty';
import dayjs from 'dayjs';
import ProductSection from './create';

export default function ManageProducts() {
    const client = useClient();
    const { companyId, eventMenuId } = useParams();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [data, setData] = useState<any>();
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [keepIsDirty, setKeepIsDirty] = useState(true);
    const { isDirty, _setIsDirty: setDirtyToTrue } = useSoftDirty('productForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const { data: productData, isLoading } = useQuery(
        ['productData', companyId, identifier!!],
        () => {
            if (identifier && identifier !== 'create') {
                return fetchMenuProductById(companyId as string, identifier as string, client).then((rsp) => {
                    return rsp;
                });
            }
        },
        keepForNMinutes(0),
    );

    // The form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        watch,
        setError,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(ProductSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        if (isEdit && identifier) {
            setProcessing(true);
            const rsp = await updateMenuProduct(
                companyId as string,
                identifier as string,
                {
                    eventMenuProductId: identifier,
                    eventMenuId: eventMenuId,
                    menuCategoryId: data.eventMenuProduct?.menuCategoryId,
                    productName: data.eventMenuProduct?.productName,
                    marketingDescription: data.eventMenuProduct?.marketingDescription,
                    allergenInfo: data.eventMenuProduct?.allergenInfo,
                    dateEffective: new Date(dayjs(data.eventMenuProduct?.dateEffective).format('YYYY-MM-DD')),
                    dateExpires: new Date(dayjs(data.eventMenuProduct?.dateExpires).format('YYYY-MM-DD')),
                    sortOrder: parseInt(data.eventMenuProduct?.sortOrder),
                },
                client,
            );
            setProcessing(false);
            if (!rsp?.traceId) {
                addNotification({
                    description: t('pages.products.messages.success_description'),
                    title: t('pages.products.messages.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                navigate(`/companies/${companyId}/menus/${eventMenuId}/products`, { replace: true });
            } else {
                addNotification({
                    description: t('pages.products.messages.error_description'),
                    title: t('pages.products.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        } else {
            setProcessing(true);
            const rsp = await addMenuProduct(
                companyId,
                eventMenuId,
                {
                    eventMenuProduct: {
                        eventMenuId,
                        menuCategoryId: data.eventMenuProduct?.menuCategoryId,
                        productName: data.eventMenuProduct?.productName,
                        marketingDescription: data.eventMenuProduct?.marketingDescription,
                        allergenInfo: data.eventMenuProduct?.allergenInfo,
                        dateEffective: new Date(dayjs(data.eventMenuProduct?.dateEffective).format('YYYY-MM-DD')),
                        dateExpires: new Date(dayjs(data.eventMenuProduct?.dateExpires).format('YYYY-MM-DD')),
                        sortOrder: parseInt(data.eventMenuProduct?.sortOrder),
                    },
                },
                client,
            );
            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if (!rsp?.traceId) {
                if (rsp?.success || rsp?.status === 200) {
                    addNotification({
                        description: t('pages.products.messages.save_success_description'),
                        title: t('pages.products.messages.save_success_title'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    navigate(`/companies/${companyId}/menus/${eventMenuId}/products`, { replace: true });
                }
            } else {
                addNotification({
                    description: t('pages.products.messages.error_description'),
                    title: t('pages.products.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
            }
        }
    });

    useEffect(() => {
        if (productData) {
            setData(productData);
        }
        if ((data?.status && data?.status !== 200) || !data === undefined) {
            addNotification({
                description: t('pages.products.messages.single_menue_error_description'),
                title: t('pages.products.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            if (isEdit && data) {
                setValue('eventMenuProduct.productName', data?.productName);
                setValue('eventMenuProduct.menuCategoryId', data?.menuCategoryId);
                setValue('eventMenuProduct.dateEffective', new Date(dayjs(data.dateEffective).format('YYYY-MM-DD')));
                setValue(
                    'eventMenuProduct.dateExpires',
                    data.dateExpires ? new Date(dayjs(data.dateExpires).format('YYYY-MM-DD')) : null,
                );
                setValue('eventMenuProduct.sortOrder', data?.sortOrder);
                setValue('eventMenuProduct.marketingDescription', data?.marketingDescription);
                setValue('eventMenuProduct.allergenInfo', data?.allergenInfo);
                setDirtyToTrue();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData, data]);

    const dateEffective = watch('eventMenuProduct.dateEffective');
    const expireDate = watch('eventMenuProduct.dateExpires');

    useEffect(() => {
        if (expireDate && dateEffective) {
            if (dateEffective < expireDate) {
                setKeepIsDirty(true);
            } else {
                setKeepIsDirty(false);
                setError('eventMenuProduct.dateExpires', {
                    type: 'manual',
                    message: 'pages.menus.errors.invalid_date_expire',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateEffective, expireDate]);

    return (
        <>
            <Spin spinning={(isEdit && isLoading) || processing}>
                <FormActionPanel
                    isDirty={keepIsDirty ? isDirty : keepIsDirty}
                    processing={processing}
                    handleReset={handleReset}
                    contextTitle={'pages.products.context.title'}
                    contextDescription={'pages.products.context.description'}
                    contextTarget={HelpContextTarget.Users}
                    formTarget='productForm'
                />
                <form onSubmit={onSubmit} noValidate={true} id='productForm' className='space-y-6'>
                    <ProductSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        setDirty={setDirtyToTrue}
                    />
                </form>
            </Spin>
        </>
    );
}
