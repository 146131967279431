import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: ApplicationInsights, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    const addCustomData = (envelope: ITelemetryItem) => {
        if (envelope) {
            if (envelope.data) {
                envelope.data = {
                    Application: process.env.REACT_APP_APPLICATION,
                    Environment: process.env.REACT_APP_ENVIRONMENT,
                    ...envelope.data,
                };
            } else {
                envelope.data = {
                    Application: process.env.REACT_APP_APPLICATION,
                    Environment: process.env.REACT_APP_ENVIRONMENT,
                };
            }
        }

        return true;
    };

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @return {void}
     */
    const initialize = (instrumentationKey: string, connectionString: string) => {
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.ts');
        }

        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                connectionString: connectionString,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                extensions: [reactPlugin],
                // addRequestContext: function (requestContext?: IRequestContext | undefined): ICustomProperties {
                //     return { Application: process.env.REACT_APP_APPLICATION, Environment: process.env.REACT_APP_ENVIRONMENT } as ICustomProperties
                // },
            },
        });

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer(addCustomData);
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;
export const getReactPlugin = (): ReactPlugin => reactPlugin;
