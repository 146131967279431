import {
    FieldErrors,
    UseFormRegister,
    FieldValues,
    UseFormGetFieldState,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/form-fields';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    enabled: boolean;
    setEnabled: any;
}

export default function ContentSection(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.content_cache.context.title', 'MISSING')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.content_cache.context.description', 'MISSING')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-12 gap-6'>
                            <TextField
                                fieldName='content.entryId'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.content_cache.field_names.entry_id'
                                isRequired={false}
                                data_cy='entryId'
                            />
                            <TextField
                                fieldName='content.contentType'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.content_cache.field_names.content_type'
                                isRequired={false}
                                data_cy='contentType'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
