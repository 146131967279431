import { DateFormats } from 'core/types';

export function convertToTimeFormat(totalMinutes: any) {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;

    var ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12 for 12-hour format

    var hoursString = hours.toString().padStart(2, '0');
    var minutesString = minutes.toString().padStart(2, '0');

    return hoursString + ':' + minutesString + ' ' + ampm;
}

export function getIsoDateWithTime(date: any, time: any) {
    // Parse the date string into a Date object
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    let hours = parseInt(time.slice(0, -2));
    let minutes = parseInt(time.slice(-2));
    // Create a new Date object in UTC with the given year, month, day, hours, minutes, and seconds.
    // Note that JavaScript's months are 0-indexed, so subtract 1 from the month.
    let dateWithTime = new Date(Date.UTC(year, month - 1, day, hours, minutes));
    // Return the date as an ISO string.
    return dateWithTime.toISOString();
}

export function mergeDateAndTime(dateString: any, timeString: any) {
    const date = new Date(dateString);
    const [hours, minutes] = timeString.split(':');

    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);

    const mergedDateAndTime = date.toISOString();

    return mergedDateAndTime;
}

export function convertTime(startTime: any, endTime: any) {
    const formatTime = (time: any) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        const period = hours >= 12 ? 'PM' : 'AM';

        let formattedHours = hours % 12;
        if (formattedHours === 0) {
            formattedHours = 12;
        }

        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const convertedStartTime = formatTime(startTime);
    const convertedEndTime = formatTime(endTime);

    return [convertedStartTime, convertedEndTime];
}

export function convert12to24(time12: any) {
    const [time, period] = time12.split(' ');
    const [hours, minutes] = time.split(':');

    let hours24 = parseInt(hours, 10);

    if (period === 'PM' && hours24 !== 12) {
        hours24 += 12;
    }

    if (period === 'AM' && hours24 === 12) {
        hours24 = 0;
    }

    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
}

export function getTimeFromDate(dateString: any) {
    const date = new Date(dateString);
    const time = date.toLocaleTimeString([], { hour12: false });

    return time;
}

export function getDateRange() {
    var startDate = new Date(); // Current date
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 7); // Adding 7 days to current date

    // Formatting the dates as "YYYY-MM-DDTHH:mm:ss.sssZ"
    var startDateString = startDate.toISOString().split('T')[0];
    var endDateString = endDate.toISOString().split('T')[0];
    return {
        startDate: startDateString,
        endDate: endDateString,
    };
}

export function isValidTimePeriod(startTime: any, endTime: any) {
    const start = convertTo24HourFormat(startTime);
    const end = convertTo24HourFormat(endTime);

    return start < end;
}

function convertTo24HourFormat(time: any) {
    const [hour, minute, period] = time.split(/:| /);
    let hour24 = parseInt(hour);

    if (period === 'PM' && hour24 !== 12) {
        hour24 += 12;
    } else if (period === 'AM' && hour24 === 12) {
        hour24 = 0;
    }

    return hour24 * 100 + parseInt(minute);
}

export function formatDate(inputDate: string) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function filterEventsByDate(arr: any, eventDate: any) {
    // Event date is greater than Menu Date Effective  or Event Date is less than Menu DateExpires.
    // If Menu Date Expires is null and Event Date is greater than Menu Date Effective then menu can be used.
    // Means if Menu has DateExpires then it must be greater than EventDate. if DateExpires is null then it can be used.
    const parsedEventDate = new Date(eventDate);
    const filteredArray = arr?.filter((item: any) => {
        const parsedDateExpires = new Date(item.dateExpires);
        const parsedDateEffective = new Date(item.dateEffective);
        const isEventDateGreaterThanEffectiveDate = parsedEventDate > parsedDateEffective;
        const isEventDateLessThanExpireDate = parsedEventDate < parsedDateExpires;

        if (!item?.dateExpires) {
            return isEventDateGreaterThanEffectiveDate;
        }

        return isEventDateGreaterThanEffectiveDate || isEventDateLessThanExpireDate;
    });
    return filteredArray;
}

export function formatDateAndTime(override: Date | null): DateFormats {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    const optionsWithTime: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false, // Set to false to use 24-hour format
    };

    const today = new Date();
    const now = override ? override : new Date();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');

    const shortDate = `${day}/${month}/${now.getFullYear()}`;
    const longDate = now.toLocaleDateString(undefined, options);
    const longDateTime = now.toLocaleTimeString(undefined, optionsWithTime);

    return {
        shortDate,
        longDate,
        longDateTime,
        day: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        date: now,
        offset: today.getHours() * 60 + today.getMinutes(),
        isToday:
            today.getFullYear() === now.getFullYear() &&
            today.getMonth() === now.getMonth() &&
            today.getDate() === now.getDate(),
    };
}
