import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getAppInsights } from './telemetry-service';

let queue: any[] = [];

setInterval(sendProfileQueue, 5000);

function sendProfileQueue() {
    if (!queue.length) {
        return Promise.resolve({ success: true });
    }
    const queueToSend = [...queue];
    queue = [];

    const appInsights = getAppInsights();
    appInsights.trackTrace(
        {
            message: `Profile`,
            severityLevel: SeverityLevel.Information,
        },
        {
            Application: process.env.REACT_APP_APPLICATION,
            Environment: process.env.REACT_APP_ENVIRONMENT,
            data: queueToSend,
        },
    );
}

function reportProfile(
    id: string, // the "id" prop of the Profiler tree that has just committed
    phase: 'mount' | 'update', // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration: number, // time spent rendering the committed update
    baseDuration: number, // estimated time to render the entire subtree without memoization
    startTime: number, // when React began rendering this update
    commitTime: number, // when React committed this update
    interactions: Set<any>, // the Set of interactions belonging to this update
) {
    queue.push({
        id,
        phase,
        actualDuration,
        baseDuration,
        startTime,
        commitTime,
        interactions,
    });
}
export { reportProfile };
