import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnGeneratorInputProps } from '../../../core/types';
import { formattedDate } from 'core/utils';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    let columnDefs = [
        columnHelper.accessor((row) => row.bookingStatusName, {
            id: 'bookingStatusName',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.resource_slot_headers.name', ''),
        }),
        columnHelper.accessor((row) => row.bookingSize, {
            id: 'bookingSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.resource_slot_headers.size', ''),
        }),
        columnHelper.accessor((row) => row.bookingTotal, {
            id: 'bookingTotal',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.resource_slot_headers.total', ''),
        }),
        columnHelper.accessor((row) => row.bookingDate, {
            id: 'bookingDate',
            cell: (info) => (
                <>{info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}</>
            ),
            header: () => t('pages.event_venues.resource_config_table.resource_slot_headers.date', ''),
        }),
    ];

    return columnDefs;
}
