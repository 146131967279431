import { Switch } from '@headlessui/react';
import { CompanyDto } from 'api/operator-api-types';
import { EmailField, TextField } from 'components/form-fields';
import { classNames } from 'core/utils';
import { BookingFeeNumberField } from 'pages/event-venue/manage/sub-section-add';
import {
    FieldErrors,
    UseFormGetFieldState,
    FieldValues,
    UseFormRegister,
    UseFormGetValues,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    selectedBookingTypeId: string;
    bookingFeeTypes: any;
    setIsAffiliate: React.Dispatch<React.SetStateAction<boolean>>;
    isAffiliate: boolean;
    trigger: UseFormTrigger<FieldValues>;
    company: CompanyDto;
}
export default function CompanySection(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.company_detail.context.title')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>{t('pages.company_detail.context.description')}</p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-6 gap-6'>
                            <TextField
                                fieldName='detail.name'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.name'
                                isRequired={true}
                                data_cy='name'
                            />
                            <TextField
                                fieldName='detail.displayName'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.displayName'
                                isRequired={true}
                                data_cy='displayName'
                            />
                            <EmailField
                                fieldName='detail.email'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                isRequired={true}
                                fieldLabel='pages.company_detail.detail.field_names.email'
                                data_cy='email'
                            />
                            <TextField
                                fieldName='detail.phone'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                isRequired={false}
                                fieldLabel='pages.company_detail.detail.field_names.phone'
                                data_cy='phone'
                            />
                            <BookingFeeNumberField
                                fieldName='detail.vatPercentage'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                minimum={0}
                                isRequired={true}
                                fieldLabel='pages.company_detail.detail.field_names.vat_percentage'
                                data_cy='vatPercentage'
                            />
                            <BookingFeeTypeSelect
                                referenceData={props.bookingFeeTypes?.bookingFeeTypes ?? []}
                                referenceType='bookingFeeType'
                                showDefault={true}
                                fieldName='detail.bookingFeeType'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                isRequired={true}
                                fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee_type'
                                data_cy='bookingFeeType'
                                errors={props.errors}
                            />
                            {props.selectedBookingTypeId != '0' && (
                                <>
                                    <BookingFeeNumberField
                                        fieldName='detail.bookingFee'
                                        getFieldState={props.getFieldState}
                                        register={props.register}
                                        minimum={0}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.booking_fee'
                                        isRequired={true}
                                        data_cy='bookingFee'
                                    />
                                </>
                            )}
                            {(props.selectedBookingTypeId == '2' || props.selectedBookingTypeId == '3') && (
                                <>
                                    <TextField
                                        fieldName='detail.minBookingFee'
                                        getFieldState={props.getFieldState}
                                        register={props.register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.min_booking_fee'
                                        data_cy='minBookingFee'
                                    />
                                    <TextField
                                        fieldName='detail.maxBookingFee'
                                        getFieldState={props.getFieldState}
                                        register={props.register}
                                        fieldLabel='pages.event_venues_detail.resources.field_names.max_booking_fee'
                                        data_cy='maxBookingFee'
                                    />
                                </>
                            )}
                            <div>
                                <h1>{t('pages.company_detail.detail.field_names.switch_title', 'MISSING')}</h1>
                                <div className='mt-1 flex items-center justify-between'>
                                    <Switch
                                        checked={props.isAffiliate}
                                        onChange={props.setIsAffiliate}
                                        className={`${
                                            props.isAffiliate ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-12 items-center rounded-full`}>
                                        <span className='sr-only'>Enable notifications</span>
                                        <span
                                            className={`${
                                                props.isAffiliate ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.company_detail.detail.field_names.addressTitle')}
                        </h3>
                        <div className='grid grid-cols-6 gap-6'>
                            <TextField
                                fieldName='detail.address.line1'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.address.addressLine1'
                                isRequired={true}
                                data_cy='addressLine1'
                            />
                            <TextField
                                fieldName='detail.address.addressLine2'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                isRequired={false}
                                fieldLabel='pages.company_detail.detail.field_names.address.addressLine2'
                                data_cy='addressLine2'
                            />
                            <TextField
                                fieldName='detail.address.addressLine3'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                isRequired={false}
                                fieldLabel='pages.company_detail.detail.field_names.address.addressLine3'
                                data_cy='addressLine3'
                            />
                            <TextField
                                fieldName='detail.address.town'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.address.town'
                                isRequired={true}
                                data_cy='town'
                            />
                            <TextField
                                fieldName='detail.address.city'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.address.city'
                                isRequired={true}
                                data_cy='city'
                            />
                            <TextField
                                fieldName='detail.address.postCode'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.company_detail.detail.field_names.address.postcode'
                                isRequired={true}
                                data_cy='postcode'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function BookingFeeTypeSelect(props: any) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    return (
        <>
            <div className='col-span-3 lg:col-span-3'>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && <option value=''>Select an option</option>}
                    {props.referenceData?.map((item: any, index: number) => (
                        <option key={`${props.referenceType}-${item.id}-${index}`} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {props.errors?.detail?.bookingFeeType && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.detail?.bookingFeeType?.message}
                    </p>
                )}
            </div>
        </>
    );
}
