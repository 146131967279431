import { useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DataTable from 'components/table';
import { ColumnActionType, EventFundSubSectionTarget, NotificationType } from 'core/types';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';

import { SaveButton } from 'components/buttons';

// columns
import EventInfoColumns from './event-details-columns';
import EventBookingSummaryColumns from './event-booking-summary-columns';

import { getAllEvenFundsData } from 'fetch/finance';
import SubSectionAddForm from './sub-section-add';
import { calculateTotal } from './helpers';

// An interface for our actions
interface ReducerAction {
    type: EventFundSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: EventFundSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== EventFundSubSectionTarget.Closed,
    };

    switch (action.type) {
        case EventFundSubSectionTarget.Fund:
            res.title = 'pages.refund.context.title';
            res.description = 'pages.refund.context.description';
            break;
    }

    return res;
}

function EventFundDetails() {
    let { eventId, eventVenueResourceId } = useParams();
    const client = useClient();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState<any[]>();
    const [currentRow, setCurrentRow] = useState();
    const [bookingSummary, setBookingSummary] = useState<any[]>();

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                openAddSubSection(EventFundSubSectionTarget.Fund);
                setCurrentRow(row);
                break;
        }
    };

    async function getEventFundDetails() {
        setIsLoading(true);
        try {
            const eventData = await getAllEvenFundsData(eventId!!, eventVenueResourceId!!, client);
            if (eventData) {
                setIsLoading(false);
                if (eventData === undefined || typeof eventData !== 'object' || eventData === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.refund.list_error_description'),
                            title: t('pages.refund.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const eventInfoData: any[] = [
                        {
                            eventName: eventData?.eventName,
                            brandName: eventData?.brandName,
                            locationName: eventData?.locationName,
                            eventDate: eventData?.eventDate,
                            startTime: eventData?.eventStartTime,
                            endTime: eventData?.eventEndTime,
                        },
                    ];
                    setEventInfo(eventInfoData);

                    const bookingSummary: any[] = eventData?.bookings?.map((item: any) => {
                        return {
                            firstName: item?.firstName,
                            lastName: item?.lastName,
                            email: item?.email,
                            partySize: item?.partySize,
                            total: calculateTotal(item?.total, item?.discount, item?.totalWithDiscount),
                            bookingFee: item?.bookingFee,
                            bookingDate: item?.bookingDate,
                            bookingStatusName: item?.bookingStatusName,
                            bookingStatusId: item?.bookingStatusId,
                            customerEventBookingId: item?.customerEventBookingId,
                            bookingResources: item?.bookingResources,
                        };
                    });
                    setBookingSummary(bookingSummary);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    useEffect(() => {
        try {
            getEventFundDetails();
        } catch (error) {
            console.log('error:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 1); // last 1 year
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 2); // next 2 year

    const [state, dispatch] = useReducer(reducer, {
        panel: EventFundSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });

    const openAddSubSection = (target: EventFundSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };

    return (
        <Spin spinning={isLoading}>
            <div style={{ width: 100 }}>
                <SaveButton
                    processing={false}
                    isDirty={true}
                    formTarget={''}
                    onClick={() => navigate(`/refunds`, { state: 'fromDetail' })}
                    buttonText={t('buttons.go_back')}
                />
            </div>
            {/* Event Info Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_Info')}</h1>
            <DataTable<any>
                data={eventInfo || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnTranslationPrefix={'pages.company_reports.table.headers'}
                columns={EventInfoColumns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.company_reports.table.empty_event_info'}
            />
            {/* Event Booking Summary Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_booking_summary')}</h1>
            <DataTable<any>
                data={bookingSummary || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnTranslationPrefix={''}
                columns={EventBookingSummaryColumns}
                selectSingleAction={handleTableAction}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.company_reports.table.empty_event_summary'}
            />
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                currentRow={currentRow}
                getEventFundDetails={getEventFundDetails}
            />
        </Spin>
    );
}

export default EventFundDetails;
