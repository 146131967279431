import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';
import { classNames } from '../../core/utils';
import { NavLink } from 'react-router-dom';
import { ArrowRightOnRectangleIcon, QuestionMarkCircleIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

function System() {
    const { t } = useTranslation();
    const { logout } = useAuth();
    const navigate = useNavigate();

    const onLogoutClick = () => {
        logout(() => navigate('/', { replace: true }));
        localStorage.clear();
    };

    return (
        <div className='space-y-1 border-t p-2'>
            <h3
                className='px-3 mt-2 text-sm font-semibold text-gray-300 uppercase tracking-wider'
                id='booking-headline'>
                {t('menu.system', '')}
                {process.env.REACT_APP_ENVIRONMENT !== 'Prod' ? ` (${process.env.REACT_APP_ENVIRONMENT})` : ''}
            </h3>
            <NavLink
                to='/system/help'
                className={({ isActive }) =>
                    classNames(
                        isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                    )
                }
                end>
                <>
                    {' '}
                    <QuestionMarkCircleIcon
                        className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                    />
                    {t('menu.help', '')}
                </>
            </NavLink>
            <NavLink
                to='/system/support'
                className={({ isActive }) =>
                    classNames(
                        isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                    )
                }
                end>
                <>
                    {' '}
                    <LifebuoyIcon
                        className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                    />
                    {t('menu.support', '')}
                </>
            </NavLink>
            <button
                onClick={onLogoutClick}
                className='w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-semibold focus:outline-none rounded-md'>
                <>
                    {' '}
                    <ArrowRightOnRectangleIcon
                        className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                    />
                    {t('buttons.logout', '')}
                </>
            </button>
        </div>
    );
}

export default System;
