import { OPERATOR_API_URL } from 'core/api-client';
import { ClientApiOptions } from 'core/types';

export const getEventTickets = async (
    companyId: string,
    startDate: string,
    endDate: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventticket/${companyId}/events?startDate=${startDate}&endDate=${endDate}`,
        {
            methodOverride: 'GET',
        },
    )
        .then((rsp) => rsp?.companyEvents)
        .catch((rsp) => rsp);
};

export const getTicketsByEmail = async (
    companyId: string,
    email: string,
    eventVenueResourceId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventticket/${companyId}/events/${eventVenueResourceId}/searchticket?email=${email}`,
        {
            methodOverride: 'GET',
        },
    )
        .then((rsp) => rsp?.customerEventTickets)
        .catch((rsp) => rsp);
};

export const validateTicketsByScanning = async (
    companyId: string,
    userId: string,
    eventVenueResourceId: string,
    ticketCode: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(
        `${OPERATOR_API_URL}/api/v1/eventticket/${userId}/${companyId}/events/${eventVenueResourceId}/ticket?ticketCode=${ticketCode}`,
        {
            methodOverride: 'PUT',
        },
    )
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
