import { PlusIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { EventVenueResourceAdminDto, EventVenueResourceConfigurationAdminDto } from 'api/operator-api-types';
import DataTable, { DeleteCss, IndeterminateCheckbox } from 'components/table';
import { useConfirm } from 'context/confirmation-context';
import { ColumnActionType, ColumnGeneratorInputProps, EventVenueSubSectionTarget } from 'core/types';
import { timer } from 'core/utils';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    data: EventVenueResourceConfigurationAdminDto[] | null;
    addCallback: (target: EventVenueSubSectionTarget) => void;
    setIsDirty?: () => void;
    setConfigurations: React.Dispatch<SetStateAction<EventVenueResourceConfigurationAdminDto[]>>;
    setKeepDirty?: any;
}

export default function EventVenueConfigurationSection(props: InputProps) {
    const { t } = useTranslation();
    const { data: input, addCallback, setConfigurations, setKeepDirty } = props;
    const [data, setData] = useState<EventVenueResourceConfigurationAdminDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();
    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
    }, [input]);

    useEffect(() => {
        if (data?.length === 0) {
            setKeepDirty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDelete = (row: EventVenueResourceConfigurationAdminDto, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                var newData = [...data];
                newData.splice(index, 1);
                setData((data) => [...newData]);
                setConfigurations(newData);
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.event_venues_detail.configurations.title')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.event_venues_detail.configurations.description')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(EventVenueSubSectionTarget.Configuration)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<EventVenueResourceAdminDto>
                                columnTranslationPrefix={'pages.event_venues_detail.configurations.table.headers'}
                                columns={VenueColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={data}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.event_venues_detail.configurations.table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function VenueColumns(props: ColumnGeneratorInputProps<EventVenueResourceConfigurationAdminDto>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<EventVenueResourceConfigurationAdminDto>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [];

    columnDefs.push(
        columnHelper.accessor((row) => row.displayTitle, {
            id: 'displayTitle',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues_detail.configurations.table.headers.display_title'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.totalResources, {
            id: 'totalResources',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues_detail.configurations.table.headers.total_resources'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.minSize, {
            id: 'minSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues_detail.configurations.table.headers.min_size'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.maxSize, {
            id: 'maxSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues_detail.configurations.table.headers.max_size'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.eventVenueResourceConfigurationAmount![0].eventAmount, {
            id: 'eventVenueResourceConfigurationAmount',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues_detail.configurations.table.headers.amount'),
        }),
    );

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <button
                        type='button'
                        onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)}
                        className={DeleteCss()}
                        data-cy='delete'>
                        {t('buttons.delete')}
                    </button>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
