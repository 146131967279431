import { EventDto } from 'api/operator-api-types';
import { NumberField, TextField, SingleDateSelectionField, TextAreaField } from 'components/form-fields';
import { classNames } from 'core/utils';
import { useEffect } from 'react';
import {
    FieldErrors,
    UseFormGetFieldState,
    FieldValues,
    UseFormRegister,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface InputProps {
    errors: FieldErrors;
    getFieldState: UseFormGetFieldState<FieldValues>;
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    event: EventDto;
    setDirty?: any;
    trigger?: any;
    brandList?: any;
}
export default function EventSection(props: InputProps) {
    const { t } = useTranslation();
    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.event_detail.context.title')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>{t('pages.event_detail.context.description')}</p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0'>
                        <div className='grid grid-cols-6 gap-6'>
                            <TextField
                                fieldName='name'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.event_detail.detail.field_names.name'
                                isRequired={true}
                                data_cy='name'
                            />

                            <TextField
                                fieldName='displayTitle'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.event_detail.detail.field_names.display_title'
                                isRequired={true}
                                data_cy='display-title'
                            />

                            <NumberField
                                fieldName='sortOrder'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.event_detail.detail.field_names.sort_order'
                                minimum={0}
                                maximum={100}
                                isRequired={true}
                                data_cy='sort-order'
                            />

                            <SingleDateSelectionField
                                fieldName='dateEffective'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                trigger={props.trigger}
                                minimum={new Date()}
                                fieldLabel='pages.event_detail.detail.field_names.date_effective'
                                defaultValue={null}
                                isRequired={true}
                                data_cy='date-effective'
                                setDirty={props.setDirty}
                            />

                            <TextField
                                fieldName='urlSlug'
                                overrideContainerCss='col-span-3'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.event_detail.detail.field_names.url_slug'
                                isRequired={true}
                                data_cy='url-slug'
                            />
                            <Dropdown
                                referenceData={props?.brandList ?? []}
                                referenceType='brandId'
                                overrideContainerCss='col-span-3'
                                showDefault={true}
                                fieldName='brandId'
                                getFieldState={props.getFieldState}
                                getValues={props.getValues}
                                setValue={props.setValue}
                                register={props.register}
                                isRequired={true}
                                fieldLabel='pages.event_detail.detail.field_names.brand'
                                data_cy='brandId'
                                errors={props.errors}
                            />

                            <TextAreaField
                                fieldName='description'
                                overrideContainerCss='col-span-6'
                                getFieldState={props.getFieldState}
                                register={props.register}
                                fieldLabel='pages.event_detail.detail.field_names.marketing_description'
                                isRequired={true}
                                data_cy='marketing-description'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Dropdown(props: any) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);
    const currentBrandId = props.getValues('brandId');

    useEffect(() => {
        if (currentBrandId === 0) {
            props.setValue('brandId', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBrandId]);

    return (
        <>
            <div className='col-span-3 lg:col-span-3'>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm mr-1 text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option value={''}>Select an option</option>
                    )}
                    {props?.referenceData?.map((item: any, index: number) => (
                        <option key={`${props.referenceType}-${item.brandId}-${index}`} value={item.brandId}>
                            {item.name}
                        </option>
                    ))}
                </select>
                {props.errors?.brandId && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {props?.errors?.brandId?.message}
                    </p>
                )}
            </div>
        </>
    );
}
