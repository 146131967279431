import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldInputProps, NumberField, Spacer, TextAreaField } from 'components/form-fields';
import { NotificationType, EventFundSubSectionTarget } from 'core/types';
import { CompanyCommissionSchema, EventReFundSchema } from 'core/validation-schema';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { classNames } from 'core/utils';
import { useNotifications } from 'context/notification-context';
import { BookingFeeNumberField } from 'pages/event-venue/manage/sub-section-add';
import { CompanyCommissionDto } from 'api/operator-api-types';
import { addRefund } from 'fetch/finance';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';

export interface InputProps {
    closeCallback: (target: EventFundSubSectionTarget) => void;
    panel: EventFundSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
    currentRow: any;
    getEventFundDetails: any;
}

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    setDirty?: () => void;
}

interface SubFormInputProps {
    setDirty?: () => void;
    closeCallback: (target: EventFundSubSectionTarget) => void;
    currentRow: any;
    getEventFundDetails: any;
}

export default function SubSectionAddForm(props: InputProps) {
    const { t } = useTranslation();
    const { closeCallback, open, title, description, panel, currentRow, getEventFundDetails } = props;

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10'
                    onClose={() => closeCallback(EventFundSubSectionTarget.Closed)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-[500px] max-w-4xl'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(title, 'MISSING')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() =>
                                                                closeCallback(EventFundSubSectionTarget.Closed)
                                                            }
                                                            data-cy='close'>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>{t(description, 'MISSING')}</p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    {panel === EventFundSubSectionTarget.Fund && (
                                                        <AddRefundForm
                                                            closeCallback={closeCallback}
                                                            currentRow={currentRow}
                                                            getEventFundDetails={getEventFundDetails}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function AddRefundForm(props: SubFormInputProps) {
    const { currentRow, getEventFundDetails, closeCallback } = props;
    const { t } = useTranslation();
    const client = useClient();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [selectedResources, setSelectedResources] = useState<number[]>([]);

    const handleCheckboxChange = (resourceId: number) => {
        // Check if the resourceId is already in the selectedResources array
        if (selectedResources.includes(resourceId)) {
            // If it is, remove it
            setSelectedResources((prevSelected) => prevSelected.filter((id) => id !== resourceId));
        } else {
            // If it's not, add it
            setSelectedResources((prevSelected) => [...prevSelected, resourceId]);
        }
    };
    const {
        register,
        handleSubmit,
        getFieldState,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventReFundSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        const newData = {
            customerEventBookingId: currentRow?.customerEventBookingId,
            refundAmount: data?.amount,
            refundSummary: data?.summary,
            resources: selectedResources,
        };

        setProcessing(true);
        const rsp = await addRefund(newData, client);
        if (rsp.traceId) {
            setTraceId(rsp.traceId);
        }
        if (!rsp?.traceId || rsp?.status === 200 || rsp?.status === 202) {
            addNotification({
                title: t('pages.refund.refund_api_success'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 3000,
            });
            setProcessing(false);
            closeCallback(EventFundSubSectionTarget.Closed);
            getEventFundDetails();
        } else {
            addNotification({
                title: t('pages.refund.refund_api_error'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setProcessing(false);
        }
    });

    useEffect(() => {
        if (currentRow) {
            setValue('total', currentRow?.total);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRow]);

    return (
        <Spin spinning={processing}>
            <form onSubmit={onSubmit} noValidate={true} id='refundForm' className='space-y-6'>
                <div className='grid grid-cols-6 gap-6'>
                    <BookingFeeNumberField
                        fieldName='amount'
                        overrideContainerCss='col-span-6'
                        getFieldState={getFieldState}
                        register={register}
                        minimum={0}
                        isRequired={true}
                        fieldLabel='pages.refund.amount'
                        data_cy='amount'
                    />
                    <TextAreaField
                        fieldName='summary'
                        overrideContainerCss='col-span-6'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.refund.summary'
                        isRequired={false}
                        data_cy='summary'
                    />
                    <div className='col-span-6'>
                        <h1 style={{ marginBottom: 10 }}>{t('pages.refund.available_resources', 'MISSING')}</h1>

                        {currentRow?.bookingResources?.map((item: any) => (
                            <div key={item.customerEventBookingResourceId}>
                                <CheckField
                                    isChecked={selectedResources.includes(item.customerEventBookingResourceId)}
                                    handleCheckboxChange={() =>
                                        handleCheckboxChange(item.customerEventBookingResourceId)
                                    }
                                    fieldName={item.customerEventBookingResourceId.toString()}
                                    fieldLabel={item.resourceName}
                                    data_cy='summary'
                                />
                            </div>
                        ))}
                    </div>

                    <Spacer />
                    <div className='col-span-6 lg:col-span-6 text-right'>
                        <button
                            type='button'
                            onClick={() => props.closeCallback(EventFundSubSectionTarget.Closed)}
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='button'>
                            {t('pages.refund.cancel')}
                        </button>
                        <button
                            type='submit'
                            form='refundForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing ? t('pages.event_detail.button.saving_changes') : t('pages.refund.add')}
                        </button>
                    </div>
                </div>
            </form>
        </Spin>
    );
}

function CheckField(props: any) {
    return (
        <>
            <div className='flex col-span-6 lg:col-span-3 space-y-10'>
                <div className='relative flex items-center'>
                    <div className='flex h-6 items-center'>
                        <input
                            checked={props.isChecked}
                            onChange={props.handleCheckboxChange}
                            id={props.fieldName}
                            name={props.fieldName}
                            type='checkbox'
                            className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                            data-cy={`${props.data_cy}`}
                        />
                    </div>
                    <div className='ml-3 text-sm leading-6 w-32'>
                        <label htmlFor={props.fieldName} className='font-medium text-gray-900'>
                            {props.fieldLabel}
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}
