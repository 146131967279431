import { Transition, Dialog, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    CheckField,
    FieldInputProps,
    NumberFieldInputProps,
    ReferenceField,
    Spacer,
    TextField,
} from 'components/form-fields';
import { NotificationType, EventSubSectionTarget } from 'core/types';
import { EventExperienceSchema, EventImageSchema, EventVenueSchema } from 'core/validation-schema';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { classNames, keepForOneDay } from 'core/utils';
import { useClient } from 'core/api-client';
import { useNotifications } from 'context/notification-context';
import { ErrorPanel } from 'components/action-panel';
import { EventExperienceDto, EventImageDto, EventVenueDto, ExperienceAdminDto } from 'api/operator-api-types';
import { addExperience, getAllExperiences } from 'fetch/experience';
import { useQuery } from 'react-query';
import { fetchLocationData } from 'fetch/location';

export interface InputProps {
    closeCallback: (target: EventSubSectionTarget) => void;
    panel: EventSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
    identifier: string;
    addExperience: (images: EventExperienceDto) => void;
    addImage: (images: EventImageDto) => void;
    addVenue: (venues: EventVenueDto) => void;
    addToExistingExperiences: (experience: ExperienceAdminDto) => void;
    existingExperiences: ExperienceAdminDto[];
    experienceData: ExperienceAdminDto[];
    setDirty?: any;
}

export interface ReferenceFieldInputProps extends FieldInputProps {
    referenceData: Array<any>;
    referenceType: string;
    showDefault?: boolean;
    setDirty?: any;
}

interface SubFormInputProps {
    identifier: string;
    addExperience?: (images: EventExperienceDto) => void;
    addImage?: (images: EventImageDto) => void;
    addVenue?: (venues: EventVenueDto) => void;
    addToExistingExperiences?: (experience: ExperienceAdminDto) => void;
    existingExperiences?: ExperienceAdminDto[];
    experienceData?: ExperienceAdminDto[];
    setDirty?: any;
}

export default function SubSectionAddForm(props: InputProps) {
    const { t } = useTranslation();
    const {
        closeCallback,
        open,
        title,
        description,
        panel,
        identifier,
        addExperience,
        addImage,
        addVenue,
        existingExperiences,
        addToExistingExperiences,
        experienceData,
    } = props;

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={() => closeCallback(EventSubSectionTarget.Closed)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'>
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-4xl'>
                                        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                                            <div className='bg-gray-800 py-6 px-4 sm:px-6'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='text-lg font-medium text-white'>
                                                        {t(title, 'MISSING')}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                                            onClick={() => closeCallback(EventSubSectionTarget.Closed)}
                                                            data-cy='close'>
                                                            <span className='sr-only'>{t('close_panel')}</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <p className='text-sm text-gray-300'>{t(description, 'MISSING')}</p>
                                                </div>
                                            </div>
                                            <div className='relative flex-1 py-6 px-4 sm:px-6'>
                                                {/* Replace with your content */}
                                                <div className='absolute inset-0 py-6 px-4 sm:px-6'>
                                                    {panel === EventSubSectionTarget.Experiences && (
                                                        <EventExperienceForm
                                                            identifier={identifier}
                                                            addExperience={addExperience}
                                                            existingExperiences={existingExperiences}
                                                            addToExistingExperiences={addToExistingExperiences}
                                                            experienceData={experienceData}
                                                            setDirty={props.setDirty}
                                                        />
                                                    )}
                                                    {panel === EventSubSectionTarget.Images && (
                                                        <EventImageForm identifier={identifier} addImage={addImage} />
                                                    )}
                                                    {panel === EventSubSectionTarget.Venues && (
                                                        <EventVenueForm identifier={identifier} addVenue={addVenue} />
                                                    )}
                                                </div>
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function EventExperienceForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const { addExperience, existingExperiences, addToExistingExperiences, experienceData } = props;

    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventExperienceSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        if (experienceData) {
            var currentExperience = experienceData.find((exp: ExperienceAdminDto) => {
                return exp.experienceId == data.experienceId;
            });
            data.ratingValue = process.env.REACT_APP_RATING_VALUE;
            setProcessing(true);
            setIsFormError(false);
            let newData = {
                ...data,
                isFeatured: enabled,
            };
            if (addExperience != undefined) {
                addExperience(EventExperienceDto.fromJS(newData));
            }

            if (currentExperience && addToExistingExperiences) {
                addToExistingExperiences(currentExperience);
            }

            setProcessing(false);

            addNotification({
                description: t('pages.event_detail.experiences.success_description'),
                title: t('pages.event_detail.experiences.success_title'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 2000,
            });
            reset();
        }
    });

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventExperienceForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <ExperienceReferenceField
                        referenceData={
                            experienceData != null
                                ? experienceData.filter((exp: ExperienceAdminDto) => {
                                      return !existingExperiences?.includes(exp);
                                  })
                                : []
                        }
                        referenceType='experienceId'
                        showDefault={true}
                        fieldName='experienceId'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.experiences.table.headers.experience'
                        data_cy='experience-dropdown'
                    />

                    <NumberField
                        fieldName='sortOrder'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.experiences.table.headers.sort_order'
                        isRequired={true}
                        minimum={0}
                        data_cy='sort-order'
                    />
                    <div className='grid col-span-3'>
                        <div className='mt-6 flex items-center'>
                            <h1 className='text-base'>
                                {t('pages.event_detail.experiences.table.headers.is_featured', 'MISSING')}
                            </h1>
                            <Switch
                                style={{ marginLeft: '20px' }}
                                checked={enabled}
                                onChange={setEnabled}
                                className={`${
                                    enabled ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                <span className='sr-only'>Enable notifications</span>
                                <span
                                    className={`${
                                        enabled ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                    </div>
                    <Spacer />
                    <div className='col-span-6 lg:col-span-6 text-right'>
                        <button
                            type='submit'
                            form='eventExperienceForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_detail.button.saving_changes')
                                : t('pages.event_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
function EventImageForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const client = useClient();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const { identifier, addImage } = props;

    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventImageSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        setIsFormError(false);
        if (addImage != undefined) {
            addImage(EventImageDto.fromJS(data));
        }

        setProcessing(false);

        addNotification({
            description: t('pages.event_detail.images.success_description'),
            title: t('pages.event_detail.images.success_title'),
            style: NotificationType.SuccessSimple,
            timeoutInMilliseconds: 2000,
        });
        reset();
    });

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventImageForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <TextField
                        fieldName='name'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.images.table.headers.name'
                        isRequired={true}
                        data_cy='name'
                    />

                    <NumberField
                        fieldName='sortOrder'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.images.table.headers.sort_order'
                        isRequired={true}
                        minimum={0}
                        data_cy='sort-order'
                    />

                    <TextField
                        fieldName='imageUrl'
                        overrideContainerCss='col-span-6'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.images.table.headers.image_url'
                        isRequired={true}
                        data_cy='image-url'
                    />

                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='eventImageForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_detail.button.saving_changes')
                                : t('pages.event_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
function EventVenueForm(props: SubFormInputProps) {
    const { t } = useTranslation();
    const client = useClient();
    const { addNotification } = useNotifications();
    const [traceId, setTraceId] = useState<string | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [formError, setIsFormError] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const { identifier, addVenue } = props;

    const {
        register,
        handleSubmit,
        getFieldState,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventVenueSchema),
        mode: 'onSubmit',
    });

    const { data: locationsData, isLoading: locationsIsLoading } = useQuery(
        ['locations-data'],
        () => {
            return fetchLocationData(client);
        },
        keepForOneDay(),
    );

    const onSubmit = handleSubmit(async (data) => {
        data.address.countryId = process.env.REACT_APP_COUNTRY_ID;
        setProcessing(true);
        setIsFormError(false);
        let newData = {
            ...data,
            isFeatured: enabled,
        };
        if (addVenue != undefined) {
            addVenue(EventVenueDto.fromJS(newData));
        }

        setProcessing(false);

        addNotification({
            description: t('pages.event_detail.venues.success_description'),
            title: t('pages.event_detail.venues.success_title'),
            style: NotificationType.SuccessSimple,
            timeoutInMilliseconds: 2000,
        });
        reset();
    });

    return (
        <>
            <form onSubmit={onSubmit} noValidate={true} id='eventVenueForm' className='space-y-6'>
                <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                <div className='grid grid-cols-6 gap-6'>
                    <LocationReferenceField
                        referenceData={locationsData ?? []}
                        referenceType='locationId'
                        showDefault={true}
                        fieldName='locationId'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.location'
                        data_cy='locations-dropdown'
                    />
                    <TextField
                        fieldName='longitude'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.longitude'
                        isRequired={true}
                        data_cy='longitude'
                    />

                    <TextField
                        fieldName='latitude'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.latitude'
                        isRequired={true}
                        data_cy='latitude'
                    />

                    <TextField
                        fieldName='contactEmail'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.contact_email'
                        isRequired={true}
                        data_cy='contact-email'
                    />

                    <NumberField
                        fieldName='contactPhone'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.contact_phone'
                        isRequired={true}
                        minimum={11}
                        maximum={11}
                        data_cy='contact-phone'
                    />

                    <TextField
                        fieldName='menuUrl'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.menu_url'
                        isRequired={false}
                        data_cy='menu-url'
                    />
                    <div className='grid col-span-3'>
                        <div className='mt-6 flex items-center'>
                            <h1 className='text-base'>
                                {t('pages.event_detail.venues.field_names.is_featured', 'MISSING')}
                            </h1>
                            <Switch
                                style={{ marginLeft: '20px' }}
                                checked={enabled}
                                onChange={setEnabled}
                                className={`${
                                    enabled ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}>
                                <span className='sr-only'>Enable notifications</span>
                                <span
                                    className={`${
                                        enabled ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                    </div>

                    <h1 className='col-span-6'>{t('pages.event_detail.venues.field_names.address')}</h1>
                    <TextField
                        fieldName='address.addressLine1'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_1'
                        isRequired={true}
                        data_cy='address-line-1'
                    />
                    <TextField
                        fieldName='address.addressLine2'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_2'
                        isRequired={false}
                        data_cy='address-line-2'
                    />
                    <TextField
                        fieldName='address.addressLine3'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.address_line_3'
                        isRequired={false}
                        data_cy='address-line-3'
                    />
                    <TextField
                        fieldName='address.town'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.town'
                        isRequired={true}
                        data_cy='address-town'
                    />
                    <TextField
                        fieldName='address.city'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.city'
                        isRequired={true}
                        data_cy='address-city'
                    />
                    <TextField
                        fieldName='address.postcode'
                        overrideContainerCss='col-span-3'
                        getFieldState={getFieldState}
                        register={register}
                        fieldLabel='pages.event_detail.venues.field_names.postcode'
                        isRequired={true}
                        data_cy='address-postcode'
                    />

                    <Spacer />
                    <div className='col-span-6 lg:col-span-3 text-right'>
                        <button
                            type='submit'
                            form='eventVenueForm'
                            disabled={processing}
                            className={classNames(
                                'inline-flex h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm',
                                processing ? '' : 'bg-gray-700 hover:bg-gray-800',
                                'focus:outline-none focus:ring-0 bg-gray-700',
                            )}
                            data-cy='submit'>
                            {processing ? (
                                <svg
                                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'>
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'></circle>
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                </svg>
                            ) : (
                                ''
                            )}
                            {processing
                                ? t('pages.event_detail.button.saving_changes')
                                : t('pages.event_detail.button.save_changes')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

function NumberField(props: NumberFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div hidden={props.hidden} className={props.overrideContainerCss ?? 'col-span-6 lg:col-span-3'}>
                <div className='flex'>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='mr-1 text-sm text-red-500' id={`${props.fieldName}-required`}>
                            *
                        </span>
                    )}
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                </div>
                <input
                    {...props.register(props.fieldName)}
                    type='number'
                    value={props.defaultValue}
                    min={props.minimum}
                    max={props.maximum}
                    name={props.fieldName}
                    id={props.fieldName}
                    step={props.step ?? '1'}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}
                />
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function ExperienceReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-6 lg:col-span-3'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>

                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option key={`${props.referenceType}-default`} value={-1}>
                            {t('default_select')}
                        </option>
                    )}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item.name}-${index}`} value={item.experienceId}>
                            {t(`referenceData.${props.referenceType}.${item.id}`, `${item.name}`)}
                        </option>
                    ))}
                </select>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}

function LocationReferenceField(props: ReferenceFieldInputProps) {
    const { t } = useTranslation();
    const state = props.getFieldState(props.fieldName);

    return (
        <>
            <div className='col-span-6 lg:col-span-6'>
                <div className='flex justify-between'>
                    <label htmlFor={props.fieldName} className='block text-sm font-medium text-gray-700'>
                        {t(props.fieldLabel, 'MISSING')}
                    </label>
                    {props.isRequired !== undefined && props.isRequired && (
                        <span className='text-sm text-red-500' id={`${props.fieldName}-required`}>
                            {t('required_field')}
                        </span>
                    )}
                </div>

                <select
                    {...props.register(props.fieldName)}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete='off'
                    className={classNames(
                        'mt-1 block w-full rounded-md shadow-sm sm:text-sm',
                        state.error
                            ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300  focus:border-gray-500 focus:ring-gray-500',
                    )}
                    data-cy={`${props.data_cy}`}>
                    {props.showDefault !== undefined && props.showDefault && (
                        <option key={`${props.referenceType}-default`} value={-1}>
                            {t('default_select')}
                        </option>
                    )}
                    {props.referenceData?.map((item, index) => (
                        <option key={`${props.referenceType}-${item.name}-${index}`} value={item.locationId}>
                            {t(`referenceData.${props.referenceType}.${item.id}`, `${item.name}`)}
                        </option>
                    ))}
                </select>
                {state.error && (
                    <p
                        className='mt-1 text-sm text-red-600'
                        id={`${props.fieldName}-error`}
                        data-cy={`${props.data_cy}-error`}>
                        {t(`${state.error.message}`, 'MISSING')}
                    </p>
                )}
            </div>
        </>
    );
}
