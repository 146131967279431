import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';
import { formattedDate, toTimeString } from 'core/utils';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.eventName, {
            id: 'eventName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.eventName', ''),
        }),
        columnHelper.accessor((row) => row.brandName, {
            id: 'brandName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.brandName', ''),
        }),
        columnHelper.accessor((row) => row.locationName, {
            id: 'locationName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.locationName', ''),
        }),

        columnHelper.accessor((row) => row.eventDate, {
            id: 'eventDate',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.refund.eventDate', ''),
        }),
        columnHelper.accessor((row) => row.startTime, {
            id: 'startTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.refund.startTime', ''),
        }),
        columnHelper.accessor((row) => row.endTime, {
            id: 'endTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.refund.endTime', ''),
        }),
    ];

    return columnDefs;
}
