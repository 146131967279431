import React, { useEffect, useState } from 'react';

const useSoftDirty = (form: any) => {
    const [isDirty, setIsDirty] = useState(false);

    const handleInput = () => {
        if (isDirty) return;
        setIsDirty(true);
    };

    const _setIsDirty = () => {
        setIsDirty(true);
    };

    useEffect(() => {
        let _form = form;
        if (typeof _form === 'string') {
            _form = document.getElementById(form);
        }
        _form?.addEventListener('input', handleInput);
        return () => _form?.removeEventListener('input', () => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isDirty, _setIsDirty };
};

export default useSoftDirty;
