import { EventVenueSubSectionTarget, HelpContextTarget, NotificationType } from 'core/types';
import EventVenueResourceSection from './venue-resource-section';
import EventVenueConfigurationSection from './venue-configuration-section';
import {
    EventVenueResourceAdminDto,
    EventVenueResourceConfigurationAdminDto,
    EventVenueResourceCreateRequest,
} from 'api/operator-api-types';
import { useEffect, useReducer, useState } from 'react';
import { ChangesPanel, ErrorPanel, FormActionPanel } from 'components/action-panel';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SubSectionAddForm from './sub-section-add';
import { createEventVenueResource } from 'fetch/event/event-venue';
import { useNotifications } from 'context/notification-context';
import { useTranslation } from 'react-i18next';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';
import useSoftDirty from 'hooks/useSoftDirty';
import { useQuery } from 'react-query';
import { findMaxDate, findMinDate, hasManageClaims, keepForNMinutes, keepForOneDay } from 'core/utils';
import EventMenuConfigurationSection from './event-menu-configuration-section';
import { getBookingFeeTypes, getCompany, getMenus } from 'fetch/company';
import { useAuth } from 'context/auth-context';
import { useSelectedCompany } from 'context/company-context';
import { fetchAuthenticated } from 'fetch/operator/system';

// An interface for our actions
interface ReducerAction {
    type: EventVenueSubSectionTarget;
}

// An interface for our state
interface ReducerState {
    panel: EventVenueSubSectionTarget;
    open: boolean;
    title: string;
    description: string;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    let res = {
        ...state,
        panel: action.type,
        open: action.type !== EventVenueSubSectionTarget.Closed,
    };

    switch (action.type) {
        case EventVenueSubSectionTarget.Resource:
            res.title = 'pages.event_venues_detail.resources.title';
            res.description = 'pages.event_venues_detail.resources.description';
            break;

        case EventVenueSubSectionTarget.Configuration:
            res.title = 'pages.event_venues_detail.configurations.title';
            res.description = 'pages.event_venues_detail.configurations.description';
            break;
    }

    return res;
}

export default function ManageEventVenue() {
    const [resources, setResources] = useState<EventVenueResourceAdminDto[]>([]);
    const [configurations, setConfigurations] = useState<EventVenueResourceConfigurationAdminDto[]>([]);

    const navigate = useNavigate();
    const client = useClient();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { companyId, identifier } = useParams();
    const [processing, setProcessing] = useState(false);
    const [minEventDate, setMinEventDate] = useState();
    const [maxEventDate, setMaxEventDate] = useState();
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [keepDirty, setKeepDirty] = useState(false);
    const [showMenuSection, setShowMenuSection] = useState(false);
    const [data, setData] = useState([]);
    const [state, dispatch] = useReducer(reducer, {
        panel: EventVenueSubSectionTarget.Closed,
        open: false,
        title: '',
        description: '',
    });
    const { isDirty, _setIsDirty: setIsDirty } = useSoftDirty('addEventForm');

    const { user } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    // Load the claims.
    const { data: claims, isLoading: claimsLoading } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasAllClaims = hasManageClaims(claims, selectedCompanyId);
            if (!hasAllClaims) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    const {
        handleSubmit,
        reset,
        register,
        getFieldState,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
    });

    const { data: eventMenusData, isLoading: eventMenusDataLoading } = useQuery(
        ['event-menus-data', companyId],
        () => {
            return getMenus(companyId as string, client);
        },
        keepForNMinutes(1),
    );

    const { data: bookingFeeTypes } = useQuery(
        ['booking-fee-types'],
        () => {
            return getBookingFeeTypes(client);
        },
        keepForNMinutes(1),
    );
    const { data: companyData, isLoading } = useQuery(
        ['companyData', companyId],
        () => {
            return getCompany(parseInt(companyId!!)!!, client).then((rsp) => {
                return rsp;
            });
        },
        keepForNMinutes(1),
    );

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        const rsp = await createEventVenueResource(
            Number(identifier),
            EventVenueResourceCreateRequest.fromJS({
                eventVenueResource: resources,
                eventVenueResourceConfiguration: configurations,
                eventResourceMenu:
                    data?.eventMenuId && data?.eventMenuId > 0
                        ? { eventMenuId: parseInt(data.eventMenuId) }
                        : undefined,
            }),
            client,
        );
        setProcessing(false);
        if (rsp.traceId) {
            setTraceId(rsp.traceId);
        }

        if (rsp.status && rsp.status !== 200) {
            if (rsp?.errors && rsp?.errors?.request[0] === 'AlreadyExists') {
                addNotification({
                    description: t('pages.event_detail.detail.duplicate_event_error_description'),
                    title: t('pages.event_detail.detail.duplicate_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDirty();
                setKeepDirty(true);
            } else {
                addNotification({
                    description: t('pages.event_detail.detail.error_description'),
                    title: t('pages.event_detail.detail.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDirty();
                setKeepDirty(true);
            }
        } else {
            // Success
            addNotification({
                description: t('pages.event_detail.detail.success_description'),
                title: t('pages.event_detail.detail.success_title'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 3000,
            });
            if (rsp != null) {
                navigate(`../`, { replace: true });
            }
        }
    });

    const openAddSubSection = (target: EventVenueSubSectionTarget) => {
        dispatch({
            type: target,
        });
    };

    const handleReset = () => {
        setServerErrorList([]);
        setTraceId(null);
        reset();
    };

    useEffect(() => {
        if (resources?.length > 0 && configurations?.length > 0) {
            setIsDirty();
            setKeepDirty(true);
        } else {
            setKeepDirty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources, configurations]);

    const addResource = (data: EventVenueResourceAdminDto) => {
        var newResources = [...resources];
        newResources.push(data);
        setResources(newResources);
    };

    const addConfiguration = (data: EventVenueResourceConfigurationAdminDto) => {
        var newConfigurations = [...configurations];
        newConfigurations.push(data);
        setConfigurations(newConfigurations);
    };

    useEffect(() => {
        setData(eventMenusData);
    }, [eventMenusData]);

    useEffect(() => {
        if (resources?.length > 0 && eventMenusData?.length > 0) {
            setShowMenuSection(true);
            const result: any = [];
            eventMenusData.forEach((item: any) =>
                resources.forEach((resource: any) => {
                    const eventDate = new Date(resource?.eventDate);
                    const dateEffective = new Date(item.dateEffective);
                    if (resource?.eventDate && dateEffective < eventDate) {
                        result.push(item);
                    }
                }),
            );
            if (result?.length === 0) {
                setShowMenuSection(false);
            }
            setData(result);
        }
    }, [resources, eventMenusData]);

    useEffect(() => {
        if (resources?.length > 0) {
            const minDate = findMinDate(resources);
            const maxDate = findMaxDate(resources);
            setMinEventDate(minDate);
            setMaxEventDate(maxDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources?.length]);

    return (
        <Spin spinning={processing || eventMenusDataLoading || isLoading || claimsLoading}>
            <FormActionPanel
                isDirty={keepDirty ? isDirty : keepDirty}
                processing={processing}
                handleReset={handleReset}
                contextTitle='pages.event_detail.context.title'
                contextDescription='pages.event_detail.context.description'
                contextTarget={HelpContextTarget.EventVenue}
                formTarget='addEventForm'
            />
            <form onSubmit={onSubmit} noValidate={true} id='addEventForm' className='space-y-6'>
                <ChangesPanel isDirty={isDirty} />
                <EventVenueResourceSection
                    setKeepDirty={setKeepDirty}
                    setIsDirty={setIsDirty}
                    setResources={setResources}
                    data={resources}
                    addCallback={openAddSubSection}
                />
                <EventVenueConfigurationSection
                    setIsDirty={setIsDirty}
                    setKeepDirty={setKeepDirty}
                    data={configurations}
                    setConfigurations={setConfigurations}
                    addCallback={openAddSubSection}
                />
                {showMenuSection && (
                    <EventMenuConfigurationSection
                        setIsDirty={setIsDirty}
                        setKeepDirty={setKeepDirty}
                        data={configurations}
                        addCallback={openAddSubSection}
                        eventMenusData={data}
                        getFieldState={getFieldState}
                        register={register}
                        minEventDate={minEventDate}
                        maxEventDate={maxEventDate}
                    />
                )}
            </form>
            <SubSectionAddForm
                closeCallback={openAddSubSection}
                panel={state.panel}
                open={state.open}
                title={state.title}
                description={state.description}
                addResource={addResource}
                addConfiguration={addConfiguration}
                bookingFeeTypes={bookingFeeTypes}
                companyData={companyData}
            />
        </Spin>
    );
}
