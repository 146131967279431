import React from 'react';
import { useAuth } from '../context/auth-context';
import { authService } from './auth-service';
import { ClientApiOptions } from './types';
const OPERATOR_API_URL = process.env.REACT_APP_OPERATOR_API_URL;
const USER_API_URL = process.env.REACT_APP_USER_API_URL;

function useClient() {
    const { user } = useAuth();
    const token = user?.accessToken;
    return React.useCallback(
        (endpoint: string, config: ClientApiOptions) => client(endpoint, { ...config, token }),
        [token],
    );
}

async function client(
    endpoint: string,
    opts: ClientApiOptions = {
        data: null,
        token: null,
        headers: null,
        config: null,
        methodOverride: null,
    },
    unauthorizedCheck: boolean = true,
) {
    const config = {
        method: opts.methodOverride ?? (opts.data ? 'POST' : 'GET'),
        body: opts.data ? JSON.stringify(opts.data) : undefined,
        headers: {
            Authorization: opts.token ? `Bearer ${opts.token}` : undefined,
            'Content-Type': opts.data ? 'application/json' : undefined,
            ...opts.headers,
        },
        ...opts.config,
    };

    return window.fetch(endpoint, config).then(async (response) => {
        if (response.status === 401 && (unauthorizedCheck || unauthorizedCheck === undefined)) {
            authService.logout();

            window.location.assign(window.location.href);
            return Promise.reject({ message: 'invalid token.' });
        }

        let data: any;

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            data = await response.json();
        } else {
            data = response.status;
        }

        if (response.ok) {
            return data;
        } else {
            return Promise.reject(data);
        }
    });
}

export { client, useClient, OPERATOR_API_URL, USER_API_URL };
