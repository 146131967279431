import { USER_API_URL, client } from './api-client';
import { Convert } from './convert/user-context';
import { AuthService, LoginForm, UserContext } from './types';

const localStorageKey = '_app_user_auth_context_';

const authService: AuthService = {
    async login(data: LoginForm): Promise<UserContext | null> {
        let context: UserContext | null = null;

        await client(
            `${USER_API_URL}/api/v1/account/login/operator`,
            {
                data: data,
            },
            false,
        )
            .then((rsp) => {
                window.localStorage.setItem(localStorageKey, JSON.stringify(rsp));
                context = rsp as UserContext;
            })
            .catch(() => {});

        return Promise.resolve(context);
    },

    getUserContext(): UserContext | null {
        const storedContext = window.localStorage.getItem(localStorageKey);

        if (storedContext) {
            try {
                const contx = Convert.toUserContext(storedContext);
                return contx;
            } catch (e) {}
        }

        return null;
    },

    logout() {
        window.localStorage.removeItem(localStorageKey);
    },
};

export { authService };
