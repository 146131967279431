import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from './auth-context';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { ai, getReactPlugin } from '../core/telemetry-service';
import AppError from '../pages/app-error';
import { AppCompanyContext } from './company-context';
import { AppNotificationContext } from './notification-context';
import { AppConfirmActionContext } from './confirmation-context';

// Create a client for the queries.
const queryClient = new QueryClient();

function AppProviders({ children }: { children: JSX.Element }) {
    ai.initialize(process.env.REACT_APP_AI_INSTRUMENTATION_KEY!!, process.env.REACT_APP_AI_CONNECTIONSTRING!!);
    return (
        <AppInsightsErrorBoundary onError={() => <AppError />} appInsights={getReactPlugin()}>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AppCompanyContext>
                        <AppNotificationContext>
                            <AppConfirmActionContext>
                                <AuthProvider queryClient={queryClient}>{children}</AuthProvider>
                            </AppConfirmActionContext>
                        </AppNotificationContext>
                    </AppCompanyContext>
                </Router>
                <ReactQueryDevtools position='bottom-left' initialIsOpen={false} />
            </QueryClientProvider>
        </AppInsightsErrorBoundary>
    );
}

export { AppProviders };
