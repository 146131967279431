import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/table';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, LocationUiModel, NotificationType } from 'core/types';
import { keepForNMinutes } from 'core/utils';
import { useClient } from 'core/api-client';
import { deleteCompanyUser, getUser } from 'fetch/company';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';
import Columns from './columns';
import { useConfirm } from 'context/confirmation-context';
interface UserData {
    data:
        | {
              users: any[];
          }
        | undefined;
    isLoading: boolean;
    refetch: Function;
}

export default function Users() {
    let { companyId } = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<any[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        id: true,
        description: true,
        url: true,
        dateEffective: true,
        sortOrder: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();
    const { isConfirmed, setProgress } = useConfirm();

    const handleAdd = () => {
        navigate(`/companies/${companyId}/users/create`);
    };

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                navigate(`/companies/${companyId}/users/${row.userId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.userId);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    const {
        data: userData,
        isLoading,
        refetch,
    }: UserData = useQuery(
        ['users-data', companyId],
        () => {
            setLoading(true);
            const res = getUser(companyId as string, client)
                .then((res) => {
                    if (res?.traceId) {
                        addNotification({
                            description: t('pages.users.messages.list_error_description'),
                            title: t('pages.users.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                        setLoading(false);
                        setData([]);
                    } else {
                        setLoading(false);
                        return res;
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log('error in getting users', error);
                })
                .finally(() => setLoading(false));
            if (res) return res;
        },
        keepForNMinutes(1),
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, companyId]);

    // delete user
    async function handleDelete(userId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteCompanyUser(companyId as string, userId, client);
            if (res === 202 || res === 204) {
                addNotification({
                    description: t('pages.users.messages.delete_success_description'),
                    title: t('pages.users.messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('pages.users.messages.delete_error_description'),
                    title: t('pages.users.messages.error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('pages.users.messages.delete_error_description'),
                title: t('pages.users.messages.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting user');
        }
    }
    // Set the user data to a format that is easier to use in the UI.
    useEffect(() => {
        if (userData && userData.users) {
            const uiModel = userData.users?.map((item) => {
                return {
                    firstName: item?.firstName,
                    lastName: item?.lastName,
                    email: item?.email,
                    phone: item?.phoneNumber,
                    userId: item?.userId,
                    claims: item?.claims,
                };
            });
            setData(uiModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.users, isLoading]);

    return (
        <Spin spinning={loading || isLoading || isDeleting}>
            <TableActionPanel
                setGlobalFilter={setGlobalFilter}
                setColumnFilterOpen={setColumnFilterOpen}
                title='pages.users.context.title'
                description='pages.users.context.description'
                actionButtonText='pages.users.action_button'
                target={HelpContextTarget.Users}
                handleActionButton={handleAdd}
            />
            <DataTable<any>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.users.table.headers'}
                columns={Columns}
                canExpand={false}
                // renderSubData={SubData}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.users.table.empty'}
            />
        </Spin>
    );
}
