import { useEffect, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { TableActionPanel } from 'components/action-panel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from 'context/auth-context';
import LoadingPlaceHolder from 'components/loading';
import LoadingError from 'components/loading-error';
// cg import { useConfirm } from '../../context/confirmation-context';
import DataTable from 'components/table';
import { HelpContextTarget, ColumnActionType, ColumnFilterList, ExperienceUiModel, NotificationType } from 'core/types';
import { keepForNMinutes, keepForOneDay, timer } from 'core/utils';
import { deleteExperience, fetchExperienceData } from 'fetch/experience';
import { useClient } from 'core/api-client';

import Columns from './columns';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'context/confirmation-context';
import { useNotifications } from 'context/notification-context';
import Spin from 'components/Spin';
// cg import SubData from './sub-data';

function Experiences() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isConfirmed } = useConfirm();
    const { addNotification } = useNotifications();
    const [isDeleting, setIsDeleting] = useState(false);
    const location = useLocation();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [data, setData] = useState<ExperienceUiModel[]>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        id: true,
        description: true,
        url: true,
        dateEffective: true,
        sortOrder: true,
    });
    const [columnFilterList, setColumnFilterList] = useState<ColumnFilterList[]>([]);
    const client = useClient();

    const handleAdd = () => {
        navigate('/experience/create');
    };

    const handleTableAction = (row: ExperienceUiModel, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                navigate(`/experience/${row.experienceId}`);
                break;
            case ColumnActionType.Delete:
                const deleteItem = async () => {
                    const confirmed = await isConfirmed({
                        confirmationTitle: null,
                        confirmationDescription: null,
                        confirmButton: null,
                        cancelButton: null,
                    });

                    if (confirmed) {
                        handleDelete(row.experienceId!!);
                    }
                };

                deleteItem();
                break;
        }
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            // console.log('cg bulk delete!');
        };

        deleteBulkItem();
    };

    // delete location
    async function handleDelete(locationId: string) {
        try {
            setIsDeleting(true);
            const res = await deleteExperience(locationId as string, client);
            if (res === 202 || res === 200) {
                addNotification({
                    description: t('common.api_messages.status_202'),
                    title: t('common.api_messages.delete_success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                refetch();
                setIsDeleting(false);
            } else if (res === 409) {
                addNotification({
                    description: t('common.api_messages.status_409'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else if (res === 422) {
                addNotification({
                    description: t('common.api_messages.status_422'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            } else {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setIsDeleting(false);
            }
        } catch (error) {
            addNotification({
                description: t('common.api_messages.status_400'),
                title: t('common.api_messages.delete_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
            setIsDeleting(false);
            console.log('error while deleting location');
        }
    }

    const {
        data: experienceData,
        isLoading: experiencesDataLoading,
        refetch,
    } = useQuery(
        ['experience-data'],
        () => {
            return fetchExperienceData(client);
        },
        keepForNMinutes(1),
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Set the user data to a format that is easier to use in the UI.
    useEffect(() => {
        if (experiencesDataLoading) {
            return;
        }

        if (experienceData === undefined || !Array.isArray(experienceData)) {
            return;
        }

        const uiModel: ExperienceUiModel[] = experienceData.map((item) => {
            return {
                experienceId: item.experienceId,
                name: item.name,
                description: item.description,
                url: item.urlSlug,
                dateEffective: item.dateEffective,
                sortOrder: item.sortOrder,
                original: item,
            };
        });
        setData(uiModel);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experienceData, experiencesDataLoading]);

    if (experiencesDataLoading) {
        return <LoadingPlaceHolder resourceName='pages.experiences.loading_experiences' />;
    }

    if (experienceData === undefined || experienceData.experience === null) {
        return <LoadingError resourceName='pages.experiences.loading_experiences_error' />;
    }

    return (
        <Spin spinning={isDeleting}>
            <TableActionPanel
                setGlobalFilter={setGlobalFilter}
                setColumnFilterOpen={setColumnFilterOpen}
                title='pages.experiences.context.title'
                description='pages.experiences.context.description'
                actionButtonText='pages.experiences.action_button'
                target={HelpContextTarget.Experiences}
                handleActionButton={handleAdd}
            />

            <DataTable<ExperienceUiModel>
                data={data}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnFilterList={setColumnFilterList}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.experiences.table.headers'}
                columns={Columns}
                canExpand={false}
                // renderSubData={SubData}
                canGlobalFilter={true}
                enableRowSelection={true}
                selectBulkdDelete={handleBulkDelete}
                noDataMessage={'pages.experiences.table.empty'}
            />
        </Spin>
    );
}

export default Experiences;
