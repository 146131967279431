function Overview() {
    return (
        <>
            <div className='mx-auto px-4 sm:px-6 md:px-8'>
                <h1 className='text-2xl font-semibold text-gray-900'>Overview</h1>
            </div>
            <div className='mx-auto px-4 sm:px-6 md:px-8'>
                <div className='py-1'>
                    <div>{process.env.REACT_APP_ENVIRONMENT}</div>
                    <div>{process.env.REACT_APP_APPLICATION}</div>
                    <div>{process.env.REACT_APP_MANAGEMENT_API_URL}</div>
                    <div>{process.env.REACT_APP_AI_INSTRUMENTATION_KEY}</div>
                    <div>{process.env.REACT_APP_AI_CONNECTIONSTRING}</div>
                    <div>{process.env.REACT_APP_ROOT_URL}</div>
                    <div>{process.env.NODE_ENV}</div>
                </div>
            </div>
        </>
    );
}

export default Overview;
