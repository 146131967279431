import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';
import { formattedDateWithTime } from 'core/utils';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.category, {
            id: 'category',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.category', ''),
        }),
        columnHelper.accessor((row) => row.product, {
            id: 'product',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.product', ''),
        }),
        columnHelper.accessor((row) => row.productUnitPrice, {
            id: 'product_unit_price',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() || ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.product_unit_price', ''),
        }),
        columnHelper.accessor((row) => row.quantity, {
            id: 'qty',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.brunch_report.table.headers.qty', ''),
        }),
        columnHelper.accessor((row) => row.productTotalPaid, {
            id: 'product_total_paid',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.brunch_report.table.headers.product_total_paid', ''),
        }),

        columnHelper.accessor((row) => row.bookingFee, {
            id: 'booking_fee',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.booking_fee', ''),
        }),
        columnHelper.accessor((row) => row.totalNetAmount, {
            id: 'total_net_amount',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.total_net_amount', ''),
        }),
        columnHelper.accessor((row) => row.productDiscount, {
            id: 'product_discount',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.product_discount', ''),
        }),
        columnHelper.accessor((row) => row.userName, {
            id: 'user',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.user', ''),
        }),
        columnHelper.accessor((row) => row.orderDate, {
            id: 'order_date',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()
                            ? formattedDateWithTime(new Date(info.getValue()?.toString()!!)).shortDate
                            : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.order_date', ''),
        }),
        columnHelper.accessor((row) => row.eventName, {
            id: 'event_name',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() || ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.event_name', ''),
        }),
        columnHelper.accessor((row) => row.eventDate, {
            id: 'event_date',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()
                            ? formattedDateWithTime(new Date(info.getValue()?.toString()!!)).shortDate
                            : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.event_date', ''),
        }),
        columnHelper.accessor((row) => row.eventExternalId, {
            id: 'event_external_id',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() || ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.event_external_id', ''),
        }),
        columnHelper.accessor((row) => row.locationName, {
            id: 'locationName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() || ''}
                    </span>
                </div>
            ),
            header: () => t('pages.brunch_report.table.headers.location', ''),
        }),
    ];

    return columnDefs;
}
