import { AuthenticatedResponseModel } from 'api/operator-api-types';
import { OPERATOR_API_URL } from 'core/api-client';
import { ClientApiOptions } from 'core/types';

export const fetchAuthenticated = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<AuthenticatedResponseModel>,
) => {
    const defaultReturn: AuthenticatedResponseModel = AuthenticatedResponseModel.fromJS({
        claims: [],
    });

    return client(`${OPERATOR_API_URL}/api/v1/system/authenticated`, {})
        .then((rsp) => rsp)
        .catch(() => defaultReturn);
};
