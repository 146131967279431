import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ColumnActionType, ColumnGeneratorInputProps, ExperienceUiModel } from '../../core/types';
import { DeleteCss, EditCss } from '../../components/table';
import { formattedDate } from '../../core/utils';

export default function Columns(props: ColumnGeneratorInputProps<ExperienceUiModel>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.name, {
            id: 'name',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brands.table.headers.name', ''),
        }),

        columnHelper.accessor((row) => row.displayTitle, {
            id: 'displayTitle',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.brands.table.headers.display_title', 'Display Title'),
        }),
        columnHelper.accessor((row) => row.url, {
            id: 'url',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.brands.table.headers.url', ''),
        }),
        columnHelper.accessor((row) => row.sortOrder, {
            id: 'sortOrder',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.brands.table.headers.sortOrder', ''),
        }),
        columnHelper.accessor((row) => row.dateEffective, {
            id: 'dateEffective',
            cell: (info) => <>{formattedDate(new Date(info.getValue()?.toString()!!)).longDate}</>,
            header: () => t('pages.brands.table.headers.dateEffective', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            data-cy='edit'
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                            className={EditCss()}>
                            {t('buttons.edit', '')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete', '')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
