import { createContext, useContext, useEffect, useState } from 'react';
import { ConfirmActionContextState, ConfirmActionContextType } from 'core/types';
import React from 'react';
const ConfirmActionContext = createContext<ConfirmActionContextType | null>(null);

export function AppConfirmActionContext({ children }: { children: React.ReactNode }) {
    const [state, setState] = useState<ConfirmActionContextState>({
        confirmationTitle: null,
        confirmationDescription: null,
        confirmButton: null,
        cancelButton: null,
        keepAlive: false,
        _totalTasks: null,
        _currentTaskNumber: null,
        _accept: () => {},
        _cancel: () => {},
        _isActive: false,
    });

    return <ConfirmActionContext.Provider value={{ state, setState }}>{children}</ConfirmActionContext.Provider>;
}

export function useConfirm() {
    const context = useContext(ConfirmActionContext);
    const [needsCleanup, setNeedsCleanup] = useState(false);

    if (!context) {
        throw new Error('useConfirm has to be used within <AppConfirmActionContext.Provider>');
    }

    const { state, setState } = context;

    const isConfirmed = (override: Partial<ConfirmActionContextState>) => {
        setNeedsCleanup(true);
        const promise = new Promise<void>((resolve, reject) => {
            setState({
                ...state,
                ...override,
                _isActive: true,
                _totalTasks: null,
                _currentTaskNumber: null,
                _accept: resolve,
                _cancel: reject,
            });
        });
        return promise.then(
            () => {
                setState({
                    ...state,
                    ...override,
                    _currentTaskNumber: state.keepAlive ? 0 : null,
                    _isActive: state.keepAlive,
                }); // if are showing progress then we need to keep the dialog open
                return true;
            },
            () => {
                setState({ ...state, ...override, _currentTaskNumber: null, _isActive: false });
                return false;
            },
        );
    };

    const setProgress = React.useCallback(
        (currentTaskNumber: number, totalTasks: number) => {
            setState({ ...state, _totalTasks: totalTasks, _currentTaskNumber: currentTaskNumber, _isActive: true });

            setTimeout(() => {
                if (totalTasks === currentTaskNumber) {
                    setState({
                        ...state,
                        _totalTasks: totalTasks,
                        _currentTaskNumber: currentTaskNumber,
                        _isActive: false,
                    });
                }
            }, 100);
        },
        [state, setState],
    );

    useEffect(() => {
        return () => {
            if (state._cancel && needsCleanup) {
                state._cancel();
            }
        };
    }, [state, needsCleanup]);

    return {
        ...state,
        isConfirmed,
        setProgress,
    };
}
