import { LockClosedIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/form-fields';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { NotificationType } from 'core/types';
import { VerifyEmailSchema } from 'core/validation-schema';
import { verifiedPasswordReset } from 'fetch/user/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

function VerifyEmail() {
    const params = useLocation().search;
    const userid = new URLSearchParams(params).get('userid');
    const tokenId = new URLSearchParams(params).get('tokenId');
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const client = useClient();

    const [processing, setProcessing] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);

    // Format the form handler.
    const {
        register,
        getFieldState,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VerifyEmailSchema),
        mode: 'onSubmit',
    });

    const onSubmit = handleSubmit(async (data) => {
        setProcessing(true);
        const rsp = await verifiedPasswordReset(
            {
                userId: userid || '',
                resetToken: tokenId || '',
                password: data.password,
                confirmPassword: data.confirmPassword,
            },
            client,
        );

        setProcessing(false);
        if (rsp.traceId) {
            setTraceId(rsp.traceId);
        }

        if (rsp.status && rsp.status != 200) {
            addNotification({
                description: t('pages.event_detail.detail.error_description'),
                title: t('pages.event_detail.detail.error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            // Success
            addNotification({
                description: t('pages.event_detail.detail.success_description'),
                title: t('pages.event_detail.detail.success_title'),
                style: NotificationType.SuccessSimple,
                timeoutInMilliseconds: 3000,
            });
            if (rsp != null) {
                navigate('../');
            }
        }
    });

    return (
        <>
            <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
                <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
                    <LockClosedIcon className='mx-auto h-10 w-auto gray-800' />
                    <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                        {t('pages.verify_email.header')}
                    </h2>
                </div>

                <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
                    <form onSubmit={onSubmit} noValidate={true} id='verifyEmailForm' className='space-y-6'>
                        <div>
                            <TextField
                                fieldName='password'
                                isPassword={true}
                                overrideContainerCss='col-span-3 mt-3'
                                getFieldState={getFieldState}
                                register={register}
                                fieldLabel='pages.verify_email.field_names.password'
                                data_cy='password'
                            />
                            <TextField
                                fieldName='confirmPassword'
                                isPassword={true}
                                overrideContainerCss='col-span-3 mt-3'
                                getFieldState={getFieldState}
                                register={register}
                                fieldLabel='pages.verify_email.field_names.confirmPassword'
                                data_cy='confirm-password'
                            />
                        </div>
                        <div>
                            <button
                                type='submit'
                                data-cy='submit'
                                disabled={processing}
                                tabIndex={4}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                    processing ? 'bg-gray-700' : 'bg-gray-800'
                                } hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800`}>
                                {processing ? (
                                    <svg
                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'>
                                        <circle
                                            className='opacity-25'
                                            cx='12'
                                            cy='12'
                                            r='10'
                                            stroke='currentColor'
                                            strokeWidth='4'></circle>
                                        <path
                                            className='opacity-75'
                                            fill='currentColor'
                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                                    </svg>
                                ) : (
                                    ''
                                )}
                                {processing
                                    ? t('pages.verify_email.button.clicked')
                                    : t('pages.verify_email.button.active')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>{' '}
        </>
    );
}

export default VerifyEmail;
