import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'context/confirmation-context';

export default function ConfirmDelete() {
    const { t } = useTranslation();
    const cancelButtonRef = useRef(null);
    const {
        confirmationTitle,
        confirmationDescription,
        confirmButton,
        cancelButton,
        _totalTasks,
        _currentTaskNumber,
        _accept: accept,
        _cancel: cancel,
        _isActive: isActive,
    } = useConfirm();

    return (
        <Transition.Root show={isActive} as={Fragment}>
            <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={cancel}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'>
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                                {_currentTaskNumber === null && (
                                    <>
                                        <div className='sm:flex sm:items-start'>
                                            <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                                                <ExclamationTriangleIcon
                                                    className='h-6 w-6 text-red-600'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                                <Dialog.Title
                                                    as='h3'
                                                    className='text-base font-semibold leading-6 text-gray-900'>
                                                    {confirmationTitle ?? t('generic_delete_title', 'MISSING')}
                                                </Dialog.Title>
                                                <div className='mt-2'>
                                                    <p className='text-sm text-gray-500'>
                                                        {confirmationDescription ??
                                                            t('generic_delete_description', 'MISSING')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                                            <button
                                                type='button'
                                                className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
                                                onClick={accept}
                                                data-cy='confirm-delete'>
                                                {confirmButton ?? t('buttons.delete', 'MISSING')}
                                            </button>
                                            <button
                                                type='button'
                                                className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                                                onClick={cancel}
                                                ref={cancelButtonRef}
                                                data-cy='cancel-delete'>
                                                {cancelButton ?? t('buttons.cancel', 'MISSING')}
                                            </button>
                                        </div>
                                    </>
                                )}
                                {_currentTaskNumber !== null && _totalTasks !== null && (
                                    <>
                                        <div>
                                            <h4 className='sr-only'>{t('status_sr_only')}</h4>
                                            <p className='text-bold font-medium text-gray-900'>
                                                {t('progress_overlay')}
                                            </p>
                                            <div className='mt-6' aria-hidden='true'>
                                                <div className='overflow-hidden rounded-full bg-gray-200'>
                                                    <div
                                                        className='h-2 rounded-full bg-gray-600'
                                                        style={{
                                                            width: `${(_currentTaskNumber / _totalTasks) * 100}%`,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
