import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { FormActionPanel, ErrorPanel } from 'components/action-panel';
import { useNotifications } from 'context/notification-context';
import { useClient } from 'core/api-client';
import { HelpContextTarget, NotificationType } from 'core/types';
import { BrandSchema } from 'core/validation-schema';
import BrandSection from './create';
import Spin from 'components/Spin';
import useSoftDirty from 'hooks/useSoftDirty';
import { createBrand, getBrand, updateBrand } from 'fetch/brands';

export default function ManageBrands() {
    const client = useClient();
    let { companyId } = useParams();
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const { identifier } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [serverErrorList, setServerErrorList] = useState<string[]>([]);
    const [data, setData] = useState<any>();
    const [formError, setIsFormError] = useState(false);
    const [traceId, setTraceId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const { isDirty, _setIsDirty: setDirty } = useSoftDirty('brandForm');

    useEffect(() => {
        if (identifier && identifier !== 'create') {
            setIsEdit(true);
        }
    }, [identifier]);

    const {
        data: brandData,
        isLoading,
        refetch,
    } = useQuery(['brandData', identifier!!], () => {
        if (identifier && identifier !== 'create') {
            return getBrand(companyId!!, identifier!!, client).then((rsp) => {
                return rsp;
            });
        }
    });

    // Format the form handler.
    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(BrandSchema),
        mode: 'onSubmit',
    });

    const handleReset = () => {
        reset();
    };

    useEffect(() => {
        setIsFormError(Object.keys(errors).length !== 0);
    }, [errors]);

    const onSubmit = handleSubmit(async (data) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setServerErrorList([]);
        setTraceId(null);
        if (isEdit && identifier) {
            setProcessing(true);
            const brand = {
                brand: {
                    brandId: identifier,
                    companyId: companyId,
                    name: data.brand.name,
                    displayTitle: data.brand.displayTitle,
                    sortOrder: Number(data.brand.sortOrder),
                    urlSlug: data.brand.urlSlug,
                    dateEffective: new Date(dayjs(data.brand.dateEffective).format('YYYY-MM-DD')),
                },
            };
            const rsp = await updateBrand(companyId as string, brand, client);
            setProcessing(false);
            if (rsp.traceId) {
                setTraceId(rsp.traceId);
            }
            if ((rsp.status && rsp.status === 200) || rsp === 202) {
                addNotification({
                    description: t('pages.brands.success_description'),
                    title: t('pages.brands.success_title'),
                    style: NotificationType.SuccessSimple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
                navigate(`../`, { replace: true });
                refetch();
            } else {
                if (rsp?.errors?.request[0] === 'AlreadyExists') {
                    addNotification({
                        title: t('pages.brands.error_api_messages.status_409'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setProcessing(false);
                } else {
                    addNotification({
                        description: t('pages.brands.error_description'),
                        title: t('pages.brands.error_title'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                }
                setProcessing(false);
            }
        } else {
            try {
                setProcessing(true);
                const newBrand = {
                    brand: {
                        companyId: companyId,
                        name: data.brand.name,
                        description: data.brand.description,
                        displayTitle: data.brand.displayTitle,
                        sortOrder: Number(data.brand.sortOrder),
                        urlSlug: data.brand.urlSlug,
                        dateEffective: new Date(dayjs(data.brand.dateEffective).format('YYYY-MM-DD')),
                    },
                };
                const res = await createBrand(companyId as string, newBrand, client);
                if (res.traceId) {
                    setTraceId(res.traceId);
                }
                if (!res?.traceId || res?.status === 202 || res?.status === 200) {
                    addNotification({
                        title: t('pages.brands.error_api_messages.status_202'),
                        style: NotificationType.SuccessSimple,
                        timeoutInMilliseconds: 3000,
                    });
                    navigate(`../`, { replace: true });
                    refetch();
                    setProcessing(false);
                } else if (res?.status === 409) {
                    addNotification({
                        title: t('pages.brands.error_api_messages.status_409'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setProcessing(false);
                } else if (res?.status === 422) {
                    addNotification({
                        title: t('pages.brands.error_api_messages.status_422'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setProcessing(false);
                } else {
                    addNotification({
                        title: t('pages.brands.error_api_messages.status_400_404'),
                        style: NotificationType.ErrrorSumple,
                        timeoutInMilliseconds: 3000,
                    });
                    setProcessing(false);
                }
            } catch (error) {
                addNotification({
                    description: t('common.api_messages.status_400'),
                    title: t('common.api_messages.delete_error_title'),
                    style: NotificationType.ErrrorSumple,
                    timeoutInMilliseconds: 3000,
                });
                setProcessing(false);
                console.log('error while creating brand');
            }
        }
    });

    useEffect(() => {
        setData(brandData);
        if (data?.status && data?.status !== 200) {
            addNotification({
                title: t('pages.experience_detail.single_error_title'),
                style: NotificationType.ErrrorSumple,
                timeoutInMilliseconds: 3000,
            });
        } else {
            if (isEdit && data) {
                setValue('brand.name', data?.name);
                setValue('brand.displayTitle', data?.displayTitle);
                setValue('brand.description', data?.description);
                setValue('brand.sortOrder', data?.sortOrder);
                setValue('brand.urlSlug', data?.urlSlug);
                setValue('brand.dateEffective', new Date(data.dateEffective));
                setDirty();
                refetch();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandData, data]);

    return (
        <>
            <Spin spinning={(isEdit && isLoading) || processing}>
                <span className='relative inline-flex w-full items-start justify-between'>
                    <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className='inline-flex w-[85px] h-10 mr-2 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-0 bg-gray-700'>
                        <>
                            <span className='hidden lg:block'>{t('buttons.go_back', 'MISSING')}</span>
                        </>
                    </button>

                    <FormActionPanel
                        isDirty={isDirty}
                        processing={processing}
                        handleReset={handleReset}
                        contextTitle={'pages.brands.context.title'}
                        contextDescription={'pages.brands.context.description'}
                        contextTarget={HelpContextTarget.brands}
                        formTarget='brandForm'
                    />
                </span>
                <form onSubmit={onSubmit} noValidate={true} id='brandForm' className='space-y-6'>
                    <ErrorPanel formError={formError} serverErrorList={serverErrorList} traceId={traceId} />
                    <BrandSection
                        errors={errors}
                        register={register}
                        getFieldState={getFieldState}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        setDirty={setDirty}
                    />
                </form>
            </Spin>
        </>
    );
}
