import { OPERATOR_API_URL } from '../../core/api-client';
import { ClientApiOptions } from 'core/types';

export const ClearContentCache = async (
    data: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/clearcache`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearAllPageCache = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/all-pages/cache/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearAllEventCache = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/events/cache/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearAllBrandCache = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/brands/cache/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearAllPageCacheByType = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/pages/${slug}/cache/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearEvents = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/events/${slug}/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearBrands = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/brands/${slug}/clear`, {
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const ClearSinglePageCache = async (
    slug: any,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/content/page/clear`, {
        data: {
            pageKey: `${slug}`,
        },
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
