import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnActionType, ColumnGeneratorInputProps } from '../../../core/types';
import { DeleteCss, EditCss } from '../../../components/table';
import { useNavigate, useParams } from 'react-router-dom';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId, identifier } = useParams();
    const columnHelper = createColumnHelper<any>();

    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.displayTitle, {
            id: 'displayTitle',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.display_title', ''),
        }),
        columnHelper.accessor((row) => row.resourceCapacity, {
            id: 'resourceCapacity',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.resource_capacity', ''),
        }),
        columnHelper.accessor((row) => row.totalResources, {
            id: 'totalResources',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.total_resources', ''),
        }),
        columnHelper.accessor((row) => row.minSize, {
            id: 'minSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.min_size', ''),
        }),
        columnHelper.accessor((row) => row.maxSize, {
            id: 'maxSize',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.resource_config_table.headers.max_size', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            type='button'
                            onClick={() =>
                                navigate(
                                    `/companies/${companyId}/eventvenues/${identifier}/eventdetails/configurations/${props.row.original.eventVenueResourceConfigurationId}`,
                                )
                            }
                            className={EditCss()}>
                            {t('buttons.view', 'MISSING')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
