import { OPERATOR_API_URL } from 'core/api-client';
import { LocationRequest, LocationResponseModel, LocationDto } from 'api/operator-api-types';
import { ClientApiOptions } from 'core/types';

export const fetchLocationData = async (
    client: (endpoint: string, config: ClientApiOptions) => Promise<LocationDto[]>,
) => {
    const defaultReturn = new Array<LocationDto>();

    return client(`${OPERATOR_API_URL}/api/v1/location/all`, {})
        .then((rsp) => rsp)
        .catch(() => defaultReturn);
};

export const createLocation = async (
    data: LocationRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<LocationResponseModel | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/location`, {
        data: data,
        methodOverride: 'POST',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const updateLocation = async (
    locationId: string,
    data: LocationRequest,
    client: (endpoint: string, config: ClientApiOptions) => Promise<LocationResponseModel | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/location/${locationId}`, {
        data: data,
        methodOverride: 'PUT',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const deleteLocation = async (
    id: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<any | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/location/${id}`, {
        methodOverride: 'DELETE',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export const getLocation = async (
    locationId: string,
    client: (endpoint: string, config: ClientApiOptions) => Promise<LocationResponseModel | ValidationProblemDetails>,
) => {
    return client(`${OPERATOR_API_URL}/api/v1/location/${locationId}`, {
        methodOverride: 'GET',
    })
        .then((rsp) => rsp)
        .catch((rsp) => rsp);
};

export interface ProblemDetails {
    $type: string;
    type: string | null;
    title: string | null;
    status: number | null;
    detail: string | null;
    instance: string | null;
}

export interface ValidationProblemDetails extends ProblemDetails {
    readonly errors: { [key: string]: string[] } | null;
}
