import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { AppProviders } from './context';
import { getAppInsights } from './core/telemetry-service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
function TrackWebVitals({ id, name, value }: { id: string; name: string; value: any }) {
    const appInsights = getAppInsights();
    appInsights.trackTrace(
        {
            message: `TrackWebVitals`,
            severityLevel: SeverityLevel.Information,
        },
        {
            Application: process.env.REACT_APP_APPLICATION,
            Environment: process.env.REACT_APP_ENVIRONMENT,
            Name: name,
            Id: id,
            data: value,
        },
    );
}

root.render(
    <React.StrictMode>
        <AppProviders>
            <App />
        </AppProviders>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(TrackWebVitals);
