import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DataTable from 'components/table';
import { ColumnActionType, NotificationType } from 'core/types';
import { useClient } from 'core/api-client';
import Spin from 'components/Spin';
import { useNotifications } from 'context/notification-context';

import { SaveButton } from 'components/buttons';

// columns
import EventInfoColumns from './event-details-columns';
import EventSalesSummaryColumns from './event-sales-summary-columns';
import EventBookingSummaryColumns from './event-booking-summary-columns';
import EventResourcesColumns from './event-resources-columns';
import EventResourcesChildColumns from './event-resources-child-columns';

import { useAuth } from 'context/auth-context';
import { hasClaim, keepForNMinutes } from 'core/utils';
import { fetchAuthenticated } from 'fetch/operator/system';
import { useQuery } from 'react-query';
import { getAllEvenSalesData } from 'fetch/reports';
import MultiLevelTable from 'components/multi-level-table';

function EventLiveSales() {
    let { companyId, eventId, eventVenueResourceId } = useParams();
    const client = useClient();
    const { user } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState<any[]>();
    const [salesSummary, setSalesSummary] = useState<any[]>();
    const [bookingSummary, setBookingSummary] = useState<any[]>();
    const [eventResources, setEventResources] = useState<any[] | undefined>();
    const [eventResourcesChildData, setEventResourcesChildData] = useState<any[]>();
    // Load the claims.
    const { data: claims, isLoading: isFetching } = useQuery(
        ['operator-claims', user?.userId!!],
        () => {
            return fetchAuthenticated(client);
        },
        keepForNMinutes(30),
    );

    useEffect(() => {
        if (claims?.claims) {
            const hasReportAccess = hasClaim(claims?.claims, 'operator.access.report', ['full']);
            const manageCompanyReportClaim = claims?.claims?.find((claim) => claim?.type === 'manage.company.report');
            if (!hasReportAccess && !manageCompanyReportClaim) {
                navigate('/');
            } else {
                if (
                    !hasReportAccess &&
                    manageCompanyReportClaim?.value &&
                    manageCompanyReportClaim?.value !== companyId
                ) {
                    navigate('/');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    const handleTableAction = (row: any, action: ColumnActionType) => {
        switch (action) {
            case ColumnActionType.Edit:
                if (!eventResources) return;
                let dataCopy = [...eventResources];
                if (row?.showChildTable) {
                    dataCopy[row?.index].showChildTable = false;
                } else {
                    dataCopy[row?.index].showChildTable = true;
                }
                setEventResources(dataCopy);
                break;
        }
    };

    async function getEventLiveSalesData() {
        setIsLoading(true);
        try {
            const salesData = await getAllEvenSalesData(companyId as string, eventId!!, eventVenueResourceId!!, client);
            if (salesData) {
                setIsLoading(false);
                if (salesData === undefined || typeof salesData !== 'object' || salesData === null) {
                    if (!isLoading) {
                        addNotification({
                            description: t('pages.company_reports.messages.sales_list_error_description'),
                            title: t('pages.company_reports.messages.error_title'),
                            style: NotificationType.ErrrorSumple,
                            timeoutInMilliseconds: 3000,
                        });
                    }
                } else {
                    const eventInfoData: any[] = [
                        {
                            locationName: salesData?.companyEventLiveSales?.locationName,
                            eventName: salesData?.companyEventLiveSales?.eventName,
                            displayName: salesData?.companyEventLiveSales?.eventName,
                            eventDescription: salesData?.companyEventLiveSales?.eventDescription,
                            totalCapacity: salesData?.companyEventLiveSales?.totalCapacity,
                            totalSold: salesData?.companyEventLiveSales?.totalSold,
                        },
                    ];
                    setEventInfo(eventInfoData);

                    const salesSummary: any[] = [
                        {
                            today: salesData?.companyEventLiveSales?.salesSummary?.today,
                            thisMonth: salesData?.companyEventLiveSales?.salesSummary?.thisMonth,
                            thisWeek: salesData?.companyEventLiveSales?.salesSummary?.thisWeek,
                        },
                    ];
                    setSalesSummary(salesSummary);
                    const bookingSummary: any[] = salesData?.companyEventLiveSales?.salesData?.map((item: any) => {
                        return {
                            bookingDate: item?.bookingDate,
                            customerEventBookingId: item?.customerEventBookingId,
                            discount: item?.discount,
                            email: item?.email,
                            eventVenueResourceBookingSlotId: item?.eventVenueResourceBookingSlotId,
                            firstName: item?.firstName,
                            lastName: item?.lastName,
                            partySize: item?.partySize,
                            phoneNumber: item?.phoneNumber,
                            total: item?.total,
                            totalWithDiscountApplied: item?.totalWithDiscountApplied,
                        };
                    });
                    setBookingSummary(bookingSummary);

                    const eventResources: any[] = salesData?.companyEventLiveSales?.resources?.map(
                        (item: any, index: number) => {
                            return {
                                capacity: item?.capacity,
                                maxSize: item?.maxSize,
                                minSize: item?.minSize,
                                resourceName: item?.resourceName,
                                totalResources: item?.totalResources,
                                showChildTable: false,
                                index: index,
                            };
                        },
                    );
                    setEventResources(eventResources);
                    const eventResourcesChildData: any[] = salesData?.companyEventLiveSales?.resources?.map(
                        (item: any) => {
                            return item?.bookingResources?.map((item: any) => {
                                return {
                                    bookingDate: item?.bookingDate,
                                    bookingSize: item?.bookingSize,
                                    bookingStatus: item?.bookingStatus,
                                    bookingTotal: item?.bookingTotal,
                                };
                            });
                        },
                    );
                    setEventResourcesChildData(eventResourcesChildData);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    }

    useEffect(() => {
        try {
            getEventLiveSalesData();
        } catch (error) {
            console.log('error:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 1); // last 1 year
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 2); // next 2 year

    return (
        <Spin spinning={isLoading || isFetching}>
            <div style={{ width: 100 }}>
                <SaveButton
                    processing={false}
                    isDirty={true}
                    formTarget={''}
                    onClick={() => navigate(`/companies/${companyId}/reports`, { state: 'fromSales' })}
                    buttonText={t('buttons.go_back')}
                />
            </div>
            {/* Event Info Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_Info')}</h1>
            <DataTable<any>
                data={eventInfo || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnTranslationPrefix={'pages.company_reports.table.headers'}
                columns={EventInfoColumns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.company_reports.table.empty_event_info'}
            />
            {/* Event Sales Summary Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_summary')}</h1>
            <DataTable<any>
                data={salesSummary || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnTranslationPrefix={'pages.company_reports.table.headers'}
                columns={EventSalesSummaryColumns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.company_reports.table.empty_event_summary'}
            />
            {/* Event Resources Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_resources')}</h1>
            <MultiLevelTable<any>
                data={eventResources || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                selectSingleAction={handleTableAction}
                columnTranslationPrefix={'pages.company_reports.table.headers'}
                columns={EventResourcesColumns}
                canExpand={false}
                renderSubData={() => (
                    <DataTable<any>
                        data={eventResourcesChildData?.flat() || []}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        columnTranslationPrefix={'pages.company_reports.table.headers'}
                        columns={EventResourcesChildColumns}
                        canExpand={false}
                        canGlobalFilter={false}
                        enableRowSelection={false}
                        noDataMessage={'pages.company_reports.table.empty_event_resources'}
                    />
                )}
                canGlobalFilter={false}
                enableRowSelection={false}
                noDataMessage={'pages.company_reports.table.empty_event_resources'}
            />
            {/* Event Booking Summary Section */}
            <h1 className='font-semibold mt-8'>{t('pages.company_reports.event_booking_summary')}</h1>
            <DataTable<any>
                data={bookingSummary || []}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnTranslationPrefix={'pages.company_reports.table.headers'}
                columns={EventBookingSummaryColumns}
                canExpand={false}
                canGlobalFilter={true}
                enableRowSelection={true}
                noDataMessage={'pages.company_reports.table.empty_event_summary'}
            />
        </Spin>
    );
}

export default EventLiveSales;
