/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from '@headlessui/react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { classNames, getClaims, hasClaim, hasManageClaims } from 'core/utils';
import { AuthenticatedResponseModel } from 'api/operator-api-types';
import { useSelectedCompany } from 'context/company-context';

interface InputProperties {
    claims: AuthenticatedResponseModel | undefined;
}

function Company(props: InputProperties) {
    const { t } = useTranslation();
    const [isShowing, setIsShowing] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
    const { companyIdentifier, setCompanyIdentifier: setGlobalCompanyIdentifier } = useSelectedCompany();
    const [hasManageTicketAccess, setHasManageTicketAccess] = useState<boolean>(false);
    const [hasManageReportAccess, setHasManageReportAccess] = useState<boolean>(false);
    const { claims } = props;
    const hasFullCompanyAccess = hasClaim(claims?.claims, 'operator.access.company', ['full']);
    const hasCompanyAccess = hasClaim(claims?.claims, 'manage.company', ['*']);
    const hasCompanyUserAccess = hasClaim(claims?.claims, 'manage.company.user', ['*']);
    const hasTicketAccess = hasClaim(claims?.claims, 'operator.access.ticket', ['full']);
    const hasReportAccess = hasClaim(claims?.claims, 'operator.access.report', ['full']);

    // Watch for a location change in global state.
    useEffect(() => {
        setSelectedCompanyId(companyIdentifier);
        setGlobalCompanyIdentifier(companyIdentifier);
    }, [companyIdentifier]);

    useEffect(() => {
        const manageCompanyClaims = getClaims(props?.claims?.claims, 'manage.company');
        const defaultCompanyId = parseInt(manageCompanyClaims[0]?.value ?? '0', 10);
        const manageEventTicketClaim = claims?.claims?.find((claim) => claim?.type === 'manage.event.ticket');
        const manageCompanyReportClaim = claims?.claims?.find((claim) => claim?.type === 'manage.company.report');
        if (manageCompanyClaims.length === 1) {
            setSelectedCompanyId(parseInt(manageCompanyClaims[0].value ?? '0', 10));
        }
        if (
            hasTicketAccess ||
            (manageEventTicketClaim?.value &&
                parseInt(manageEventTicketClaim?.value) === (selectedCompanyId || defaultCompanyId))
        ) {
            setHasManageTicketAccess(true);
        }
        if (
            hasReportAccess ||
            (manageCompanyReportClaim?.value &&
                parseInt(manageCompanyReportClaim?.value) === (selectedCompanyId || defaultCompanyId))
        ) {
            setHasManageReportAccess(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claims]);

    if (!hasCompanyAccess && !hasFullCompanyAccess) {
        return <></>;
    }

    if (selectedCompanyId <= 0) {
        return <></>;
    }

    return (
        <>
            <button
                onClick={() => setIsShowing((isShowing) => !isShowing)}
                className='text-gray-300 hover:bg-gray-700 hover:text-white group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-semibold rounded-md focus:outline-none'>
                <BriefcaseIcon
                    className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden='true'
                />
                <span className='flex-1'>{t('menu.company', 'MISSING')}</span>
                <svg
                    className={classNames(
                        isShowing ? 'text-gray-600 rotate-90' : 'text-gray-300',
                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-500',
                    )}
                    viewBox='0 0 20 20'
                    aria-hidden='true'>
                    <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                </svg>
            </button>
            <Transition
                show={isShowing}
                enter='transition-opacity duration-100'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                {hasFullCompanyAccess && (
                    <NavLink
                        key='menu.company_all'
                        to={`/companies`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.company_all', 'MISSING')}
                    </NavLink>
                )}
                {hasManageClaims(claims, selectedCompanyId) ? (
                    <NavLink
                        key='menu.brands'
                        to={`/companies/${selectedCompanyId}/brands`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.brands', 'MISSING')}
                    </NavLink>
                ) : null}
                {hasManageClaims(claims, selectedCompanyId) ? (
                    <NavLink
                        key='menu.company_events'
                        to={`/companies/${selectedCompanyId}/events`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.company_events', 'MISSING')}
                    </NavLink>
                ) : null}
                {(hasCompanyUserAccess || hasFullCompanyAccess) && (
                    <NavLink
                        key='menu.company_users'
                        to={`/companies/${selectedCompanyId}/users`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.company_users', 'MISSING')}
                    </NavLink>
                )}
                {hasManageClaims(claims, selectedCompanyId) ? (
                    <NavLink
                        key='menu.company_menu'
                        to={`/companies/${selectedCompanyId}/menus`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.company_menu', 'MISSING')}
                    </NavLink>
                ) : null}
                {(hasManageTicketAccess || hasFullCompanyAccess) && (
                    <NavLink
                        key='menu.tickets'
                        to={`/companies/${selectedCompanyId}/tickets`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.tickets', 'MISSING')}
                    </NavLink>
                )}
                {hasManageReportAccess && (
                    <NavLink
                        key='menu.reports'
                        to={`/companies/${selectedCompanyId}/reports`}
                        className={({ isActive }) =>
                            classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center pl-11 px-2 py-2 text-sm font-semibold focus:outline-none rounded-md',
                            )
                        }
                        end>
                        {t('menu.reports', 'MISSING')}
                    </NavLink>
                )}
            </Transition>
        </>
    );
}

export default Company;
