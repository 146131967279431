import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnActionType, ColumnGeneratorInputProps } from '../../../core/types';
import { EditCss } from '../../../components/table';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.displayName, {
            id: 'displayName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_tickets.table.headers.ticket_holder_name', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        {props.row.original?.isUsed ? (
                            <p>{t('pages.event_tickets.messages.already_use_ticket')}</p>
                        ) : (
                            <button
                                type='button'
                                onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                                className={EditCss()}>
                                {t('buttons.use_ticket')}
                            </button>
                        )}
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
