import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnGeneratorInputProps } from 'core/types';
import { formattedDate } from 'core/utils';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    let columnDefs = [
        columnHelper.accessor((row) => row.firstName, {
            id: 'firstName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.first_name', ''),
        }),

        columnHelper.accessor((row) => row.partySize, {
            id: 'partySize',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.party_size', ''),
        }),

        columnHelper.accessor((row) => row.total, {
            id: 'total',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total', ''),
        }),
        columnHelper.accessor((row) => row.discount, {
            id: 'discount',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.discount', ''),
        }),
        columnHelper.accessor((row) => row.totalWithDiscountApplied, {
            id: 'totalWithDiscountApplied',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_with_discount', ''),
        }),
        columnHelper.accessor((row) => row.bookingDate, {
            id: 'bookingDate',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? formattedDate(new Date(info.getValue()?.toString()!!)).longDate : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.booking_date_time', ''),
        }),
    ];

    return columnDefs;
}
