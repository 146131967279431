import { HelpContextTarget } from 'core/types';
import ContextHelp from 'components/context-help';
import { ResetButton, SaveButton } from 'components/buttons';

export interface InputProps {
    processing: boolean;
    isDirty: boolean;
    handleReset: () => void;
    contextTitle: string;
    contextDescription: string;
    contextTarget: HelpContextTarget;
    formTarget: string;
    buttonText?: string;
}

export default function FormActionPanel(props: InputProps) {
    const { processing, isDirty, handleReset, contextTitle, contextDescription, contextTarget, formTarget } = props;
    return (
        <>
            <div className='sticky z-10 top-16 md:top-1 right-10 w-full flex justify-end'>
                <ResetButton processing={processing} isDirty={isDirty} handleReset={handleReset} />
                <span className='relative inline-flex mr-2'>
                    {/* TODO: add not allowed style when buttons are disabled */}
                    <SaveButton
                        processing={processing}
                        isDirty={isDirty}
                        formTarget={formTarget}
                        buttonText={props.buttonText}
                    />
                    {isDirty && !processing && (
                        <span className='flex absolute h-3 w-3 top-0 right-0 -mt-1 mr-1'>
                            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
                            <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500'></span>
                        </span>
                    )}
                </span>
                <ContextHelp title={contextTitle} description={contextDescription} target={contextTarget} />
            </div>
        </>
    );
}
