import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnActionType, ColumnGeneratorInputProps } from 'core/types';
import { EditCss } from 'components/table';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.resourceName, {
            id: 'resourceName',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.resource_name', ''),
        }),
        columnHelper.accessor((row) => row.capacity, {
            id: 'capacity',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.capacity', ''),
        }),

        columnHelper.accessor((row) => row.totalResources, {
            id: 'totalResources',
            cell: (info) => (
                <div className='truncate-cell'>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.total_resources', ''),
        }),

        columnHelper.accessor((row) => row.minSize, {
            id: 'minSize',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.min_size', ''),
        }),
        columnHelper.accessor((row) => row.maxSize, {
            id: 'maxSize',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.company_reports.table.headers.max_size', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <button
                        type='button'
                        onClick={() => selectSingleAction(props.row.original, ColumnActionType.Edit, 0)}
                        className={EditCss()}>
                        {props.row.original?.showChildTable ? 'Collapse' : 'Expand'}
                    </button>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
