import { PlusIcon } from '@heroicons/react/20/solid';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { EventVenueResourceAdminDto } from 'api/operator-api-types';
import DataTable, { DeleteCss, IndeterminateCheckbox } from 'components/table';
import { useConfirm } from 'context/confirmation-context';
import { ColumnActionType, ColumnGeneratorInputProps, EventVenueSubSectionTarget } from 'core/types';
import { formatDateTime, formattedDate, timer } from 'core/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';
import { formatDateAndTime } from '../helper';

export interface InputProps {
    data: EventVenueResourceAdminDto[] | null;
    addCallback: (target: EventVenueSubSectionTarget) => void;
    setIsDirty: () => void;
    setResources: Dispatch<SetStateAction<EventVenueResourceAdminDto[]>>;
    setKeepDirty?: any;
}

export default function EventVenueResourceSection(props: InputProps) {
    const { t } = useTranslation();
    const { data: input, addCallback, setResources, setKeepDirty } = props;
    const [data, setData] = useState<EventVenueResourceAdminDto[]>([]);
    const { isConfirmed, setProgress } = useConfirm();

    useEffect(() => {
        if (input !== null) {
            setData(input);
        }
    }, [input]);

    useEffect(() => {
        if (data?.length === 0) {
            setKeepDirty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDelete = (row: EventVenueResourceAdminDto, action: ColumnActionType, index: number) => {
        if (action !== ColumnActionType.Delete) {
            return;
        }

        async function deleteItem() {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: null,
                confirmButton: null,
                cancelButton: null,
            });

            if (confirmed) {
                var newData = [...data];
                newData.splice(index, 1);
                setData((data) => [...newData]);
                setResources(newData);
            }
        }

        deleteItem();
    };

    const handleBulkDelete = (rowIdentifiers: number[], success?: () => void) => {
        const deleteBulkItem = async () => {
            const confirmed = await isConfirmed({
                confirmationTitle: null,
                confirmationDescription: t('generic_bulk_delete_description', { count: rowIdentifiers.length }),
                confirmButton: null,
                cancelButton: null,
            });
            if (confirmed) {
                const process = async function () {
                    // We need to wrap the loop into an async function for this to work
                    for (var i = 0; i < rowIdentifiers.length; i++) {
                        setProgress(i + 1, rowIdentifiers.length);
                        var newData = [...data];
                        newData.splice(rowIdentifiers[i], 1);
                        setData((data) => [...newData]);
                        await timer(100); // then the created Promise can be awaited
                    }
                    if (success) {
                        success();
                    }
                };
                process();
            }
        };

        deleteBulkItem();
    };

    return (
        <>
            <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6'>
                <div className='lg:grid lg:grid-cols-3 lg:gap-6'>
                    <div className='lg:col-span-1'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                            {t('pages.event_venues_detail.resources.title')}
                        </h3>
                        <p className='mt-1 text-sm text-gray-500'>
                            {t('pages.event_venues_detail.resources.description')}
                        </p>
                    </div>
                    <div className='mt-5 space-y-6 lg:col-span-2 lg:mt-0 text-right'>
                        <div>
                            <button
                                type='button'
                                onClick={() => addCallback(EventVenueSubSectionTarget.Resource)}
                                className='inline-flex h-10 items-center rounded-md border border-transparent px-3 py-2 text-sm font-semibold leading-4 text-white shadow-sm bg-gray-700 hover:bg-gray-800'
                                data-cy='open-add-reducer'>
                                <PlusIcon
                                    className='text-white group-hover:text-gray-300 lex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                        <div className='relative'>
                            <DataTable<EventVenueResourceAdminDto>
                                columnTranslationPrefix={'pages.event_venues_detail.resources.table.headers'}
                                columns={VenueColumns}
                                selectSingleAction={handleDelete}
                                showPaging={false}
                                cssWrapperOverride='overflow-x-auto sticky shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'
                                data={data}
                                enableRowSelection={true}
                                selectBulkdDelete={handleBulkDelete}
                                noDataMessage={'pages.event_venues_detail.resources.table.empty'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function VenueColumns(props: ColumnGeneratorInputProps<EventVenueResourceAdminDto>): any {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<EventVenueResourceAdminDto>();

    // unwind props.
    const { selectSingleAction } = props;
    let columnDefs = [];

    columnDefs.push(
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            cell: (info) => (
                <div className='container'>
                    <p className='text-ellipsis'>{info.getValue()}</p>
                </div>
            ),
            header: () => t('pages.event_venues_detail.resources.table.headers.description'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.eventDate, {
            id: 'eventDate',
            cell: (info) => <>{formatDateAndTime(new Date(info.getValue()?.toString()!!)).shortDate}</>,
            header: () => t('pages.event_venues_detail.resources.table.headers.event_date'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.startTime, {
            id: 'startTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.event_venues_detail.resources.table.headers.start_time'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.endTime, {
            id: 'endTime',
            cell: (info) => <>{toTimeString(Number(info.getValue()))}</>,
            header: () => t('pages.event_venues_detail.resources.table.headers.end_time'),
        }),
    );
    columnDefs.push(
        columnHelper.accessor((row) => row.bookingCutOffDateTime, {
            id: 'bookingCutOffDateTime',
            cell: (info) => (
                <>{info.getValue() ? formatDateAndTime(new Date(info.getValue()?.toString()!!)).longDateTime : ''}</>
            ),
            header: () => t('pages.event_venues_detail.resources.field_names.booking_date_time'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.menuOrderingCutOffDateTime, {
            id: 'menuOrderingCutOffDateTime',
            cell: (info) => (
                <>{info.getValue() ? formatDateAndTime(new Date(info.getValue()?.toString()!!)).longDateTime : ''}</>
            ),
            header: () => t('pages.event_venues.table.headers.ordering_date_time', ''),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.eventExternalCode, {
            id: 'eventExternalCode',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.event_external_code'),
        }),
    );
    columnDefs.push(
        columnHelper.accessor((row) => row.bookingFeeTypeId, {
            id: 'bookingFeeTypeId',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.booking_fee_type_id'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.bookingFee, {
            id: 'bookingFee',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.booking_fee'),
        }),
    );
    columnDefs.push(
        columnHelper.accessor((row) => row.minBookingFee, {
            id: 'minBookingFee',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.min_booking_fee'),
        }),
    );

    columnDefs.push(
        columnHelper.accessor((row) => row.maxBookingFee, {
            id: 'maxBookingFee',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.max_booking_fee'),
        }),
    );
    columnDefs.push(
        columnHelper.accessor((row) => row.allowBooking, {
            id: 'allowBooking',
            cell: (info) => (
                <>{info.getValue() === true ? <CheckIcon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}</>
            ),
            header: () => t('pages.event_venues.table.headers.booking', ''),
        }),
    );
    columnDefs.push(
        columnHelper.accessor((row) => row.allowVoucher, {
            id: 'allowVoucher',
            cell: (info) => (
                <>{info.getValue() === true ? <CheckIcon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}</>
            ),
            header: () => t('pages.event_venues_detail.resources.field_names.allow_voucher', 'MISSING'),
        }),
    );

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <button
                        type='button'
                        onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, props.row.index)}
                        className={DeleteCss()}
                        data-cy='delete'>
                        {t('buttons.delete')}
                    </button>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}

export function toTimeString(time: number) {
    var h = Math.floor(time / 60);
    var m = time % 60;
    var hours = h < 10 ? '0' + h : h;
    var minutes = m < 10 ? '0' + m : m;
    if (h === 24) {
        return '00:00:00';
    } else if (h > 24) {
        return `${h - 24}:${minutes}`;
    } else {
        return `${hours}:${minutes}`;
    }
}
