import React from 'react';
import { QueryClient } from 'react-query';
import { authService } from '../core/auth-service';
import { LoginForm, UserContext } from '../core/types';
import { AuthContextType } from '../core/types';
const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
};

// const boostrapData = async (user: UserContext | null, queryClient: QueryClient): Promise<void> => {
//     if (user) {
//         await queryClient.prefetchQuery(["operator-authenticated", user?.userId], () => {
//             return client(`${OPERATOR_API_URL}/api/v1/system/authenticated`, {
//                 token: user.accessToken,
//             }).then(rsp => rsp)
//         }, keepForNMinutes(30));
//     }
// }

const AuthContext = React.createContext<AuthContextType>(null!);
AuthContext.displayName = 'AuthContext';

const AuthProvider = ({ queryClient, children }: { queryClient: QueryClient; children: React.ReactNode }) => {
    const loggedUser = React.useRef<UserContext | null>(authService.getUserContext());

    const [user, setUserContext] = React.useState<UserContext | null>(loggedUser.current);

    const login = React.useCallback(
        (data: LoginForm, success: VoidFunction, failed: VoidFunction) => {
            authService
                .login(data)
                .then((userContext) => {
                    if (userContext) {
                        setUserContext(userContext);
                        success();
                    } else {
                        failed();
                    }
                })
                .catch(() => failed());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setUserContext],
    );

    const logout = React.useCallback(
        (callback: VoidFunction) => {
            authService.logout();
            setUserContext(null);
            callback();
        },
        [setUserContext],
    );

    const value = React.useMemo(() => ({ user, login, logout }), [user, login, logout]);

    // if (bootstrap.status === "loading" || bootstrap.status === "not-requested") {
    //     return <AppLoading />
    // }

    // if (bootstrap.status === "error") {
    //     return <AppError />
    // }

    // if (bootstrap.status === "success") {
    //     return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    // }

    // throw new Error(`Unhandled status: ${bootstrap.status}`)

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext, useAuth };
