import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ColumnActionType, ColumnGeneratorInputProps } from '../../core/types';
import { DeleteCss, EditCss } from '../../components/table';
import { formattedDate } from '../../core/utils';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateAndTime } from './helper';

export default function Columns(props: ColumnGeneratorInputProps<any>): any {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { companyId, identifier } = useParams();

    const columnHelper = createColumnHelper<any>();

    const { selectSingleAction } = props;
    let columnDefs = [
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue()}
                    </span>
                </div>
            ),
            header: () => t('pages.event_venues.table.headers.description', ''),
        }),
        columnHelper.accessor((row) => row.eventDate, {
            id: 'eventDate',
            cell: (info) => <>{formatDateAndTime(new Date(info.getValue()?.toString()!!)).shortDate}</>,
            header: () => t('pages.event_venues.table.headers.event_date', ''),
        }),
        columnHelper.accessor((row) => row.startTime, {
            id: 'startTime',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.start_time', ''),
        }),
        columnHelper.accessor((row) => row.endTime, {
            id: 'endTime',
            cell: (info) => <>{info.getValue()}</>,
            header: () => t('pages.event_venues.table.headers.end_time', ''),
        }),
        columnHelper.accessor((row) => row.bookingCutOffDateTime, {
            id: 'bookingCutOffDateTime',
            cell: (info) => (
                <div>
                    <span title={info?.getValue() || ''} className=' hover:overflow-visible '>
                        {info.getValue() ? formatDateAndTime(new Date(info.getValue()?.toString()!!)).longDateTime : ''}
                    </span>
                </div>
            ),
            header: () => t('pages.event_venues.table.headers.booking_date_time', ''),
        }),
        columnHelper.accessor((row) => row.menuOrderingCutOffDateTime, {
            id: 'menuOrderingCutOffDateTime',
            cell: (info) => (
                <>{info.getValue() ? formatDateAndTime(new Date(info.getValue()?.toString()!!)).longDateTime : ''}</>
            ),
            header: () => t('pages.event_venues.table.headers.food_ordering_cutoff_date_time', ''),
        }),
        columnHelper.accessor((row) => row.eventExternalCode, {
            id: 'eventExternalCode',
            cell: (info) => <>{info.getValue() ? info.getValue() : ''}</>,
            header: () => t('pages.event_venues.table.headers.event_external_code', ''),
        }),
        columnHelper.accessor((row) => row.allowBooking, {
            id: 'allowBooking',
            cell: (info) => (
                <>{info.getValue() === true ? <CheckIcon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}</>
            ),
            header: () => t('pages.event_venues.table.headers.booking', ''),
        }),
        columnHelper.accessor((row) => row.allowVoucher, {
            id: 'allowVoucher',
            cell: (info) => (
                <>{info.getValue() === true ? <CheckIcon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}</>
            ),
            header: () => t('pages.event_venues.table.headers.voucher', ''),
        }),
    ];

    if (selectSingleAction !== undefined) {
        columnDefs.push(
            columnHelper.display({
                id: 'actions',
                cell: (props) => (
                    <>
                        <button
                            type='button'
                            onClick={() =>
                                navigate(
                                    `/companies/${companyId}/eventvenues/${identifier}/eventdetails/${props.row.original.eventVenueResourceId}/configurations`,
                                )
                            }
                            // onClick={() => console.log('props.row.original', props.row.original)}
                            className={EditCss()}>
                            {t('buttons.edit')}
                        </button>
                        <button
                            type='button'
                            onClick={() => selectSingleAction(props.row.original, ColumnActionType.Delete, 1)}
                            className={DeleteCss()}>
                            {t('buttons.delete')}
                        </button>
                    </>
                ),
                enableHiding: false,
                enableSorting: false,
                enableMultiSort: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
            }),
        );
    }

    return columnDefs;
}
